import {Line, Text} from '@react-three/drei';
import {Position, resizePositionsMinMaxValues} from 'libs/models/position';
import {AxesDistanceLines, DistanceLine} from 'libs/models/room';
import {cmToThree, pointThreeFromPointCM} from 'libs/view';
import React from 'react';
import {useRoomBounds} from 'state/room/roomHooks';
import * as THREE from 'three';
import {primaryDark} from 'app/config/configuratorColors';

export type DistanceToPowerOutletProps = {
    distanceLines: AxesDistanceLines | null;
    position: Position | null;
};

export const DistanceToPowerOutlet = ({distanceLines, position}: DistanceToPowerOutletProps): JSX.Element => {
    const {roomBounds} = useRoomBounds();
    const boundsWithOffset = resizePositionsMinMaxValues(roomBounds, 120)

    if (distanceLines && position) {
        // noinspection RequiredAttributes
        return (
            <>
                <Text
                    color={primaryDark}
                    fontSize={0.2}
                    rotation={[THREE.MathUtils.degToRad(-90), 0, 0]}
                    position={[cmToThree(boundsWithOffset.minX), 0, cmToThree(position.y)]}
                >
                    {distanceLines.left.length} cm
                </Text>
                <DistanceAsLine distanceLine={distanceLines.left}/>

                <Text
                    color={primaryDark}
                    fontSize={0.2}
                    rotation={[THREE.MathUtils.degToRad(-90), 0, 0]}
                    position={[cmToThree(boundsWithOffset.maxX), 0, cmToThree(position.y)]}
                >
                    {distanceLines.right.length} cm
                </Text>
                <DistanceAsLine distanceLine={distanceLines.right}/>

                <Text
                    color={primaryDark}
                    fontSize={0.2}
                    rotation={[THREE.MathUtils.degToRad(-90), 0, 0]}
                    position={[cmToThree(position.x), 0, cmToThree(boundsWithOffset.minY)]}
                >
                    {distanceLines.top.length} cm
                </Text>
                <DistanceAsLine distanceLine={distanceLines.top}/>

                <Text
                    color={primaryDark}
                    fontSize={0.2}
                    rotation={[THREE.MathUtils.degToRad(-90), 0, 0]}
                    position={[cmToThree(position.x), 0, cmToThree(boundsWithOffset.maxY)]}
                >
                    {distanceLines.bottom.length} cm
                </Text>
                <DistanceAsLine distanceLine={distanceLines.bottom}/>
            </>
        );
    }

    return <></>;
};

export type DistanceAsLineProps = {
    distanceLine: DistanceLine | null;
};

export const DistanceAsLine = ({distanceLine}: DistanceAsLineProps): JSX.Element => {
    if (distanceLine) {
        const pointFrom = pointThreeFromPointCM(distanceLine.from)
        const pointTo = pointThreeFromPointCM(distanceLine.to)

        return (
            <>
                <Line key={distanceLine.id}
                      color={'#723782'}
                      points={[
                          [pointFrom.x, 0, pointFrom.y],
                          [pointTo.x, 0, pointTo.y]
                      ]}
                      lineWidth={distanceLine.isHorizontal ? 0.6 : 0.5}
                />
            </>
        );
    }

    return <></>;
};
