import DOMPurify from 'dompurify';

export type RawHtmlProps = { content: string | null }

export const RawHtml = ({content}: RawHtmlProps): JSX.Element | null => {
    if (!content) {
        return null;
    }
    return <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}} />

}
