import React, {useState} from 'react';
import {tw} from 'twind/css';
import {Button} from '../buttons/Button';
import {styles} from 'components/common/modal/styles/styles';

export type ModalProps = React.ComponentProps<'html'> & {
    buttonTitle?: string,
    modalTitle?: string,
    closeTitle?: string,
    icon?: React.ReactChild,
}


export const Modal = ({children, buttonTitle, modalTitle, icon, closeTitle}: ModalProps): JSX.Element => {
    const [isShown, setIsShown] = useState(false)
    return (
        <div className={tw(styles)}>
            <Button
                onClick={() => setIsShown(true)}
                className={'modal-open'}
            >
                {icon} {buttonTitle}
            </Button>
            <div className={isShown ? 'modal active' : 'modal'}>
                <div className={'modal-title'}>
                    <h3>
                        {modalTitle}
                    </h3>
                    <button onClick={() => setIsShown(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             height={24}
                             width={24}
                             viewBox="0 0 24 24"
                             fill="#000000"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                            />
                        </svg>
                    </button>
                </div>
                <div className={'modal-body'}>
                    {children}
                    <div className={'modal-close'}>
                        <Button
                            variant={'secondary'}
                            onClick={() => setIsShown(false)}
                        >
                            {closeTitle}
                        </Button>
                    </div>
                </div>
            </div>
            <div
                className={'modal-bg'}
                onClick={() => setIsShown(false)}
            >&nbsp;</div>
        </div>
    )
}


