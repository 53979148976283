import {useAppSelector} from 'app/store/hooks';
import {ResetOrderAction} from 'components/actions/order/ResetOrderAction';
import {SaveOrderAction} from 'components/actions/order/SaveOrderAction';
import {selectStepNeedsResetPageOrder} from 'state/step/selectStepNeedReset';


export const OrderActions = (): JSX.Element => {
    const needsReset = useAppSelector(selectStepNeedsResetPageOrder);

    if (needsReset) {
        return (
            <ResetOrderAction/>
        );
    }
    return (
        <SaveOrderAction/>
    );
}
