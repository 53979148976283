import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Knot} from 'libs/models/knot';
import {getValidatedNumberOfSpotlightsOrDefault, Spotlight} from 'libs/models/light/light';
import {moveSpotlightTo} from 'libs/models/light/moveLight';
import {Room} from 'libs/models/room';
import {lightNotifications} from 'libs/notification/lightNotifications';
import {NotificationProvider} from 'libs/notification/notificationProvider';
import {PositionThree} from 'libs/view';
import {deleteEntity, loadEntity, saveEntity} from 'state/entity/entityApi';
import {stepResetPageLight} from 'state/step/stepReset';

const addNotifyItem = NotificationProvider().addNotifyItem;

export interface LightState {
    hasSpotlightAmountChanged: boolean;
    spotlightAmount: number;
    spotlights: Spotlight[];
    errorId: string | null;
}

export const initialLightState: LightState = {
    hasSpotlightAmountChanged: false,
    spotlightAmount: 0,
    spotlights: [],
    errorId: null,
};

const lightSlice = createSlice({
    name: 'light',
    initialState: initialLightState,
    reducers: {
        setSpotlightsTo: (state, {payload}: PayloadAction<{ spotlights: Spotlight[] }>) => {
            const currentSpotlights = state.spotlights;
            const updatedSpotlights = payload.spotlights;
            if (currentSpotlights !== updatedSpotlights) {
                state.spotlights = [];
                state.spotlights = updatedSpotlights;
                state.hasSpotlightAmountChanged = false;
            }
        },
        setSpotlightPositionTo: (state, {payload}: PayloadAction<{ spotlight: Spotlight, position: PositionThree, knots: Knot[], room: Room }>) => {
            const currentSpotlights = state.spotlights;
            const lightValidation = moveSpotlightTo(currentSpotlights, payload.spotlight, payload.position, payload.knots, payload.room);

            if (!lightValidation.isValid) {
                addNotifyItem(lightNotifications(lightValidation.type));
            }

            if (currentSpotlights !== lightValidation.spotlights && lightValidation.isValid) {
                state.spotlights = [];
                state.spotlights = lightValidation.spotlights;
            }
        },
        setSpotlightAmountTo: (state, {payload}: PayloadAction<{ spotlightAmount: number, maxNumberOfSpotlights: number }>) => {
            const currentSpotlightAmount = state.spotlightAmount;
            const numberOfSpotlightsValidation = getValidatedNumberOfSpotlightsOrDefault(payload.spotlightAmount, payload.maxNumberOfSpotlights);

            if (numberOfSpotlightsValidation.isValid && numberOfSpotlightsValidation.type !== null) {
                addNotifyItem(lightNotifications(numberOfSpotlightsValidation.type));
                state.errorId = 'numberOfSpotlightsValidation';
            }

            if (currentSpotlightAmount !== numberOfSpotlightsValidation.numberOfSpotlights) {
                state.spotlightAmount = numberOfSpotlightsValidation.numberOfSpotlights;
                state.hasSpotlightAmountChanged = true;
            }
        },
        resetLightErrorId: (state) => {
            state.errorId = null;
        }
    },
    extraReducers: (builder) => {

        // >>> Reset
        builder.addCase(stepResetPageLight.fulfilled, () => {
            return initialLightState;
        });

        // >>> Load
        builder.addCase(loadEntity.fulfilled, (state, {payload}) => {
            state.spotlightAmount = payload.data.light.spotlightAmount;
            state.spotlights = payload.data.light.spotlights;
        });
        builder.addCase(loadEntity.rejected, () => {
            return initialLightState;
        });

        // >>> Save
        builder.addCase(saveEntity.fulfilled, (state, {payload}) => {
            state.spotlightAmount = payload.data.light.spotlightAmount;
            state.spotlights = payload.data.light.spotlights;
        });
        builder.addCase(saveEntity.rejected, () => {
            return initialLightState;
        });

        // >>> Delete
        builder.addCase(deleteEntity.fulfilled, () => {
            return initialLightState;
        });
        builder.addCase(deleteEntity.rejected, () => {
            return initialLightState;
        });
    },
});

export const {setSpotlightPositionTo, setSpotlightAmountTo, setSpotlightsTo, resetLightErrorId} = lightSlice.actions;
export const lightReducer = lightSlice.reducer;
