import {knotMinimumDistanceToKnot, trackMinimumDistanceToWall} from 'app/config/track';
import {Knot, KnotId} from 'libs/models/knot';
import {IndexedLine, Line, lineDirection, lineOrientation} from 'libs/models/line';
import {meassurePositionsMinMaxValues, Position} from 'libs/models/position';
import {doIntersect, Room} from 'libs/models/room';
import {validatePositionAIsInTheOffsetOfLine} from 'libs/models/validation/lineValidation';

/**
 * Prüft, ob die neu gezeichnete Linie innerhalb des gegebenen Raumes ist
 * @param walls
 * @param line
 */
export function doesTrackIntersectWithInline(walls: IndexedLine[], line: Line<Position>): boolean {
    return walls.some((wall) => {
        return doIntersect(line, wall);
    });
}

/**
 * prüft, ob die neu gezeichnete Linie mit einem anderen Knoten intersectet
 * @param knots
 * @param line
 * @param childKnotId
 * @param parentKnotId
 */
export function doesTrackIntersectWithKnot(knots: Knot[], line: Line<Position>, childKnotId: KnotId, parentKnotId: KnotId): boolean {
    return knots.some((knot) => {
        if (knot.id !== childKnotId && knot.id !== parentKnotId) {
            return validatePositionAIsInTheOffsetOfLine(line, knot.position, trackMinimumDistanceToWall - 1);
        }
        return false;
    });
}

/**
 * Generiert Linien aus den Knoten der Tracks
 * @param start
 * @param end
 */
export function generateLineFromPoints(start: Position, end: Position): Line<Position> {
    return {
        from: {
            x: start.x,
            y: start.y
        },
        to: {
            x: end.x,
            y: end.y,
        }
    };
}

/**
 * Prüft, ob die Linie innerhalb des Raumes ist, wenn man die Länge in der Sidebar verändert
 * @param room
 * @param line
 * @param newLength
 */
export function getValidatedTrackLengthOrDefault(room: Room, line: IndexedLine, newLength: number): number {
    const minMaxValues = meassurePositionsMinMaxValues(room.corners);

    const orientation = lineOrientation(line);
    const direction = lineDirection(line);

    let maxLength = 0;

    if (orientation.isHorizontal) {
        if (direction === -1) {
            maxLength = Math.abs((minMaxValues.minX + trackMinimumDistanceToWall) - line.from.x);
        }

        if (direction === 1) {
            maxLength = Math.abs((minMaxValues.maxX - trackMinimumDistanceToWall) - line.from.x);
        }
    }

    if (orientation.isVertical) {
        if (direction === -1) {
            maxLength = Math.abs((minMaxValues.minY + trackMinimumDistanceToWall) - line.from.y);
        }

        if (direction === 1) {
            maxLength = Math.abs((minMaxValues.maxY - trackMinimumDistanceToWall) - line.from.y);
        }
    }

    return (newLength > maxLength) ? maxLength : newLength < knotMinimumDistanceToKnot ? knotMinimumDistanceToKnot : newLength;
}
