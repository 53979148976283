import {createSlice} from '@reduxjs/toolkit';
import {deleteEntity, loadEntity, saveEntity} from 'state/entity/entityApi';

type Operation = 'idle' | 'load' | 'save' | 'delete';


export type EntityState = {
    fetching: boolean;
    operation: Operation;
    entityId: string | null;
};

export const initialEntityState: EntityState = {
    fetching: false,
    operation: 'idle',
    entityId: null,
};


const slice = createSlice({
    name: 'entity',
    initialState: initialEntityState,
    reducers: {},
    extraReducers: (builder) => {

        // >>> load
        builder.addCase(loadEntity.pending, (state) => {
            state.fetching = true;
            state.operation = 'delete';
        });
        builder.addCase(loadEntity.fulfilled, (state, {payload}) => {
            state.fetching = false;
            state.operation = 'idle';
            state.entityId = payload.entityId
        });
        builder.addCase(loadEntity.rejected, () => {
            return initialEntityState;
        });

        // >>> save
        builder.addCase(saveEntity.pending, (state) => {
            state.operation = 'save';
            state.fetching = true;
        });
        builder.addCase(saveEntity.fulfilled, (state, {payload}) => {
            state.fetching = false;
            state.operation = 'idle';
            state.entityId = payload.entityId
        });
        builder.addCase(saveEntity.rejected, () => {
            return initialEntityState;
        });

        // >>> delete
        builder.addCase(deleteEntity.pending, (state) => {
            state.fetching = true;
            state.operation = 'delete';
        });
        builder.addCase(deleteEntity.fulfilled, () => {
            return initialEntityState;
        });
        builder.addCase(deleteEntity.rejected, () => {
            return initialEntityState;
        });
    }
});


export const entityReducer = slice.reducer;
