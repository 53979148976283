import {PayloadAction} from '@reduxjs/toolkit';
import {useAppSelector} from 'app/store/hooks';
import {Location} from 'redux-first-router';
import {Match} from 'redux-first-router-link';
import {selectEntityId} from 'state/entity/entitySelectors';
import {selectLocationType} from 'state/location/locationSelectors';
import {
    pageEditLight,
    pageEditOrder,
    pageEditOutlet,
    pageEditRoom,
    pageEditTrack,
    pageSelectType
} from 'state/location/pageRoutes';
import {
    checkStepAvailabiltyForPageLight,
    checkStepAvailabiltyForPageOrder,
    checkStepAvailabiltyForPageOutlet,
    checkStepAvailabiltyForPageRoom,
    checkStepAvailabiltyForPageTrack,
    checkStepAvailabiltyForPageType
} from 'state/step/checkStepAvailability';
import {EntityIdPayload} from 'state/location/routing/editPageRouting';


export interface StepNavigationInfo<T> {
    route: PayloadAction<T> | null;
    showInNavigation: boolean;
    isActive?: (match: Match<never>, location: Location) => boolean;
}

/**
 * Hook um die Infos für den Schritt "Raumform" abzufragen.
 */
export function useStepNavigationForPageType(): StepNavigationInfo<void> {
    const currentType = useAppSelector(selectLocationType);

    return {
        route: pageSelectType.route(),
        showInNavigation: checkStepAvailabiltyForPageType(currentType),
        isActive: (match, location) => checkStepAvailabiltyForPageType(location.type)
    };
}

/**
 * Hook um die Infos für den Schritt "Grundriss" abzufragen.
 */

export function useStepNavigationForPageRoom(): StepNavigationInfo<EntityIdPayload> {
    const entityId = useAppSelector(selectEntityId);
    const currentType = useAppSelector(selectLocationType);

    return {
        route: entityId ? pageEditRoom.route({entityId: entityId}) : null,
        showInNavigation: checkStepAvailabiltyForPageRoom(currentType),
        isActive: (match, location) => checkStepAvailabiltyForPageRoom(location.type)
    };
}

/**
 * Hook um die Infos für den Schritt "Stromauslass" abzufragen.
 */
export function useStepNavigationForPageOutlet(): StepNavigationInfo<EntityIdPayload> {
    const entityId = useAppSelector(selectEntityId);
    const currentType = useAppSelector(selectLocationType);

    return {
        route: entityId ? pageEditOutlet.route({entityId: entityId}) : null,
        showInNavigation: checkStepAvailabiltyForPageOutlet(currentType),
        isActive: (match, location) => checkStepAvailabiltyForPageOutlet(location.type)
    };
}

/**
 * Hook um die Infos für den Schritt "Lichtleisten" abzufragen.
 */
export function useStepNavigationForPageTrack(): StepNavigationInfo<EntityIdPayload> {
    const entityId = useAppSelector(selectEntityId);
    const currentType = useAppSelector(selectLocationType);

    return {
        route: entityId ? pageEditTrack.route({entityId: entityId}) : null,
        showInNavigation: checkStepAvailabiltyForPageTrack(currentType),
        isActive: (match, location) => checkStepAvailabiltyForPageTrack(location.type)
    };
}


/**
 * Hook um die Infos für den Schritt "Strahler" abzufragen.
 */
export function useStepNavigationForPageLight(): StepNavigationInfo<EntityIdPayload> {
    const entityId = useAppSelector(selectEntityId);
    const currentType = useAppSelector(selectLocationType);

    return {
        route: entityId ? pageEditLight.route({entityId: entityId}) : null,
        showInNavigation: checkStepAvailabiltyForPageLight(currentType),
        isActive: (match, location) => checkStepAvailabiltyForPageLight(location.type)
    };
}


/**
 * Hook um die Infos für den Schritt "Bestellung" abzufragen.
 */
export function useStepNavigationForPageOrder(): StepNavigationInfo<EntityIdPayload> {
    const entityId = useAppSelector(selectEntityId);
    const currentType = useAppSelector(selectLocationType);

    return {
        route: entityId ? pageEditOrder.route({entityId: entityId}) : null,
        showInNavigation: checkStepAvailabiltyForPageOrder(currentType),
        isActive: (match, location) => checkStepAvailabiltyForPageOrder(location.type)
    };
}

