import {css} from 'twind/css';

export const wallIconStyles = css({
    '@apply': 'pointer-events-none flex justify-center items-center bg-white rounded-full shadow-lg',
    'width': '40px',
    'height': '40px',
    'svg': {
        '@apply': 'fill-primary'
    },
    '.horizontal': {
        '@apply': 'transform rotate-90'
    },
    '.wall-name': {
        '@apply': 'absolute font-bold text-primary z-10',
        '&::before': {
            'content': '""',
            'width': '17px',
            'height': '17px',
            '@apply': 'block absolute bg-white transform -translate-y-2/4 -translate-x-2/4 top-2/4 left-2/4 -z-10',
        }
    },
});
