import {Html, Line} from '@react-three/drei';
import {nameForIndex} from 'app/config';
import {htmlDistanceFactor} from 'app/config/3DParams';
import {useAppSelector} from 'app/store/hooks';
import {trackKnotMenuStyles} from 'components/scene/track/edit/styles/styles';
import {positionThreeFromPositionCM} from 'libs/view';
import React from 'react';
import {selectTrackKnots, selectTrackLines} from 'state/track/trackSelectors';
import {tw} from 'twind/css';

export const TrackView = (): JSX.Element | null => {
    const trackLines = useAppSelector(selectTrackLines);
    const trackKnots = useAppSelector(selectTrackKnots);

    const trackKnotsView = trackKnots.filter(knot => knot.id !== 0).map((knot, idx): JSX.Element => {
            // noinspection RequiredAttributes
            return (
                <Html position={positionThreeFromPositionCM(knot.position, 0.01)}
                      distanceFactor={htmlDistanceFactor}
                      key={idx + '-knot'}
                      center>
                    <div className={tw(trackKnotMenuStyles)}>
                        {nameForIndex(knot.id)}
                    </div>
                </Html>
            );
    });

    const trackLinesView = trackLines.map((line, idx): JSX.Element => (
        <Line
            key={idx + '-line'}
            color={'gray'}
            points={[positionThreeFromPositionCM(line.from), positionThreeFromPositionCM(line.to)]}
        />
    ));

    return (
        <>
            {trackKnotsView}
            {trackLinesView}
        </>
    );
};
