import {
    Environment,
    OrbitControls,
    OrthographicCamera,
    PerspectiveCamera,
    Preload,
    useContextBridge,
} from '@react-three/drei';
import {Canvas, useThree} from '@react-three/fiber';
import {publicPath} from 'app/config/gltf';
import {useAppSelector} from 'app/store/hooks';
import {ThreeDScene} from 'components/scene/3d/ThreeDScene';
import {LightScene} from 'components/scene/light/LightScene';
import {OrderCanvas} from 'components/scene/order/canvas/OrderCanvas';
import {OutletScene} from 'components/scene/outlet/OutletScene';
import {RoomScene} from 'components/scene/room/RoomScene';
import {sceneCss} from 'components/scene/styles/styles';
import React, {Suspense, useEffect, useState} from 'react';
import {ReactReduxContext} from 'react-redux';
import {selectViewMode, ViewModeModel} from 'state/scene/sceneSelectors';
import {selectIsEditModeOrder} from 'state/view/viewSelectors';
import {tw} from 'twind/css';
import {TrackScene} from './track/TrackScene';

export const MainScene: React.FC = () => {
    const ContextBridge = useContextBridge(ReactReduxContext);
    const sceneViewMode = useAppSelector(selectViewMode);
    const isOrderMode = useAppSelector(selectIsEditModeOrder);

    // noinspection RequiredAttributes
    return (
        <div className={tw(sceneCss) + ' scene-wrapper'}>
            <Canvas style={{touchAction: 'none'}} frameloop="demand">
                <ContextBridge>
                    <Suspense fallback={null}>
                        <SwitchableCamera sceneViewMode={sceneViewMode}/>
                        <SwitchableScene sceneViewMode={sceneViewMode}/>
                        <SwitchableLight sceneViewMode={sceneViewMode}/>
                        <SwitchableControl sceneViewMode={sceneViewMode}/>
                        <SwitchableHelper sceneViewMode={sceneViewMode}/>
                        <Preload all/>
                    </Suspense>
                </ContextBridge>
            </Canvas>
            <SwitchableOrderCanvas orderCanvasMode={isOrderMode} />
        </div>
    );
};

type SwitchableOrderCanvasProps = {
    orderCanvasMode: boolean
}

const SwitchableOrderCanvas = ({orderCanvasMode}: SwitchableOrderCanvasProps): JSX.Element => {
    if (orderCanvasMode) {
        return <OrderCanvas />;
    }
    return <></>;
};

type SwitchableComponentProps = {
    sceneViewMode: ViewModeModel
}

const SwitchableScene = ({sceneViewMode}: SwitchableComponentProps): JSX.Element => {
    if (sceneViewMode.isTwo) {
        return (
            <>
                <RoomScene/>
                <OutletScene/>
                <TrackScene/>
                <LightScene/>
            </>
        );
    }
    return <ThreeDScene/>;
};

const SwitchableCamera = ({sceneViewMode}: SwitchableComponentProps): JSX.Element => {
    const {camera} = useThree();
    const zoom = useCheckMobileScreen() ? 80 : 120

    useEffect(() => {
        if (sceneViewMode.isTwo) {
            camera.position.set(0, 10, 0);
        }
        if (sceneViewMode.isThree) {
            camera.position.set(1, -0.5, 5);
        }
    }, [sceneViewMode, camera]);

    // noinspection RequiredAttributes
    return (
        <>
            <PerspectiveCamera position={[1, -0.5, 5]} fov={75} makeDefault={sceneViewMode.isThree}/>
            <OrthographicCamera
                position={[0, 10, 0]}
                near={-200}
                far={200}
                zoom={zoom}
                makeDefault={sceneViewMode.isTwo}
            />
        </>
    );
};

const SwitchableLight = ({sceneViewMode}: SwitchableComponentProps): JSX.Element => {
    const {scene} = useThree();
    if (sceneViewMode.isTwo) {
        return <ambientLight color={'#FFFFFF'}/>;
    }

    // noinspection RequiredAttributes
    return <Environment background={false} files={'lebombo_1k.hdr'} path={publicPath('/hdr/')} scene={scene}/>;
};

const SwitchableControl = ({sceneViewMode}: SwitchableComponentProps): JSX.Element => {
    const {camera} = useThree();

    return <OrbitControls
        makeDefault={true}
        camera={camera}
        enablePan={sceneViewMode.isTwo}
        enableZoom={true}
        enableRotate={sceneViewMode.isThree}
    />;
};

const SwitchableHelper = ({sceneViewMode}: SwitchableComponentProps): JSX.Element => {
    if (sceneViewMode.isTwo) {
        return <gridHelper args={[100, 200]}/>;
    }
    return <></>;
};

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}
