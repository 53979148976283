import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store/store';
import {LightEntityData} from 'libs/client/clientApi';
import {Spotlight} from 'libs/models/light/light';
import {LightState} from 'state/light/lightReducer';

export const selectLightState = (state: RootState): LightState => state.light;

export const selectLightErrorId = createSelector(
    [selectLightState],
    (state): string | null => state.errorId
);
export const selectSpotlightAmount = createSelector(
    [selectLightState],
    (state): number => state.spotlightAmount
);
export const selectHasSpotlightAmountChanged = createSelector(
    [selectLightState],
    (state): boolean => state.hasSpotlightAmountChanged
);

export const selectHasSpotlightAmount = createSelector(
    [selectLightState],
    (state): boolean => state.spotlightAmount > 0
);

export const selectSpotlightsHasSpots = createSelector(
    [selectLightState],
    (state): boolean => state.spotlights.length > 0
);

export const selectSpotlights = createSelector(
    [selectLightState],
    (state): Spotlight[] => state.spotlights
);

/**
 * Das sind die Daten, die in der Entität gespeichert werden.
 */
export const selectLightEntityData = createSelector(
    [selectSpotlightAmount, selectSpotlights],
    (spotlightAmount, spotlights): LightEntityData => {
        return {
            spotlightAmount,
            spotlights
        };
    }
);
