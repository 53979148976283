import {css} from 'twind/css';

export const outlineEditorStyles = {
    base: css({
        '@apply': 'flex align-center bg-black-lightest m-4 py-2 px-3 rounded',
        '.item-title': {
            '@apply': 'text-white font-bold mr-4 flex-grow flex whitespace-nowrap',
        },
        '.form-field': {
            '@apply': 'flex justify-end relative'
        },
        '.form-field input': {
            '@apply': 'flex items-center text-black font-bold pl-3 leading-normal rounded-tr-none rounded-br-none rounded-l w-4/12 w-65 min-w-64'
        },
        '.form-field label': {
            '@apply': 'flex items-center bg-gray-200 text-black font-bold px-3 leading-normal'
        },
        '.submit-group': {
            '@apply': 'relative flex justify-center items-center',
            '&.disabled': {
                '.submit': {
                    '@apply': 'px-4 py-0 min-w-unset rounded-tl-none rounded-bl-none bg-gray-300 pointer-events-none',
                },
              'svg path': {
                  'stroke': '#9c9c9c'
              },
            },
            'svg': {
                '@apply': 'pointer-events-none absolute',
            },
            'svg path': {
              '@apply': 'pointer-events-none absolute',
              'stroke-width': '6px',
              'stroke': '#fff',
            },
            '.submit': {
                '@apply': 'px-4 py-0 min-w-unset rounded-tl-none rounded-bl-none rounded-r bg-green-500 cursor-pointer w-auto min-w-unset',
            }
        }
    }),
    empty: css({
        '.form-field input': {
            '@apply': 'flex items-center text-black font-bold pl-3 leading-normal',
            'width': '65px'
        },
    })
};
