import {Position} from './position';

/**
 * Sind die Werte beider Positionen gleich?
 *
 * @param left
 * @param right
 */
export function equalPosition(left: Position, right: Position): boolean {
    return equalPositionValueX(left, right) && equalPositionValueY(left, right);
}

/**
 * Ist der X-Wert beider Positionen gleich?
 *
 * @param left
 * @param right
 */
export function equalPositionValueX(left: Position, right: Position): boolean {
    return left.x === right.x;
}

/**
 * Ist der Y-Wert beider Positionen gleich?
 *
 * @param left
 * @param right
 */
export function equalPositionValueY(left: Position, right: Position): boolean {
    return left.y === right.y;
}

/**
 * Sind alle Positionen in den Arrays Wertgleich?
 *
 * Es werden immer die Positionen mit gleichem Index veglichen.
 */
export function equalPositionsInArrays(leftPositions: Position[], rightPositions: Position[]): boolean {
    if (leftPositions.length !== rightPositions.length) {
        return false;
    }
    return leftPositions.every((left, index) => equalPosition(left, rightPositions[index]));
}
