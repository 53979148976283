import {useAppDispatch} from 'app/store/hooks';
import {ResetAction} from 'components/common/actions/ResetAction';
import React from 'react';
import {stepResetPageType} from 'state/step/stepReset';
import {useStepNavigationForPageRoom} from 'state/step/useStepNavigation';


export const ResetTypeAction: React.FC = () => {
    const dispatch = useAppDispatch();
    const nextNavigation = useStepNavigationForPageRoom();

    const handleConfirmDelete = () => {
        dispatch(stepResetPageType());
    };
    const handleCancelDelete = () => {
        if (nextNavigation.route) {
            dispatch(nextNavigation.route);
        }
    };

    return (
        <ResetAction onConfirm={handleConfirmDelete} onCancel={handleCancelDelete}>
            <p>
                Durch Änderungen an der Raumform gehen alle bereits durchgeführten Anpassungen verloren.
            </p>
        </ResetAction>
    );
};
