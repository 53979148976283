import {Options, RoutesMap} from 'redux-first-router';
import {
    pageEditLight,
    pageEditOrder,
    pageEditOutlet,
    pageEditRoom,
    pageEditTrack,
    pageSelectType
} from 'state/location/pageRoutes';
import {pageErrorEntityNotFound} from 'state/location/errorRoutes';


export const appRoouteOptions: Options = {
    basename: '/'
};


export const appRoutes: RoutesMap = {
    [pageSelectType.route.type]: {
        path: '/',
        thunk: pageSelectType.thunk
    },
    [pageEditRoom.route.type]: {
        path: '/grundrissgestaltung/:entityId',
        thunk: pageEditRoom.thunk
    },
    [pageEditOutlet.route.type]: {
        path: '/stromauslass/:entityId',
        thunk: pageEditOutlet.thunk
    },
    [pageEditTrack.route.type]: {
        path: '/lichtschienen/:entityId',
        thunk: pageEditTrack.thunk
    },
    [pageEditLight.route.type]: {
        path: '/strahler/:entityId',
        thunk: pageEditLight.thunk
    },
    [pageEditOrder.route.type]: {
        path: '/bestellen/:entityId',
        thunk: pageEditOrder.thunk
    },
    [pageErrorEntityNotFound.route.type]: {
        path: '/ojemine/entity-notfound',
        thunk: pageErrorEntityNotFound.thunk
    }
};
