import {useThree} from '@react-three/fiber';
import {Corner} from 'components/common/3d/wall/Corner';
import {Position} from 'libs/models/position';
import {PositionThree} from 'libs/view';
import {convertMouseToPositionInCM} from 'libs/view/mouse';
import React from 'react';
import {useDrag} from 'react-use-gesture';

export type EditableCornerProps = {
    cornerPosition: PositionThree,
    onPositionChange: (position: Position) => void,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const EditableCorner = (
    {
        cornerPosition,
        onPositionChange
    }: EditableCornerProps): JSX.Element => {

    const {mouse, viewport, camera} = useThree();

    const bindDrag = useDrag(() => {
        const mouseCM = convertMouseToPositionInCM({mouse, viewport, camera});
        onPositionChange(mouseCM);
    }, {})

    return (
        <group {...bindDrag() as any} position={cornerPosition}>
            <Corner />
        </group>
    );
}
