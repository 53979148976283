import {useAppSelector} from 'app/store/hooks';
import {styles} from 'components/common/panel/styles/PanelEditContainerStyles';
import React from 'react';
import {selectOrderConnectorTypes} from 'state/order/orderSelectors';
import {tw} from 'twind/css';

export const OrderConnectorTypes = (): JSX.Element => {
    const orderConnectors = useAppSelector(selectOrderConnectorTypes)

    return (
        <div className={tw(styles)}>
            <div className={'container'}>
                <div className={'title'}>
                    <h2>Verbinder</h2>
                </div>
            </div>
            <div className={'item'}>
                <p>
                    {orderConnectors.innerConnectors} x Innenliegend
                    <br/>
                    {orderConnectors.lConnectors} x L-Stück
                    <br/>
                    {orderConnectors.tConnectors} x T-Stück
                    <br/>
                    {orderConnectors.endCaps} x Endkappe
                    <br/>
                </p>
            </div>
        </div>
    );
};
