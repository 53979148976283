export type MinConfig = {
    min: number;
}

export type MaxConfig = {
    max: number;
}

export type MinMaxConfig = MinConfig & MaxConfig;


/**
 * returns true, if length is between min and max
 * @param length
 * @param config
 */
export function isValidLength(config: MinMaxConfig, length: number): boolean {

    return length >= config.min && length <= config.max;

}

/**
 * returns validated length or default
 * @param length
 * @param config
 */
export function getValidatedLengthOrDefault(config: MinMaxConfig, length: number): number {

    if (isValidLength(config, length)) {
        return length;
    }

    return (length <= config.min) ? config.min : (length >= config.max) ? config.max : length;

}

