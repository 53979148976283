import {
    pageEditLight,
    pageEditOrder,
    pageEditOutlet,
    pageEditRoom,
    pageEditTrack,
    pageSelectType
} from 'state/location/pageRoutes';

/**
 * Das ist für die Hauptnavigation,
 *
 * Die Schritte sind jeweils verfügbar
 * - wenn sie die aktive route sind
 * - oder einer der Schritte weiter "hinten" aktiv ist.
 *
 * (Rückwärts immer - vorwärts nimmer)
 */


/**
 * Ist die Seite "Raumform" in der Navigation verfügbar?
 */
export const checkStepAvailabiltyForPageType = (routeType: string): boolean => {
    return (routeType === pageSelectType.route.type) || checkStepAvailabiltyForPageRoom(routeType);
};

/**
 * Ist die Seite "Grundriss" verfügbar?
 */
export const checkStepAvailabiltyForPageRoom = (routeType: string): boolean => {
    return (routeType === pageEditRoom.route.type) || checkStepAvailabiltyForPageOutlet(routeType);
};

/**
 * Ist die Seite "Stromauslass" verfügbar?
 */
export const checkStepAvailabiltyForPageOutlet = (routeType: string): boolean => {
    return (routeType === pageEditOutlet.route.type) || checkStepAvailabiltyForPageTrack(routeType);
};

/**
 * Ist die Seite "Lichtleisten" verfügbar?
 */
export const checkStepAvailabiltyForPageTrack = (routeType: string): boolean => {
    return (routeType === pageEditTrack.route.type) || checkStepAvailabiltyForPageLight(routeType);
};

/**
 * Ist die Seite "Strahler" verfügbar?
 */
export const checkStepAvailabiltyForPageLight = (routeType: string): boolean => {
    return (routeType === pageEditLight.route.type) || checkStepAvailabiltyForPageOrder(routeType);
};

/**
 * Ist die Seite "Bestellen" verfügbar?
 */
export const checkStepAvailabiltyForPageOrder = (routeType: string): boolean => {
    return (routeType === pageEditOrder.route.type);
};



