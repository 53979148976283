import {useAppSelector} from 'app/store/hooks';
import {styles} from 'components/common/panel/styles/PanelEditContainerStyles';
import React from 'react';
import {selectOrderTracksInfo} from 'state/order/orderSelectors';
import {tw} from 'twind/css';

export const OrderTracksInfo = (): JSX.Element => {
    const groupedLineLengths = useAppSelector(selectOrderTracksInfo)

    return (
        <div className={tw(styles)}>
            <div className={'container'}>
                <div className={'title'}>
                    <h2>Benötigte Längen</h2>

                </div>
            </div>
            <div className={'item'}>
                <p>
                    {groupedLineLengths.map(a => (
                        <span key={a.key}>
                            {a.amount} x {a.key} ({a.forLines?.join(', ')})
                            <br/>
                        </span>
                    ))}
                </p>
            </div>
        </div>
    );
};
