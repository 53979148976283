import {useGLTF} from '@react-three/drei';
import {publicPath} from 'app/config/gltf';
import {useAppSelector} from 'app/store/hooks';
import {ThreeDOutlet} from 'components/scene/3d/ThreeDOutlet';
import {ThreeDSpotlights} from 'components/scene/3d/ThreeDSpotlights';
import {ThreeDTracks} from 'components/scene/3d/ThreeDTracks';
import {materialBySystemColor, MaterialGLTFResult} from 'libs/models/3d/gltf';
import React, {useEffect, useState} from 'react';
import {selectSpotlights} from 'state/light/lightSelectors';
import {selectOutletPosition} from 'state/outlet/outletSelectors';
import {selectWallSegments} from 'state/room/roomSelectors';
import {
    selectShopActiveMainProductId,
    selectShopActiveVariantProductAdditionalColor,
    selectShopActiveVariantProductSystemColor
} from 'state/shop/shopSelectors';
import {selectTrackLines} from 'state/track/trackSelectors';
import * as THREE from 'three';
import {ThreeDPlanes} from './ThreeDPlanes';

export const ThreeDScene = (): JSX.Element => {
    // Configuration
    const roomHeight = 2.2;
    const {materials} = useGLTF(publicPath('/gltf/materialien.gltf')) as MaterialGLTFResult;

    // Spotlight product number
    const spotlightProductNumber = useAppSelector(selectShopActiveMainProductId);

    /**
     * System colors: sw (black), ws (white), ch (chrome), mcgy (matt chrom)
     */
    const systemColor = useAppSelector(selectShopActiveVariantProductSystemColor);
    const systemMaterialKey = materialBySystemColor(systemColor);
    const [systemMaterial, setSystemMaterial] = useState<THREE.MeshStandardMaterial>(materials[systemMaterialKey]);
    useEffect(() => {
        setSystemMaterial(materials[systemMaterialKey]);
    }, [materials, systemMaterialKey]);

    /**
     * Additional colors: ch (black / syscol = chrome), gr (black / syscol = matt chrom), sg (gold / syscol = black),
     *   sw (black / syscol = black), wg (gold / syscol = white), ws (black / syscol = white)
     */
    const additionalColor = useAppSelector(selectShopActiveVariantProductAdditionalColor);
    const additionalMaterialKey = materialBySystemColor(additionalColor);
    const [additionalMaterial, setAdditionalMaterial] = useState<THREE.MeshStandardMaterial>(materials[additionalMaterialKey]);
    useEffect(() => {
        setAdditionalMaterial(materials[additionalMaterialKey]);
    }, [materials, additionalMaterialKey]);


    // 1. Walls, Roof and Floor <ThreeDPlanes />
    const wallSegments = useAppSelector(selectWallSegments);

    // 2. Outlet <ThreeDOutlet />
    const outletPosition = useAppSelector(selectOutletPosition);

    // 3. Tracks
    const trackLines = useAppSelector(selectTrackLines);

    // 4. Spotlights
    const spotlights = useAppSelector(selectSpotlights);

    return (
        <>
            <ThreeDPlanes
                wallSegments={wallSegments}
                roomHeight={roomHeight}
            />
            <ThreeDOutlet
                outletPosition={outletPosition}
                roomHeight={roomHeight}
                systemMaterial={systemMaterial}
            />
            <ThreeDTracks
                trackLines={trackLines}
                roomHeight={roomHeight}
                systemMaterial={systemMaterial}
            />
            <ThreeDSpotlights
                productNumber={spotlightProductNumber}
                spotlights={spotlights}
                roomHeight={roomHeight}
                additionalMaterial={additionalMaterial}
                systemMaterial={systemMaterial}
                linsenMaterial={materials.glas_weiss}
            />
        </>
    );
};

useGLTF.preload(publicPath('/gltf/materialien.gltf'));
