import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {SpotProduct} from 'libs/shop/shopClient';
import React from 'react';
import {clearActiveMainProductdId, clearActiveVariantProductId, setActiveMainProductId} from 'state/shop/shopReducer';
import {selectShopActiveVariantProduct, selectShopMainProducts} from 'state/shop/shopSelectors';
import {tw} from 'twind/css';
import {productContainerStyles, produktListStyles} from './styles/styles';

export const SpotMainProductSelector = (): JSX.Element | null => {
    const dispatch = useAppDispatch();

    const mainProducts = useAppSelector(selectShopMainProducts);
    const activeVariantProduct = useAppSelector(selectShopActiveVariantProduct);

    const onSelectMainProduct = (productId: string) => {
        dispatch(setActiveMainProductId(productId));
    };
    const onUnselectMainProduct = () => {
        dispatch(clearActiveMainProductdId());
    };

    const handleChangeVariant = () => {
        dispatch(clearActiveVariantProductId());
    };


    if (activeVariantProduct !== null) {
        return (
            <div className={tw(productContainerStyles)}>
                <h2 className={'title'}>Gewählter Strahler</h2>
                <div className={'active-product'}>
                    <button onClick={handleChangeVariant} className={'selected-product'}>
                        {activeVariantProduct.cover &&
                        <figure>
                            <img width={600} src={activeVariantProduct.cover.url}
                                 alt={activeVariantProduct.name ?? ''}/>
                        </figure>
                        }
                        <div className={'product'}>
                            {activeVariantProduct.name}
                        </div>
                    </button>
                    <button className={'close'} onClick={onUnselectMainProduct}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             height={18}
                             width={18}
                             viewBox="0 0 24 24"
                             fill="#000000"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                            />
                        </svg>
                    </button>
                </div>
                <h2 className={'title'}>Strahler</h2>
                <div className={'container'}>
                    {mainProducts.map((product) => (
                            <SpotProductSelectItem
                                key={product.id}
                                product={product}
                                isSelected={false}
                                onSelect={onSelectMainProduct}
                                onUnselect={onUnselectMainProduct}
                            />
                        )
                    )}
                </div>
            </div>
        );
    }
    return (
        <>
            <div className={tw(productContainerStyles)}>
                <h2 className={'title'}>Gewählter Strahler</h2>
                <div className={'selected-product'}>
                    <div className={'product'}>
                        Kein Strahler gewählt
                    </div>
                </div>
                <h2 className={'title sticky'}>Strahler</h2>
                <div className={'container'}>
                    {mainProducts.map((product) => (
                            <SpotProductSelectItem
                                key={product.id}
                                product={product}
                                isSelected={false}
                                onSelect={onSelectMainProduct}
                                onUnselect={onUnselectMainProduct}
                            />
                        )
                    )}
                </div>
            </div>
        </>
    );
};


type SpotProductSelectItemProps = {
    product: SpotProduct,
    isSelected: boolean,
    onSelect: (productId: string) => void,
    onUnselect: (productId: string) => void,
}

const SpotProductSelectItem = (
    {
        product,
        isSelected,
        onSelect,
        onUnselect,
    }: SpotProductSelectItemProps): JSX.Element => {

    const handleClick = () => {
        if (isSelected) {
            onUnselect(product.id);
        } else {
            onSelect(product.id);
        }
    };

    return (
        <div className={tw(produktListStyles)}>
            <div className={'item'}>
                <button onClick={handleClick}>
                    {product.cover &&
                    <figure>
                        <img src={product.cover.url} alt={product.name ?? ''}/>
                    </figure>
                    }
                    <div className={'content'}>
                        <p>{product.productNumber}</p>
                        <div className={'name'}>
                            <p>{product.name}</p>
                            <p>ab {product.price} €</p>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );
};




