import {Tooltip} from 'components/common/tooltip/Tooltip';

import React from 'react';


export const RoomToolTip = (): JSX.Element => {
    return (
        <Tooltip title={'Grundriss'} setDisbandTime={5000}>
            Mit den auf den Wänden platzierten Kreisen können Sie diese per Drag & Drop verschieben.<br/>
            Die entsprechenden Werte sind in der Sidebar eingetragen und lassen sich dort ändern.
        </Tooltip>
    );
};
