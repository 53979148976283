import {OrbitControls, OrthographicCamera} from '@react-three/drei';
import {useAppSelector} from 'app/store/hooks';
import {TrackLinesAndTrackKnotsWithLabel} from 'components/scene/order/canvas/TrackLinesAndTrackKnotsWithLabel';
import {RoomView} from 'components/scene/room/view/RoomView';
import {calculateZoom} from 'libs/models/order/pdf/CalculateZoom';
import React from 'react';
import {selectRoom} from 'state/room/roomSelectors';

type OrderCanvasSceneProps = {
    isOrderMode: boolean,
}

export const OrderCanvasScene = ({isOrderMode}: OrderCanvasSceneProps): JSX.Element => {
    const room = useAppSelector(selectRoom);
    if (isOrderMode) {
        // noinspection RequiredAttributes
        return (
            <>
                <RoomView/>
                <TrackLinesAndTrackKnotsWithLabel/>
                <OrbitControls
                    makeDefault={true}
                    enablePan={false}
                    enableZoom={false}
                    enableRotate={false}
                />
                <OrthographicCamera
                    position={[0, 10, 0]}
                    near={-220}
                    far={200}
                    zoom={calculateZoom(room)}
                    makeDefault={true}
                />
            </>
        );
    }
    return <></>;
};
