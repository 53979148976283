import {nameForTrackLine} from 'app/config';
import {
    defaultMaxKnotConnections,
    knotOffsetForTrackConnection,
    trackMinimumDistanceToKnot,
    trackMinimumDistanceToTrack
} from 'app/config/track';
import {findKnotById} from 'libs/models/knot/findKnot';
import {buildKnotWithoutConnections, Knot, KnotId, mainKnotId, nextKnotId, noKnotId} from 'libs/models/knot/knot';
import {createKnotTable} from 'libs/models/knot/knotCollection';
import {mapKnotConnectionsToUniqueLines} from 'libs/models/knot/knotLines';
import {buildLineInDirection, IndexedLine, Line} from 'libs/models/line';
import {equalPosition, Position, validatePositionAIsInTheOffsetOfPositionB,} from 'libs/models/position';
import {doIntersect, Room} from 'libs/models/room';
import {
    Direction,
    directionIsHorizontal,
    directionOpposite,
    directionRecordAsArray,
    removeDirectionFromRecord
} from 'libs/models/types';
import {doesTrackIntersectWithInline, doesTrackIntersectWithKnot} from 'libs/models/validation';
import {validatePositionAIsInTheOffsetOfLine} from 'libs/models/validation/lineValidation';
import {TrackValidation} from 'libs/notification/trackNotifications';

export function validatePositionOfMainKnot(knots: Knot[], mainKnotPosition: Position): Knot[] {
    const mainKnot = findKnotById(knots, mainKnotId);
    if (mainKnot === null || !equalPosition(mainKnot.position, mainKnotPosition)) {
        return [
            buildKnotWithoutConnections(mainKnotId, mainKnotPosition)
        ];
    }
    return knots;
}

export function isConnectedToMainKnot(knots: Knot[], id: number): boolean {
    if (id === 0) {
        return true;
    }

    const lookup = createKnotTable(knots);

    if (!lookup[id]) {
        return false;
    }

    const startKnot = lookup[id];
    const visited: { [key: number]: Knot } = {};

    const connections: number[] = directionRecordAsArray(startKnot.connections)
        .flatMap(function loop(connectionId: number): number[] {
            if (connectionId === -1 || visited[connectionId]) {
                return [];
            }
            if (connectionId === 0) {
                return [0];
            }
            const current: Knot = lookup[connectionId];
            visited[connectionId] = current;

            return current ? directionRecordAsArray(current.connections).flatMap(loop) : [];
        });

    return connections.find((id) => id === 0) === 0;
}

export function createConnectedKnot(knots: Knot[], parentKnotId: KnotId, direction: Direction, length: number, validRoom: Room, room: Room): TrackValidation {
    const parentKnot = findKnotById(knots, parentKnotId);

    if (parentKnot === null || length === 0) {
        return {isValid: false, knots: knots, type: null};
    }

    const childId = nextKnotId(knots);
    const line = buildLineInDirection(parentKnot.position, length, direction);

    if (doesTrackIntersectWithInline(room.walls, line)) {
        return {isValid: false, knots: knots, type: 'invalid-position-not-in-room'};
    }

    if (doesTrackIntersectWithInline(validRoom.walls, line)) {
        return {isValid: false, knots: knots, type: 'invalid-distance-to-wall'};
    }

    const changedKnots = findAndUseExistingKnotForConnection(knots, parentKnot, line, direction);
    if (changedKnots && changedKnots.length > 0) {
        return {isValid: true, knots: changedKnots, type: null};
    }

    if (isLineTooCloseToKnot(knots, line.to)) {
        return {isValid: false, knots: knots, type: 'invalid-distance-track-to-knot'};
    }

    if (doesTrackIntersectWithKnot(knots, line, childId, parentKnot.id)) {
        return {isValid: false, knots: knots, type: 'invalid-track-intersect-with-knot'};
    }

    const trackLines = mapKnotConnectionsToUniqueLines(knots);
    if (doesTrackIntersect(trackLines, line)) {
        return {isValid: false, knots: knots, type: 'invalid-track-intersect-with-track'};
    }

    if (isLineTooCloseToAnother(trackLines, line)) {
        return {isValid: false, knots: knots, type: 'invalid-distance-track-to-track'};
    }

    const childKnot = buildKnotWithoutConnections(childId, line.to);
    const newKnots = connectKnotsById([...knots, childKnot], parentKnot.id, childKnot.id, direction);
    return {isValid: true, knots: newKnots, type: null};
}

/**
 * Check if Line is too close to a knot
 * @param knots
 * @param trackLine
 * @param childKnotId
 * @param parentKnotId
 */
export function isLineTooCloseToKnotWithoutParentAndChildKnot(knots: Knot[], trackLine: IndexedLine, childKnotId: KnotId, parentKnotId: KnotId): boolean {
    return knots.some((knot) => {
        if (knot.id !== childKnotId && knot.id !== parentKnotId) {
            return validatePositionAIsInTheOffsetOfLine(trackLine, knot.position, trackMinimumDistanceToKnot - 1);
        }
        return false;
    });
}

/**
 * Check if end of new dragged Line is too close to a knot
 * @param knots
 * @param endPointOfNewTrack
 */
export function isLineTooCloseToKnot(knots: Knot[], endPointOfNewTrack: Position): boolean {
    return knots.some((knot) => {
        return validatePositionAIsInTheOffsetOfPositionB(knot.position, endPointOfNewTrack, trackMinimumDistanceToKnot);
    });
}

/**
 * Prüft, ob die Linie zu nah an einer anderen ist.
 * Klammer sich selbst und Eckpunkte aus.
 * @param trackLines
 * @param changedLine
 */
export function isLineTooCloseToAnotherWithoutSelfAndEdges(trackLines: IndexedLine[], changedLine: IndexedLine): boolean {
    return trackLines.some((trackLine) => {
        if (nameForTrackLine(trackLine.from.id, trackLine.to.id) !== nameForTrackLine(changedLine.from.id, changedLine.to.id)) {
            if (!equalPosition(trackLine.from, changedLine.from) &&
                !equalPosition(trackLine.from, changedLine.to) &&
                !equalPosition(trackLine.to, changedLine.from) &&
                !equalPosition(trackLine.to, changedLine.to)) {
                return validatePositionAIsInTheOffsetOfLine(trackLine, changedLine.to, trackMinimumDistanceToTrack - 1);
            }
        }
        return false;
    });
}

/**
 * Check if one line is too close to another in the array.
 * @param trackLines
 * @param newLine
 */
export function isLineTooCloseToAnother(trackLines: IndexedLine[], newLine: Line<Position>): boolean {
    return trackLines.some((trackLine) => {
        return validatePositionAIsInTheOffsetOfLine(trackLine, newLine.to, trackMinimumDistanceToTrack);
    });
}

/**
 * Prüft, ob die Linie mit einer anderen Überschneidungen hat.
 * Klammer sich selbst und Eckpunkte aus.
 * @param trackLines
 * @param changedLine
 */
export function doesTrackIntersectWithoutSelfAndEdges(trackLines: IndexedLine[], changedLine: IndexedLine): boolean {
    return trackLines.some((trackLine) => {
        if (nameForTrackLine(trackLine.from.id, trackLine.to.id) !== nameForTrackLine(changedLine.from.id, changedLine.to.id)) {
            if (!equalPosition(trackLine.from, changedLine.from) &&
                !equalPosition(trackLine.from, changedLine.to) &&
                !equalPosition(trackLine.to, changedLine.from) &&
                !equalPosition(trackLine.to, changedLine.to)) {
                if (doIntersect(changedLine, trackLine)) {
                    return true;
                }
            }
        }
        return false;
    });
}

/**
 * Prüft, ob die Linie mit dem neuen Knoten mit einer anderen Überschneidungen hat
 * @param trackLines
 * @param newLine
 */

export function doesTrackIntersect(trackLines: IndexedLine[], newLine: Line<Position>): boolean {

    if (trackLines.length < 3) {
        return false;
    }

    return trackLines.some((trackLine) => {
        if (!equalPosition(trackLine.to, newLine.from) && !equalPosition(trackLine.from, newLine.from)) {
            if (doIntersect(newLine, trackLine)) {
                return true;
            }
        }

        return false;
    });
}

export function connectKnotsById(knots: Knot[], parentId: KnotId, childId: KnotId, direction: Direction): Knot[] {
    const oppositeDirection = directionOpposite(direction);

    return knots.map((knot) => {
        if (knot.id === parentId) {
            return {
                ...knot,
                connections: {
                    ...knot.connections,
                    [direction]: childId
                }
            };
        }
        if (knot.id === childId) {
            return {
                ...knot,
                connections: {
                    ...knot.connections,
                    [oppositeDirection]: parentId
                }
            };
        }
        return knot;
    });

}

/**
 * Removes the connection between two knots and validates the knots.
 * If the validation fails, the knot is also deleted and all other connections / knots are checked.
 * @param knots {Knot[]}
 * @param fromId {KnotId}
 * @param toId {KnotId}
 * @return {Knot[]} a list of knots without the given connection and knots, otherwise the given list
 */
export function removeConnectionBetweenKnotsById(knots: Knot[], fromId: KnotId, toId: KnotId): Knot[] {
    const fromKnot: Knot | null = findKnotById(knots, fromId);
    const toKnot: Knot | null = findKnotById(knots, toId);

    if (fromKnot === null || toKnot === null) {
        return knots;
    }

    const changedFromKnot: Knot = {
        ...fromKnot,
        connections: removeDirectionFromRecord(fromKnot.connections, toKnot.id, noKnotId)
    };
    const changedToKnot: Knot = {
        ...toKnot,
        connections: removeDirectionFromRecord(toKnot.connections, fromKnot.id, noKnotId)
    };

    const changedKnots = checkChangedKnot(
        checkChangedKnot(knots, changedFromKnot),
        changedToKnot
    );

    const knotIdsNotConnectedToMainKnot = changedKnots
        .filter(ck => !isConnectedToMainKnot(changedKnots, ck.id))
        .map(ck => ck.id);

    return changedKnots.filter(ck => !knotIdsNotConnectedToMainKnot.includes(ck.id));
}

/**
 * Checks if a knot has at least one connection left and is connected to the main knot.
 * Otherwise the knot gets removed from the list.
 * @param knots {Knot[]}
 * @param changedKnot {Knot}
 * @return {Knot[]} a list of knots with or without the given knot
 */
export function checkChangedKnot(knots: Knot[], changedKnot: Knot): Knot[] {
    const filteredKnots: Knot[] = knots.filter(knot => knot.id !== changedKnot.id);

    if (changedKnot.id === 0) {
        return [...filteredKnots, changedKnot];
    }

    if (changedKnot.id !== 0 && hasAtLeastOneConnection(changedKnot) && isConnectedToMainKnot([...filteredKnots, changedKnot], changedKnot.id)) {
        return [...filteredKnots, changedKnot];
    }

    return filteredKnots;
}

/**
 * Validates knot connections has at least one connection.
 * @param knot {Knot}
 * @return boolean, true if active connections >= one otherwise false
 */
export function hasAtLeastOneConnection(knot: Knot): boolean {
    const connections = directionRecordAsArray(knot.connections).filter(con => con !== -1);
    return connections.length >= 1;
}

/**
 * Validates the max connections of a knot.
 * @param knot {Knot}
 * @param maxConnections optional number, default 3
 * @return boolean, true if active connections < max otherwise false
 */
export function hasEnoughFreeConnections(knot: Knot, maxConnections?: number): boolean {
    const max = maxConnections || defaultMaxKnotConnections;
    const connections = directionRecordAsArray(knot.connections).filter(con => con !== -1);

    return connections.length < max;
}

/**
 * Validates the given connection point of a knot.
 * Excluded is knot with id === 0
 * @param knot {Knot}
 * @param direction {Direction}
 * @return boolean, true if the connection on the knot is free, otherwise false
 */
export function hasFreeConnectionInDirection(knot: Knot, direction: Direction): boolean {
    if (knot.id === 0) {
        const opposite = directionOpposite(direction);
        return knot.connections[opposite] !== -1 && knot.connections[direction] === -1;
    }
    return knot.connections[direction] === -1;
}

/**
 * Tries to find an existing knot for the new connection.
 * @param knots {Knot[]}
 * @param parentKnot {Knot}
 * @param line {Line<Position>}
 * @param direction {Direction}
 * @return {Knot[]} a list of knots with the new connection, otherwise empty list
 */
export function findAndUseExistingKnotForConnection(knots: Knot[], parentKnot: Knot, line: Line<Position>, direction: Direction): Knot[] {

    const knotWithEqualPosition: Knot | undefined = knots.find((knot): boolean => {
        return equalPosition(knot.position, line.to) || validatePositionAIsInTheOffsetOfPositionB(line.to, knot.position, knotOffsetForTrackConnection);
    });

    if (!knotWithEqualPosition) {
        return [];
    }

    if (doesTrackIntersectWithKnot(knots, line, knotWithEqualPosition.id, parentKnot.id)) {
        return [];
    }

    const enoughFreeConnections = hasEnoughFreeConnections(knotWithEqualPosition);
    const freeConnectionInDirection = hasFreeConnectionInDirection(knotWithEqualPosition, directionOpposite(direction));

    if (knotWithEqualPosition && enoughFreeConnections && freeConnectionInDirection) {
        if (equalPosition(knotWithEqualPosition.position, line.to)) {
            return connectKnotsById([...knots], parentKnot.id, knotWithEqualPosition.id, direction);
        }

        if (validatePositionAIsInTheOffsetOfPositionB(line.to, knotWithEqualPosition.position, knotOffsetForTrackConnection)) {
            const changedKnots = knots.map((knot) => {
                if (parentKnot.id === knot.id) {
                    if (directionIsHorizontal(direction)) {
                        if (knot.id === 0) {
                            knotWithEqualPosition.position.y = knot.position.y;
                        } else {
                            knot.position.y = knotWithEqualPosition.position.y;
                        }
                    } else {
                        if (knot.id === 0) {
                            knotWithEqualPosition.position.x = knot.position.x;
                        } else {
                            knot.position.x = knotWithEqualPosition.position.x;
                        }
                    }
                }
                return knot;
            });
            return connectKnotsById([...changedKnots], parentKnot.id, knotWithEqualPosition.id, direction);
        }
    }

    return [];
}
