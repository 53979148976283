import {LightActions} from 'components/actions/light/LightActions';
import {OrderActions} from 'components/actions/order/OrderActions';
import {OutletActions} from 'components/actions/outlet/OutletActions';
import {RoomActions} from 'components/actions/room/RoomActions';
import {TrackActions} from 'components/actions/track/TrackActions';
import {TypeActions} from 'components/actions/type/TypeActions';
import React from 'react';
import {EditModeComponentMap, useEditModeComponent} from 'state/view/viewModels';

const componentMap: EditModeComponentMap = {
    type: () => <TypeActions />,
    room: () => <RoomActions />,
    outlet: () => <OutletActions />,
    track: () => <TrackActions />,
    light: () => <LightActions />,
    order: () => <OrderActions />,
    error: () => null
}

export const MainActions = (): JSX.Element | null => {
    const currentComponent = useEditModeComponent(componentMap);
    return currentComponent ? currentComponent() : null;
};
