import {nanoid} from '@reduxjs/toolkit';
import {appEnvironment} from 'app/config/environment';
import {ClientApi} from 'libs/client/clientApi';
import {createStorageClient} from 'libs/client/storageClient';
import {createHttpClient} from 'libs/client/httpClient';
import axios from 'axios';


export const apiClient: ClientApi = createClient();


function createClient(): ClientApi {
    const type = appEnvironment.apiClient;

    switch (type) {
        case 'localStorage':
            return createStorageClient({
                idGenerator: () => 'tracklight-' + nanoid(),
                storage: window.localStorage
            });
        case 'httpStorage':
            return createHttpClient({
                axios: axios.create({
                    headers: {
                        'Accept': ' application/json',
                        'Content-Type': 'application/json',
                        'sw-access-key': appEnvironment.shopwareAccessKey,
                        'sw-inheritance': '1',
                    }
                })
            })
    }
    throw Error('Unknown apiClient: ' + type);
}
