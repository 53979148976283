import {css} from 'twind/css';

export const styles = css({
    '@apply': 'flex flex-col items-center text-xs border-gray-400 tracking-wide border-1 first:border-l-0 border-l-0 last:border-r-0 py-2 md:px-2 font-bold text-primary opacity-30 transition duration-200 ease-in-out relative md:mr-12 lg:mr-4 xl:mr-12 md:border-0 md:text-sm md:py-0 min-h-64 max-h-64 lg:min-h-unset',
    'width': '73px',
    '&:not(div)': {
        '&:hover': {
            '@apply': 'opacity-100',
        },
    },
    '&:focus, &:active': {
        '@apply': '',
    },
    '&.active': {
        '@apply': 'opacity-100 border-gray-400 text-white md:text-primary md:bg-transparent',
        '&:last-of-type': {
            '@apply': 'bg-primary md:bg-transparent',
            'span': {
                '@apply': 'text-white md:text-primary',
            },
        },
    },
    '&::after': {
        'content': '""',
        '@apply': 'bg-primary hidden md:block absolute top-1/2',
        'right': '-50px',
        'height': '2px',
        'width': '50px',
    },
    '@screen lg': {
        '&::after': {
            'right': '-20px',
            'width': '25px',
        },
    },
    '@screen xl': {
        '&::after': {
            'right': '-50px',
            'width': '50px',
        },
    },
    '&:last-child': {
        'margin-right': '0',
        '&::after': {
            '@apply': 'hidden',
        },
    },
});