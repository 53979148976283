import {useAppSelector} from 'app/store/hooks';
import {styles} from 'components/common/panel/styles/PanelEditContainerStyles';
import {getOutletTypeName} from 'libs/models/order';
import React from 'react';
import {selectOrderOutletType} from 'state/order/orderSelectors';
import {tw} from 'twind/css';

export const OrderOutletType = (): JSX.Element => {
    const orderOutletType = useAppSelector(selectOrderOutletType);

    return (
        <div className={tw(styles)}>
            <div className={'container'}>
                <div className={'title'}>
                    <h2>Stromauslass</h2>
                </div>
            </div>
            <div className={'item'}>
                <p>
                    {getOutletTypeName(orderOutletType)}
                </p>
            </div>
        </div>
    );
};


