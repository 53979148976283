import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store/store';
import {SceneEntityData} from 'libs/client/clientApi';
import {SceneState} from 'state/scene/sceneReducer';

export const selectSceneState = (state: RootState): SceneState => state.scene;

export type ViewModeModel = { isThree: boolean, isTwo: boolean };

export const selectViewMode = createSelector(
    [selectSceneState],
    (sceneView): ViewModeModel => {
        return {
            isThree: sceneView.viewMode === 'three',
            isTwo: sceneView.viewMode === 'two',
        };
    }
);

export const selectEntityColors = createSelector(
    [selectSceneState],
    (state) => {
        return {scene: {
            wallColor: state.wallColor,
            roofColor: state.roofColor,
            floorTexture: state.floorTexture
        }};
    }
);

export const selectWallColor = createSelector(
    [selectSceneState],
    (state): string => {
        return state.wallColor;
    }
);

export const selectRoofColor = createSelector(
    [selectSceneState],
    (state): string => {
        return state.roofColor;
    }
);

export const selectFloorTexture = createSelector(
    [selectSceneState],
    (state): string => {
        return state.floorTexture;
    }
);

/**
 * Das sind die Daten, die in der Entität gespeichert werden.
 */
export const selectSceneEntityData = createSelector(
    [selectWallColor, selectRoofColor, selectFloorTexture],
    (wallColor, roofColor, floorTexture): SceneEntityData => {
        return {
            wallColor,
            roofColor,
            floorTexture
        };
    }
);
