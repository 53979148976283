import {useThree} from '@react-three/fiber';
import {ThreeEvent} from '@react-three/fiber/dist/declarations/src/core/events';
import {OutletMesh} from 'components/common/3d/outlet/OutletMesh';
import {PowerOutletProps} from 'components/common/3d/outlet/PowerOutlet';
import {Position} from 'libs/models/position';
import {PositionThree, positionThreeFromPositionCM} from 'libs/view';
import {convertMouseToPositionInCM} from 'libs/view/mouse';
import React, {useEffect, useState} from 'react';
import {useDrag} from 'react-use-gesture';
import {useOutletBounds, useOutletInitialPosition} from 'state/outlet/outletHook';

export type EditablePowerOutletProps = PowerOutletProps & {
    onPositionChange: (position: Position) => void;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const EditablePowerOutlet = ({position, onPositionChange}: EditablePowerOutletProps): JSX.Element => {
    const {isPointInRoom} = useOutletBounds();
    const {calculateInitialPosition} = useOutletInitialPosition();
    const [outletPosition, setOutletPosition] = useState<PositionThree>();

    useEffect(() => {
        setOutletPosition(positionThreeFromPositionCM(position ?? calculateInitialPosition()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position])

    const {mouse, viewport, camera} = useThree();
    const bind = useDrag<ThreeEvent<PointerEvent>>(() => {
        const mouseCM = convertMouseToPositionInCM({mouse, viewport, camera});
        if (isPointInRoom(mouseCM)) {
            onPositionChange(mouseCM);
        }
    });

    return (
        <group {...bind() as any} position={outletPosition}>
            <OutletMesh/>
        </group>
    );
};
