import {
    IndexedLine,
    lineCenter,
    LineDirection,
    lineDirection,
    lineIsHorizontal,
    lineLength,
    mapLinesIndexedFromPositions
} from 'libs/models/line';
import {Position} from 'libs/models/position';
import {pointCMFromPositionThree, PositionThree, positionThreeFromPositionCM} from './position';

export interface WallSegmentModel {
    index: number;
    isHorizontal: boolean;
    length: number;
    direction: LineDirection;
    from: PositionThree;
    to: PositionThree;
    center: PositionThree;
}


export function mapWallSegmentsFromPositions(positions: Position[]): WallSegmentModel[] {
    return mapLinesIndexedFromPositions(positions)
        .map(mapWallSegmentFromLine);
}


export function mapWallSegmentFromLine(line: IndexedLine): WallSegmentModel {
    return {
        index: line.from.id,
        isHorizontal: lineIsHorizontal(line),
        length: lineLength(line),
        direction: lineDirection(line),
        from: positionThreeFromPositionCM(line.from),
        to: positionThreeFromPositionCM(line.to),
        center: positionThreeFromPositionCM(lineCenter(line))
    }
}

export function mapLineFromWallSegment(wall: WallSegmentModel): IndexedLine {
    return {
        from: {...pointCMFromPositionThree(wall.from), id: wall.index},
        to: {...pointCMFromPositionThree(wall.to), id: wall.index},
    }
}

