import {Line} from '@react-three/drei';
import {BaseLineProps} from 'components/common/3d/lines/BaseLine';
import React from 'react';

type OutlineLineProps = BaseLineProps & {
    opacity?: number | 1;
}


export const OutlineLine = ({points, opacity}: OutlineLineProps): JSX.Element => {
    return (
        <Line opacity={opacity} points={points} color={'#777777'} lineWidth={1} />
    );
}
