import {Position} from 'libs/models/position';
import {svgPositionsToPath, svgPositionsToViewBox} from 'libs/svg/svgPosition';
import React from 'react';

export interface PositionSvgProps extends Omit<React.ComponentProps<'svg'>, 'viewBox'> {
    positions: Position[];
    offset: number;
}

export const PositionSvg = ({positions, offset, ...svgProps}: PositionSvgProps): JSX.Element => {
    const path = svgPositionsToPath(positions);
    const viewBox = svgPositionsToViewBox(positions, offset);
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox={viewBox} className={'position-icon'}>
            <g fill={'none'}>
                <path d={path} stroke={'#000'} strokeWidth={10} strokeLinejoin={'round'} />
            </g>
        </svg>
    );
}

