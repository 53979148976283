import {spotlightDistanceFromConnector, spotlightDistanceFromSpotlight} from 'app/config/light';
import {Spotlight} from 'libs/models/light/light';
import {unknownValidation} from 'libs/notification/defaultNotifications';
import {NotifyItem} from 'libs/notification/notificationProvider';

export type LightNotificationType = 'invalid-position-spot-to-knot' |
    'invalid-position-spot-to-spot' |
    'invalid-spotlight-amount-min' |
    'invalid-spotlight-amount-max' |
    null;

export type LightNotification = {
    type: LightNotificationType;
    item: NotifyItem;
}

export type SpotlightsValidation = {
    isValid: boolean;
    spotlights: Spotlight[];
    type: LightNotificationType;
}
export type NumberOfSpotlightsValidation = {
    isValid: boolean;
    numberOfSpotlights: number;
    type: LightNotificationType;
}

export const lightNotifications = (lnt: LightNotificationType): NotifyItem => {
    const notifyItem = lightNotificationItems.find(nItem => nItem.type === lnt)

    if (notifyItem) {
        return notifyItem.item;
    }

    return unknownValidation;
}

const lightNotificationItems: LightNotification[] = [
    {
        type: 'invalid-position-spot-to-knot',
        item: {
            id: 'invalid-position-spot-to-knot',
            title: 'Ungültige Position!',
            message: `Der Abstand zu einem Verbinder muss min. ${spotlightDistanceFromConnector}cm betragen.`,
            type: 'danger'
        }
    },
    {
        type: 'invalid-position-spot-to-spot',
        item: {
            id: 'invalid-position-spot-to-spot',
            title: 'Ungültige Position!',
            message: `Der Abstand zu einem Strahler muss min. ${spotlightDistanceFromSpotlight}cm betragen.`,
            type: 'danger'
        }
    },
    {
        type: 'invalid-spotlight-amount-min',
        item: {
            id: 'invalid-spotlight-amount-min',
            title: 'Ungültige Anzahl!',
            message: 'Die Anzahl ist zu gering, der Wert wurde auf 0 gesetzt.',
            type: 'info'
        }
    },
    {
        type: 'invalid-spotlight-amount-max',
        item: {
            id: 'invalid-spotlight-amount-max',
            title: 'Ungültige Anzahl!',
            message: 'Die Anzahl ist zu hoch, der Wert wurde auf das maximal zulässige gesetzt.',
            type: 'info'
        }
    },
]
