import {useAppSelector} from 'app/store/hooks';
import {styles} from 'components/common/panel/styles/PanelEditContainerStyles';
import React from 'react';
import {selectCartPrice} from 'state/order/orderSelectors';
import {tw} from 'twind/css';

export const OrderCartPrice = (): JSX.Element => {
    const cartPrice = useAppSelector(selectCartPrice);
    const price = () => {
        if (cartPrice && cartPrice.calculatedTaxes && cartPrice.calculatedTaxes.length > 0) {
            return (
                <>
                    Netto: {cartPrice.netPrice} €
                    <br/>
                    Steuer: {cartPrice.calculatedTaxes[0].tax} €
                    <br/>
                    Summe: {cartPrice.totalPrice} €
                </>
            );
        }
        return <></>;
    };

    return (
        <div className={tw(styles)}>
            <div className={'container'}>
                <div className={'title price'}>
                    <h2>Preis</h2>
                </div>
            </div>
            <div className={'item'}>
                <p>
                    {price()}
                </p>
            </div>
        </div>
    );
};


