
/**
 * Eine Richtung: oben unten links rechts.
 */
export type Direction = 'top' | 'right' | 'left' | 'bottom';

/**
 * Generischer Typ mit Richtungen.
 */
export type DirectionRecord<T> = Record<Direction, T>;

export type DirectonList<T> = [top: T, right: T, bottom: T, left: T];

export type DirectionPair<T> = {
    direction: Direction;
    connected: T;
};

export type DirectionPredicate = (direction: Direction) => boolean;


/**
 * Erstellt einen Predicate der prüft ob eine Richtung ein "Nachbar" ist.
 */
export function createDirectionNeighborPredicate(referenceDirection: Direction): DirectionPredicate {
    const next = directionNext(referenceDirection);
    const previous = directionPrevious(referenceDirection);

    return (direction) => (direction === next) || (direction === previous);
}

/**
 * Erstellt einen Predicate der prüft ob eine Richtung ein "Gegenüber" ist.
 */
export function createDirectionOppositePredicate(referenceDirection: Direction): DirectionPredicate {
    const opposite = directionOpposite(referenceDirection);
    return (direction) => (direction === opposite);
}


/**
 * Die "gegenüberliegende" Richtung.
 */
export function directionOpposite(direction: Direction): Direction {
    switch (direction) {
        case 'top':
            return 'bottom';
        case 'right':
            return 'left';
        case 'bottom':
            return 'top';
        case 'left':
            return 'right';
    }
}

/**
 * Die "nächste" Richtung (im Uhrzeigersinn).
 */
export function directionNext(direction: Direction): Direction {
    switch (direction) {
        case 'top':
            return 'right';
        case 'right':
            return 'bottom';
        case 'bottom':
            return 'left';
        case 'left':
            return 'top';
    }
}

/**
 * Die "vorherige" Richtung (im Uhrzeigersinn).
 */
export function directionPrevious(direction: Direction): Direction {
    switch (direction) {
        case 'top':
            return 'left';
        case 'right':
            return 'top';
        case 'bottom':
            return 'right';
        case 'left':
            return 'bottom';
    }
}

/**
 * Ist die Richtung horizontal (links/rechts)?
 */
export function directionIsHorizontal(direction: Direction): boolean {
    return (direction === 'left') || (direction === 'right');
}

/**
 * Ist die Richtung vertikal (oben/unten)?
 */
export function directionIsVertical(direction: Direction): boolean {
    return (direction === 'top') || (direction === 'bottom');
}

/**
 * Macht aus einem Record mit Richtungen ein Array mit Richtungen - die Richtung der Verbindung geht verloren.
 */
export function directionRecordAsArray<T>(directions: DirectionRecord<T>): DirectonList<T> {
    return [
        directions.top,
        directions.right,
        directions.bottom,
        directions.left
    ];
}

/**
 * Macht aus einem Array mit Richtungen einen Record mit Richtungen.
 */
export function directionArrayAsRecord<T>(directions: DirectonList<T>): DirectionRecord<T> {
    return {
        top: directions[0],
        right: directions[1],
        bottom: directions[2],
        left: directions[3]
    }
}

/**
 * Macht aus einem Record mit Richtungen ein Array mit Richtungen - die Richtung der Verbindung bleibt erhalten.
 */
export function directionRecordAsPairs<T>(directions: DirectionRecord<T>): DirectonList<DirectionPair<T>> {
    return [
        {direction: 'top', connected: directions.top},
        {direction: 'right', connected: directions.right},
        {direction: 'bottom', connected: directions.bottom},
        {direction: 'left', connected: directions.left},
    ];
}

/**
 * Setzt in einem Record mit Richtungen eine Richtung zurueck.
 */
export function removeDirectionFromRecord<T>(directions: DirectionRecord<T>, direction: T, resetDirection: T): DirectionRecord<T> {
    const recordAsArray = directionRecordAsArray(directions);

    return directionArrayAsRecord(removeDirectionFromArray(recordAsArray, direction, resetDirection));
}

/**
 * Setzt in einem Array mit Richtungen eine Richtung zurueck.
 */
export function removeDirectionFromArray<T>(directions: DirectonList<T>, direction: T, resetDirection: T): DirectonList<T> {
    const changedDirections = directions.map(value => {
        if (value === direction) {
            return resetDirection
        }
        return value
    });

    return [
        changedDirections[0],
        changedDirections[1],
        changedDirections[2],
        changedDirections[3],
    ]
}
