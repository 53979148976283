import {Knot} from 'libs/models/knot/knot';
import {IndexedPosition} from 'libs/models/position';

export type KnotTable = {
    [key: number]: Knot;
};

export type KnotPositionTable = {
    [key: number]: IndexedPosition;
};


export function createKnotTable(knots: Knot[]): KnotTable {
    return knots.reduce((acc: KnotTable, cur) => {
        acc[cur.id] = cur;
        return acc;
    }, {});
}


/**
 * Baut eine Tabelle mit Knoten auf.
 */
export function createKnotPositionTable(knots: Knot[]): KnotPositionTable {
    return knots.reduce((acc: KnotPositionTable, cur) => {
        acc[cur.id] = {
            id: cur.id,
            ...cur.position
        };
        return acc;
    }, {});
}
