import {useGLTF} from '@react-three/drei';
import {publicPath} from 'app/config/gltf';
import {SpotLenxxGLTFResult} from 'libs/models/3d/gltf';
import {SpotLenxxModeComponentMap, SpotMode} from 'libs/models/3d/spotModels';
import {PositionThree} from 'libs/view';
import * as THREE from 'three';
import {MeshStandardMaterial} from 'three';

type SpotLenxxProps = {
    systemMaterial: THREE.MeshStandardMaterial;
    additionalMaterial: THREE.MeshStandardMaterial;
    spotMode: SpotMode;
    position: PositionThree;
    isHorizontal: boolean;
}

export const SpotLenxx = ({systemMaterial, additionalMaterial, spotMode, position, isHorizontal}: SpotLenxxProps): JSX.Element => {
    const {nodes} = useGLTF(publicPath('/gltf/spotLenxxFinalDraco.gltf')) as SpotLenxxGLTFResult;

    const strahlerkopfMaterial = systemMaterial;
    const innenreflektorMaterial = additionalMaterial;

    const baseRotation: PositionThree = isHorizontal ? [0, 0, 0] : [0, Math.PI / 2, 0]

    const spotComponent = spotComponentMap[spotMode]
    const spot = spotComponent({nodes, innenreflektorMaterial, strahlerkopfMaterial})

    return (
        <group scale={0.04} position={position} dispose={null}>
            <group name={'base'}>
                <mesh
                    geometry={nodes.schienenadapter.geometry}
                    material={systemMaterial}
                    rotation={baseRotation}
                    scale={[0.8, 0.2, -0.3]}
                />
                <mesh
                    geometry={nodes.stab.geometry}
                    material={systemMaterial}
                    position={[0.5, -0.5, 0]}
                    rotation={[0, 0, 3.14]}
                    scale={[0.08, 0.4, -0.08]}
                />
            </group>
            {spot}
        </group>
    );
};

const spotComponentMap: SpotLenxxModeComponentMap = {
    top: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps) => (
        <SpotLenxxTop
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    right: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps) => (
        <SpotLenxxRight
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    bottom: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps) => (
        <SpotLenxxBottom
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    left: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps) => (
        <SpotLenxxLeft
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    down: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps) => (
        <SpotLenxxDown
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
};

export type SpotLenxx3DProps = {
    nodes: SpotLenxxGLTFResult['nodes'];
    innenreflektorMaterial: MeshStandardMaterial;
    strahlerkopfMaterial: MeshStandardMaterial;
}

export const SpotLenxxTop = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps): JSX.Element => {
    return (
        <group name={'top'}>
            <mesh
                geometry={nodes.innenreflektor_links.geometry}
                material={innenreflektorMaterial}
                position={[0.5, -1.57, -0.67]}
                rotation={[Math.PI / 4, 0, 3.14]}
                scale={[0.53, 0.8, -0.53]}
            />
            <mesh
                geometry={nodes.strahlerkopf_links.geometry}
                material={strahlerkopfMaterial}
                position={[0.5, -1.5, -0.6]}
                rotation={[Math.PI / 4, 0, 3.14]}
                scale={[0.55, 0.9, -0.55]}
            />
        </group>
    );
};
export const SpotLenxxBottom = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps): JSX.Element => {
    return (
        <group name={'bottom'}>
            <mesh
                geometry={nodes.innenreflektor_strahler_rechts.geometry}
                material={innenreflektorMaterial}
                position={[0.5, -1.57, 0.67]}
                rotation={[-Math.PI / 4, 0, 3.14]}
                scale={[0.53, 0.8, -0.53]}
            />
            <mesh
                geometry={nodes.strahlerkopf_strahler_rechts.geometry}
                material={strahlerkopfMaterial}
                position={[0.5, -1.5, 0.6]}
                rotation={[-Math.PI / 4, 0, 3.14]}
                scale={[0.55, 0.9, -0.55]}
            />
        </group>
    );
};
export const SpotLenxxLeft = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps): JSX.Element => {
    return (
        <group name={'left'}>
            <mesh
                geometry={nodes.innenreflektor_strahler_hinten.geometry}
                material={innenreflektorMaterial}
                position={[-0.07, -1.57, 0]}
                rotation={[0, 0, 2.36]}
                scale={[0.53, 0.8, -0.53]}
            />
            <mesh
                geometry={nodes.strahlerkopf_strahler_hinten.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.5, 0]}
                rotation={[0, 0, 2.36]}
                scale={[0.55, 0.9, -0.55]}
            />
        </group>
    );
};
export const SpotLenxxRight = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps): JSX.Element => {
    return (
        <group name={'right'}>
            <mesh
                geometry={nodes.innenreflektor_strahler_vorne.geometry}
                material={innenreflektorMaterial}
                position={[1.07, -1.57, 0]}
                rotation={[0, 0, -2.36]}
                scale={[0.53, 0.8, -0.53]}
            />
            <mesh
                geometry={nodes.strahlerkopf_strahler_vorne.geometry}
                material={strahlerkopfMaterial}
                position={[1, -1.5, 0]}
                rotation={[0, 0, -2.36]}
                scale={[0.55, 0.9, -0.55]}
            />
        </group>
    );
};
export const SpotLenxxDown = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotLenxx3DProps): JSX.Element => {
    return (
        <group name={'down'}>
            <mesh
                geometry={nodes.innenreflektor_unten.geometry}
                material={innenreflektorMaterial}
                position={[0.5, -1.6, 0]}
                rotation={[0, 0, 3.14]}
                scale={[0.53, 0.8, -0.53]}
            />
            <mesh
                geometry={nodes.strahlerkopf_unten.geometry}
                material={strahlerkopfMaterial}
                position={[0.5, -1.5, 0]}
                rotation={[0, 0, 3.14]}
                scale={[0.55, 0.9, -0.55]}
            />
        </group>
    );
};

useGLTF.preload(publicPath('/gltf/spotLenxxFinalDraco.gltf'));
