import {Line, Text} from '@react-three/drei';
import {nameForIndex} from 'app/config';
import {useAppSelector} from 'app/store/hooks';
import {lineCenter} from 'libs/models/line';
import {positionThreeFromPositionCM} from 'libs/view';
import React from 'react';
import {selectTrackKnots, selectTrackLines} from 'state/track/trackSelectors';
import * as THREE from 'three';

export const TrackLinesAndTrackKnotsWithLabel = (): JSX.Element => {
    const trackLines = useAppSelector(selectTrackLines);
    const trackLinesView = trackLines.map((line, idx): JSX.Element => (
        <group key={idx + '-track-and-text'}>
            <Text
                fillOpacity={1}
                color={'black'}
                fontSize={0.15}
                position={positionThreeFromPositionCM(lineCenter(line))}
                rotation={[THREE.MathUtils.degToRad(-90), 0, 0]}
            >
                {idx + 1}
            </Text>
            <Line
                color={'white'}
                points={[positionThreeFromPositionCM(line.from), positionThreeFromPositionCM(line.to)]}
            />
        </group>
    ));

    const knots = useAppSelector(selectTrackKnots);
    const trackKnots = knots.map((knot, idx): JSX.Element => {
        // noinspection RequiredAttributes
        return (
            <group key={idx + '-knot'} position={positionThreeFromPositionCM(knot.position, 0.01)}>
                <mesh>
                    <cylinderGeometry args={[0.1, 0.1, 0.1, 64]}/>
                    <meshBasicMaterial color={'white'}/>
                </mesh>

                <Text
                    fillOpacity={1}
                    key={idx}
                    color={'black'}
                    fontSize={0.15}
                    position={[0, 0.5, 0]}
                    rotation={[THREE.MathUtils.degToRad(-90), 0, 0]}
                >
                    {nameForIndex(knot.id)}
                </Text>
            </group>
        )
    })

    return (
        <>
            {trackKnots}
            {trackLinesView}
        </>
    )
}
