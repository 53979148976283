import {meassurePositionsMinMaxValues, Position, resizePositionsMinMaxValues} from 'libs/models/position';


/**
 * Baut einen SVG Pfad aus den Positionen (für <path d="" />)
 */
export function svgPositionsToPath(positions: Position[]): string {
    const commands = positions.map((pos, idx) => {
        if (idx === 0) {
            return `M ${pos.x},${pos.y}`;
        }
        return `L ${pos.x}, ${pos.y}`;
    });
    return [commands, 'Z'].join(' ');
}


/**
 * Berechnet den string für das Attribut "view-box" in SVG.
 *
 * Das padding verschiebt die Originalabmessungen der Positionen.
 *
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/viewBox
 */
export function svgPositionsToViewBox(positions: Position[], offset = 0): string {
    const bounds = meassurePositionsMinMaxValues(positions);
    const viewBounds = resizePositionsMinMaxValues(bounds, offset);

    return [
        viewBounds.minX,
        viewBounds.minY,
        (viewBounds.maxX - viewBounds.minX),
        (viewBounds.maxY - viewBounds.minY),
    ].join(',');
}
