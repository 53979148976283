import {createPageRoute, PageRoute} from 'state/location/routing/pageRouting';
import {extendDefaultView} from 'state/view/viewReducer';


export function createErrorPageRoute(pageName: string) : PageRoute {
    return createPageRoute(`error/${pageName}`, {
        view: extendDefaultView({
            editMode: 'error'
        }),
        loader: () => {
            return Promise.resolve();
        }
    })
}
