import {useAppSelector} from 'app/store/hooks';
import {RoofColorPicker, WallColorPicker} from 'components/panel/3d/ColorPicker';
import {FloorCovering} from 'components/panel/3d/FloorCovering';
import React from 'react';
import {selectViewMode} from 'state/scene/sceneSelectors';
import {selectThreeDPanelStyles} from 'components/panel/3d/styles/styles';
import {tw} from 'twind/css';

export const EditThreeDPanel = (): JSX.Element => {
    const sceneViewMode = useAppSelector(selectViewMode);
    const threeDMenu = sceneViewMode.isThree ? <ThreeDMenu/> : null;

    return (
        <div className={tw(selectThreeDPanelStyles)}>
            {threeDMenu}
        </div>
    );
};

const ThreeDMenu = (): JSX.Element => {
    return (
        <>
            <div className={'title'}>
                <h2>Deckenfarbe</h2>
            </div>
            <div className={'selection selection__color'}>
                <RoofColorPicker/>
            </div>
            <div className={'title'}>
                <h2>Wandfarbe</h2>
            </div>
            <div className={'selection selection__color'}>
                <WallColorPicker/>
            </div>
            <div className={'title'}>
                <h2>Bodenart</h2>
            </div>
            <div>
                <FloorCovering/>
            </div>
        </>
    );
};
