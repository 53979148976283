import {Knot} from 'libs/models/knot/knot';

export * from './knot';
export * from './findKnot';
export * from './knotCollection';

export const exampleKnotArray: Knot[] = [
    {
        id: 0,
        position: {x: 30, y: 30},
        connections: {
            top: -1,
            right: 1,
            bottom: -1,
            left: -1
        }
    },
    {
        id: 1,
        position: {x: 50, y: 30},
        connections: {
            top: 1,
            bottom: -1,
            left: 0,
            right: -1,
        }
    },
]

export const advancedExampleKnotArray: Knot[] = [
    ...exampleKnotArray,
    {
        id: 2,
        position: {x: 50, y: 50},
        connections: {
            top: 1,
            right: -1,
            bottom: -1,
            left: 3
        }
    },
    {
        id: 3,
        position: {x: 30, y: 50},
        connections: {
            top: -1,
            right: 2,
            bottom: -1,
            left: -1,
        }
    }
]
