import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {PositionSvg} from 'components/common/svg/PositionSvg';
import {TypeToolTip} from 'components/panel/type/TypeToolTip';
import {EntityResource} from 'libs/client/clientApi';
import React from 'react';
import {selectEntityId} from 'state/entity/entitySelectors';
import {setCurrentPresetId} from 'state/preset/presetReducer';
import {selectCurrentPresetId, selectPresetEntities} from 'state/preset/presetSelectors';
import {setPreviewRoom} from 'state/room/roomReducer';
import {selectStepNeedsResetPageType} from 'state/step/selectStepNeedReset';
import {ContactBanner} from 'components/common/contactBanner/ContactBanner';
import {selectTypePanelStyles, SelectTypeItemStyles} from 'components/panel/type/styles/styles';
import {tw} from 'twind/css';

export const EditTypePanel = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentEntityId = useAppSelector(selectEntityId);
    const presetEntities = useAppSelector(selectPresetEntities);
    const currentPresetId = useAppSelector(selectCurrentPresetId);
    const needsReset = useAppSelector(selectStepNeedsResetPageType);

    const handleSelection = (entity: EntityResource) => {
        if (!needsReset && (currentEntityId === null || currentEntityId !== currentPresetId)) {
            dispatch(setCurrentPresetId(entity.entityId));
            dispatch(setPreviewRoom(entity));
        }
    }

    return (
        <div className={tw(selectTypePanelStyles)}>
            <div className={'title'}>
                <h2>Raumform</h2>
                <TypeToolTip/>
            </div>

            <div className={'selection'}>
                {presetEntities.map((entity) => (
                    <SelectTypeItem key={entity.entityId}
                                    entity={entity}
                                    currentPresetId={currentPresetId}
                                    onSelect={handleSelection} />
                ))}
            </div>
            <div>
               <ContactBanner title={'Ihre Raumform ist nicht dabei?'} linkUrl={'mailto:info@seyfert-lichtdesign.de'} linkTitle={'Raumform anfragen'} />
            </div>
        </div>
    );
}

interface SelectTypeItemProps {
    entity: EntityResource;
    onSelect: (entity: EntityResource) => void;
    currentPresetId?: string | null;
}

const SelectTypeItem = ({currentPresetId, entity, onSelect}: SelectTypeItemProps): JSX.Element => {
    const isSelected = entity.entityId === currentPresetId;
    return (
        <button
            className={isSelected ? tw(SelectTypeItemStyles) + ' active' : tw(SelectTypeItemStyles)}
            onClick={() => onSelect(entity)}
        >
            <PositionSvg positions={entity.data.room.corners} offset={50} />
            <span>{entity.info.title}</span>
        </button>
    );
};

