import {createSelector} from '@reduxjs/toolkit';
import {equalPositionsInArrays} from 'libs/models/position';
import {selectHasSpotlightAmount} from 'state/light/lightSelectors';
import {selectCurrentPresetEntity} from 'state/preset/presetSelectors';
import {selectRoomEntityData} from 'state/room/roomSelectors';
import {selectStepCompletePageOutlet, selectStepCompletePageTrack} from 'state/step/selectStepComplete';


/**
 * Schritt 6 (Bestellung) - löschen benötigt?
 *
 * evtl. brauchen wir das gar nicht, aber es kost ja nix.
 */
export const selectStepNeedsResetPageOrder = createSelector(
    [],
    (): boolean => {
        return false;
    }
);

/**
 * SLK-218: Schritt 5 (Strahler) - löschen benötigt?
 */
export const selectStepNeedsResetPageLight = createSelector(
    [],
    (): boolean => {
        return false;
    }
);


/**
 * SLK-206: Schritt 4 (Schienen) - löschen benötigt?
 *
 * Es wurde eine Strahler platziert.
 */
export const selectStepNeedsResetPageTrack = createSelector(
    [selectHasSpotlightAmount],
    (lightComplete): boolean => lightComplete
);

/**
 * SLK-202: Schritt 3 (Auslass) - löschen benötigt?
 *
 * - Es wurde eine Schiene platziert.
 */
export const selectStepNeedsResetPageOutlet = createSelector(
    [selectStepCompletePageTrack, selectHasSpotlightAmount],
    (trackComplete, lightComplete): boolean => trackComplete || lightComplete
);


/**
 * SLK-204: Schritt 2 (Grundriss) - löschen benötigt?
 *
 * - Es wurde ein Stromauslass platziert
 * -
 */
export const selectStepNeedsResetPageRoom = createSelector(
    [selectStepCompletePageOutlet, selectStepCompletePageTrack, selectHasSpotlightAmount],
    (outletComplete, trackComplete, lightComplete): boolean => outletComplete || trackComplete || lightComplete
);

/**
 * Hilfsfunktion: Wurde der Raum im Vergleich zur Vorlage geändert?
 */
const selectHasRoomChangesToPreset = createSelector(
    [selectCurrentPresetEntity, selectRoomEntityData],
    (presetEntity, roomEntity): boolean => {
        if (!presetEntity) {
            return false;
        }
        return !equalPositionsInArrays(presetEntity.data.room.corners, roomEntity.corners);
    }
);


/**
 * SLK-207: Schritt 1 (Raumform) - löschen benötigt?
 */
export const selectStepNeedsResetPageType = createSelector(
    [selectHasRoomChangesToPreset, selectStepCompletePageOutlet, selectStepCompletePageTrack, selectHasSpotlightAmount],
    (selectHasRoomChangesToPreset, outletComplete, trackComplete, lightComplete): boolean => {
        return selectHasRoomChangesToPreset || outletComplete || trackComplete || lightComplete
    }
);

