import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {SaveAction} from 'components/common/actions/SaveAction';
import React from 'react';
import {saveEntity} from 'state/entity/entityApi';
import {selectEntityId} from 'state/entity/entitySelectors';
import {selectOrderProducts} from 'state/order/orderSelectors';
import {saveOrderProducts} from 'state/shop/shopApi';
import {selectStepCompletePageLight} from 'state/step/selectStepComplete';
import {useStepNavigationForPageOrder} from 'state/step/useStepNavigation';

export const SaveLightAction = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const nextNavigation = useStepNavigationForPageOrder();
    const isComplete = useAppSelector(selectStepCompletePageLight);
    const entityId = useAppSelector(selectEntityId);
    const products = useAppSelector(selectOrderProducts);

    const handleSave = async () => {
        await dispatch(saveEntity());
        if (entityId) {
            await dispatch(saveOrderProducts({entityId, products}));
        }
        if (nextNavigation.route) {
            dispatch(nextNavigation.route);
        }
    };

    return (
        <SaveAction
            disabled={!isComplete}
            onSave={handleSave}
            hasNext={nextNavigation.route !== null} />
    );
};
