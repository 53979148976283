import {Position} from 'libs/models/position';
import {DirectionRecord} from 'libs/models/types';

export type KnotId = number;
export type KnotConnections = DirectionRecord<KnotId>;

export interface Knot {
    id: KnotId;
    position: Position;
    connections: KnotConnections;
}


export const mainKnotId = 0;
export const noKnotId = -1;

export const defaultEmptyConnections: KnotConnections = {
    top: noKnotId,
    right: noKnotId,
    bottom: noKnotId,
    left: noKnotId,
}

/**
 * Ein neuer Knoten ohne Verbundene Kinder.
 */
export function buildKnotWithoutConnections(id: KnotId, position: Position): Knot {
    return {
        id,
        position,
        connections: {
            ...defaultEmptyConnections
        }
    }
}


export function buildKnotWithConnections(id: KnotId, position: Position, connections: Partial<KnotConnections>): Knot {
    return {
        id,
        position,
        connections: {
            ...defaultEmptyConnections,
            ...connections
        }
    }
}


export function nextKnotId(knots: Knot[]): KnotId {
    const maxId: number = knots.reduce((acc: number, curr: Knot): number => {
        return Math.max(curr.id, acc);
    }, 0);

    return (maxId + 1);
}
