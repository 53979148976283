import {nameForIndex} from 'app/config';
import {useAppSelector} from 'app/store/hooks';
import {getKnotConnectionTypeTitle} from 'libs/models/order';
import React from 'react';
import {selectTrackKnots} from 'state/track/trackSelectors';

export const ItemConnectorDetail = (): JSX.Element => {
    const knots = useAppSelector(selectTrackKnots);
  const titleStyle = {
    fontWeight: 700,
    paddingBottom: '.5rem',
  };

  const tableStyles = {
    width: '120mm',
    marginLeft: '25mm',
    marginBottom: '5mm',
    backgroundColor: '#fff'
  };

  const ThStyles = {
    fontWeight: 500,
    marginBottom: '1mm',
    paddingBottom: '0.5rem',
    width: '40mm',
  };

  const tBodyStyles = {
    width: '145mm',
  };


  const tHeadStyles = {
    borderBottom: '1px solid black',
  };

  const tdStyles = {
    borderRight: '1px solid rgb(100,100,100)',
    paddingBottom: '0.25rem',
    width: '40mm'
  }

    const tBodyItems = knots.filter((knot) => knot.id !== 0).map((knot, idx) => {
        return (
            <tr key={idx + '-tr-knot'}>
                <td style={tdStyles}>{nameForIndex(knot.id)}</td>
                <td>{getKnotConnectionTypeTitle(knot)}</td>
            </tr>
        );
    });

    return (
        <div className={'pdf_item_table'}>
            <table style={tableStyles}>
                <thead style={tHeadStyles}>
                <tr>
                  <h3 style={titleStyle}>Verbinder Detail</h3>
                </tr>
                <tr>
                    <th style={ThStyles}>Verbindung</th>
                    <th style={ThStyles}>Typ</th>
                </tr>
                </thead>
                <tbody style={tBodyStyles}>
                    {tBodyItems}
                </tbody>
            </table>
        </div>
    );
};


