import {useAppSelector} from 'app/store/hooks';
import {OutletNavigationSvg} from 'components/common/svg/OutletNavigationSvg';
import {RoomNavigationSvg} from 'components/common/svg/RoomNavigationSvg';
import {TrackNavigationSvg} from 'components/common/svg/TrackNavigationSvg';
import {TypeNavigationSvg} from 'components/common/svg/TypeNavigationSvg';
import {LightNavigationSvg} from 'components/common/svg/LightNavigationSvg';
import {OrderNavigationSvg} from 'components/common/svg/OrderNavigationSvg';
import {NavigationItem} from 'components/navigation/NavigationItem';
import React from 'react';
import {selectViewMode} from 'state/scene/sceneSelectors';
import {
    useStepNavigationForPageLight,
    useStepNavigationForPageOrder,
    useStepNavigationForPageOutlet,
    useStepNavigationForPageRoom,
    useStepNavigationForPageTrack,
    useStepNavigationForPageType
} from 'state/step/useStepNavigation';
import {mainNavigationStyles} from 'components/navigation/styles/MainNavigationStyles';
import {tw} from 'twind/css';


export const MainNavigation = (): JSX.Element => {

    const sceneViewMode = useAppSelector(selectViewMode);
    const isThree = sceneViewMode.isThree;

    return (
        <nav className={isThree? tw(mainNavigationStyles) + ' disabled' : tw(mainNavigationStyles)}>
            <SelectTypeLink/>
            <EditRoomLink/>
            <EditOutletLink/>
            <EditTrackLink/>
            <EditLightLink/>
            <EditOrderLink/>
        </nav>
    );
};

const SelectTypeLink = (): JSX.Element => {
    const navigation = useStepNavigationForPageType();
    return (
        <NavigationItem label={'Raumform'} {...navigation}>
            <TypeNavigationSvg />
        </NavigationItem>
    );
}

const EditRoomLink = (): JSX.Element => {
    const navigation = useStepNavigationForPageRoom();
    return (
        <NavigationItem label={'Grundriss'} {...navigation}>
            <RoomNavigationSvg/>
        </NavigationItem>
    );
}

const EditOutletLink = (): JSX.Element => {
    const navigation = useStepNavigationForPageOutlet();

    return (
        <NavigationItem label={'Auslass'} {...navigation}>
            <OutletNavigationSvg/>
        </NavigationItem>
    );
}

const EditTrackLink = (): JSX.Element => {
    const navigation = useStepNavigationForPageTrack();

    return (
        <NavigationItem label={'Schienen'} {...navigation}>
            <TrackNavigationSvg/>
        </NavigationItem>
    );
}


const EditLightLink = (): JSX.Element => {
    const navigation = useStepNavigationForPageLight();

    return (
        <NavigationItem label={'Strahler'} {...navigation}>
            <LightNavigationSvg/>
        </NavigationItem>
    );
}


const EditOrderLink = (): JSX.Element => {
    const navigation = useStepNavigationForPageOrder();

    return (
        <NavigationItem label={'Abschluss'} {...navigation}>
            <OrderNavigationSvg/>
        </NavigationItem>
    );
}




