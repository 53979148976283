import {Line, lineDirection} from 'libs/models/line';
import {Position, PositionsMinMax, validatePositionIsInMinMaxInclusive} from 'libs/models/position';

export function calculateOffsetBoundsForLine(line: Line<Position>, offset: number): PositionsMinMax {
    const direction = lineDirection(line);
    if(direction < 0) {
        return {
            maxX: line.from.x + offset,
            minX: line.to.x - offset,
            maxY: line.from.y + offset,
            minY: line.to.y - offset,
        }
    }

    return {
        minX: line.from.x - offset,
        maxX: line.to.x + offset,
        minY: line.from.y - offset ,
        maxY: line.to.y + offset,
    }
}

export function validatePositionAIsInTheOffsetOfLine(line: Line<Position>, position: Position, offset: number): boolean {
    const bounds = calculateOffsetBoundsForLine(line, offset);

    return validatePositionIsInMinMaxInclusive(position, bounds);

}
