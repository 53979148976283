import {PositionsMinMax} from 'libs/models/position/meassurePosition';
import {Position} from 'libs/models/position/position';


/**
 * Prüft ob der Punkt innerhalb oder auf den MinMax werten liegt.
 */
export function validatePositionIsInMinMaxInclusive(position: Position, bounds: PositionsMinMax): boolean {
    return (position.x >= bounds.minX)
        && (position.x <= bounds.maxX)
        && (position.y >= bounds.minY)
        && (position.y <= bounds.maxY)
        ;
}

/**
 * Prüft, ob der Punkt innerhalb aber nicht auf den MinMax Werten liegt.
 */
export function validatePositionIsInMinMaxExclusive(position: Position, bounds: PositionsMinMax): boolean {
    return (position.x > bounds.minX)
        && (position.x < bounds.maxX)
        && (position.y > bounds.minY)
        && (position.y < bounds.maxY)
        ;
}

/**
 * Check if position A is in min and max position B plus offset.
 * @param positionA {Position}
 * @param positionB {Position}
 * @param offset number
 * @return boolean, true if the position A is in position B plus offset otherwise false
 */
export function validatePositionAIsInTheOffsetOfPositionB(positionA: Position, positionB: Position, offset: number): boolean {
    const offsetBounds = calculateOffsetBoundsForPosition(positionB, offset);

    return validatePositionIsInMinMaxInclusive(positionA, offsetBounds)
}


/**
 * Calculates MinMaxValues for a position
 * @param position
 * @param offset
 */

export function calculateOffsetBoundsForPosition(position: Position, offset: number): PositionsMinMax {
    return {
        minX: position.x - offset,
        maxX: position.x + offset,
        minY: position.y - offset,
        maxY: position.y + offset,
    }
}


