import {Line, Text} from '@react-three/drei';
import {lineCenter, lineLength, PositionLine} from 'libs/models/line';
import {positionThreeFromPointThree, rotationHorizontal, threeToCM} from 'libs/view';
import React from 'react';

type DragLineProps = {
    isActive: boolean;
    line: PositionLine;
}

export const DragLine = ({isActive, line}: DragLineProps): JSX.Element => {
    const length = threeToCM(lineLength(line))
    const center = positionThreeFromPointThree(lineCenter(line), 1)

    if (isActive) {
        // noinspection RequiredAttributes
        return (
            <>
                <Text position={center} rotation={rotationHorizontal} fontSize={0.1} color={'black'} outlineColor={'white'}  outlineWidth={0.015    } outlineBlur={0.01}>{length} cm</Text>
                <Line
                    points={[
                        positionThreeFromPointThree(line.from),
                        positionThreeFromPointThree(line.to)
                    ]}
                    color={'#c45ee0'}
                    lineWidth={2}
                />
            </>
        )
    }

    return (<></>)
}
