import {useAppSelector} from 'app/store/hooks';
import {ResetOutletAction} from 'components/actions/outlet/ResetOutletAction';
import {SaveOutletAction} from 'components/actions/outlet/SaveOutletAction';
import {selectStepNeedsResetPageOutlet} from 'state/step/selectStepNeedReset';

export const OutletActions = (): JSX.Element => {
    const needsReset = useAppSelector(selectStepNeedsResetPageOutlet);
    if (needsReset) {
        return (
            <ResetOutletAction />
        );
    }
    return (
        <SaveOutletAction />
    );
}
