export const eckConnectorOffset = 2.5;
export const tConnectorOffset = {
    withOpposite: 6.5,
    withoutOpposite: 7.4,
};

/**
 * System colors:
 * sw (black),
 * ws (white),
 * ch (chrome),
 * mcgy (matt chrom)
 */
export type SystemColor = 'sw' | 'ws' | 'ch' | 'mcgy';

/**
 * Additional colors:
 *   sw (black),
 *   go (gold),
 */
export type AdditionalColor = 'sw' | 'go';

export type ConnectorTypes = 'innerConnectors' | 'lConnectors' | 'tConnectors' | 'endCaps';

/**
 * Accessories parts mapping grouped by system color.
 * Maps product number to parts.
 */
export const AccessoriesPartsMapping = {
    sw: {
        innerConnectors: '860082sw',
        lConnectors: '860081sw',
        tConnectors: '860084sw',
        endCaps: '860090sw',
        trackTwoMeter: '860020sw',
        trackOneMeter: '860010sw',
        outlets: {
            end: '860052sw',
            mittel: '860053sw',
            eck: '860064sw',
        }
    },
    ws: {
        innerConnectors: '860082ws',
        lConnectors: '860081ws',
        tConnectors: '860084ws',
        endCaps: '860090ws',
        trackTwoMeter: '860020ws',
        trackOneMeter: '860010ws',
        outlets: {
            end: '860052ws',
            mittel: '860053ws',
            eck: '860064ws',
        }
    },
    ch: {
        innerConnectors: '860082ch',
        lConnectors: '860081ch',
        tConnectors: '860084ch',
        endCaps: '860090mcgy',
        trackTwoMeter: '860020ch',
        trackOneMeter: '860010ch',
        outlets: {
            end: '860052ch',
            mittel: '860053ch',
            eck: '860064ch',
        }
    },
    mcgy: {
        innerConnectors: '860082mcgy',
        lConnectors: '860081mcgy',
        tConnectors: '860084mcgy',
        endCaps: '860090mcgy',
        trackTwoMeter: '860020mcgy',
        trackOneMeter: '860010mcgy',
        outlets: {
            end: '860052mcgy',
            mittel: '860053mcgy',
            eck: '860064mcgy',
        }
    },
};

