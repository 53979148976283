import React from 'react';
import {styles} from 'components/common/contactBanner/styles/styles';
import {tw} from 'twind/css';

export type ContactBannerProps = React.ComponentProps<'html'> & {
    title: string,
    linkUrl: string,
    linkTitle: string,
}


export const ContactBanner = ({title, linkUrl, linkTitle}: ContactBannerProps): JSX.Element => {
    return (
        <div className={tw(styles)}>
            <h2>{title}</h2>
            <div className={'content'}>
                <a
                    className={'contact'}
                    href={linkUrl}
                >
                    {linkTitle}
                </a>
            </div>
        </div>
    )
}


