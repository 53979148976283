import React, {ChangeEvent} from 'react';


export type NumberInputEventArgs = {
    value: number;
};
export type NumberInputEventHandler = (args: NumberInputEventArgs) => void;

export type NumberInputProps = {
    onNumberChange?: NumberInputEventHandler,
    confirmNumberChange?: () => void,
} & Omit<React.ComponentProps<'input'>, 'onChange' | 'type'>

export const NumberInput = ({onNumberChange, ...inputProps}: NumberInputProps): JSX.Element => {
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onNumberChange) {
            onNumberChange({value: Number(event.target.value)});
        }
    };

    return (
        <input
            {...inputProps}
            type={'number'}
            onChange={handleInputChange}
        />
    )
}

export const NumberInputConfirm = ({onNumberChange, confirmNumberChange, ...inputProps}: NumberInputProps): JSX.Element => {

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onNumberChange) {
            onNumberChange({value: parseInt(event.target.value)})
        }
    }

    const handleKeyInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (confirmNumberChange) {
                confirmNumberChange()
            }
        }
    }

    return (
        <input
            {...inputProps}
            type={'number'}
            onInput={handleInputChange}
            onChange={handleInputChange}
            onKeyUp={handleKeyInput}
        />
    )
}
