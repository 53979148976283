import {Html} from '@react-three/drei';
import {nameForIndex} from 'app/config';
import {htmlDistanceFactor} from 'app/config/3DParams';
import {wallIconStyles} from 'components/common/3d/wall/styles/styles';
import {Wall} from 'components/common/3d/wall/Wall';
import {Position} from 'libs/models/position';
import {PositionThree, WallSegmentModel} from 'libs/view';
import {tw} from 'twind/css';

export type EditableWallSegmentProps = {
    onLengthChange: (newLength: number) => void,
    onPositionChange: (position: Position) => void,
    wall: WallSegmentModel;
    position?: PositionThree;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const EditableWallSegment = (
    {
        wall,
    }: EditableWallSegmentProps): JSX.Element => {

    // noinspection RequiredAttributes
    return (
        <>
            <group position={wall.center}>
                <Html
                    distanceFactor={htmlDistanceFactor}
                    className={tw(wallIconStyles)}
                    center
                >
                    <p className={'wall-name'}>
                        {nameForIndex(wall.index)}
                    </p>
                    <div className={wall.isHorizontal ? 'icon-container horizontal' : 'icon-container vertical'}>
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 24 24"
                            width={30}
                            height={30}
                        >
                            <path className={tw('fill-none')} d="M0,0h24v24H0V0z"/>
                            <polygon points="22.4,12 18.4,8 18.4,11 17.1,11 6.4,11 5.1,11 5.1,8 1.1,12 5.1,16 5.1,13 6.4,13 17.1,13 18.4,13 18.4,16 "/>
                        </svg>
                    </div>
                </Html>
                <Wall
                    isHorizontal={wall.isHorizontal}
                    length={wall.length}
                    position={[0, 0, 0]}
                    color={'#723782'}
                />
            </group>
        </>
    );
}
