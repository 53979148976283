import {useAppSelector} from 'app/store/hooks';
import {Position, PositionsMinMax, validatePositionIsInMinMaxExclusive} from 'libs/models/position';
import {isPointInside, Room} from 'libs/models/room';
import {useCallback} from 'react';
import {selectRoom, selectRoomMinMaxValues} from 'state/room/roomSelectors';

export function useRoomBounds(): {isPointInRoom: (position: Position) => boolean, roomBounds: PositionsMinMax, room: Room} {
    const room = useAppSelector(selectRoom);
    const roomBounds = useAppSelector(selectRoomMinMaxValues);

    const isPointInRoom = useCallback((position: Position) => {
        return validatePositionIsInMinMaxExclusive(position, roomBounds)
            && isPointInside(room.walls, position);
    }, [room, roomBounds]);

    return {isPointInRoom, roomBounds, room};
}

