import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store/store';
import {EntityData} from 'libs/client/clientApi';
import {EntityState} from 'state/entity/entityReducer';
import {selectLightEntityData} from 'state/light/lightSelectors';
import {selectOrderProducts} from 'state/order/orderSelectors';
import {selectOutletEntityData} from 'state/outlet/outletSelectors';
import {selectRoomEntityData, selectRoomOutlines} from 'state/room/roomSelectors';
import {selectSceneEntityData} from 'state/scene/sceneSelectors';
import {selectShopEntityData} from 'state/shop/shopSelectors';
import {selectTrackEntityData} from 'state/track/trackSelectors';

export const selectEntityState = (state: RootState): EntityState => state.entity;

export const selectEntityId = createSelector(
    [selectEntityState],
    (client) => client.entityId
);

/**
 * Dieser Selector wird zum speichern der Daten verwendet.
 * Alles was gespeichert werden soll muss hier eingetragen werden.
 */
export const selectEntityData = createSelector(
    [selectRoomEntityData, selectOutletEntityData, selectTrackEntityData, selectLightEntityData, selectSceneEntityData, selectRoomOutlines, selectOrderProducts, selectShopEntityData],
    (room, outlet, tracks, light, scene, outlines, products, shop): EntityData => {
        return {
            room,
            outlet,
            tracks,
            light,
            scene,
            outlines,
            products,
            shop
        };
    }
);
