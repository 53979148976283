import {useAppDispatch} from 'app/store/hooks';
import {PanelEditContainer} from 'components/common/panel/PanelEditContainer';
import React, {useEffect} from 'react';
import {fetchSpotProducts} from 'state/shop/shopApi';
import {SpotAmountEditor} from 'components/panel/light/SpotAmountEditor';
import {SpotMainProductSelector} from 'components/panel/light/SpotMainProductSelector';
import {SpotAmountDisplay} from 'components/panel/light/SpotAmountDisplay';
import {SpotVariantProductSelector} from 'components/panel/light/SpotVariantProductSelector';


export const EditLightPanel = (): JSX.Element => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchSpotProducts())
    }, [dispatch]);


    return (
        <PanelEditContainer title={'Strahler'}>
            <SpotAmountDisplay />
            <SpotAmountEditor />
            <SpotMainProductSelector />
            <SpotVariantProductSelector />
        </PanelEditContainer>
    );
};
