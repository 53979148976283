import React from 'react';
import {tw} from 'twind/css';

export const LightNavigationSvg = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px" y="0px"
            viewBox="0 0 22.8 22.8"
            className={tw('mb-1') + ' light-icon'}
            width={24}
            height={24}
            {...props}
        >
            <g>
                <polygon
                    className={tw('fill-none')}
                    points="2,2 2,12.2 12.2,2"
                />
                <path
                    className={tw('fill-current')}
                    d="M0,0v17.1L16.7,0.4L17.1,0H0z M2,12.2V2h10.1L2,12.2z"
                />
                <polygon
                    className={tw('fill-current')}
                    points="5.5,15.6 5.5,22.8 7.6,22.8 7.6,13.6"
                />
                <polygon
                    className={tw('fill-current')}
                    points="15.6,5.5 13.6,7.6 22.8,7.6 22.8,5.5"
                />
                <rect
                    x="15.3" y="11"
                    transform="matrix(0.7072 -0.707 0.707 0.7072 -6.723 16.2644)"
                    className={tw('fill-current')}
                    width={2}
                    height="10.5"
                />
            </g>
        </svg>
    );
};
