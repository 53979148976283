import {styles} from 'components/common/panel/styles/PanelEditContainerStyles';
import React from 'react';
import Link from 'redux-first-router-link';
import {tw} from 'twind/css';

export const ErrorPanel = (): JSX.Element => {

    return (
        <>
            <div className={tw(styles)}>
                <div className={'container'}>
                    <div className={'title'}>
                        <h2>Fehler</h2>
                    </div>
                </div>
                <div className={'item'}>
                    <p>
                        Es ist ein Fehler aufgetreten.
                        <br/>
                        <Link to={'/'}>Neu Starten</Link>
                    </p>
                </div>
            </div>
        </>
    );
};
