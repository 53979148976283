import {useAppDispatch} from 'app/store/hooks';
import {ResetAction} from 'components/common/actions/ResetAction';
import React from 'react';
import {stepResetPageLight} from 'state/step/stepReset';
import {useStepNavigationForPageOrder} from 'state/step/useStepNavigation';

export const ResetLightAction = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const nextNavigation = useStepNavigationForPageOrder();


    const handleConfirmDelete = () => {
        dispatch(stepResetPageLight());
    };
    const handleCancelDelete = () => {
        if (nextNavigation.route) {
            dispatch(nextNavigation.route);
        }
    };


    return (
        <ResetAction onConfirm={handleConfirmDelete} onCancel={handleCancelDelete}>
            <p>
                Durch das ändern der Lichter, TODO: (evtl. die Bestellung löschen?)
            </p>
        </ResetAction>
    );
}
