import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {PanelEditContainer} from 'components/common/panel/PanelEditContainer';
import {OrderCartPrice} from 'components/panel/order/OrderCartPrice';
import {OrderConnectorTypes} from 'components/panel/order/OrderConnectorTypes';
import {OrderOutletType} from 'components/panel/order/OrderOutletType';
import {OrderSpotlightAmount} from 'components/panel/order/OrderSpotlightAmount';
import {OrderTracksInfo} from 'components/panel/order/OrderTracksInfo';
import {OrderTracksList} from 'components/panel/order/OrderTracksList';
import React, {useEffect} from 'react';
import {selectEntityId} from 'state/entity/entitySelectors';
import {selectOrderProducts} from 'state/order/orderSelectors';
import {fetchSpotProducts, saveOrderProducts} from 'state/shop/shopApi';

export const EditOrderPanel = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const entityId = useAppSelector(selectEntityId);
    const products = useAppSelector(selectOrderProducts);

    useEffect(() => {
        dispatch(fetchSpotProducts())
          }, [dispatch]);

    useEffect(() => {
        if (entityId && products.length > 0) {
            dispatch(saveOrderProducts({entityId, products}));
        }
    }, [dispatch, entityId, products]);

    return (
        <>
            <PanelEditContainer title={'Konfigurationsübersicht'}>
                    <OrderCartPrice />
                    <OrderOutletType />
                    <OrderSpotlightAmount />
                    <OrderConnectorTypes />
                    <OrderTracksInfo />
                    <OrderTracksList />
            </PanelEditContainer>
        </>
    );
};
