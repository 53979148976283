import {Position} from 'libs/models/position/index';
import {wallSizeConfig} from 'app/config/wallSize';

export type RoomOuterPoints = [
    topLeft: Position,
    topRight: Position,
    bottomRight: Position,
    bottomLeft: Position,
]

export type PositionsMinMax = {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
};

export const EMPTY_MIN_MAX: PositionsMinMax = {
    minX: wallSizeConfig.max,
    maxX: -wallSizeConfig.max,
    minY: wallSizeConfig.max,
    maxY: -wallSizeConfig.max
};


/**
 * Berechnet die 4 Ecken die die gegebenen Positionen umschließen.
 *
 * Die Außenmaße - also das Viereck, in das der Raum (unabhängig von der Form) reinpasst.
 */
export function meassurePositionsOuterPoints(positions: Position[]): RoomOuterPoints {
    const values = meassurePositionsMinMaxValues(positions);

    return [
        {
            x: values.minX,
            y: values.minY,
        },
        {
            x: values.maxX,
            y: values.minY,
        },
        {
            x: values.maxX,
            y: values.maxY
        },
        {
            x: values.minX,
            y: values.maxY
        }
    ];
}

/**
 * Berechnet die minimalen und maximalen X und Y Werte.
 */
export function meassurePositionsMinMaxValues(positions: Position[]): PositionsMinMax {
    return positions.reduce((acc, curr) => {
        return {
            minX: Math.min(acc.minX, curr.x),
            maxX: Math.max(acc.maxX, curr.x),
            minY: Math.min(acc.minY, curr.y),
            maxY: Math.max(acc.maxY, curr.y),
        }
    }, EMPTY_MIN_MAX);
}

/**
 * Vergrößert oder verkleinert die MinMax Werte um den angegeben Wert.
 */
export function resizePositionsMinMaxValues(bounds: PositionsMinMax, amount: number): PositionsMinMax {
    if (amount === 0) {
        return bounds;
    }
    return {
        minX: bounds.minX - amount,
        maxX: bounds.maxX + amount,
        minY: bounds.minY - amount,
        maxY: bounds.maxY + amount,
    }

}
