import {createAction} from '@reduxjs/toolkit';
import {PayloadActionCreator} from '@reduxjs/toolkit/src/createAction';
import {AppDispatch, RootState} from 'app/store/store';
import {StateGetter} from 'redux-first-router';
import {ViewConfiguration} from 'state/view/viewModels';
import {updateViewConfiguration} from 'state/view/viewReducer';


export type PayloadType = Record<string, unknown> | void;


export type PageRouteActions<PagePayload extends PayloadType = void> = {
    route: PayloadActionCreator<PagePayload>;
    fullfilled: PayloadActionCreator<PagePayload>;
    rejected: PayloadActionCreator<unknown>;
}


export type PageRouteThunk<PagePayload> = (dispatch: AppDispatch, getState: StateGetter<RootState>) => Promise<PagePayload>

export type PageRoute<PagePayload extends PayloadType = void> = PageRouteActions<PagePayload> & {
    thunk: PageRouteThunk<PagePayload>;
}


function createPageRouteActions<PagePayload extends PayloadType = void>(pageName: string): PageRouteActions<PagePayload> {
    return {
        route: createAction<PagePayload>(`page/${pageName}/to`),
        fullfilled: createAction<PagePayload>(`page/${pageName}/fullfilled`),
        rejected: createAction(`page/${pageName}/rejected`),
    };
}

function createPageRouteThunk<PagePayload extends PayloadType = void>(actions: PageRouteActions<PagePayload>, configuration: PageRouteConfig<PagePayload>): PageRouteThunk<PagePayload> {
    const viewConfig = configuration.view;

    return function (dispatch: AppDispatch, getState: StateGetter<RootState>): Promise<PagePayload> {
        const payload = getState().location.payload as PagePayload;
        const context: PageRouteContext = {
            getState,
            dispatch
        };
        return configuration.loader(payload, context)
            .then(() => {
                dispatch(updateViewConfiguration(viewConfig));
            })
            .then(() => {
                dispatch(actions.fullfilled(payload));
                return Promise.resolve(payload);
            })
            .catch((rejectedReason) => {
                dispatch(actions.rejected({...payload, rejectedReason}))
                return {...payload, rejectedReason};
            });
    }
}


export type PageRouteContext = {
    dispatch: AppDispatch,
    getState: StateGetter<RootState>
};

export type PageLoader<PagePayload extends PayloadType = void> = (payload: PagePayload, ctx: PageRouteContext) => Promise<unknown>;

export type PageRouteConfig<PagePayload extends PayloadType = void> = {
    view: ViewConfiguration;
    loader: PageLoader<PagePayload>;

}

export function createPageRoute<PagePayload extends PayloadType = void>(pageName: string, configuration: PageRouteConfig<PagePayload>): PageRoute<PagePayload> {
    const actions = createPageRouteActions<PagePayload>(pageName);
    const thunk = createPageRouteThunk<PagePayload>(actions, configuration);

    return {...actions, thunk};
}

