import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {entityReducer} from 'state/entity/entityReducer';
import {lightReducer} from 'state/light/lightReducer';
import {locationEnhancer, locationMiddleware, locationReducer} from 'state/location/locationReducer';
import {orderReducer} from 'state/order/orderReducer';
import {outletReducer} from 'state/outlet/outletReducer';
import {presetReducer} from 'state/preset/presetReducer';
import {roomReducer} from 'state/room/roomReducer';
import {sceneReducer} from 'state/scene/sceneReducer';
import {shopReducer} from 'state/shop/shopReducer';
import {trackReducer} from 'state/track/trackReducer';
import {viewReducer} from 'state/view/viewReducer';


export const store = configureStore({
    reducer: {
        location: locationReducer,
        room: roomReducer,
        outlet: outletReducer,
        track: trackReducer,
        scene: sceneReducer,
        view: viewReducer,
        entity: entityReducer,
        preset: presetReducer,
        light: lightReducer,
        order: orderReducer,
        shop: shopReducer,
    },
    middleware: (getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredPaths: ['location']
        }
    }).concat(locationMiddleware)),
    enhancers: (defaultEnhancers) => [locationEnhancer, ...defaultEnhancers]
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;


export type AppAsyncThunkConfig = {
    state: RootState;
    dispatch: AppDispatch;
};
