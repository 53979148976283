import {maxDistanceFromWallToSetSpotDirectionDown} from 'app/config/light';
import {Knot} from 'libs/models/knot';
import {mapKnotConnectionsToUniqueLines} from 'libs/models/knot/knotLines';
import {Spotlight} from 'libs/models/light/light';
import {lineIsHorizontal} from 'libs/models/line';
import {
    axesDistanceLinesAsArrayWithDirection,
    calculateAxesDistanceAsLines,
    DistanceLineWithDirection,
    Room
} from 'libs/models/room';
import {isSpotlightInOffsetOfAKnot, isSpotlightIntersectingWithAnother,} from 'libs/models/validation/lightValidation';
import {SpotlightsValidation} from 'libs/notification/lightNotifications';
import {pointCMFromPositionThree, PositionThree} from 'libs/view';

export function moveSpotlightTo(spotlights: Spotlight[], spotlight: Spotlight, position: PositionThree, knots: Knot[], room: Room): SpotlightsValidation {

    if (isSpotlightInOffsetOfAKnot(knots, position)) {
        return {isValid: false, spotlights: spotlights, type: 'invalid-position-spot-to-knot'};
    }

    if (isSpotlightIntersectingWithAnother(position, spotlights, spotlight)) {
        return {isValid: false, spotlights: spotlights, type: 'invalid-position-spot-to-spot'};
    }

    const axesDistanceLines = calculateAxesDistanceAsLines(room.walls, pointCMFromPositionThree(position));
    const shortestDistanceLine = axesDistanceLinesAsArrayWithDirection(axesDistanceLines).reduce((previousValue, currentValue): DistanceLineWithDirection => {
        return previousValue.length < currentValue.length ? previousValue : currentValue;
    });

    const lines = mapKnotConnectionsToUniqueLines(knots);
    const newPoint = pointCMFromPositionThree(position);
    const line = lines.find((indexLine) =>
        (indexLine.to.x === newPoint.x && indexLine.from.x === newPoint.x) ||
        (indexLine.to.y === newPoint.y && indexLine.from.y === newPoint.y)
    );

    const changedSpotlights: Spotlight[] = spotlights.map((spot) => {
        if (spot.id === spotlight.id && line) {
            return {
                ...spot,
                isHorizontal: lineIsHorizontal(line),
                position: position,
                direction: shortestDistanceLine.length < maxDistanceFromWallToSetSpotDirectionDown ? shortestDistanceLine.direction : 'down',
                point: pointCMFromPositionThree(position)
            };
        }

        return spot;
    });

    return {isValid: true, spotlights: changedSpotlights, type: null};
}
