import {css, apply} from 'twind/css';

export const variantMap = {
    primary: css({
        '@apply': 'bg-primary text-white tracking-wide min-w-190 shadow-lg z-7777777',
        '&:hover': {
            '@apply': 'bg-primary-light shadow-xl',
        },
        '&:focus, &:active': {
            '@apply': 'bg-primary-dark text-white',
        },
        '&[disabled]': {
            '@apply' : 'bg-gray-300 pointer-events-none',
        }
    }),
    secondary: css({
        '@apply': 'border-2 border-primary text-primary tracking-wide min-w-190 z-7777777',
        '&:hover': {
            '@apply': 'text-white bg-primary-light border-primary-light',
        },
        '&:focus, &:active': {
            '@apply': 'bg-primary-dark text-white',
        },
        '&[disabled]': {
            '@apply' : 'bg-gray-300 pointer-events-none',
        }
    }),
}

export const baseStyles = apply`font-bold px-8 py-3 rounded rounded-full text-sm transition duration-200 ease-in-out`;
