import {Position} from 'libs/models/position/position';
import {Direction} from 'libs/models/types';

export function movePositionByOffsetInDirection<T extends Position>(position: T, offset: number, direction: Direction): T {
    switch (direction) {
        case 'top':
            return {
                ...position,
                y: (position.y - offset)
            };
        case 'right':
            return {
                ...position,
                x: (position.x + offset)
            };
        case 'bottom':
            return {
                ...position,
                y: (position.y + offset)
            };
        case 'left':
            return {
                ...position,
                x: (position.x - offset)
            };


    }
    return position;
}
