import {MainActions} from 'components/actions/MainActions';
import {Modal} from 'components/common/modal/Modal';
import {BurgerMenu} from 'components/navigation/BurgerMenu';
import {MainNavigation} from 'components/navigation/MainNavigation';
import {MainPanel} from 'components/panel/MainPanel';
import {MainScene} from 'components/scene/MainScene';
import {appMainStyles, AppNavigationStyles, tracklightAppStyles, tracklightAppLoadingStyles} from 'components/styles/styles';
import React, {useEffect, useState} from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import {ReactComponent as Help} from 'style/assets/help.svg';
import {ReactComponent as Logo} from 'style/assets/logo.svg';
import {tw} from 'twind/css';
import {useAppSelector} from 'app/store/hooks';
import {selectIsLoading} from 'state/view/viewSelectors';
import {ToggleViewModeButton} from './panel/3d/ToggleViewMode';

export const App = (): JSX.Element => {
    const isLoading = useAppSelector(selectIsLoading);
    if(isLoading) {
      return (
        <>
          <div  className={tw(tracklightAppLoadingStyles)}>
            <div className={'container'}>
              <div className="inner">
                <h2 className={'loading-headline'}>
                  Ihre Konfiguration wird gespeichert, bitte haben Sie einen Augenblick Geduld.
                </h2>
                <div className={'spinner'}></div>
              </div>
              <div className={'blur'}>
                <AppNavigation/>
                <AppMain/>
              </div>
            </div>
          </div>
        </>
      );
    } else {
    return (
          <>
              <ReactNotification className={tw(tracklightAppStyles)} isMobile={true} />
              <div className={'main'}>
                  <AppNavigation/>
                  <AppMain/>
              </div>
          </>
      );
  }
}

const AppNavigation = (): JSX.Element => {
    const icon = <Help className={'help-icon'}/>;
    return (
        <header className={tw(AppNavigationStyles)}>
            <div className={'logo-container'}>
                <Logo className={'logo'}/>
            </div>
            <MainNavigation/>
            <div className={'SecondaryNavigation'}>
                <Modal
                    buttonTitle={'Hilfe'}
                    modalTitle={'Brauchst du Hilfe?'}
                    icon={icon}
                    closeTitle={'Zurück'}
                >
                    <p>
                        Falls Sie weitergehende Fragen haben: Immer her damit! Sie möchten mehr zum Konfigurator, Material
                        oder der Austattung erfahren? Wir sind für Sie da und haben immer ein offenes Ohr für Ihre
                        Anliegen.
                        Ihnen fehlen Optionen oder Möglichkeiten im Konfigurator? Kein Problem! Auch für Ihre
                        individuellen Anforderungen finden wir gemeinsam die perfekte Lösung.
                        Also nichts wie los, Seyfert wartet auf Sie und auf Ihren Anruf oder eine
                        E-Mail!
                    </p>
                    <div className={tw('flex flex-col')}>
                        <a
                            className={'contact'}
                            href="mailto:info@seyfert-lichtdesign.de"
                        >
                            info@seyfert-lichtdesign.de
                        </a>
                        <a className={'contact'}
                           href="tel:+49026129683028"
                        >
                            +49 (0) 261.296 83 028
                        </a>
                    </div>
                </Modal>
                <BurgerMenu/>
            </div>
        </header>
    );
};

const AppMain = (): JSX.Element => {
    const [isShown, setIsShown] = useState(false)

    const toggleClass = () => {
        const sceneWrapper = document.getElementsByTagName('main')[0];
        if(isShown) {
            sceneWrapper.style.width = 'calc(100% - var(--sb-width)';
        }
        setIsShown(!isShown);
    };

    const GetNavHeight = () => {
        useEffect(() => {
            const header = document.getElementsByTagName('header')[0];
            const hHeight = header.offsetHeight;
            const root = document.documentElement;
            if(hHeight) {
              root.style.setProperty('--header-height', hHeight + 'px');
            }
            if(!hHeight) {
              root.style.setProperty('--header-height', 'calc(100vh - 84px)');
            }
        });
    };
    GetNavHeight();

    const GetCanvasHeight = () => {
      useEffect(() => {
        const header = document.getElementsByTagName('main')[0];
        const hHeight = header.offsetHeight;
        const root = document.documentElement;
        root.style.setProperty('--main-height', hHeight + 'px');
      });
    };
  GetCanvasHeight();

    const GetSidebarWidth = () => {
        useEffect(() => {
            const sidebar = document.getElementsByTagName('aside')[0];
            const sbWidth = sidebar.offsetWidth;
            const root = document.documentElement;
            root.style.setProperty('--sb-width', sbWidth + 'px');
        });
    };
    GetSidebarWidth();

    return (
        <div className={tw(appMainStyles)}>
            <aside className={isShown? 'not-shown' : ''}>
            <div className={'btn-wrapper'}>
              <button
                      onClick={toggleClass}
                      className={'btn-show-sidebar'}
                  >
                      <svg xmlns='http://www.w3.org/2000/svg'
                           height={24}
                           width={24}
                           viewBox='0 0 24 24'
                           className={'fill-white'}
                      >
                          <path d="M0 0h24v24H0z" fill="none"/>
                          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                      </svg>
                  </button>
                  <ToggleViewModeButton
                    showIcon={'true'}
                  />
            </div>
                <div className={'main-panel'}>
                    <MainPanel/>
                    <MainActions/>
                </div>
            </aside>
            <main>
                <MainScene/>
                <MainActions/>
            </main>
        </div>
    );
};
