import {createSelector} from '@reduxjs/toolkit';
import {AccessoriesPartsMapping, ConnectorTypes} from 'app/config/order';
import {RootState} from 'app/store/store';
import {ProductItem} from 'libs/client/clientApi';
import {
    calculateAllConnectors,
    calculateConnectorsFromKnots,
    calculateInnerConnectorsFromTracks,
    calculateLinesWithoutConnectorOffset,
    calculateLinesWithoutConnectorOffsetWithoutSort,
    calculateOrderTracksFromLineLengths,
    calculateSameNumbers,
    calculateSingleTracksFromLineLengths,
    getOutletTypeFromKnots
} from 'libs/models/order';
import {selectSpotlightAmount} from 'state/light/lightSelectors';
import {OrderState} from 'state/order/orderReducer';
import {
    selectShopActiveVariantProductNumber,
    selectShopActiveVariantProductSystemColor
} from 'state/shop/shopSelectors';
import {selectTrackKnots} from 'state/track/trackSelectors';

export const selectOrderState = (state: RootState): OrderState => state.order;

export const selectOrderTracks = createSelector(
    [selectTrackKnots],
    (knots) => calculateLinesWithoutConnectorOffset(knots)
);
export const selectOrderTracksWithoutSort = createSelector(
    [selectTrackKnots],
    (knots) => calculateLinesWithoutConnectorOffsetWithoutSort(knots)
);

export const selectOrderOutletType = createSelector(
    [selectTrackKnots],
    (knots) => getOutletTypeFromKnots(knots)
);

export const selectOrderSpotlightAmount = createSelector(
    [selectSpotlightAmount],
    (spotlightAmount) => spotlightAmount
);

export const selectOrderConnectorTypes = createSelector(
    [selectTrackKnots, selectOrderTracks],
    (knots, orderTracks) => {
        const singleTracksFromLineLengths = calculateSingleTracksFromLineLengths(orderTracks);
        const innerConnectorsFromTracks = calculateInnerConnectorsFromTracks(singleTracksFromLineLengths);
        const connectorsFromKnots = calculateConnectorsFromKnots(knots);
        return calculateAllConnectors(innerConnectorsFromTracks, connectorsFromKnots);
    }
);

export const selectOrderTracksInfo = createSelector(
    [selectOrderTracks],
    (orderTracks) => calculateSameNumbers(orderTracks)
);

export const selectOrderTracksList = createSelector(
    [selectOrderTracks],
    (orderTracks) => calculateOrderTracksFromLineLengths(orderTracks)
);

export const selectOrderTracksTwoMetersAmount = createSelector(
    [selectOrderTracksList],
    (orderListResults) => orderListResults.reduce((acc, curr) => curr.trackLength === 200 ? acc + 1 : acc, 0)
);

export const selectOrderTracksOneMetersAmount = createSelector(
    [selectOrderTracksList],
    (orderListResults) => orderListResults.reduce((acc, curr) => curr.trackLength === 100 ? acc + 1 : acc, 0)
);

export const selectOrderProducts = createSelector(
    [
        selectShopActiveVariantProductSystemColor,
        selectShopActiveVariantProductNumber,
        selectOrderSpotlightAmount,
        selectOrderOutletType,
        selectOrderConnectorTypes,
        selectOrderTracksTwoMetersAmount,
        selectOrderTracksOneMetersAmount,
    ],
    (
        systemColor,
        variantProductNumber,
        spotlightAmount,
        outletType,
        connectorTypes,
        trackTwoMetersAmount,
        trackOneMeterAmount,
    ) => {
        const connectorTypesDefinition: ConnectorTypes[] = ['innerConnectors', 'lConnectors', 'tConnectors', 'endCaps'];
        const productNumbers: ProductItem[] = [];

        if (variantProductNumber) {
            productNumbers.push({productNumber: variantProductNumber, quantity: spotlightAmount});

            if (systemColor) {
                const accessoriesParts = AccessoriesPartsMapping[systemColor];
                if (accessoriesParts) {

                    if (outletType && accessoriesParts.outlets[outletType]) {
                        const outletProductNumber = accessoriesParts.outlets[outletType];
                        productNumbers.push({productNumber: outletProductNumber, quantity: 1});
                    }

                    connectorTypesDefinition.forEach((key) => {
                        if (connectorTypes[key] > 0) {
                            productNumbers.push({productNumber: accessoriesParts[key], quantity: connectorTypes[key]});
                        }
                    });

                    if (trackTwoMetersAmount > 0) {
                        productNumbers.push({productNumber: accessoriesParts.trackTwoMeter, quantity: trackTwoMetersAmount});
                    }

                    if (trackOneMeterAmount > 0) {
                        productNumbers.push({productNumber: accessoriesParts.trackOneMeter, quantity: trackOneMeterAmount});
                    }

                }
            }
        }

        return productNumbers;
    }
);

export const selectCartPrice = createSelector(
    [selectOrderState],
    (orderState) => orderState.price
);
