import {Knot} from 'libs/models/knot/knot';
import {Spotlight} from 'libs/models/light/light';
import {Position} from 'libs/models/position';
import {Direction} from 'libs/models/types';

// >>> ID
export type EntityId = string;


// >>> Room
export interface RoomEntityData {
    corners: Position[];
}

export const defaultRoomEntityData: RoomEntityData = {
    corners: [],
}


// >>> Outlet
export interface OutletEntityData {
    position: Position | null;
}

export const defaultOutletEntityData: OutletEntityData = {
    position: null,
}


// >>> Track
export interface TrackEntityData {
    knots: Knot[];
}

export const defaultTrackEntityData: TrackEntityData = {
    knots: [],
}


// >>> Light
export interface LightEntityData {
    spotlightAmount: number;
    spotlights: Spotlight[];
}

export const defaultLightEntityData: LightEntityData = {
    spotlightAmount: 0,
    spotlights: [],
}

// >>> Shop
export interface ShopEntityData {
    activeMainProductId: string | null,
    activeVariantProductId: string | null,
}

export const defaultShopEntityData: ShopEntityData = {
    activeMainProductId: null,
    activeVariantProductId: null,
}

// >>> Scene
export interface SceneEntityData {
    wallColor: string;
    roofColor: string;
    floorTexture: string;
}

export const defaultSceneEntityData: SceneEntityData = {
    wallColor: '#ffffff',
    roofColor: '#ffffff',
    floorTexture: 'ahorn.jpg',
}


export interface ProductItem {
    productNumber: string;
    quantity: number;
}

// >>> Entity
export interface EntityData {
    room: RoomEntityData;
    outlet: OutletEntityData;
    tracks: TrackEntityData;
    light: LightEntityData;
    scene: SceneEntityData;
    outlines: Direction[];
    products: ProductItem[];
    shop: ShopEntityData;
}

export const defaultEntityData: EntityData = {
    room: defaultRoomEntityData,
    outlet: defaultOutletEntityData,
    tracks: defaultTrackEntityData,
    light: defaultLightEntityData,
    scene: defaultSceneEntityData,
    outlines: [],
    products: [],
    shop: defaultShopEntityData,
};


// >>> Extra
export interface ResourceInfoData {
    title: string;
}

export const defaultResourceInfoData: ResourceInfoData = {
    title: '',
}

// >>> Price
export interface CartPrice {
    netPrice: number;
    totalPrice: number;
    positionPrice: number;
    taxStatus: string;
    rawTotal?: number;
    calculatedTaxes: CalculateTax[];
    taxRules: TaxRule[];
    apiAlias: string;
}
export interface CalculateTax {
    price: number;
    tax: number;
    taxRate: number;
    apiAlias: string;
}
export interface TaxRule {
    percentage: number;
    taxRate: number;
    apiAlias: string;
}
export const defaultCartPriceData: CartPrice = {
    netPrice: 0,
    totalPrice: 0,
    positionPrice: 0,
    taxStatus: '',
    calculatedTaxes: [],
    taxRules: [],
    apiAlias: '',
}

// >>> Resource
export interface EntityResource {
    entityId: EntityId;
    data: EntityData;
    info: ResourceInfoData;
}

export const defaultEntityResource: Omit<EntityResource, 'entityId'> = {
    data: defaultEntityData,
    info: defaultResourceInfoData,
};


// >>> Client
export interface ClientApi {

    findEntity(entityId: EntityId): Promise<EntityResource>;

    createEntity(data: EntityData): Promise<EntityResource>;

    updateEntity(entityId: EntityId, data: EntityData): Promise<EntityResource>;

}

