import {Box} from '@react-three/drei';
import {IndexedLine, lineDirectionToDirection, lineIsHorizontal, lineLength} from 'libs/models/line';
import {movePositionByOffsetInDirection} from 'libs/models/position/movePosition';
import {cmToThree, PositionThree, positionThreeFromPositionCM} from 'libs/view';
import React from 'react';
import * as THREE from 'three';

type ThreeDTracksProps = {
    trackLines: IndexedLine[];
    roomHeight: number;
    systemMaterial: THREE.MeshStandardMaterial;
}

export const ThreeDTracks = ({trackLines, roomHeight, systemMaterial}: ThreeDTracksProps): JSX.Element => {

    const tracks = trackLines.map((track, index) => {
        const direction = lineDirectionToDirection(track);
        const length = cmToThree(lineLength(track));
        const offsetPos = movePositionByOffsetInDirection(track.from, lineLength(track) / 2, direction);
        const pos = positionThreeFromPositionCM(offsetPos, (roomHeight / 2));

        const rot: PositionThree = lineIsHorizontal(track) ? [0, Math.PI / 2, 0] : [0, 0, 0];

        return <TrackGroup
            key={'trackgroup-' + index}
            position={pos}
            rotation={rot}
            trackLength={length}
            systemMaterial={systemMaterial}
        />
    })

    return (
        <>
            {tracks}
        </>
    )
}

type TrackGroupProps = {
    position: PositionThree;
    rotation: PositionThree;
    trackLength: number;
    systemMaterial: THREE.MeshStandardMaterial;
}

const TrackGroup = ({position, rotation, trackLength, systemMaterial}: TrackGroupProps) => {
    const trackLengthWithOffset = trackLength + 0.025;
    // noinspection RequiredAttributes
    return (
        <group position={position} rotation={rotation}>
            <Box
                args={[0.025, 0.001, trackLengthWithOffset]}
                material={systemMaterial}
            />
            <Box
                args={[0.025, 0.001, trackLengthWithOffset]}
                rotation={[0, 0, Math.PI / 2]}
                position={[-0.012, -0.0125, 0]}
                material={systemMaterial}
            />
            <Box
                args={[0.025, 0.001, trackLengthWithOffset]}
                rotation={[0, 0, Math.PI / 2]}
                position={[0.012, -0.0125, 0]}
                material={systemMaterial}
            />
            <Box
                args={[0.008, 0.001, trackLengthWithOffset]}
                position={[-0.008, -0.0245, 0]}
                material={systemMaterial}
            />
            <Box
                args={[0.008, 0.001, trackLengthWithOffset]}
                position={[0.008, -0.0245, 0]}
                material={systemMaterial}
            />
        </group>
    )
}
