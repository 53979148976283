import {useAppSelector} from 'app/store/hooks';
import {getInitialSpotlights, getMaxSpotlightsPerTrackLine, Spotlight} from 'libs/models/light/light';
import {IndexedLine} from 'libs/models/line';
import {useCallback} from 'react';
import {selectSpotlightAmount} from 'state/light/lightSelectors';
import {selectRoom} from 'state/room/roomSelectors';
import {selectTrackLines} from 'state/track/trackSelectors';
import {spotlightDistanceFromSpotlight} from 'app/config/light';

export function useInitialSpotlights(): { initialSpotlights: () => Spotlight[] } {
    const room = useAppSelector(selectRoom);
    const trackLines: IndexedLine[] = useAppSelector(selectTrackLines);
    const spotlightAmount: number = useAppSelector(selectSpotlightAmount);

    const initialSpotlights = useCallback(() => {
        return getInitialSpotlights(trackLines, spotlightAmount, room)
    }, [trackLines, spotlightAmount, room])

    return {initialSpotlights}
}

export function useMaxNumberOfSpotlights(): { maxNumberOfSpotlights: () => number } {
    const trackLines: IndexedLine[] = useAppSelector(selectTrackLines);

    const maxNumberOfSpotlights = useCallback(() => {
        const maxSpotlightsPerTrack = getMaxSpotlightsPerTrackLine(trackLines, spotlightDistanceFromSpotlight);
        let sum = 0;

        maxSpotlightsPerTrack.forEach(spotlight => {
            sum += spotlight;
        })
        return sum;
    }, [trackLines]);

    return {maxNumberOfSpotlights};
}
