import {useAppSelector} from 'app/store/hooks';
import {ItemTable} from 'components/scene/order/canvas/items/ItemTable';
import React from 'react';
import {selectOrderConnectorTypes} from 'state/order/orderSelectors';

export const ItemConnector = (): JSX.Element => {
    const orderConnectors = useAppSelector(selectOrderConnectorTypes);
    const tdStyles = {
      borderRight: '1px solid rgb(100,100,100)',
      paddingBottom: '0.25rem',
      width: '40mm'
    }
    const innerConnectors = () => {
        if (orderConnectors.innerConnectors > 0) {
            return (
                <tr>
                    <td style={tdStyles}>{orderConnectors.innerConnectors}</td>
                    <td style={tdStyles}>Innenverbinder</td>
                    <td> ? </td>
                </tr>
            )
        }
        return null;
    };
    const lConnectors = () => {
        if (orderConnectors.lConnectors > 0) {
            return (
                <tr>
                    <td style={tdStyles}>{orderConnectors.lConnectors}</td>
                    <td style={tdStyles}>L-Stück</td>
                    <td> ? </td>
                </tr>
            )
        }
        return null;
    };
    const tConnectors = () => {
        if (orderConnectors.tConnectors > 0) {
            return (
                <tr>
                    <td style={tdStyles}>{orderConnectors.tConnectors}</td>
                    <td style={tdStyles}>T-Stück</td>
                    <td> ? </td>
                </tr>
            )
        }
        return null;
    };
    const endCaps = () => {
        if (orderConnectors.endCaps > 0) {
            return (
                <tr>
                    <td style={tdStyles}>{orderConnectors.endCaps}</td>
                    <td style={tdStyles}>Endkappe</td>
                    <td> ? </td>
                </tr>
            )
        }
        return null;
    };

    return (
        <ItemTable title={'Verbinder'}>
            {innerConnectors()}
            {lConnectors()}
            {tConnectors()}
            {endCaps()}
        </ItemTable>
    );
};


