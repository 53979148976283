import {doubleSpotlightDistanceFromConnector} from 'app/config/light';
import {Direction} from 'libs/models/types';
import {equalPositionValueX, equalPositionValueY, IndexedPosition, Position} from 'libs/models/position';

/**
 * Eine Linie mit zwei Punkten.
 */
export interface Line<T extends Position> {
    from: T;
    to: T;
}

export type Lines<T extends Position> = Line<T>[];

/**
 * Linie mit Positionen
 */
export type PositionLine = Line<Position>;

/**
 * Linie mit indizierten Positionen
 */
export type IndexedLine = Line<IndexedPosition>;

/**
 * Die Richtung einer Linie (positiv, unbekannt, negativ)
 */
export type LineDirection = 1 | 0 | -1;

/**
 * Die Eigenschaften der der Ausrichtung.
 */
export interface LineOrientation {
    isHorizontal: boolean;
    isVertical: boolean;
}


/**
 * Die Ausrichtung einer Linie (positiv, negativ, unbekannt).
 *
 * - Horizontal positiv => links nach rechts
 * - Horizontal negativ => rechts nach links
 * - Vertikal positiv => oben nach unten
 * - Verikal negativ => unten nach oben
 *
 * @param line
 */
export function lineDirection<T extends Position>(line: Line<T>): LineDirection {
    let diff = 0;
    if (lineIsHorizontal(line)) {
        diff = (line.to.x - line.from.x);
    }
    if (lineIsVertical(line)) {
        diff = (line.to.y - line.from.y);
    }
    if (diff > 0) {
        return 1;
    }
    if (diff < 0) {
        return -1;
    }
    return 0;
}

/**
 * Die Linie zu einer Ausrichtung.
 */
export function lineDirectionToDirection<T extends Position>(line: Line<T>): Direction {
    if (lineIsHorizontal(line)) {
        if (lineDirection(line) < 0) {
            return 'left'
        }
        return 'right'
    }

    if (lineDirection(line) < 0) {
        return 'top'
    }
    return 'bottom'
}

/**
 * Prüft, ob die Linie eine horizontale Ausrichtung hat.
 *
 * @param line
 */
export function lineIsHorizontal<T extends Position>(line: Line<T>): boolean {
    return !equalPositionValueX(line.from, line.to) && equalPositionValueY(line.from, line.to);
}

/**
 * Prüft, ob die Linie eine vertikale Ausrichtung hat.
 *
 * @param line
 */
export function lineIsVertical<T extends Position>(line: Line<T>): boolean {
    return !equalPositionValueY(line.from, line.to) && equalPositionValueX(line.from, line.to);
}

/**
 * Berechnet die Länge der Linie.
 *
 * Wenn die Länge ungültig ist, wird 0 zurückgegeben.
 *
 * @param line
 */
export function lineLength<T extends Position>(line: Line<T>): number {
    if (lineIsVertical(line)) {
        return Math.abs(line.from.y - line.to.y);
    }
    if (lineIsHorizontal(line)) {
        return Math.abs(line.from.x - line.to.x);
    }

    return 0;
}

/**
 * Die Orientierung einer Linie.
 *
 * @param line
 */
export function lineOrientation<T extends Position>(line: Line<T>): LineOrientation {
    return {
        isHorizontal: lineIsHorizontal(line),
        isVertical: lineIsVertical(line),
    }
}

/**
 * Berechnet die Mitte eine Linie.
 *
 * @param line
 */
export function lineCenter(line: Line<Position>): Position {
    const orientation = lineOrientation(line);
    const length = lineLength(line);
    const direction = lineDirection(line);

    if (orientation.isHorizontal) {
        return {
            x: (line.from.x + (0.5 * length * direction)),
            y: line.from.y
        }
    }
    if (orientation.isVertical) {
        return {
            x: line.from.x,
            y: (line.from.y + (0.5 * length * direction))
        }
    }
    return {x: 0, y: 0}
}

/**
 * Summe aller Linien laengen.
 * @param lines {Lines}
 */
export function sumLinesLengthWithoutCornerOffset<T extends Position>(lines: Lines<T>): number {
    return lines.map(line => (lineLength(line) - doubleSpotlightDistanceFromConnector)).reduce((accumulator, currentValue) => accumulator + currentValue);
}
