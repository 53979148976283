import React, {PropsWithChildren} from 'react';
import {tw} from 'twind/css';
import {styles, variantCss} from 'components/common/panel/styles/PanelEditItemStyles';

export type PanelEditItemProps = PropsWithChildren<{
    title: string;
    label?: string;
    variant?: 'input' | 'limit';
    handleClickEvent?: React.MouseEventHandler;
    isDisabled?: boolean;
}>;
/**
 * @param title
 * @param label
 * @param variant
 * @param children
 * @param isDisabled
 * @param handleClickEvent
 * @constructor
 */

export const PanelEditItem = (
    {
        title,
        label,
        variant,
        children,
        isDisabled,
        handleClickEvent
    }: PanelEditItemProps): JSX.Element => {
    const varianStyles = variant ? variantCss[variant] : null;
    return (
        <div className={'amounts-wrapper ' + tw(styles, varianStyles)}>
            <p className={'item-title'}>{title}</p>
            <div className={'form-field'}>
                {children}
                {label &&
                  <label>{label}</label>
                }
                <div className={isDisabled ? 'disabled submit-group' : 'submit-group'}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 64 64'
                      width={16}
                      height={16}
                    >
                      <path data-name='layer2' fill='none' d='M52 62V34H12v28m10-18h20m-20 8h16'></path>
                      <path data-name='layer1' fill="none" d='M54 2l8 8v52H2V2h52zm-12 8v4'></path>
                      <path data-name='layer1' d='M50 2v18a2 2 0 0 1-2 2H28a2 2 0 0 1-2-2V2' fill='none' stroke='#202020' stroke-miterlimit='10' stroke-width='2'></path>
                    </svg>
                    <input type={'submit'} onClick={handleClickEvent} value={''} className={'submit'}/>
                </div>
            </div>
        </div>
    );
};
