import {css} from 'twind/css';

export const resetAction  = css({
    '@apply': 'flex flex-wrap md:flex-nowrap items-center justify-center lg:justify-between absolute top-0 w-full py-5 px-4 bg-red-100 z-9999999',
    'max-width': '100%',
    '.warning-message': {
        '@apply': 'flex flex-wrap lg:flex-nowrap w-full align-center xl:items-center'
    },
    'h2': {
        '@apply': 'font-bold text-red-500 mr-2',
    },
    '.alert-icon': {
        '@apply': 'flex items-center bg-red-50 border-2 border-red-500 justify-center h-10 w-10 flex-shrink-0 rounded-full md:mr-4',
    },
    'svg': {
        '@apply': 'fill-red'
    },
    'p': {
        '@apply': 'text-center md:text-left text-red-700 mt-2 md:mt-0 xl:w-full'
    },
    '.button-group': {
        '@apply': 'flex flex-wrap md:flex-nowrap w-full justify-center md:justify-start lg:mt-2 xl:w-6/12 2xl:mt-0 2xl:justify-end'
    },
    'button': {
        '@apply': 'mt-2 px-0 md:px-3 lg:mt-0 first:order-2'
    },
    '@screen md': {
        'button': {
            '@apply': 'first:mr-2 first:order-1 last:order-2',
        }
    }
});

export const saveAction = css({
    '@apply': 'flex items-center justify-center items-center lg:justify-end absolute bottom-0 w-full py-5 px-4',
    '&.disabled button:not(.toggle-icon)': {
        '@apply': 'bg-gray-300 pointer-events-none'
    },
  '.toggle-icon': {
    '@apply': 'absolute lg:hidden left-4 mt-0'
  },
});
