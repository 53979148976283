import React, {useState} from 'react';
import {css, tw} from 'twind/css';
import {burgerMenuStyles} from 'components/navigation/styles/BurgerMenuStyles';

export type BurgerMenuProps = React.ComponentProps<'html'> & {
    buttonTitle?: string|null,
}

export const BurgerMenu = ({buttonTitle}: BurgerMenuProps): JSX.Element => {
    const [ isShown, setIsShown ] = useState(false)
    return(
        <div className={tw(burgerMenuStyles)}>
            <div className={'icon-group'}>
                <svg
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    width={28}
                    height={28}

                    viewBox='0 0 24 24'
                    className={'menu-open'}
                    onClick={() => setIsShown(true)}
                >
                    {buttonTitle}
                    <path
                        className={'path'}
                        d='M0,0h24v24H0V0z'
                    />
                    <path
                        d='M3,18h18v-2H3V18z M3,13h18v-2H3V13z M3,6v2h18V6H3z'
                    />
                </svg>

            </div>

            <div className={isShown ? 'menu active' : 'menu'}>
                <div className={'menu-title'}>
                    <svg xmlns='http://www.w3.org/2000/svg'
                         height={24}
                         width={24}
                         viewBox='0 0 24 24'
                         fill='#000000'
                         className={'menu-close'}
                         onClick={() => setIsShown(false)}
                    >
                        <path d='M0 0h24v24H0V0z' fill='none'/>
                        <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'
                        />
                    </svg>
                </div>
                <div className={'menu-body'}>
                    <BurgerMenuLink
                        title={'Zur Webseite'}
                        link={'https://seyfert-lichtdesign.de'}
                        />
                    <BurgerMenuLink
                        title={'Datenschutzerklärung'}
                        link={'https://www.seyfert-lichtdesign.de/Rechtliches/Datenschutzerklaerung/'}
                    />
                </div>
            </div>
            <div
                className={'menu-bg'}
                onClick={() => setIsShown(false)}
            >&nbsp;</div>
        </div>
    )
}

export type BurgerMenuLinkProps = React.ComponentProps<'html'> & {
    title: string,
    link: string,
}

const BurgerMenuLinkStyles = css({
    '@apply' : 'relative flex items-center font-medium tracking-wide transition-all ease-in-out duration-200',
    '&:not(:first-of-type)': {
        '@apply': 'border-t border-gray-200 my-2 py-2'
    },
    '&:hover': {
        '@apply': 'text-primary transition-all ease-in-out duration-200',
        'svg': {
            '@apply': 'transition-all ease-in-out duration-200',
            'right': '-10px'
        },
    },
    'svg': {
        '@apply': 'absolute right-0 transition-all ease-in-out duration-200'
    },
});

export const BurgerMenuLink = ({title, link}: BurgerMenuLinkProps): JSX.Element => {
    return(
        <a
            href={link}
            target={'_BLANK'}
            rel={'noreferrer'}
            className={tw(BurgerMenuLinkStyles)}
        >
            {title}
            <svg
                xmlns='http://www.w3.org/2000/svg'
                height={15}
                width={15}
                viewBox='0 0 24 24'
                fill='#000000'
            >
                <path d='M0 0h24v24H0z' fill='none'/><
                path d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z'/>
            </svg>
        </a>
    )
}
