import {AdditionalColor, SystemColor} from 'app/config/order';
import {AxiosInstance} from 'axios';
import {CartPrice, ProductItem} from 'libs/client/clientApi';
import {OrderPDF, ShopClient, ShopProductImage, SpotProduct} from './shopClient';

export interface ShopwareConfig {
    axios: AxiosInstance
}

export function createShopwareClient(config: ShopwareConfig): ShopClient {
    const http = config.axios;

    return {
        fetchSpotProducts(): Promise<SpotProduct[]> {
            const body = createProductRequestBody();

            return http.post<SearchResponse<ProductStruct>>('/store-api/product', body)
                .then((response) => {
                    return response?.data?.elements?.map(productStructToSpotProduct) ?? [];
                });
        },
        uploadOrderPDF(orderPdf: OrderPDF): Promise<boolean> {
            return http.post<boolean>('/store-api/bitit/tracklight/entity/' + orderPdf.entityId + '/media', orderPdf)
                .then((response) => {
                    return response?.data;
                });
        },
        saveOrderProducts(entityId: string, orderProducts: ProductItem[]): Promise<CartPrice> {
            return http.post<CartStruct>('/store-api/bitit/tracklight/entity/' + entityId + '/cart', {
                products: orderProducts
            })
                .then((response) => {
                    return response?.data.price;
                });
        }
    }
}


function productStructToSpotProduct(product: ProductStruct): SpotProduct {
    return {
        id: product.id,
        parentId: product.parentId,
        productNumber: product.productNumber,
        name: product.translated.name ?? product.name,
        description: product.translated.description ?? product.description,
        productType: product.customFields.tracklight_product_type ?? 'none',
        productSystem: product.customFields.tracklight_product_system ?? null,
        productAdditional: product.customFields.tracklight_product_additional ?? null,
        price: product.calculatedCheapestPrice.totalPrice ?? 0,
        cover: productMediaToShopImage(product.cover),
        images: productMediaArrayToShopImages(product.media)
    }
}

function productMediaArrayToShopImages(productMedias: ProductMediaStruct[] | null): ShopProductImage[] {
    if (!productMedias) {
        return []
    }
    return productMedias.map(productMediaToShopImage)
        .flatMap((p) => p !== null ? [p] : [])
}

function productMediaToShopImage(productMedia: ProductMediaStruct | null): ShopProductImage | null {
    if (!productMedia || !productMedia.media) {
        return null;
    }
    const mimeType = productMedia.media.mimeType || 'application/octet-stream';
    if (!mimeType.startsWith('image')) {
        return null;
    }

    return {
        url: productMedia.media.url,
        mimeType: mimeType
    }
}

function createProductRequestBody(): string {
    return JSON.stringify({
        includes: {
            product: [
                'id',
                'parentId',
                'name',
                'productNumber',
                'customFields',
                'description',
                'translated.name',
                'translated.description',
                'calculatedCheapestPrice',
                'cover',
                'media'
            ],
            calculated_cheapest_price: [
                'totalPrice'
            ],
            productMedia: [
                'media',
            ],
            media: [
                'mimeType',
                'url'
            ]
        },
        associations: {
            media: {}
        },
        filter: [{
            type: 'multi',
            operator: 'and',
            queries: [
                {type: 'equals', field: 'customFields.tracklight_product_type', value: 'spot'}
            ]
        }]
    });
}


type SearchResponse<T> = {
    entity: string;
    total: number;
    page: number;
    limit: number;
    elements: T[];
}
type ProductStruct = {
    id: string;
    productNumber: string;
    parentId: string | null;
    name: string | null;
    description: string | null;
    translated: {
        name: string | null,
        description: string | null;
    }
    customFields: {
        tracklight_product_type?: string;
        tracklight_product_system?: SystemColor;
        tracklight_product_additional?: AdditionalColor;
    },
    calculatedCheapestPrice: {
        totalPrice: number | null;
    },
    cover: ProductMediaStruct | null,
    media: ProductMediaStruct[]
}

type ProductMediaStruct = {
    media: MediaStruct
}

type MediaStruct = {
    mimeType: string | null;
    url: string;
}

type CartStruct = {
    price: CartPrice;
}
