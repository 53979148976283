import {Html} from '@react-three/drei';
import {MeshProps, useThree} from '@react-three/fiber';
import {htmlDistanceFactor} from 'app/config/3DParams';
import {DragLine} from 'components/scene/track/edit/DragLine';
import {lineLength, PositionLine} from 'libs/models/line';
import {cmToThree, movePositionXByCM, movePositionZByCM, PositionThree, threeToCM} from 'libs/view';
import {convertMouseToPositionInCM} from 'libs/view/mouse';
import React, {useEffect, useState} from 'react';
import {useGesture} from 'react-use-gesture';
import {FullGestureState} from 'react-use-gesture/dist/types';
import {TrackMenuStyles} from 'components/scene/track/edit/styles/styles';
import {tw} from 'twind/css';

type TrackMenuConf = MeshProps & {
    axis: 'x' | 'y';
    axisCheck: (m: number) => boolean;
    knotPosition: PositionThree;
    arrowClass: string;
    addNewTrackKnot: (length: number) => void;
}

type TrackMenuProps = {
    active: boolean;
    position: PositionThree;
    offset: number;
    connection: number;
    addNewTrackKnot: (length: number) => void;
}

const TrackMenu = (
    {
        position,
        rotation,
        axis,
        axisCheck,
        knotPosition,
        addNewTrackKnot,
        arrowClass
    }: TrackMenuConf): JSX.Element => {
    const [color, setColor] = useState('#ffffff')
    const [isDragging, setDragging] = useState(false)
    const [line, setLine] = useState<PositionLine>({
        from: {x: knotPosition[0], y: knotPosition[2]},
        to: {x: knotPosition[0], y: knotPosition[2]}
    });
    const [isMouseOver, setMouse] = useState(false);

    useEffect(() => {
        document.body.style.cursor = isMouseOver ? 'grabbing' : 'auto'
    }, [isMouseOver])

    useEffect(() => {
        setLine({from: {x: knotPosition[0], y: knotPosition[2]}, to: {x: knotPosition[0], y: knotPosition[2]}})
    }, [knotPosition])

    const {mouse, viewport, camera} = useThree();
    const dragMe = (state: Omit<FullGestureState<'drag'>, 'event'> & { event: React.PointerEvent | PointerEvent }) => {
        if (state.down && axis === 'x' && axisCheck(state.movement[0])) {
            const mouseCM = convertMouseToPositionInCM({mouse, viewport, camera});
            const newLine = {...line}
            newLine.to = {x: cmToThree(mouseCM.x), y: knotPosition[2]}
            setLine(newLine)
        }
        if (state.down && axis === 'y' && axisCheck(state.movement[1])) {
            const mouseCM = convertMouseToPositionInCM({mouse, viewport, camera});
            const newLine = {...line}
            newLine.to = {x: knotPosition[0], y: cmToThree(mouseCM.y)}
            setLine(newLine)
        }
    }

    const bindUseGesture = useGesture({
        onDrag: state => dragMe(state),
        onDragStart: () => setDragging(true),
        onDragEnd: () => {
            const length = threeToCM(lineLength(line))
            addNewTrackKnot(length)
            setDragging(false)
        },
    }, {drag: {axis: axis}})

    /* eslint-disable @typescript-eslint/no-explicit-any */
    // noinspection RequiredAttributes
    return (
        <>
            <DragLine isActive={isDragging} line={line}/>
            <mesh
                {...bindUseGesture() as any}
                onPointerEnter={() => {
                    setColor('#c45ee0')
                    setMouse(true)
                }}
                onPointerLeave={() => {
                    setColor('#ffffff')
                    setMouse(false)
                }}
                position={position}
                rotation={!isDragging ? rotation : [0, 0, 0]}
            >
                <boxBufferGeometry args={[0.20, 0.20, 0]}/>
                <meshBasicMaterial color={color}/>
            </mesh>
            <Html
                distanceFactor={htmlDistanceFactor}
                position={position}
                rotation={!isDragging ? rotation : [0, 0, 0]}
                className={isDragging ? tw(TrackMenuStyles) + ' dragged' : tw(TrackMenuStyles)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height={34}
                    width={34}
                    viewBox="0 0 24 24"
                    className={arrowClass}
                >
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/>
                </svg>
            </Html>
        </>
    )
}

export const TrackMenuTop = ({active, position, offset, connection, addNewTrackKnot}: TrackMenuProps): JSX.Element => {
    const threePositionTop = movePositionZByCM(position, -offset)

    if (connection === -1 && active) {
        return (
            <TrackMenu
                axis={'y'}
                axisCheck={(m) => (m < 0)}
                position={threePositionTop}
                knotPosition={position}
                arrowClass={'arrow-top'}
                rotation={[-Math.PI / 2, 0, Math.PI / 2]}
                addNewTrackKnot={(length) => addNewTrackKnot(length)}
            />
        )
    }

    return <></>
}

export const TrackMenuRight = (
    {
        active,
        position,
        offset,
        connection,
        addNewTrackKnot
    }: TrackMenuProps): JSX.Element => {
    const threePositionRight = movePositionXByCM(position, offset)

    if (connection === -1 && active) {
        return (
            <TrackMenu
                axis={'x'}
                axisCheck={(m) => m > 0}
                position={threePositionRight}
                knotPosition={position}
                arrowClass={'arrow-right'}
                rotation={[-Math.PI / 2, 0, 0]}
                addNewTrackKnot={(length) => addNewTrackKnot(length)}
            />
        )
    }

    return <></>
}

export const TrackMenuBottom = (
    {
        active,
        position,
        offset,
        connection,
        addNewTrackKnot
    }: TrackMenuProps): JSX.Element => {
    const threePositionBottom = movePositionZByCM(position, offset)

    if (connection === -1 && active) {
        return (
            <TrackMenu
                axis={'y'}
                axisCheck={(m) => m > 0}
                position={threePositionBottom}
                knotPosition={position}
                arrowClass={'arrow-bottom'}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                addNewTrackKnot={(length) => addNewTrackKnot(length)}
            />
        )
    }

    return <></>
}

export const TrackMenuLeft = ({active, position, offset, connection, addNewTrackKnot}: TrackMenuProps): JSX.Element => {
    const threePositionLeft = movePositionXByCM(position, -offset)

    if (connection === -1 && active) {
        return (
            <TrackMenu
                axis={'x'}
                axisCheck={(m) => m < 0}
                position={threePositionLeft}
                knotPosition={position}
                arrowClass={'arrow-left'}
                rotation={[-Math.PI / 2, 0, Math.PI]}
                addNewTrackKnot={(length) => addNewTrackKnot(length)}
            />
        )
    }

    return <></>
}
