import {outletMinimumDistanceToWall} from 'app/config/outlet';
import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {Position, resizePositionsMinMaxValues, validatePositionIsInMinMaxExclusive} from 'libs/models/position';
import {getRoomNullPosition, isPointInside, mapRoomFromPositions, moveRoomPositionsByOffset} from 'libs/models/room';
import {calculateInitialOutletPosition, validateOutletPosition} from 'libs/models/validation';
import {useCallback} from 'react';
import {setOutletPosition} from 'state/outlet/outletReducer';
import {selectRoom, selectRoomMinMaxValues} from 'state/room/roomSelectors';

export function useOutletValidation(): { isValidOutletPosition: (outletPosition: Position) => boolean } {
    const room = useAppSelector(selectRoom);


    const isValidOutletPosition = useCallback((outletPosition: Position) => {
        return validateOutletPosition(room, outletPosition)
    }, [room]);

    return {isValidOutletPosition};
}

export function useOutletInitialPosition(): { calculateInitialPosition: () => Position } {
    const room = useAppSelector(selectRoom);
    const roomBounds = useAppSelector(selectRoomMinMaxValues);
    const nullPosition = getRoomNullPosition(roomBounds);
    const outletRoom = mapRoomFromPositions(moveRoomPositionsByOffset(outletMinimumDistanceToWall, useAppSelector(selectRoom)));
    const dispatch = useAppDispatch();

    const calculateInitialPosition = useCallback(() => {
        let initialPosition = {x: 0, y: 0};
        if (isPointInside(outletRoom.walls, nullPosition)) {
            initialPosition = nullPosition
        }
        if (!isPointInside(outletRoom.walls, nullPosition)) {
            if (room && room.corners && room.corners[0]) {
                const roomCorner = room.corners[0];
                initialPosition = {x: (roomCorner.x + 30), y: (roomCorner.y + 30)}
            } else {
                initialPosition = calculateInitialOutletPosition(outletRoom.walls);
            }
        }
        dispatch(setOutletPosition(initialPosition));
        return initialPosition;
    }, [nullPosition, outletRoom, dispatch, room]);

    return {calculateInitialPosition};
}

export function useOutletBounds(): { isPointInRoom: (position: Position) => boolean } {
    const outletRoom = mapRoomFromPositions(moveRoomPositionsByOffset(outletMinimumDistanceToWall, useAppSelector(selectRoom)));
    const outletBounds = resizePositionsMinMaxValues(useAppSelector(selectRoomMinMaxValues), outletMinimumDistanceToWall)

    const isPointInRoom = useCallback((position: Position) => {
        return validatePositionIsInMinMaxExclusive(position, outletBounds)
            && isPointInside(outletRoom.walls, position);
    }, [outletRoom, outletBounds]);

    return {isPointInRoom};
}
