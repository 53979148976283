import {createAsyncThunk} from '@reduxjs/toolkit';
import {AppAsyncThunkConfig} from 'app/store/store';
import {deleteEntity, saveEntity} from 'state/entity/entityApi';
import {loadPresetEntityIfEmpty} from 'state/entity/entityPreset';


/**
 * Hier sind die Aktionen für das zurücksetzen der einzelnen Schritte.
 */


/**
 * Die Seite "Raumform" zurücksetzen.
 *
 * Dieser Schritt ist __nicht__ rekursiv, weil einfach alle Daten gelöscht werden.
 *
 * - Löscht die aktuelle Entität.
 * - Lädt die erste Konfiguration aus den Presets.
 */

export const stepResetPageType = createAsyncThunk<boolean, void, AppAsyncThunkConfig>(
    'step/stepResetPageType',
    async (_, thunkAPI) => {
        try {
            await thunkAPI.dispatch(deleteEntity());
            await thunkAPI.dispatch(loadPresetEntityIfEmpty());
            return true;
        } catch (_) {
            return false;
        }
    }
);

/**
 * Die Seite "Grundriss" zurücksetzen.
 *
 * Dieser Schritt ist rekursiv, damit alle weiteren Schritte zurückgesetzt werden.
 *
 * - löst den Schritt "Auslass zurücksetzten" aus.
 */
export const stepResetPageRoom = createAsyncThunk<boolean, void, AppAsyncThunkConfig>(
    'step/stepResetPageRoom',
    async (_, thunkAPI) => {
        try {
            await thunkAPI.dispatch(stepResetPageOutlet());
            await thunkAPI.dispatch(saveEntity());
            return true;
        } catch (_) {
            return false;
        }
    }
);


/**
 * Die Seite "Auslass" zurücksetzen.
 *
 * Dieser Schritt ist rekursiv, damit alle weiteren Schritte zurückgesetzt werden.
 *
 * - löst den Schritt "Schienen zurücksetzten" aus.
 */
export const stepResetPageOutlet = createAsyncThunk<boolean, void, AppAsyncThunkConfig>(
    'step/stepResetPageOutlet',
    async (_, thunkAPI) => {
        try {
            await thunkAPI.dispatch(stepResetPageTrack());
            await thunkAPI.dispatch(saveEntity());
            return true;
        } catch (_) {
            return false;
        }
    }
);


/**
 * TODO: Die Seite "Schienen" zurücksetzen.
 *
 * Dieser Schritt ist rekursiv, damit alle weiteren Schritte zurückgesetzt werden.
 */
export const stepResetPageTrack = createAsyncThunk<boolean, void, AppAsyncThunkConfig>(
    'step/stepResetPageTrack',
    async (_, thunkAPI) => {
        try {
            await thunkAPI.dispatch(stepResetPageLight());
            await thunkAPI.dispatch(saveEntity());
            return true;
        } catch (_) {
            return false;
        }
    }
);

/**
 * TODO: Die Seite "Strahler" zurücksetzen.
 *
 * Dieser Schritt ist rekursiv, damit alle weiteren Schritte zurückgesetzt werden.
 */
export const stepResetPageLight = createAsyncThunk<boolean, void, AppAsyncThunkConfig>(
    'step/stepResetPageLight',
    async (_, thunkAPI) => {
        try {
            await thunkAPI.dispatch(stepResetPageOrder());
            await thunkAPI.dispatch(saveEntity());
            return true;
        } catch (_) {
            return false;
        }
    }
);

/**
 * TODO: Die Seite "Bestellung" zurücksetzen.
 *
 * Dieser Schritt sollte eigentlich der letzte in der Reihe sein.
 */
export const stepResetPageOrder = createAsyncThunk<boolean, void, AppAsyncThunkConfig>(
    'step/stepResetPageOrder',
    async () => {
        try {
            return true;
        } catch (_) {
            return false;
        }
    }
);

