import {useAppSelector} from 'app/store/hooks';
import {TooltipButtonStyles, TooltipStyles} from 'components/common/tooltip/styles/styles';
import Cookies from 'js-cookie';
import React, {useEffect, useState} from 'react';
import {selectEditMode} from 'state/view/viewSelectors';
import {tw} from 'twind/css';

export type TooltipProps = React.ComponentProps<'html'> & {
    setDisbandTime?: number | 3000
    title?: string,
}

export const Tooltip = ({children, title, setDisbandTime}: TooltipProps): JSX.Element => {
    const currentEditMode = useAppSelector(selectEditMode);
    const [isShown, setShowToolTip] = useState(false);
    const mainPanel = document.querySelectorAll('.main-panel')[0];

    const setCookie = () => {
        if (currentEditMode) {
            Cookies.set(currentEditMode, 'disbandTooltip', {expires: 7});
        }
    };

    const hasCookie = (): boolean => {
        if (currentEditMode) {
            const currentCookie = Cookies.get(currentEditMode);
            if (currentCookie && currentCookie === 'disbandTooltip') {
                return true;
            }
        }
        return false;
    };

    const disbandTooltip = () => {
        setShowToolTip(false);
        mainPanel.classList.remove('overflow');
        setCookie();
    };

    const disbandTooltipAfterTime = () => {
        setTimeout(() => {
            setShowToolTip(false);
            mainPanel.classList.remove('overflow');
            setCookie();
        }, setDisbandTime);
    };

    const showTooltip = () => {
        setShowToolTip(true);

        mainPanel.classList.toggle('overflow');

    };

    useEffect(() => {
        const showAlert = document.getElementsByClassName('alert-icon').length;
        if (hasCookie()) {
            setShowToolTip(false);
        } else {
            setShowToolTip(true);

            if(!showAlert) {
                mainPanel.classList.add('overflow');
            }
            disbandTooltipAfterTime();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div
                className={tw(TooltipButtonStyles)}
                onClick={showTooltip}
            >
                <svg xmlns="http://www.w3.org/2000/svg"
                     height={24}
                     width={24}
                     viewBox="0 0 24 24"
                     fill="#000000"
                >
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path
                        d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/>
                </svg>
            </div>
            <div className={isShown ? tw(TooltipStyles) + ' active' : tw(TooltipStyles)}>
                <div
                    onClick={disbandTooltip}
                    className={'close-icon'}
                >
                    <svg viewBox="0 0 20 20"
                         width={18}
                         height={18}
                    >
                        <path
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        >
                        </path>
                    </svg>
                </div>
                <div className={'tooltip-body'}>
                    <h3 className={'tooltip-title'}>{title}</h3>
                    <p>{children}</p>
                </div>
            </div>
        </div>
    );
};


