import {createAsyncThunk} from '@reduxjs/toolkit';
import {apiClient} from 'app/config/apiClient';
import {AppAsyncThunkConfig} from 'app/store/store';
import {defaultEntityData, EntityData, EntityResource} from 'libs/client/clientApi';
import {selectEntityId} from 'state/entity/entitySelectors';
import {setCurrentPresetId} from 'state/preset/presetReducer';
import {selectCurrentOrFirstPresetEntity} from 'state/preset/presetSelectors';
import {setPreviewRoom} from 'state/room/roomReducer';


/**
 * Erstellt eine neue Entität aus einer Kopie der Daten einer anderen Entität.
 */
export const createEntityFromPresetEntity = createAsyncThunk<EntityResource, EntityResource, AppAsyncThunkConfig>(
    'entity/createEntityFromPresetEntity',
    async (preset) => {
        const data: EntityData = {
            ...defaultEntityData,
            ...preset.data,
        };
        return await apiClient.createEntity(data);
    }
);

/**
 * Wenn keine Entität geladen ist (leerer State), wird eine Entität aus den Presets geladen.
 */
export const loadPresetEntityIfEmpty = createAsyncThunk<void, void, AppAsyncThunkConfig>(
    'entity/loadPresetEntityIfEmpty',
    (_, thunkAPI) => {
        const currentEntityId = selectEntityId(thunkAPI.getState());

        if (!currentEntityId) {
            const presetEntity = selectCurrentOrFirstPresetEntity(thunkAPI.getState());
            if (presetEntity) {
                thunkAPI.dispatch(setCurrentPresetId(presetEntity.entityId));
                thunkAPI.dispatch(setPreviewRoom(presetEntity));
            }
        }
        return Promise.resolve();
    }
);
