import {useGLTF} from '@react-three/drei';
import {publicPath} from 'app/config/gltf';
import {SpotStarGLTFResult} from 'libs/models/3d/gltf';
import {SpotMode, SpotStarModeComponentMap} from 'libs/models/3d/spotModels';
import {PositionThree} from 'libs/view';
import * as THREE from 'three';
import {MeshStandardMaterial} from 'three';

type SpotStarProps = {
    systemMaterial: THREE.MeshStandardMaterial;
    additionalMaterial: THREE.MeshStandardMaterial;
    spotMode: SpotMode;
    position: PositionThree;
    isHorizontal: boolean;
}

export const SpotStar = (
    {
        systemMaterial,
        additionalMaterial,
        spotMode,
        position,
        isHorizontal
    }: SpotStarProps): JSX.Element => {
    const {nodes} = useGLTF(publicPath('/gltf/spotStarDraco.gltf')) as SpotStarGLTFResult;

    const strahlerkopfMaterial = systemMaterial;
    const innenreflektorMaterial = additionalMaterial;

    const baseRotation: PositionThree = isHorizontal ? [0, 0, 0] : [0, Math.PI / 2, 0];

    const spotComponent = spotComponentMap[spotMode];
    const spot = spotComponent({nodes, innenreflektorMaterial, strahlerkopfMaterial});

    return (
        <group scale={0.04} position={position} dispose={null}>
            <group name={'base'}>
                <mesh
                    geometry={nodes.schienenadapter.geometry}
                    material={systemMaterial}
                    rotation={baseRotation}
                    scale={[0.8, 0.2, -0.3]}
                />
                <mesh
                    geometry={nodes.stab.geometry}
                    material={systemMaterial}
                    position={[0, -0.42, 0]}
                    rotation={[0, 0, 3.14]}
                    scale={[0.08, 0.32, -0.08]}
                />
            </group>
            {spot}
        </group>
    );
};

const spotComponentMap: SpotStarModeComponentMap = {
    top: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps) => (
        <SpotStarTop
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    right: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps) => (
        <SpotStarRight
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    bottom: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps) => (
        <SpotStarBottom
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    left: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps) => (
        <SpotStarLeft
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    down: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps) => (
        <SpotStarDown
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
};

export type SpotStar3DProps = {
    nodes: SpotStarGLTFResult['nodes'];
    innenreflektorMaterial: MeshStandardMaterial;
    strahlerkopfMaterial: MeshStandardMaterial;
}

export const SpotStarTop = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps): JSX.Element => {
    return (
        <group name={'top'}>
            <mesh
                geometry={nodes.reflektor_vorne.geometry}
                material={innenreflektorMaterial}
                position={[0, -1.83, -0.69]}
                rotation={[-1.02, 0, 1.57]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.strahler_vorne_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.78, -0.04]} rotation={[-1.02, 0, 1.57]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.strahler_vorne_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.78, -0.04]} rotation={[-1.02, 0, 1.57]} scale={[0.02, 0.02, 0.02]}
            />
        </group>
    );
};
export const SpotStarBottom = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps): JSX.Element => {
    return (
        <group name={'bottom'}>
            <mesh
                geometry={nodes.reflektor_hinten.geometry}
                material={innenreflektorMaterial}
                position={[0, -1.83, 0.69]}
                rotation={[1.02, 0, 1.57]}
                scale={[0.02, 0.02, -0.02]}
            />
            <mesh
                geometry={nodes.strahler_hinten_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.78, 0.04]} rotation={[1.02, 0, 1.57]} scale={[0.02, 0.02, -0.02]}
            />
            <mesh
                geometry={nodes.strahler_hinten_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.78, 0.04]} rotation={[1.02, 0, 1.57]} scale={[0.02, 0.02, -0.02]}
            />
        </group>
    );
};
export const SpotStarLeft = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps): JSX.Element => {
    return (
        <group name={'left'}>
            <mesh
                geometry={nodes.reflektor_links.geometry}
                material={innenreflektorMaterial}
                position={[-0.66, -1.83, 0]}
                rotation={[1.57, -0.55, 3.14]}
                scale={[0.02, 0.02, -0.02]}
            />
            <mesh
                geometry={nodes.strahler_links_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.78, 0]} rotation={[1.57, -0.55, 3.14]} scale={[0.02, 0.02, -0.02]}
            />
            <mesh
                geometry={nodes.strahler_links_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.78, 0]} rotation={[1.57, -0.55, 3.14]} scale={[0.02, 0.02, -0.02]}
            />
        </group>
    );
};
export const SpotStarRight = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps): JSX.Element => {
    return (
        <group name={'right'}>
            <mesh
                geometry={nodes.reflektor_rechts.geometry}
                material={innenreflektorMaterial}
                position={[0.65, -1.83, 0]}
                rotation={[-1.57, -0.55, 0]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.strahler_rechts_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.78, 0]} rotation={[-1.57, -0.55, 0]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.strahler_rechts_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.78, 0]} rotation={[-1.57, -0.55, 0]} scale={[0.02, 0.02, 0.02]}
            />
        </group>
    );
};
export const SpotStarDown = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotStar3DProps): JSX.Element => {
    return (
        <group name={'down'}>
            <mesh
                geometry={nodes.reflektor_unten.geometry}
                material={innenreflektorMaterial}
                position={[0, -2.1, 0]}
                rotation={[-Math.PI / 2, 0, 1.57]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.strahler_unten_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.86, 0]} rotation={[-Math.PI / 2, 0, 1.57]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.strahler_unten_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.86, 0]} rotation={[-Math.PI / 2, 0, 1.57]} scale={[0.02, 0.02, 0.02]}
            />
        </group>
    );
};

useGLTF.preload(publicPath('/gltf/spotStarDraco.gltf'));
