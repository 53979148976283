import {useTexture} from '@react-three/drei';
import {publicPath} from 'app/config/gltf';
import {useAppSelector} from 'app/store/hooks';
import {CustomPlaneProps} from 'components/scene/3d/ThreeDPlanes';
import {PositionThree} from 'libs/view';
import React from 'react';
import {selectFloorTexture} from 'state/scene/sceneSelectors';
import * as THREE from 'three';

export const FloorPlane = ({shape, roomHeight}: CustomPlaneProps): JSX.Element => {
    const floorTexture = useAppSelector(selectFloorTexture);

    const texture = useTexture(publicPath('/texture/' + floorTexture));
    texture.repeat.set(0.5, 0.5);
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

    const rotation: PositionThree = [THREE.MathUtils.degToRad(90), 0, 0];

    // noinspection RequiredAttributes
    return (
        <mesh
            position={[0, -(roomHeight / 2), 0]}
            rotation={rotation}
        >
            <shapeBufferGeometry args={[shape]} />
            <meshStandardMaterial map={texture} side={THREE.BackSide} />
        </mesh>
    )
}
