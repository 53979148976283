import {useAppSelector} from 'app/store/hooks';
import {ResetRoomAction} from 'components/actions/room/ResetRoomAction';
import {SaveRoomAction} from 'components/actions/room/SaveRoomAction';
import React from 'react';
import {selectStepNeedsResetPageRoom} from 'state/step/selectStepNeedReset';

export const RoomActions = (): JSX.Element => {
    const needsReset = useAppSelector(selectStepNeedsResetPageRoom);
    if (needsReset) {
        return (
            <ResetRoomAction />
        );
    }
    return (
      <>
        <SaveRoomAction />
      </>
    );
};

