import {Position, PositionsMinMax} from 'libs/models/position';
import {Line, lineDirection, lineOrientation} from './line';


export function isInnerLine(line: Line<Position>, roomMinMaxValues: PositionsMinMax): boolean {

    const orientation = lineOrientation(line);
    const direction = lineDirection(line);

    if (orientation.isHorizontal) {

        if (direction === 1) {
            return !(line.from.y === roomMinMaxValues.minY || line.to.y === roomMinMaxValues.maxY);
        }
        if (direction === -1) {
            return !(line.from.y === roomMinMaxValues.maxY || line.to.y === roomMinMaxValues.minY);
        }
    }
    if (orientation.isVertical) {
        if (direction === 1) {
            return !(line.from.x === roomMinMaxValues.minX || line.to.x === roomMinMaxValues.maxX);
        }
        if (direction === -1) {
            return !(line.from.x === roomMinMaxValues.maxX || line.to.x === roomMinMaxValues.minX);
        }
    }

    return false;
}


