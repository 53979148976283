import {ColorSpace, jsPDF} from 'jspdf';

type ImageProperties = {
    alias: number;
    width: number;
    height: number;
    colorSpace: ColorSpace;
    bitsPerComponent: number;
    filter: string;
    decodeParameters?: string;
    predictor?: number;
    index: number;
    data: string;
}

export function createImgData(canvas: HTMLCanvasElement): string {
    return canvas.toDataURL('image/png');
}

export function createImage(pdf: jsPDF, canvas: HTMLCanvasElement): ImageProperties {
    return pdf.getImageProperties(createImgData(canvas));
}
