import {ClientApi, defaultResourceInfoData, EntityData, EntityId, EntityResource} from 'libs/client/clientApi';

export type ClientStorage = Pick<Storage, 'setItem' | 'getItem'>;
export type IdGenerator = () => string;

export type StorageClientConfig = {
    idGenerator: IdGenerator;
    storage: ClientStorage;
}

export function createStorageClient({idGenerator, storage}: StorageClientConfig): ClientApi {

    const findEntity = (entryId: EntityId): Promise<EntityResource> => {
        return new Promise<EntityResource>((resolve, reject) => {
            const item = storage.getItem(entryId);
            if (!item) {
                return reject(Error('entry not found in storage'))
            }
            const resource = JSON.parse(item) as EntityResource;
            if (!resource) {
                return reject(Error('could not parse entry from sorage'))
            }
            return resolve(resource);
        });
    }

    const saveEntity = (resource: EntityResource): Promise<EntityResource> => {
        return new Promise((resolve, reject) => {
            try {
                storage.setItem(resource.entityId, JSON.stringify(resource));
                return resolve(resource);
            } catch (e) {
                return reject(e);
            }
        });
    };

    const createEntity = (data: EntityData): Promise<EntityResource> => {
        const entryId = idGenerator();
        const resource: EntityResource = {
            entityId: entryId,
            data,
            info: {
                ...defaultResourceInfoData,
                title: 'Raum ' + entryId,
            }
        };
        return saveEntity(resource);
    };

    const updateEntity = (entryId: EntityId, data: EntityData): Promise<EntityResource> => {
        return findEntity(entryId)
            .then((resource) => {
                return {
                    ...resource,
                    data: data,
                };
            })
            .then(saveEntity);
    };

    return {
        findEntity,
        updateEntity,
        createEntity,
    };
}
