import {IndexedLine, lineLength} from 'libs/models/line';
import {Room} from 'libs/models/room';

export function calculateZoom(room: Room): number {

    const largestWall = calculateLargestWall(room.walls);

    return (150 / largestWall) * 600;
}

function calculateLargestWall(roomWalls: IndexedLine[]): number {
    let longestWall = 0;
    roomWalls.forEach(wall => {
        const line = lineLength(wall)
        if(line > longestWall) {
            longestWall = line;
        }
    })

    return longestWall;
}
