import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SpotProduct} from 'libs/shop/shopClient';
import {deleteEntity, loadEntity, saveEntity} from 'state/entity/entityApi';
import {stepResetPageLight} from 'state/step/stepReset';
import {fetchSpotProducts} from './shopApi';

export interface ShopState {
    status: string | null
    error: string | null,
    spotProducts: SpotProduct[],
    activeMainProductId: string | null,
    activeVariantProductId: string | null,
}

export const initialShopState: ShopState = {
    status: null,
    error: null,
    spotProducts: [],
    activeMainProductId: null,
    activeVariantProductId: null
}

const shopSlice = createSlice({
    name: 'shop',
    initialState: initialShopState,
    reducers: {
        clearActiveMainProductdId: (state) => {
            state.activeMainProductId = null;
            state.activeVariantProductId = null;
        },
        setActiveMainProductId: (state, {payload}: PayloadAction<string>) => {
            state.activeMainProductId = payload;
            state.activeVariantProductId = null;
        },
        clearActiveVariantProductId: (state) => {
            state.activeVariantProductId = null;
        },
        setActiveVariantProductId: (state, {payload}: PayloadAction<string>) => {
            state.activeVariantProductId = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(stepResetPageLight.fulfilled, (state) => {
            state.activeMainProductId = null;
            state.activeVariantProductId = null;
        });

        builder.addCase(loadEntity.fulfilled, (state, {payload}) => {
            state.activeMainProductId = payload.data.shop.activeMainProductId;
            state.activeVariantProductId = payload.data.shop.activeVariantProductId;
        });
        builder.addCase(loadEntity.rejected, () => {
            return initialShopState;
        });
        builder.addCase(saveEntity.fulfilled, (state, {payload}) => {
            state.activeMainProductId = payload.data.shop.activeMainProductId;
            state.activeVariantProductId = payload.data.shop.activeVariantProductId;
        });
        builder.addCase(saveEntity.rejected, () => {
            return initialShopState;
        });
        builder.addCase(deleteEntity.fulfilled, () => {
            return initialShopState;
        });
        builder.addCase(deleteEntity.rejected, () => {
            return initialShopState;
        });

        builder.addCase(fetchSpotProducts.pending, (state) => {
            state.status = 'loading'
        });
        builder.addCase(fetchSpotProducts.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.spotProducts = action.payload;
        });
        builder.addCase(fetchSpotProducts.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message ?? null;
            state.spotProducts = [];
        });
    }
})

export const {
    clearActiveMainProductdId,
    setActiveMainProductId,
    clearActiveVariantProductId,
    setActiveVariantProductId
} = shopSlice.actions;

export const shopReducer = shopSlice.reducer;
