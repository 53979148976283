import {IndexedLine} from 'libs/models/line';
import {Position, PositionsMinMax} from 'libs/models/position';


export interface Room {
    corners: Position[];
    walls: IndexedLine[];
}

export function getRoomNullPosition(minMaxValues: PositionsMinMax): Position {
    return {
        x: Math.floor(minMaxValues.maxX - ((Math.abs(minMaxValues.minX) + Math.abs(minMaxValues.maxX)) / 2)),
        y: Math.floor(minMaxValues.maxY - ((Math.abs(minMaxValues.minY) + Math.abs(minMaxValues.maxY)) / 2)),
    }
}

