import {nameForDirection} from 'app/config';
import {outletMinimumDistanceToWall} from 'app/config/outlet';
import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {PanelEditItem} from 'components/common/panel/PanelEditItem';
import {OutletToolTip} from 'components/panel/outlet/OutletToolTip';
import {Position} from 'libs/models/position';
import {DistanceLine} from 'libs/models/room';
import {Direction} from 'libs/models/types';
import {getValidatedOutletLengthOrDefault} from 'libs/models/validation';
import React, {useCallback, useEffect, useState} from 'react';
import {useOutletValidation} from 'state/outlet/outletHook';
import {setOutletPosition} from 'state/outlet/outletReducer';
import {selectOutletDistanceLines} from 'state/outlet/outletSelectors';
import {outlineEditorStyles} from 'components/panel/outlet/styles/styles';
import {css, tw} from 'twind/css';

function calculateOutletPosition(distanceLine: DistanceLine, direction: Direction, length: number): Position {
    const delta = distanceLine.length - length;
    if (delta === 0) {
        return distanceLine.from;
    }

    if (direction === 'top') {
        return {...distanceLine.from, y: distanceLine.from.y - delta};
    }
    if (direction === 'right') {
        return {...distanceLine.from, x: distanceLine.from.x + delta};
    }
    if (direction === 'bottom') {
        return {...distanceLine.from, y: distanceLine.from.y + delta};
    }
    if (direction === 'left') {
        return {...distanceLine.from, x: distanceLine.from.x - delta};
    }

    return distanceLine.from;
}

type LineChangedEventArgs = {
    length: number;
    direction: Direction;
    distanceLine: DistanceLine;
}

export const EditOutletPanel = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const outletAxesDistanceLines = useAppSelector(selectOutletDistanceLines);
    const {isValidOutletPosition} = useOutletValidation();

    const handleLineChange = useCallback(({direction, length, distanceLine}: LineChangedEventArgs): void => {
        const updatedOutletPosition = calculateOutletPosition(distanceLine, direction, length);
        if (updatedOutletPosition !== distanceLine.from && isValidOutletPosition(updatedOutletPosition)) {
            dispatch(setOutletPosition(updatedOutletPosition));
        }
    }, [dispatch, isValidOutletPosition]);

    const selectTypePanelStyles = css({
        '.title': {
            '@apply': 'relative relative bg-black text-white font-bold px-4 py-2'
        },
    });

    return (
        <div className={tw(selectTypePanelStyles)}>
            <div className={'title'}>
                <h2>Stromauslass</h2>
                <OutletToolTip/>
            </div>
            <PanelEditItem title={'Mindestabstand zur Wand:'} variant={'limit'} isDisabled={true}>
                <p>{outletMinimumDistanceToWall} cm</p>
            </PanelEditItem>
            <div className={'selection'}>
                <OutletlineEditor direction={'top'}
                                  distanceLine={outletAxesDistanceLines?.top || null}
                                  onChange={handleLineChange}
                />
                <OutletlineEditor direction={'right'}
                                  distanceLine={outletAxesDistanceLines?.right || null}
                                  onChange={handleLineChange}
                />
                <OutletlineEditor direction={'bottom'}
                                  distanceLine={outletAxesDistanceLines?.bottom || null}
                                  onChange={handleLineChange}
                />
                <OutletlineEditor direction={'left'}
                                  distanceLine={outletAxesDistanceLines?.left || null}
                                  onChange={handleLineChange}
                />
            </div>
        </div>
    );
};



type OutletlineEditorProps = {
    direction: Direction;
    distanceLine: DistanceLine | null;
    onChange: (args: LineChangedEventArgs) => void;
};

const OutletlineEditor = ({direction, distanceLine, onChange}: OutletlineEditorProps): JSX.Element | null => {
    const title = nameForDirection(direction);
    const isReadonly = (distanceLine === null || distanceLine.id === -1);
    const inputValue = distanceLine?.length ?? 0;

    const outletAxesDistanceLines = useAppSelector(selectOutletDistanceLines);

    const [outletValue, setOutletValue] = useState<number>(inputValue);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        setOutletValue(inputValue);
    }, [inputValue]);

    const validateInput = (length: number, distanceLine: DistanceLine) => {
        return getValidatedOutletLengthOrDefault(outletAxesDistanceLines, length, distanceLine.isHorizontal);
    };

    const handleConfirm = () => {
        if (distanceLine && onChange) {
            const validatedLength = validateInput(outletValue, distanceLine);
            setOutletValue(validatedLength);
            onChange({
                length: validatedLength,
                direction: direction,
                distanceLine
            });
            setIsDisabled(true);
        }
    };

    const handleSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleConfirm();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOutletValue(Number(event.target.value));
        setIsDisabled(false);
    };

    const styles = [outlineEditorStyles.base];
    if (isReadonly) {
        styles.push(outlineEditorStyles.empty);
    }

    return (
        <div className={tw(styles)}>
            <p className={'item-title'}>{title}</p>
                <div className={'form-field'}>
                <input type={'number'} value={outletValue} onChange={handleChange}
                       onKeyUp={handleSubmit}/>
                <label>cm</label>
                <div className={isDisabled ? 'disabled submit-group' : 'submit-group'}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 64 64'
                        width={16}
                        height={16}
                    >
                        <path data-name='layer2' fill='none' d='M52 62V34H12v28m10-18h20m-20 8h16'></path>
                        <path data-name='layer1' fill="none" d='M54 2l8 8v52H2V2h52zm-12 8v4'></path>
                        <path data-name='layer1' d='M50 2v18a2 2 0 0 1-2 2H28a2 2 0 0 1-2-2V2' fill='none' stroke='#202020' stroke-miterlimit='10' stroke-width='2'></path>
                    </svg>
                    <input type={'submit'} value={''} onClick={handleConfirm} className={'submit'}/>
                </div>
            </div>
        </div>
    );
};

