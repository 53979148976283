import {css} from 'twind/css';

export const selectThreeDPanelStyles = css({
    '@apply': 'lg:relative',
    '.title': {
        '@apply': 'relative bg-black text-white font-bold px-4 py-2 tracking-wide'
    },
    '.selection': {
        '@apply': 'grid grid-cols-2 md:grid-cols-4 xl:block xl:grid xl:grid-cols-2 gap-4 p-4',
    },
    '.selection-view': {
        '@apply': 'grid grid-cols-2 gap-4 p-4',
        'button': {
            '@apply': 'justify-start',
        },
    },
    '.selection.selection__color': {
        '@apply': 'block',
        '.react-colorful': {
            '@apply': 'w-full',
        },
    },
    '.selection button': {
        '@apply': 'lg:mb-4 xl:mb-0',
    }
});

export const floorCoveringStyles = css({
    '@apply': 'relative flex flex-wrap px-4 pb-4',
    'img': {
        '@apply': 'cursor-pointer mr-4 mt-4',
        'width': '55px'
    }
});

export const toggleViewModeStyles = css({
    '@apply': 'lg:relative flex items-center justify-center text-white transition-all ease-in-out duration-200',
    '.toggle': {
        '@apply': 'relative block bg-white rounded-full mx-3',
        'width': '60px',
        'height': '12px',
        '&::before': {
            'content': '""',
            '@apply': 'absolute left-0 rounded-full bg-primary shadow-lg',
            'top': '-4px',
            'width': '20px',
            'height': '20px',
        },
    },
    '&.toggled': {
        '@apply': 'transition-all ease-in-out duration-200',
        '.toggle': {
            '&::before': {
                '@apply': 'right-0',
                'left': 'unset',
            }
        }
    }
});
