import {useAppSelector} from 'app/store/hooks';
import {selectIsEditModeTrack} from 'state/view/viewSelectors';
import React from 'react';
import {TrackEdit} from './edit/TrackEdit';
import {TrackView} from './view/TrackView';

export const TrackScene = (): JSX.Element => {
    const isEditMode = useAppSelector(selectIsEditModeTrack);

    if (isEditMode) {
        return (
            <TrackEdit />
        );
    }
    return (
        <TrackView />
    );
};
