import {lineLength, Lines} from 'libs/models/line/line';
import {Position} from 'libs/models/position';

/**
 * Sortierung absteigend
 * @param lines
 */
export function sortLinesFromLongToShort<T extends Position>(lines: Lines<T>): Lines<T> {
    return lines.sort((a, b) => {
        if (lineLength(a) < lineLength(b)) {
            return 1
        }
        if (lineLength(a) > lineLength(b)) {
            return -1
        }
        return 0
    });
}

/**
 * Sorteirung aufsteigend
 * @param lines
 */
export function sortLinesFromShortToLong<T extends Position>(lines: Lines<T>): Lines<T> {
    return lines.sort((a, b) => {
        if (lineLength(a) < lineLength(b)) {
            return -1
        }
        if (lineLength(a) > lineLength(b)) {
            return 1
        }
        return 0
    });
}
