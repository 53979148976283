import {nameForWallIndex} from 'app/config';
import {wallSizeConfig} from 'app/config/wallSize';
import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {NumberInputConfirm, NumberInputEventHandler} from 'components/common/forms/NumberInput';
import {PanelEditContainer} from 'components/common/panel/PanelEditContainer';
import {PanelEditItem} from 'components/common/panel/PanelEditItem';
import {getValidatedLengthOrDefault, MinMaxConfig} from 'libs/models/validation';
import React, {useEffect, useState} from 'react';
import {setWallSizeTo} from 'state/room/roomReducer';
import {selectWallSegments} from 'state/room/roomSelectors';


export const EditRoomPanel = (): JSX.Element => {
    const wallSegmentModels = useAppSelector(selectWallSegments);
    const dispatch = useAppDispatch();

    const handleEditorChange = (args: { length: number, wallId: number }) => {
        dispatch(setWallSizeTo(args));
    };

    const editors = wallSegmentModels.map((wall) => {
        return (
            <WallEditor key={wall.index}
                        title={nameForWallIndex(wall.index)}
                        length={wall.length}
                        onNumberChange={({value}) => handleEditorChange({length: value, wallId: wall.index})}
            />
        );
    });

    return (
        <PanelEditContainer title={'Wandmaße'}  >
            <LimitValues min={wallSizeConfig.min} max={wallSizeConfig.max} />
            {editors}
        </PanelEditContainer>
    );
};

type WallEditorProps = {
    title: string;
    length: number;
    onNumberChange: NumberInputEventHandler;
};

const WallEditor = ({title, length, onNumberChange}: WallEditorProps): JSX.Element => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [value, setValue] = useState(length)

    useEffect(() => {
        setValue(length);
    }, [length])

    const handleClickEvent = () => {
        setIsDisabled(true)
        setValue(getValidatedLengthOrDefault(wallSizeConfig, value))
        if (onNumberChange) {
            onNumberChange({value: value})
        }
    }

    const numberHasChanges = (length: number) => {
        setIsDisabled(false)
        setValue(length)
    }

    return (
        <PanelEditItem title={title} label={'cm'} variant={'input'} isDisabled={isDisabled}
                       handleClickEvent={handleClickEvent}>
            <NumberInputConfirm
                value={value}
                onNumberChange={({value}) => numberHasChanges(value)}
                confirmNumberChange={handleClickEvent}
            />
        </PanelEditItem>
    );
};

const LimitValues = ({min, max}: MinMaxConfig): JSX.Element => {
    return (
        <div className={'default-value'}>
            <PanelEditItem title={'Mindestlänge:'} variant={'limit'} isDisabled={true}>
                <p>{min} cm</p>
            </PanelEditItem>
            <PanelEditItem title={'Maximallänge:'} variant={'limit'} isDisabled={true}>
                <p>{max} cm</p>
            </PanelEditItem>
        </div>
    );
}
