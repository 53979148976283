import {AxiosInstance} from 'axios';
import {ClientApi, EntityData, EntityId, EntityResource} from 'libs/client/clientApi';

export type HttpClientConfig = {
    axios: AxiosInstance
}

export function createHttpClient(config: HttpClientConfig): ClientApi {
    const http = config.axios;

    return {
        createEntity(data: EntityData): Promise<EntityResource> {
            return http.post<EntityResource>('/store-api/bitit/tracklight/entity', {data})
                .then((response) => response.data);

        },
        updateEntity(entityId: EntityId, data: EntityData): Promise<EntityResource> {
            return http.put<EntityResource>('/store-api/bitit/tracklight/entity/' + entityId, {data})
                .then((response) => response.data);
        },
        findEntity(entityId: EntityId): Promise<EntityResource> {
            return http.get<EntityResource>('/store-api/bitit/tracklight/entity/' + entityId)
                .then((response) => response.data);
        }
    }
}
