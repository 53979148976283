import {equalPosition, Position} from 'libs/models/position';
import {Line} from './line';


/**
 * Vergleicht die Positionen von zwei Linien anhand der Werte und gibt die geänderten zurück.
 *
 * @param before
 * @param after
 */
export function findLineChangedPositions<T extends Position>(before: Line<T>, after: Line<T>): T[] {
    const changedPositions: T[] = [];

    if (!equalPosition(before.from, after.from)) {
        changedPositions.push(after.from);
    }
    if (!equalPosition(before.to, after.to)) {
        changedPositions.push(after.to);
    }

    return changedPositions;
}

