import {css} from 'twind/css';

export const lightsIconStyles = css({
    '@apply': 'pointer-events-none flex justify-center items-center bg-white rounded-full shadow-lg transition-all ease-in-out duration-200',
    'left': '-20px',
    'top': '-20px',
    'width': '40px',
    'height': '40px',
    'svg polygon': {
        '@apply': 'fill-primary'
    },
    '&.dragged': {
        '@apply': 'bg-primary-light',
        'svg polygon': {
            '@apply': 'fill-white'
        },
    }
});
