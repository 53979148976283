import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PresetRoomTypes} from 'app/config';
import {EntityResource} from 'libs/client/clientApi';

export interface PresetState {
    entities: EntityResource[];
    currentPresetId: string | null;
}

/**
 * Die initialen Daten werden aktuell aus der config geladen.
 */
export const initialPresetState: PresetState = {
    entities: [...PresetRoomTypes],
    currentPresetId: null,
}


const presetSlice = createSlice({
    name: 'preset',
    initialState: initialPresetState,
    reducers: {
        setCurrentPresetId: ((state, {payload}: PayloadAction<string>) => {
            if (state.currentPresetId !== payload) {
                state.currentPresetId = payload;
            }
        }),
    }
});


export const {setCurrentPresetId} = presetSlice.actions;

export const presetReducer = presetSlice.reducer;
