import {useAppSelector} from 'app/store/hooks';
import {ResetTrackAction} from 'components/actions/track/ResetTrackAction';
import {SaveTrackAction} from 'components/actions/track/SaveTrackAction';
import React from 'react';
import {selectStepNeedsResetPageTrack} from 'state/step/selectStepNeedReset';


export const TrackActions = (): JSX.Element => {
    const needsReset = useAppSelector(selectStepNeedsResetPageTrack);
    if (needsReset) {
        return (
            <ResetTrackAction />
        );
    }
    return (
        <SaveTrackAction />
    );
};
