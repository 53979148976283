import {css} from 'twind/css';

export const trackKnotMenuStyles = css({
    '@apply': 'pointer-events-none flex justify-center items-center bg-white rounded-full shadow-lg text-sm font-bold',
    'width': '40px',
    'height': '40px',
    'svg': {
        '@apply': '-mt-1'
    }
});

export const trackLineMenuStyles = css({
    '@apply': 'pointer-events-none flex justify-center items-center bg-white rounded-full shadow-lg',
    'width': '22px',
    'height': '22px',
    'svg polygon': {
        '@apply': 'fill-primary'
    },
    'svg path': {
        '@apply': 'fill-primary'
    }
});

export const TrackMenuStyles = css({
    '@apply': 'pointer-events-none -bottom-4 left-2/4 transform -translate-x-2/4',
    'transform': 'translateX(-50%) !important',
    '&.dragged': {
        '@apply': 'hidden',
    },
    'svg': {
        '@apply': 'fill-primary transform'
    },
    '.arrow-top': {
        '@apply': '-rotate-90'
    },
    '.arrow-left': {
        '@apply': '-rotate-180'
    },
    '.arrow-bottom': {
        '@apply': 'rotate-90'
    }
});
