import React from 'react';
import {variantMap, baseStyles} from 'components/common/buttons/styles/styles';
import {tw} from 'twind/css';

export type ButtonProps = React.ComponentProps<'button'> & {
    variant?: 'primary' | 'secondary',
}


export const Button = ({ variant, children, ...props }: ButtonProps): JSX.Element => {
    const activeVariant = variant || 'primary'
    return (
        <button className={tw(baseStyles, variantMap[activeVariant])} {...props}>
            {children}
        </button>
    )
}
