import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {SaveAction} from 'components/common/actions/SaveAction';
import {EntityResource} from 'libs/client/clientApi';
import React from 'react';
import {createEntityFromPresetEntity} from 'state/entity/entityPreset';

import {pageEditRoom} from 'state/location/pageRoutes';
import {selectCurrentPresetEntity} from 'state/preset/presetSelectors';
import {selectEntityColors} from 'state/scene/sceneSelectors';

export const SaveTypeAction: React.FC = () => {
    const dispatch = useAppDispatch();
    const presetEntity = useAppSelector(selectCurrentPresetEntity);
    const entityColors = useAppSelector(selectEntityColors);

    const handleSave = async () => {
        if (presetEntity !== null) {
            const changedPresetEntityData = {data: {...presetEntity.data, ...entityColors}};
            const changedPresetEntity = {...presetEntity, ...changedPresetEntityData};

            const result = await dispatch(createEntityFromPresetEntity(changedPresetEntity));
            const createdEntity = result.payload as EntityResource;
            dispatch(pageEditRoom.route(createdEntity));
        }
    };

    return (
        <SaveAction
            disabled={presetEntity === null}
            onSave={handleSave}
            hasNext={presetEntity !== null}/>
    );
};

