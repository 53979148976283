import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store/store';
import {OutletEntityData} from 'libs/client/clientApi';
import {calculateAxesDistanceAsLines} from 'libs/models/room';
import {OutletState} from 'state/outlet/outletReducer';
import {selectRoom} from '../room/roomSelectors';

export const selectOutletState = (state: RootState): OutletState => state.outlet;

export const selectOutletPosition = createSelector(
    [selectOutletState],
    (state) => state.position
);

export const selectOutletDistanceLines = createSelector(
    [selectOutletPosition, selectRoom],
    (position, room) => {
        if (position) {
            return calculateAxesDistanceAsLines(room.walls, position);
        }
        return null;
    }
);

/**
 * Das sind die Daten, die in der Entität gespeichert werden.
 */
export const selectOutletEntityData = createSelector(
    [selectOutletPosition],
    (position): OutletEntityData => {
        return {
            position,
        }
    }
);
