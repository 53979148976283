import {Tooltip} from 'components/common/tooltip/Tooltip';

import React from 'react';


export const TrackToolTip = (): JSX.Element => {
    return (
        <Tooltip title={'Hinweis'} setDisbandTime={5000}>
            Um Lichtschienen anzulegen, ziehen Sie einen der Pfeile in die gewünschte Richtung.<br/>
            Die erstellten Lichtschienen werden anschließend in der Sidebar angezeigt und Sie können dort die exakte Länge eingeben.
        </Tooltip>
    );
};
