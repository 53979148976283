import {useAppSelector} from 'app/store/hooks';
import {styles} from 'components/common/panel/styles/PanelEditContainerStyles';
import React from 'react';
import {
    selectOrderTracksList,
    selectOrderTracksOneMetersAmount,
    selectOrderTracksTwoMetersAmount
} from 'state/order/orderSelectors';
import {tw} from 'twind/css';

export const OrderTracksList = (): JSX.Element => {
    const orderListResults = useAppSelector(selectOrderTracksList);
    const twoMeters = useAppSelector(selectOrderTracksTwoMetersAmount)
    const oneMeters = useAppSelector(selectOrderTracksOneMetersAmount)

    const elements = orderListResults.map((result, idx) => {
        return (
            <p key={idx}>
                {result.trackLength}cm Leiste <br/>
                Für die Gesamtlänge {result.originalLength}cm <br/>
                Für die Verbindungen: {result.forOrderTrack.map(ot => ot.key + ' (' + ot.length + 'cm), ')} <br/>
                Verschnitt: {result.waste}cm
            </p>
        );
    });

    return (

        <div className={tw(styles)}>
            <div className={'container'}>
                <div className={'title'}>
                    <h2>Lichtschienen</h2>
                </div>
            </div>
            <div className={'item'}>
                <p>
                    {twoMeters} x 200cm Schienen
                    <br/>
                    {oneMeters} x 100cm Schienen
                </p>
            </div>
            <div className={'item'}>
                    {elements}
            </div>
        </div>
    );
};
