import {trackMinimumDistanceToWall} from 'app/config/track';
import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {RoomInlines} from 'components/scene/room/RoomInlines';
import {TrackKnotMenu} from 'components/scene/track/edit/TrackKnotMenu';
import {TrackLine} from 'components/scene/track/edit/TrackLine';
import {KnotId} from 'libs/models/knot';
import {Direction} from 'libs/models/types';
import React from 'react';
import {selectRoom} from 'state/room/roomSelectors';
import {addTrackKnot, removeTrackBetweenKnots} from 'state/track/trackReducer';
import {selectTrackKnots, selectTrackLines, selectTrackValidationRoom} from 'state/track/trackSelectors';

export const TrackEdit = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const trackKnots = useAppSelector(selectTrackKnots);
    const trackLines = useAppSelector(selectTrackLines);
    const room = useAppSelector(selectRoom);
    const validTrackRoom = useAppSelector(selectTrackValidationRoom);

    const addNewTrackKnot = (knotId: number, direction: Direction, length: number) => {
        dispatch(addTrackKnot({knotId, direction, length, validTrackRoom, room}));
    }

    const removeTrack = (fromId: KnotId, toId: KnotId) => {
        dispatch(removeTrackBetweenKnots({fromId, toId}))
    }

    const trackKnotsView = trackKnots.map((knot) => (
        <TrackKnotMenu
            key={knot.id}
            knot={knot}
            addNewTrackKnot={addNewTrackKnot}
        />
    ));

    const trackLinesView = trackLines.map((line, idx) => (
        <TrackLine
            key={idx}
            line={line}
            removeConnection={removeTrack}
        />
    ));

    return (
        <>
            <RoomInlines minimumDistance={trackMinimumDistanceToWall} />
            {trackKnotsView}
            {trackLinesView}
        </>
    )
}
