import {createSelector} from '@reduxjs/toolkit';
import {spotlightDistanceFromSpotlight} from 'app/config/light';
import {trackMinimumDistanceToWall} from 'app/config/track';
import {RootState} from 'app/store/store';
import {TrackEntityData} from 'libs/client/clientApi';
import {mapKnotConnectionsToUniqueLines} from 'libs/models/knot/knotLines';
import {getMaxSpotlightsPerTrackLine} from 'libs/models/light/light';
import {IndexedLine} from 'libs/models/line';
import {mapRoomFromPositions, moveRoomPositionsByOffset} from 'libs/models/room';
import {selectRoom} from 'state/room/roomSelectors';
import {TrackState} from 'state/track/trackReducer';

export const selectTrackState = (state: RootState): TrackState => state.track;

export const selectTrackErrorId = createSelector(
    [selectTrackState],
    (state) => state.errorId
);

export const selectTrackHasKnots = createSelector(
    [selectTrackState],
    (state) => state.knots.length > 0
);

export const selectTrackKnots = createSelector(
    [selectTrackState],
    (state) => state.knots
);

export const selectTrackLines = createSelector(
    [selectTrackKnots],
    (knots): IndexedLine[] => mapKnotConnectionsToUniqueLines(knots)
);

export const selectTrackRequiresReset = createSelector(
    [selectTrackState],
    (state) => state.knots.length > 1
);


export const selectMaxNumberOfSpotligths = createSelector(
    [selectTrackLines],
    (trackLines) :number => {
        const maxSpotlightsPerTrack = getMaxSpotlightsPerTrackLine(trackLines, spotlightDistanceFromSpotlight);
        const reducedMaxSpotlightsPerTrack = maxSpotlightsPerTrack.reduce((acc, curr) => acc + curr, 0);
        return reducedMaxSpotlightsPerTrack < 0 ? 0 : reducedMaxSpotlightsPerTrack;
    }
)

export const selectTrackValidationRoom = createSelector(
    [selectRoom],
    (room) => mapRoomFromPositions(moveRoomPositionsByOffset(trackMinimumDistanceToWall - 1, room))
);

/**
 * Das sind die Daten, die in der Entität gespeichert werden.
 */
export const selectTrackEntityData = createSelector(
    [selectTrackKnots],
    (knots): TrackEntityData => {
        return {
            knots
        };
    }
);
