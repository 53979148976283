import {Position} from 'libs/models/position';
import {Line, lineDirection, lineIsHorizontal, lineIsVertical} from './line';


/**
 * Erzeugt eine Kopie der Linie mit neuen Positionen __relativ__ zu den alten.
 *
 * Das funktioniert nur mit horizontalen und vertikalen Linien.
 * Ansonsten wird die die Linie ohne Änderung zurückgegeben.
 *
 * Wenn die Linie horizontal ist, wird die y-Achse verschoben.
 * Wenn die Linie vertikal ist, wird die x-Achse verschoben.
 */
export function moveLineBy<T extends Position>(line: Line<T>, amount: number): Line<T> {
    if (lineIsHorizontal(line)) {
        return {
            ...line,
            from: {...line.from, y: (line.from.y + amount)},
            to: {...line.to, y: (line.to.y + amount)},
        }
    }
    if (lineIsVertical(line)) {
        return {
            ...line,
            from: {...line.from, x: (line.from.x + amount)},
            to: {...line.to, x: (line.to.x + amount)},
        }
    }
    return line;
}

/**
 * Erzeugt eine Kopie der Linie mit __absoluten__ neuen Positionen.
 *
 * Das funktioniert nur mit horizontalen und vertikalen Linien.
 * Ansonsten wird die die Linie ohne Änderung zurückgegeben.
 *
 * Wenn die Linie horizontal ist, wird die y-Achse gesetzt.
 * Wenn die Linie vertikal ist, wird die x-Achse gesetzt.
 */
export function moveLineTo<T extends Position>(line: Line<T>, amount: number): Line<T> {
    if (lineIsHorizontal(line)) {
        return {
            ...line,
            from: {...line.from, y: amount},
            to: {...line.to, y: amount},
        }
    }
    if (lineIsVertical(line)) {
        return {
            ...line,
            from: {...line.from, x: amount},
            to: {...line.to, x: amount},
        }
    }
    return line;
}

/**
 * Verschiebt die Linie in seiner Orientierung um den amount
 * @param line
 * @param amount
 */

export function moveLineInDirection<T extends Position>(line: Line<T>, amount: number): Line<T> {

    if (lineIsHorizontal(line)) {
        return {
            ...line,
            from: {...line.from, x: line.from.x + amount},
            to: {...line.to, x: line.to.x + amount}
        }
    }

    if (lineIsVertical(line)) {
        return {
            ...line,
            from: {...line.from, y: line.from.y + amount},
            to: {...line.to, y: line.to.y + amount}
        }
    }

    return line;
}

/**
 * Schneidet eine Linie um den amount an beiden Enden
 * @param line
 * @param amount
 */
export function cutLineInDirection<T extends Position>(line: Line<T>, amount: number): Line<T> {
    if (lineIsHorizontal(line)) {
        if (lineDirection(line) === -1) {
            return {
                ...line,
                from: {...line.from, x: line.from.x - amount},
                to: {...line.to, x: line.to.x + amount}
            }
        }
        return {
            ...line,
            from: {...line.from, x: line.from.x + amount},
            to: {...line.to, x: line.to.x - amount}
        }
    }
    if (lineIsVertical(line)) {
        if (lineDirection(line) === -1) {
            return {
                ...line,
                from: {...line.from, y: line.from.y - amount},
                to: {...line.to, y: line.to.y + amount}
            }
        }
        return {
            ...line,
            from: {...line.from, y: line.from.y + amount},
            to: {...line.to, y: line.to.y - amount}
        }
    }
    return line;
}
