import {useAppSelector} from 'app/store/hooks';
import {EditThreeDPanel} from 'components/panel/3d/EditThreeDPanel';
import {ErrorPanel} from 'components/panel/error/ErrorPanel';
import {EditLightPanel} from 'components/panel/light/EditLightPanel';
import {EditOrderPanel} from 'components/panel/order/EditOrderPanel';
import {EditOutletPanel} from 'components/panel/outlet/EditOutletPanel';
import {EditRoomPanel} from 'components/panel/room/EditRoomPanel';
import {EditTrackPanel} from 'components/panel/track/EditTrackPanel';
import {EditTypePanel} from 'components/panel/type/EditTypePanel';
import React from 'react';
import {selectViewMode} from 'state/scene/sceneSelectors';
import {EditModeComponentMap, useEditModeComponent} from 'state/view/viewModels';
import {selectEditMode} from 'state/view/viewSelectors';

const componentMap: EditModeComponentMap = {
    type: () => <EditTypePanel />,
    room: () => <EditRoomPanel />,
    outlet: () => <EditOutletPanel />,
    track: () => <EditTrackPanel />,
    light: () => <EditLightPanel />,
    order: () => <EditOrderPanel />,
    error: () => <ErrorPanel />
};

export const MainPanel = (): JSX.Element | null => {
    const sceneViewMode = useAppSelector(selectViewMode);
    const editMode = useAppSelector(selectEditMode);
    const currentComponent = useEditModeComponent(componentMap);
    const panelEditComponents = currentComponent && sceneViewMode.isTwo ? currentComponent() : null;

    if (editMode === 'error') {
        return (
            <>
                {panelEditComponents}
            </>
        )
    }
    return (
        <>
            <EditThreeDPanel />
            {panelEditComponents}
        </>
    );
};
