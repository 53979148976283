import {css} from 'twind/css';

export const outletMeshStyles = css({
    '@apply': 'pointer-events-none flex justify-center items-center bg-white rounded-full shadow-lg transition-all ease-in-out duration-200',
    'width': '40px',
    'height': '40px',
    'svg': {
        '@apply': '-mt-1',
        'path, polygon': {
            '@apply': 'fill-current',
        },
    },
    '&.dragged': {
        '@apply': 'bg-primary-light',
        'path, polygon': {
            '@apply': 'fill-white'
        },
    }
});
