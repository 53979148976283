import {AdditionalColor, SystemColor} from 'app/config/order';
import * as THREE from 'three';
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader';

export type SpotLenxxGLTFResult = GLTF & {
    nodes: {
        schienenadapter: THREE.Mesh
        stab: THREE.Mesh
        strahlerkopf_unten: THREE.Mesh
        strahlerkopf_links: THREE.Mesh
        innenreflektor_unten: THREE.Mesh
        innenreflektor_links: THREE.Mesh
        innenreflektor_strahler_rechts: THREE.Mesh
        strahlerkopf_strahler_rechts: THREE.Mesh
        innenreflektor_strahler_vorne: THREE.Mesh
        strahlerkopf_strahler_vorne: THREE.Mesh
        innenreflektor_strahler_hinten: THREE.Mesh
        strahlerkopf_strahler_hinten: THREE.Mesh
    }
    materials: {
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
    }
}

export type SpotStarGLTFResult = GLTF & {
    nodes: {
        schienenadapter: THREE.Mesh
        stab: THREE.Mesh
        reflektor_hinten: THREE.Mesh
        reflektor_rechts: THREE.Mesh
        reflektor_links: THREE.Mesh
        reflektor_unten: THREE.Mesh
        strahler_hinten_1: THREE.Mesh
        strahler_hinten_2: THREE.Mesh
        reflektor_vorne: THREE.Mesh
        strahler_links_1: THREE.Mesh
        strahler_links_2: THREE.Mesh
        strahler_unten_1: THREE.Mesh
        strahler_unten_2: THREE.Mesh
        strahler_rechts_1: THREE.Mesh
        strahler_rechts_2: THREE.Mesh
        strahler_vorne_1: THREE.Mesh
        strahler_vorne_2: THREE.Mesh
    }
    materials: {
        ['default']: THREE.MeshStandardMaterial
        ['default.001']: THREE.MeshStandardMaterial
        ['light_4ed992bb.002']: THREE.MeshStandardMaterial
        ['light_4ed992bb.004']: THREE.MeshStandardMaterial
        ['light_4ed992bb.003']: THREE.MeshStandardMaterial
        light_4ed992bb: THREE.MeshStandardMaterial
        ['body_4ed992bb.002']: THREE.MeshStandardMaterial
        ['steel - satin_4ed992bb.002']: THREE.MeshStandardMaterial
        ['light_4ed992bb.001']: THREE.MeshStandardMaterial
        ['body_4ed992bb.003']: THREE.MeshStandardMaterial
        ['steel - satin_4ed992bb.003']: THREE.MeshStandardMaterial
        body_4ed992bb: THREE.MeshStandardMaterial
        ['steel - satin_4ed992bb']: THREE.MeshStandardMaterial
        ['body_4ed992bb.004']: THREE.MeshStandardMaterial
        ['steel - satin_4ed992bb.004']: THREE.MeshStandardMaterial
        ['body_4ed992bb.001']: THREE.MeshStandardMaterial
        ['steel - satin_4ed992bb.001']: THREE.MeshStandardMaterial
    }
}

export
type SpotFinoGLTFResult = GLTF & {
    nodes: {
        schienenadapter: THREE.Mesh
        stab: THREE.Mesh
        hinten_reflektor: THREE.Mesh
        links_reflektor: THREE.Mesh
        hinten_strahler_1: THREE.Mesh
        hinten_strahler_2: THREE.Mesh
        hinten_linse: THREE.Mesh
        links_linse: THREE.Mesh
        rechts_reflektor: THREE.Mesh
        links_strahler_1: THREE.Mesh
        links_strahler_2: THREE.Mesh
        rechts_linse: THREE.Mesh
        unten_reflektor: THREE.Mesh
        rechts_strahler_1: THREE.Mesh
        rechts_strahler_2: THREE.Mesh
        unten_strahler_1: THREE.Mesh
        unten_strahler_2: THREE.Mesh
        vorne_linse: THREE.Mesh
        unten_linse: THREE.Mesh
        vorne_reflektor: THREE.Mesh
        vorne_strahler_1: THREE.Mesh
        vorne_strahler_2: THREE.Mesh
    }
    materials: {
        ['default']: THREE.MeshStandardMaterial
        ['default.001']: THREE.MeshStandardMaterial
        ['body_4b17b6f1.002']: THREE.MeshStandardMaterial
        ['body_4b17b6f1.004']: THREE.MeshStandardMaterial
        ['base_material_4b17b6f1.002']: THREE.MeshStandardMaterial
        ['glas_outside_4b17b6f1.002']: THREE.MeshStandardMaterial
        ['glas_outside_4b17b6f1.004']: THREE.MeshStandardMaterial
        ['body_4b17b6f1.003']: THREE.MeshStandardMaterial
        ['base_material_4b17b6f1.004']: THREE.MeshStandardMaterial
        ['glas_outside_4b17b6f1.003']: THREE.MeshStandardMaterial
        body_4b17b6f1: THREE.MeshStandardMaterial
        ['base_material_4b17b6f1.003']: THREE.MeshStandardMaterial
        base_material_4b17b6f1: THREE.MeshStandardMaterial
        ['glas_outside_4b17b6f1.001']: THREE.MeshStandardMaterial
        glas_outside_4b17b6f1: THREE.MeshStandardMaterial
        ['body_4b17b6f1.001']: THREE.MeshStandardMaterial
        ['base_material_4b17b6f1.001']: THREE.MeshStandardMaterial
    }
}

export type SpotEuclidGLTFResult = GLTF & {
    nodes: {
        schienenadapter: THREE.Mesh
        stab: THREE.Mesh
        hinten_linse: THREE.Mesh
        links_linse: THREE.Mesh
        rechts_linse: THREE.Mesh
        links_strahler001: THREE.Mesh
        links_strahler001_1: THREE.Mesh
        hinten_strahler001: THREE.Mesh
        hinten_strahler001_1: THREE.Mesh
        unten_linse: THREE.Mesh
        rechts_strahler001: THREE.Mesh
        rechts_strahler001_1: THREE.Mesh
        vorne_linse: THREE.Mesh
        unten_strahler001: THREE.Mesh
        unten_strahler001_1: THREE.Mesh
        vorne_strahler001: THREE.Mesh
        vorne_strahler001_1: THREE.Mesh
    }
    materials: {
        ['default']: THREE.MeshStandardMaterial
        ['default.001']: THREE.MeshStandardMaterial
        ['glas_outside_89e380fc.004']: THREE.MeshStandardMaterial
        ['glas_outside_89e380fc.002']: THREE.MeshStandardMaterial
        ['glas_outside_89e380fc.003']: THREE.MeshStandardMaterial
        ['body_89e380fc.002']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['body_89e380fc.004']: THREE.MeshStandardMaterial
        glas_outside_89e380fc: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['body_89e380fc.003']: THREE.MeshStandardMaterial
        ['glas_outside_89e380fc.001']: THREE.MeshStandardMaterial
        body_89e380fc: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['body_89e380fc.001']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
    }
}

export type SpotBlopGLTFResult = GLTF & {
    nodes: {
        unten_linse: THREE.Mesh
        vorne_linse: THREE.Mesh
        hinten_linse: THREE.Mesh
        unten_strahler: THREE.Mesh
        vorne_strahler: THREE.Mesh
        hinten_strahler: THREE.Mesh
        schienenadapter: THREE.Mesh
        links_linse: THREE.Mesh
        stab: THREE.Mesh
        links_strahler: THREE.Mesh
        rechts_linse: THREE.Mesh
        rechts_strahler: THREE.Mesh
    }
    materials: {
        ['glas_outside_2113da3d.001']: THREE.MeshStandardMaterial
        ['glas_outside_2113da3d.002']: THREE.MeshStandardMaterial
        ['glas_outside_2113da3d.003']: THREE.MeshStandardMaterial
        ['body_2113da3d.001']: THREE.MeshStandardMaterial
        ['body_2113da3d.002']: THREE.MeshStandardMaterial
        ['body_2113da3d.003']: THREE.MeshStandardMaterial
        ['default']: THREE.MeshStandardMaterial
        ['glas_outside_2113da3d.005']: THREE.MeshStandardMaterial
        ['default.001']: THREE.MeshStandardMaterial
        ['body_2113da3d.005']: THREE.MeshStandardMaterial
        ['glas_outside_2113da3d.004']: THREE.MeshStandardMaterial
        ['body_2113da3d.004']: THREE.MeshStandardMaterial
    }
}

export type MaterialGLTFResult = GLTF & {
    nodes: {
        chrom_glanz: THREE.Mesh
        glas_schwarz: THREE.Mesh
        glas_linse: THREE.Mesh
        schwarz_matt: THREE.Mesh
        gold: THREE.Mesh
        glas_weiss: THREE.Mesh
        weiss_matt: THREE.Mesh
        rose_matt: THREE.Mesh
        chrom_matt: THREE.Mesh
    }
    materials: {
        chrom_glanz: THREE.MeshStandardMaterial
        glas_schwarz: THREE.MeshStandardMaterial
        glas_linse: THREE.MeshStandardMaterial
        schwarz_matt: THREE.MeshStandardMaterial
        gold: THREE.MeshStandardMaterial
        glas_weiss: THREE.MeshStandardMaterial
        weiss_matt: THREE.MeshStandardMaterial
        rose_matt: THREE.MeshStandardMaterial
        chrom_matt: THREE.MeshStandardMaterial
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const materialBySystemColor = (sysColor: SystemColor | AdditionalColor | null) => {
    switch (sysColor) {
        case 'sw':
            return 'schwarz_matt';
        case 'ws':
            return 'weiss_matt';
        case 'ch':
            return 'chrom_glanz';
        case 'mcgy':
            return 'chrom_matt';
        case 'go':
            return 'gold';
        default:
            return 'schwarz_matt';
    }
}
