import {RootState} from '@react-three/fiber';
import {Position} from 'libs/models/position';
import {threeToCM} from 'libs/view/position';

export function convertMouseToPositionInCM({viewport, mouse, camera}: Pick<RootState, 'mouse' | 'viewport' | 'camera'>): Position {
    const current = viewport.getCurrentViewport();
    return {
        x: threeToCM((mouse.x  * current.width) * 0.5) + threeToCM(camera.position.x),
        y: threeToCM(((mouse.y * current.height) * 0.5) * -1)  + threeToCM(camera.position.z)
    };
}

