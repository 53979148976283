import {useGLTF} from '@react-three/drei';
import {publicPath} from 'app/config/gltf';
import {SpotFinoGLTFResult} from 'libs/models/3d/gltf';
import {SpotFinoModeComponentMap, SpotMode} from 'libs/models/3d/spotModels';
import {PositionThree} from 'libs/view';
import * as THREE from 'three';
import {MeshStandardMaterial} from 'three';

type SpotFinoProps = {
    systemMaterial: THREE.MeshStandardMaterial;
    additionalMaterial: THREE.MeshStandardMaterial;
    linsenMaterial: THREE.MeshStandardMaterial;
    spotMode: SpotMode;
    position: PositionThree;
    isHorizontal: boolean;
}

export const SpotFino = (
    {
        systemMaterial,
        additionalMaterial,
        linsenMaterial,
        spotMode,
        position,
        isHorizontal
    }: SpotFinoProps): JSX.Element => {
    const {nodes} = useGLTF(publicPath('/gltf/spotFinoDraco.gltf')) as SpotFinoGLTFResult;

    const strahlerkopfMaterial = systemMaterial;
    const innenreflektorMaterial = additionalMaterial;

    const baseRotation: PositionThree = isHorizontal ? [0, 0, 0] : [0, Math.PI / 2, 0];

    const spotComponent = spotComponentMap[spotMode];
    const spot = spotComponent({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial});

    return (
        <group scale={0.04} position={position} dispose={null}>
            <group name={'base'}>
                <mesh
                    geometry={nodes.schienenadapter.geometry}
                    material={systemMaterial}
                    rotation={baseRotation}
                    scale={[0.8, 0.2, -0.3]}
                />
                <mesh
                    geometry={nodes.stab.geometry}
                    material={systemMaterial}
                    position={[0, -0.42, 0]}
                    rotation={[0, 0, 3.14]}
                    scale={[0.08, 0.32, -0.08]}
                />
            </group>
            {spot}
        </group>
    );
};

const spotComponentMap: SpotFinoModeComponentMap = {
    top: ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps) => (
        <SpotFinoTop
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
            linsenMaterial={linsenMaterial}
        />
    ),
    right: ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps) => (
        <SpotFinoRight
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
            linsenMaterial={linsenMaterial}
        />
    ),
    bottom: ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps) => (
        <SpotFinoBottom
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
            linsenMaterial={linsenMaterial}
        />
    ),
    left: ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps) => (
        <SpotFinoLeft
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
            linsenMaterial={linsenMaterial}
        />
    ),
    down: ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps) => (
        <SpotFinoDown
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
            linsenMaterial={linsenMaterial}
        />
    ),
};

export type SpotFino3DProps = {
    nodes: SpotFinoGLTFResult['nodes'];
    innenreflektorMaterial: MeshStandardMaterial;
    strahlerkopfMaterial: MeshStandardMaterial;
    linsenMaterial: THREE.MeshStandardMaterial;
}

export const SpotFinoTop = ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps): JSX.Element => {
    return (
        <group name={'top'}>
            <mesh
                geometry={nodes.rechts_reflektor.geometry}
                material={innenreflektorMaterial}
                position={[0, -2.41, -1.04]}
                rotation={[2.17, 0, 0.52]}
                scale={[-0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.rechts_strahler_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, -1.57]} scale={[-0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.rechts_strahler_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, -1.57]} scale={[-0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.rechts_linse.geometry}
                material={linsenMaterial}
                position={[0, -2, -0.76]}
                rotation={[-2.54, -0.27, -3.14]}
                scale={[-0.02, 0.02, 0.02]}
            />
        </group>
    );
};
export const SpotFinoBottom = ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps): JSX.Element => {
    return (
        <group name={'bottom'}>
            <mesh
                geometry={nodes.links_reflektor.geometry}
                material={innenreflektorMaterial}
                position={[0, -2.41, 1.04]}
                rotation={[0.97, 0, 2.62]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.links_strahler_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, -1.57]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.links_strahler_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, -1.57]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.links_linse.geometry}
                material={linsenMaterial}
                position={[0, -2, 0.76]}
                rotation={[-0.6, -0.27, 0]}
                scale={[0.02, 0.02, 0.02]}
            />
        </group>
    );
};
export const SpotFinoLeft = ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps): JSX.Element => {
    return (
        <group name={'left'}>
            <mesh
                geometry={nodes.vorne_reflektor.geometry}
                material={innenreflektorMaterial}
                position={[-1.04, -2.41, 0]}
                rotation={[1.57, -0.6, -2.09]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.vorne_strahler_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, Math.PI]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.vorne_strahler_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, Math.PI]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.vorne_linse.geometry}
                material={linsenMaterial}
                position={[-0.76, -2, 0]}
                rotation={[-2.03, -0.92, -1.94]}
                scale={[0.02, 0.02, 0.02]}
            />
        </group>
    );
};
export const SpotFinoRight = ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps): JSX.Element => {
    return (
        <group name={'right'}>
            <mesh
                geometry={nodes.hinten_reflektor.geometry}
                material={innenreflektorMaterial}
                position={[1.04, -2.41, 0]}
                rotation={[1.57, 0.6, 2.09]}
                scale={[-0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.hinten_strahler_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, -Math.PI]} scale={[-0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.hinten_strahler_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, -Math.PI]} scale={[-0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.hinten_linse.geometry}
                material={linsenMaterial}
                position={[0.76, -2, 0]}
                rotation={[-2.03, 0.92, 1.94]}
                scale={[-0.02, 0.02, 0.02]}
            />
        </group>
    );
};
export const SpotFinoDown = ({nodes, innenreflektorMaterial, strahlerkopfMaterial, linsenMaterial}: SpotFino3DProps): JSX.Element => {
    return (
        <group name={'down'}>
            <mesh
                geometry={nodes.unten_reflektor.geometry}
                material={innenreflektorMaterial}
                position={[0, -2.55, 0]}
                rotation={[1.57, 0, -2.09]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.unten_strahler_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, Math.PI]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.unten_strahler_2.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1.05, 0]} rotation={[-1.57, 0, Math.PI]} scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                geometry={nodes.unten_linse.geometry}
                material={linsenMaterial}
                position={[0, -2.06, 0]}
                rotation={[-3.14, -1.3, -3.14]}
                scale={[0.02, 0.02, 0.02]}
            />
        </group>
    );
};

useGLTF.preload(publicPath('/gltf/spotFinoDraco.gltf'));
