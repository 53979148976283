import {css} from 'twind/css';

export const sceneCss = css({
    display: 'block',
    width: '100%',
    height: 'calc(100vh - 300px)',
    margin: 0,
    padding: 0,
    touchAction: 'none',
    '@screen lg': {
        'height': '100%'
    },
});
