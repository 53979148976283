import {
    alignPositionHorizontalWith,
    alignPositionVerticalWith,
    findPositionByIndexInDirection,
    IndexedPosition,
    Position
} from 'libs/models/position';
import {LineDirection, LineOrientation} from './line';


/**
 * Ändert alle Nachbarpositionen der gegebenen Position.
 *
 * Geht davon aus, das die Nachbarn immer abwechselnd horizontal und vertikal sind, sonst klappts nicht.
 *
 * Wenn also die {referenceOrientation}
 * - Horizontal ist, werden die Nachbarn Vertikal angepasst.
 * - Vertikal ist, werden die Nachbarn Horizontal angepasst.
 *
 * Es wird ein Array mit mutierten Werten zurückgegeben.
 *
 * @param positions
 * @param changedPosition
 * @param referenceOrientation
 * @param referenceDirection
 */
export function alignLinePositionNeighbors(positions: Position[], changedPosition: IndexedPosition, referenceOrientation: LineOrientation, referenceDirection: LineDirection): IndexedPosition[] {
    const positionToAlign = findPositionByIndexInDirection(positions, changedPosition.id, referenceDirection);
    const alignedPositions: IndexedPosition[] = [];

    if (referenceOrientation.isHorizontal) {
        const aligned = alignPositionVerticalWith(positionToAlign, changedPosition);
        if (aligned !== positionToAlign) {
            alignedPositions.push(aligned);
        }
        return alignedPositions;
    }
    if (referenceOrientation.isVertical) {
        const aligned = alignPositionHorizontalWith(positionToAlign, changedPosition);
        if (aligned !== positionToAlign) {
            alignedPositions.push(aligned);
        }
        return alignedPositions;
    }
    return alignedPositions;
}

