import {
    equalPosition,
    meassurePositionsMinMaxValues,
    meassurePositionsOuterPoints,
    Position,
} from 'libs/models/position';
import {sortPositionsHorizontal, sortPositionsVertical} from 'libs/models/position/sortPosition';
import {Direction} from 'libs/models/types';
import {WallSegmentModel} from 'libs/view';


export type RoomOutlinePositions = [
    top: Position[],
    right: Position[],
    bottom: Position[],
    left: Position[],
];


export function meassureRoomOutlinePositions(positions: Position[]): RoomOutlinePositions {

    if (positions.length <= 0) {
        return [
            [],
            [],
            [],
            []
        ];
    }

    const corners = meassurePositionsOuterPoints(positions);

    const outerTop = [corners[0], corners[1]];
    const outerRight = [corners[1], corners[2]];
    const outerBottom = [corners[2], corners[3]];
    const outerLeft = [corners[3], corners[0]];


    // Abkürzung beim viereck...
    if (positions.length === 4) {
        return [
            sortPositionsHorizontal(outerTop),
            sortPositionsVertical(outerRight),
            sortPositionsHorizontal(outerBottom),
            sortPositionsVertical(outerLeft),
        ];
    }
    const bounds = meassurePositionsMinMaxValues(positions);

    const extraTop = positions
        .filter((pos) => !equalPosition(pos, outerTop[0]) && !equalPosition(pos, outerTop[1]))
        .filter((pos) => {
            return pos.y === bounds.minY;
        });

    const extraRight = positions
        .filter((pos) => !equalPosition(pos, outerRight[0]) && !equalPosition(pos, outerRight[1]))
        .filter((pos) => {
            return pos.x === bounds.maxX;
        });

    const extraBottom = positions
        .filter((pos) => !equalPosition(pos, outerBottom[0]) && !equalPosition(pos, outerBottom[1]))
        .filter((pos) => {
            return pos.y === bounds.maxY;
        });

    const extraLeft = positions
        .filter((pos) => !equalPosition(pos, outerLeft[0]) && !equalPosition(pos, outerLeft[1]))
        .filter((pos) => {
            return pos.x === bounds.minX;
        });

    return [
        sortPositionsHorizontal([...extraTop, ...outerTop]),
        sortPositionsVertical([...extraRight, ...outerRight]),
        sortPositionsHorizontal([...extraBottom, ...outerBottom]),
        sortPositionsVertical([...extraLeft, ...outerLeft]),
    ];
}

export type RoomWallsForOutlines = {
    top: WallSegmentModel[],
    right: WallSegmentModel[],
    bottom: WallSegmentModel[],
    left: WallSegmentModel[],
};

export function meassureRoomWallsForOutlines(wallSegments: WallSegmentModel[], outlines: Direction[]): RoomWallsForOutlines {
    const result: RoomWallsForOutlines = {top: [], right: [], bottom: [], left: []};

    wallSegments.forEach((wall) => {
        if(outlines[wall.index]) {
            result[outlines[wall.index]].push(wall);
        }
    })

    return result;
}

