import {Html, Line} from '@react-three/drei';
import {htmlDistanceFactor} from 'app/config/3DParams';
import {IndexedLine, lineCenter} from 'libs/models/line';
import {positionThreeFromPositionCM} from 'libs/view';
import React, {useEffect, useState} from 'react';
import {trackLineMenuStyles} from 'components/scene/track/edit/styles/styles';
import {tw} from 'twind/css';

type TrackLineProps = {
    line: IndexedLine;
    removeConnection: (fromId: number, toId: number) => void
}

export const TrackLine = ({line, removeConnection}: TrackLineProps): JSX.Element => {
    const points = [
        positionThreeFromPositionCM(line.from),
        positionThreeFromPositionCM(line.to),
    ];
    const centerPosition = positionThreeFromPositionCM(lineCenter(line))
    const lineId = line.from.id + '-' + line.to.id
    const [isMouseOver, setMouse] = useState<boolean>(false);
    const [lineColorDelete, setLineColorDelete] = useState<string>('#723782');

    useEffect(() => {
            document.body.style.cursor = isMouseOver ? 'pointer' : 'auto'
            isMouseOver ? setLineColorDelete('#ff0000') : setLineColorDelete('#723782')
    }, [isMouseOver])

    const deleteLine = () => removeConnection(line.from.id, line.to.id)

    // noinspection RequiredAttributes
    return (
        <group key={lineId}>
            <Html position={centerPosition} distanceFactor={htmlDistanceFactor} center className={tw(trackLineMenuStyles)}>
                <div className={'icon-container'}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                         x="0px" y="0px"
                         viewBox="0 0 25.9 22.5"
                         width={22}
                         height={22}
                    >
                        <polygon points="6,6 6,4 19.5,16.5 19.5,18.5"/>
                        <polygon points="19.5,6 19.5,4 6,16.5 6,18.5"/>
                    </svg>
                </div>
            </Html>
            <Line color={lineColorDelete} points={points}/>
            <mesh
                position={centerPosition}
                onClick={deleteLine}
                onPointerEnter={() => setMouse(true)}
                onPointerLeave={() => setMouse(false)}
            >
                <boxBufferGeometry args={[0.2, 0.2, 0.2]}/>
                <meshBasicMaterial transparent opacity={0} />
            </mesh>
        </group>
    )
}
