import {publicPath} from 'app/config/gltf';
import {useAppDispatch} from 'app/store/hooks';
import {floorCoveringStyles} from 'components/panel/3d/styles/styles';
import {setFloorTexture} from 'state/scene/sceneReducer';
import {tw} from 'twind/css';

export const FloorCovering = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const setFloorCovering = (textureName: string) => {
        dispatch(setFloorTexture({floorTexture: textureName}))
    }

    return (
        <>
            <div className={tw(floorCoveringStyles)}>
                <figure>
                  <img src={publicPath('/texture/wood.jpg')} onClick={() => setFloorCovering('wood.jpg')} alt={'Holzboden'} />
                </figure>
              <figure>
                <img src={publicPath('/texture/wood_two.jpg')} onClick={() => setFloorCovering('wood_two.jpg')} alt={'Holzboden 2'} />
              </figure>
                <figure>
                    <img src={publicPath('/texture/ahorn.jpg')} onClick={() => setFloorCovering('ahorn.jpg')} alt={'Ahorn'} />
                </figure>
                <figure>
                    <img src={publicPath('/texture/parquet.jpg')} onClick={() => setFloorCovering('parquet.jpg')} alt={'Parkett'} />
                </figure>
                <figure>
                    <img src={publicPath('/texture/concrete.jpg')} onClick={() => setFloorCovering('concrete.jpg')} alt={'Beton'} />
                </figure>
              <figure>
                <img src={publicPath('/texture/concrete2.jpg')} onClick={() => setFloorCovering('concrete2.jpg')} alt={'Beton 2'} />
              </figure>
              <figure>
                <img src={publicPath('/texture/tile.jpg')} onClick={() => setFloorCovering('tile.jpg')} alt={'Bodenfliesen'} />
              </figure>
                <figure>
                    <img src={publicPath('/texture/tile_two.jpg')} onClick={() => setFloorCovering('tile_two.jpg')} alt={'Bodenfliesen 2'} />
                </figure>
            </div>
        </>
    )
}
