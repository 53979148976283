import {css} from 'twind/css';

export const selectTypePanelStyles = css({
    '@apply': 'lg:relative',
    '.title': {
        '@apply': 'relative bg-black text-white font-bold px-4 py-2 tracking-wide'
    },
    '.selection': {
        '@apply': 'grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid xl:grid-cols-2 gap-4 p-4',
    },
    '.selection button': {
        '@apply': 'lg:mb-0 xl:mb-0',
    }
});

export const SelectTypeItemStyles = css({
    '@apply': 'bg-white col-span-1 shadow-sm rounded py-2 px-6 text-black flex items-center justify-center flex-col font-bold transition ease-in-out duration-200 outline-none',
    'svg': {
        'width': '76px',
        'height': '76px',
    },
    'span': {
        '@apply': 'tracking-wide mt-2',
    },

    '&:hover': {
        '@apply': 'xl:bg-primary xl:text-white',
        '@screen xl': {
            'svg g path': {
                'stroke': '#fff',
            },
        },
    },
    '&:not(.active):hover': {
        '@apply': 'transform shadow-2xl',
    },
    '&:focus': {
        '@apply': 'outline-none'
    },
    '&.active': {
        '@apply': 'text-white bg-primary shadow-lg outline-none',
        '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
        'svg g path': {
            'stroke': '#fff',
        },
    },
});
