import {useContextBridge} from '@react-three/drei';
import {Canvas} from '@react-three/fiber';
import {useAppSelector} from 'app/store/hooks';
import {OrderCanvasScene} from 'components/scene/order/canvas/CanvasScene';
import {OrderItems} from 'components/scene/order/canvas/OrderItems';
import React, {Suspense} from 'react';
import {ReactReduxContext} from 'react-redux';
import {selectIsEditModeOrder} from 'state/view/viewSelectors';

export const OrderCanvas = (): JSX.Element => {
    const ContextBridge = useContextBridge(ReactReduxContext);
    const isOrderMode = useAppSelector(selectIsEditModeOrder);
    return (
        <>
            <Canvas
                id="orderCanvas"
                style={{
                    touchAction: 'none',
                    visibility: 'hidden',
                    display: isOrderMode ? 'block' : 'none',
                }}
                frameloop="demand"
                gl={{preserveDrawingBuffer: true}}
            >
                <ContextBridge>
                    <Suspense fallback={null}>
                        <OrderCanvasScene isOrderMode={isOrderMode}/>
                    </Suspense>
                </ContextBridge>
            </Canvas>
            <OrderItems />
        </>
    );
};
