import {Direction} from 'libs/models/types';
import {IndexedLine, Line, lineCenter, lineDirection, lineIsHorizontal, lineIsVertical} from '../line';
import {equalPosition, IndexedPosition, Position} from '../position';


export interface DistanceLine extends Line<Position> {
    id: number;
    isHorizontal: boolean;
    length: number;
}

export type AxesDistanceLines = {
    left: DistanceLine;
    right: DistanceLine;
    top: DistanceLine;
    bottom: DistanceLine;
}

const initDistanceLine: DistanceLine = {
    id: -1,
    from: {x: 0, y: 0},
    to: {x: 0, y: 0},
    isHorizontal: false,
    length: 0,
}

export interface DistanceLineWithDirection extends DistanceLine {
    direction: Direction;
}

export const axesDistanceLinesAsArrayWithDirection = (axesDistanceLines: AxesDistanceLines): DistanceLineWithDirection[] => {
    return [
        {...axesDistanceLines.top, direction: 'top'},
        {...axesDistanceLines.right, direction: 'right'},
        {...axesDistanceLines.bottom, direction: 'bottom'},
        {...axesDistanceLines.left, direction: 'left'},
    ]
}

export const calculateAxesDistanceAsLines = (walls: IndexedLine[], point: Position): AxesDistanceLines => {
    const axesDistanceLines: AxesDistanceLines = {
        left: initDistanceLine,
        right: initDistanceLine,
        top: initDistanceLine,
        bottom: initDistanceLine,
    };
    const newLines: DistanceLine[] = [];

    walls.forEach((wall, index) => {
        const distanceLine = distanceLineFromPointDistanceToLine(wall, point, index);
        if (distanceLine.length > 0) {
            newLines.push(distanceLine)
        }
    })

    const verticalLines = newLines.filter(line => !line.isHorizontal)
    const horizontalLines = newLines.filter(line => line.isHorizontal)

    const filteredLines: DistanceLine[] = []

    if (verticalLines.length === 2) {
        filteredLines.push(...verticalLines)
    }
    if (horizontalLines.length === 2) {
        filteredLines.push(...horizontalLines)
    }

    if (verticalLines.length > 2) {
        const smallerY = verticalLines.filter(hline => hline.to.y < point.y);
        if (smallerY.length === 1) {
            filteredLines.push(...smallerY)
        }
        if (smallerY.length > 1) {
            const nearestSmallestY = smallerY.reduce((previousValue, currentValue) => currentValue.to.y > previousValue.to.y ? currentValue : previousValue);
            filteredLines.push(nearestSmallestY)
        }

        const biggerY = verticalLines.filter(hline => hline.to.y > point.y);
        if (biggerY.length === 1) {
            filteredLines.push(...biggerY)
        }
        if (biggerY.length > 1) {
            const nearestBiggerY = biggerY.reduce((previousValue, currentValue) => currentValue.to.y < previousValue.to.y ? currentValue : previousValue);
            filteredLines.push(nearestBiggerY)
        }
    }

    if (horizontalLines.length > 2) {
        const smallerX = horizontalLines.filter(hline => hline.to.x < point.x);
        if (smallerX.length === 1) {
            filteredLines.push(...smallerX)
        }
        if (smallerX.length > 1) {
            const nearestSmallestX = smallerX.reduce((previousValue, currentValue) => currentValue.to.x > previousValue.to.x ? currentValue : previousValue);
            filteredLines.push(nearestSmallestX)
        }

        const biggerX = horizontalLines.filter(hline => hline.to.x > point.x);
        if (biggerX.length === 1) {
            filteredLines.push(...biggerX)
        }
        if (biggerX.length > 1) {
            const nearestBiggestX = biggerX.reduce((previousValue, currentValue) => currentValue.to.x < previousValue.to.x ? currentValue : previousValue);
            filteredLines.push(nearestBiggestX)
        }
    }

    filteredLines.forEach((line) => {
        if (line.isHorizontal) {
            if (line.to.x < point.x) {
                axesDistanceLines.left = line
            }
            if (line.to.x > point.x) {
                axesDistanceLines.right = line
            }
        }

        if (!line.isHorizontal) {
            if (line.to.y < point.y) {
                axesDistanceLines.top = line
            }
            if (line.to.y > point.y) {
                axesDistanceLines.bottom = line
            }
        }
    })

    return axesDistanceLines;
}

export const distanceLineFromPointDistanceToLine = (line: Line<IndexedPosition>, point: Position, index: number): DistanceLine => {
    const id = index;
    const center = lineCenter(line);
    const distanceToLine = pointDistanceToLine(line, point);

    if (lineIsHorizontal(line)) {
        return {
            id: id,
            isHorizontal: false,
            from: {x: point.x, y: point.y},
            to: {x: point.x, y: center.y},
            length: distanceToLine
        }
    }
    if (lineIsVertical(line)) {
        return {
            id: id,
            isHorizontal: true,
            from: {x: point.x, y: point.y},
            to: {x: center.x, y: point.y},
            length: distanceToLine
        }
    }

    return initDistanceLine;
}

/**
 * Return the distance between a point and a line
 * @param line Line<Position>
 * @param point Position
 */
export const pointDistanceToLine = (line: Line<Position>, point: Position): number => {
    if (lineIsHorizontal(line)) {
        if (lineDirection(line) === 1) {
            if (line.from.x <= point.x && line.to.x >= point.x) {
                return pointDistanceToHorizontalLine(line, point)
            }
        }
        if (lineDirection(line) === -1) {
            if (line.to.x <= point.x && line.from.x >= point.x) {
                return pointDistanceToHorizontalLine(line, point)
            }
        }
    }
    if (lineIsVertical(line)) {
        if (lineDirection(line) === 1) {
            if (line.from.y <= point.y && line.to.y >= point.y) {
                return pointDistanceToVerticalLine(line, point)
            }
        }
        if (lineDirection(line) === -1) {
            if (line.to.y <= point.y && line.from.y >= point.y) {
                return pointDistanceToVerticalLine(line, point)
            }
        }
    }
    return 0;
}

/**
 * Return the distance between a point and a Horizontal line
 * @param line Line<Position>
 * @param point Position
 */
export const pointDistanceToHorizontalLine = (line: Line<Position>, point: Position): number => {
    if (lineIsHorizontal(line)) {
        const center = lineCenter(line);

        if (center.y > point.y) {
            return Math.abs(center.y - point.y);
        }
        if (center.y < point.y) {
            return Math.abs(point.y - center.y);
        }
    }

    return 0;
}

/**
 * Return the distance between a point and a Vertical line
 * @param line Line<Position>
 * @param point Position
 */
export const pointDistanceToVerticalLine = (line: Line<Position>, point: Position): number => {
    if (lineIsVertical(line)) {
        const center = lineCenter(line);

        if (center.x > point.x) {
            return Math.abs(center.x - point.x);
        }
        if (center.x < point.x) {
            return Math.abs(point.x - center.x);
        }
    }

    return 0;
}

/**
 * Returns true if the point lies inside the list of lines
 * @param lines Line<Position>[]
 * @param point Position
 */
export const isPointInside = (lines: Line<Position>[], point: Position): boolean => {
    let count = 0;

    if (isClosedObject(lines)) {
        const infinitLine: Line<Position> = {
            from: point,
            to: {x: 10000, y: point.y - 1}
        }

        lines.forEach((line) => {
            if (doIntersect(line, infinitLine)) {

                if (pointsOrientation(line.from, infinitLine.from, line.to) === 0) {
                    return pointOnSegment(line.from, infinitLine.from, line.to)
                }

                count = count + 1;
            }
        })
    }

    return (count % 2 === 1)
}

/**
 * The function that returns true if
 * line segment 'p1q1' and 'p2q2' intersect.
 * @param corner
 * @param infinitLine
 */
export const doIntersect = (corner: Line<Position>, infinitLine: Line<Position>): boolean => {
    const p1: Position = corner.from
    const q1: Position = corner.to

    const p2: Position = infinitLine.from
    const q2: Position = infinitLine.to

    const o1 = pointsOrientation(p1, q1, p2);
    const o2 = pointsOrientation(p1, q1, q2);
    const o3 = pointsOrientation(p2, q2, p1);
    const o4 = pointsOrientation(p2, q2, q1);

    if (o1 !== o2 && o3 !== o4) {
        return true;
    }

    if (o1 === 0 && pointOnSegment(p1, p2, q1)) {
        return true;
    }

    if (o2 === 0 && pointOnSegment(p1, q2, q1)) {
        return true;
    }

    if (o3 === 0 && pointOnSegment(p2, p1, q2)) {
        return true;
    }

    return o4 === 0 && pointOnSegment(p2, q1, q2);


}

/**
 * Given three colinear points from, position, to,
 * the function checks if point position lies
 * on line segment 'from&to'
 * @param from<Position> Start of the line
 * @param position<Position> Point on line 'pr'
 * @param to<Position> End of the line
 */
export const pointOnSegment = (from: Position, position: Position, to: Position): boolean => {
    return position.x <= Math.max(from.x, to.x) &&
        position.x >= Math.min(from.x, to.x) &&
        position.y <= Math.max(from.y, to.y) &&
        position.y >= Math.min(from.y, to.y);
}

/**
 * To find orientation of ordered triplet (p, q, r).
 * The function returns following values
 * 0 --> p, q and r are colinear
 * 1 --> Clockwise
 * 2 --> Counterclockwise
 * @param p<Position> Start of the line
 * @param q<Position> Point on line 'pr'
 * @param r<Position> End of the line
 */
export const pointsOrientation = (p: Position, q: Position, r: Position): number => {
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);

    if (val === 0) {
        return 0;
    }
    return (val > 0) ? 1 : 2;
}

/**
 * Is the list of lines a closed object?
 * @param lines
 * return true if it is a closed object, otherwise false
 */
export const isClosedObject = (lines: Line<Position>[]): boolean => {
    const n: number = lines.length;

    if (n < 3) {
        return false;
    }

    const maxIndex: number = n - 1;
    let isClosed = true;

    /**
     * Each end point of a line must be identical to the start point of the next line.
     */
    lines.forEach((line, index) => {
        if (index < maxIndex) {
            if (!equalPosition(line.to, lines[index + 1].from)) {
                isClosed = false;
            }
        }

        /**
         * Special case, the last end point must be identical to the start point of the first line in the list.
         */
        if (index === maxIndex) {
            if (!equalPosition(line.to, lines[0].from)) {
                isClosed = false;
            }
        }
    })

    return isClosed;
}
