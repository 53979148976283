import {spotlightDistanceFromConnector} from 'app/config/light';
import {Knot} from 'libs/models/knot';
import {Spotlight} from 'libs/models/light/light';
import {validatePositionAIsInTheOffsetOfPositionB} from 'libs/models/position';
import {pointCMFromPositionThree, PositionThree} from 'libs/view';

export function isSpotlightInOffsetOfAKnot(knots: Knot[], spotlightPosition: PositionThree): boolean {
    return knots.some((knot) => {
        return validatePositionAIsInTheOffsetOfPositionB(knot.position, pointCMFromPositionThree(spotlightPosition), spotlightDistanceFromConnector)
    })
}

export function isSpotlightIntersectingWithAnother(spotlightPosition: PositionThree, spotlights: Spotlight[], spotlight: Spotlight): boolean {
    return spotlights.some((spot) => {
        if (spot.id !== spotlight.id) {
            return validatePositionAIsInTheOffsetOfPositionB(pointCMFromPositionThree(spotlightPosition), pointCMFromPositionThree(spot.position), spotlightDistanceFromConnector);
        }

        return false;
    })
}
