import {Button} from 'components/common/buttons/Button';
import React, {PropsWithChildren} from 'react';
import {resetAction} from 'components/common/actions/styles/styles';
import {tw} from 'twind/css';



export type ResetActionProps = PropsWithChildren<{
    onConfirm: () => void;
    onCancel: () => void;
    confirmText?: string;
    cancelText?: string;
}>

export const ResetAction = ({
                                onConfirm,
                                onCancel,
                                confirmText,
                                cancelText,
                                children
                            }: ResetActionProps): JSX.Element => {
    return (
        <div className={tw(resetAction)}>
            <div className={'alert-icon'}>
                <svg viewBox="0 0 20 20"
                     width={30}
                     height={30}
                >
                    <path
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    >
                    </path>
                </svg>
            </div>
            <div className={'warning-message'}>
                <div>
                    {children}
                </div>
                <div className={'button-group'}>
                    <Button
                        variant={'secondary'}
                        onClick={onConfirm}
                    >
                        {confirmText ?? 'Konfiguration löschen'}
                    </Button>
                    <Button onClick={onCancel}>
                        {cancelText ?? 'Abbrechen'}
                    </Button>
                </div>
            </div>
        </div>
    );
};
