import {
    defaultEntityData,
    defaultEntityResource,
    defaultResourceInfoData,
    defaultRoomEntityData,
    EntityResource
} from 'libs/client/clientApi';
import {Position} from 'libs/models/position';
import {Direction} from 'libs/models/types';

/**
 * Die Vorkonfigurierten Räume
 */
export const PresetRoomTypes: EntityResource[] = [
    createRoomPreset({
        title: 'Viereck',
        presetId: 'vorlage-raum-viereck',
        corners: [
            {x: -200, y: -200}, // 0, top-left
            {x: 200, y: -200}, // 1, top-right
            {x: 200, y: 200}, // 2, bottom-right
            {x: -200, y: 200}, // 3, bottom-left
        ],
        outlines:  [
            'top',
            'right',
            'bottom',
            'left'
        ]
    }),
    createRoomPreset({
        title: 'L-Form',
        presetId: 'vorlage-raum-l',
        corners: [
            {x: -200, y: -200}, // 0, top-left
            {x: 0, y: -200},    // 1
            {x: 0, y: 0},       // 2
            {x: 200, y: 0},     // 3
            {x: 200, y: 200},   // 4, bottom-right
            {x: -200, y: 200},  // 5, bottom-left
        ],
        outlines:  [
            'top',
            'right',
            'top',
            'right',
            'bottom',
            'left'
        ]
    }),
    createRoomPreset({
        presetId: 'vorlage-raum-t',
        title: 'T-Form',
        corners: [
            {x: -200, y: -200}, // 0, top-left
            {x: 200, y: -200},
            {x: 200, y: 0},
            {x: 100, y: 0},
            {x: 100, y: 200},
            {x: -100, y: 200},
            {x: -100, y: 0},
            {x: -200, y: 0},
        ],
        outlines:  [
            'top',
            'right',
            'bottom',
            'right',
            'bottom',
            'left',
            'bottom',
            'left'
        ]
    }),
    createRoomPreset({
        title: 'U-Form',
        presetId: 'vorlage-raum-u',
        corners: [
            {x: -200, y: -200}, // 0, top-left
            {x: -100, y: -200}, // 1
            {x: -100, y: 0},    // 2
            {x: 100, y: 0},     // 3
            {x: 100, y: -200},  // 4
            {x: 200, y: -200},  // 5, top-right
            {x: 200, y: 200},   // 6, bottom-right
            {x: -200, y: 200},  // 7, bottom-left
        ],
        outlines:  [
            'top',
            'left',
            'top',
            'right',
            'top',
            'right',
            'bottom',
            'left',
        ]
    }),
];

/**
 * Hilfsfunktion zum erstellen einer vorlage
 */
function createRoomPreset(
    {
        presetId,
        title,
        corners,
        outlines
    }: { presetId: string, title: string, corners: Position[], outlines: Direction[] }): EntityResource {
    return {
        ...defaultEntityResource,
        entityId: presetId,
        info: {
            ...defaultResourceInfoData,
            title,

        },
        data: {
            ...defaultEntityData,
            outlines,
            room: {
                ...defaultRoomEntityData,
                corners,
            }
        }
    };
}
