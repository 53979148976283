import {useAppSelector} from 'app/store/hooks';
import {styles} from 'components/common/panel/styles/PanelEditContainerStyles';
import {LightToolTip} from 'components/panel/light/LightToolTip';
import {OrderToolTip} from 'components/panel/order/OrderToolTip';
import {OutletToolTip} from 'components/panel/outlet/OutletToolTip';
import {RoomToolTip} from 'components/panel/room/RoomToolTip';
import {TrackToolTip} from 'components/panel/track/TrackToolTip';
import {TypeToolTip} from 'components/panel/type/TypeToolTip';
import React, {PropsWithChildren} from 'react';
import {selectViewMode} from 'state/scene/sceneSelectors';
import {EditModeComponentMap, useEditModeComponent} from 'state/view/viewModels';
import {tw} from 'twind/css';

const toolTipComponentMap: EditModeComponentMap = {
    type: () => <TypeToolTip />,
    room: () => <RoomToolTip />,
    outlet: () => <OutletToolTip />,
    track: () => <TrackToolTip />,
    light: () => <LightToolTip />,
    order: () => <OrderToolTip />,
    error: () => null
};

export type PanelEditContainerProps = PropsWithChildren<{
    title: string;
}>

export const PanelEditContainer = ({title, children}: PanelEditContainerProps): JSX.Element => {
    const sceneViewMode = useAppSelector(selectViewMode);
    const currentToolTipComponent = useEditModeComponent(toolTipComponentMap);
    const toolTipComponents = currentToolTipComponent && sceneViewMode.isTwo ? currentToolTipComponent() : null;

    return (
        <div>
            <div className={tw(styles)}>
                <div className={'title'}>
                    <h2>{title}</h2>
                    {toolTipComponents}
                </div>
            </div>
            {children}
        </div>

    );
};
