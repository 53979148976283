import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {RawHtml} from 'components/common/elements/RawHtml';
import {getTitleBySystemColors} from 'libs/models/order';
import {SpotProduct} from 'libs/shop/shopClient';
import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';
import {clearActiveMainProductdId, setActiveVariantProductId} from 'state/shop/shopReducer';
import {
    selectShopActiveMainProduct,
    selectShopActiveVariantProduct,
    selectShopProductVariants
} from 'state/shop/shopSelectors';
import {tw} from 'twind/css';
import {spotVariantProductSelector} from './styles/styles';
import {SpotAmountDisplay} from './SpotAmountDisplay';
import {SpotAmountEditor} from './SpotAmountEditor';

export const SpotVariantProductSelector = (): JSX.Element | null => {
    const activeMainProduct = useAppSelector(selectShopActiveMainProduct);
    const possibleVariants = useAppSelector(selectShopProductVariants);
    const activeVariantProduct = useAppSelector(selectShopActiveVariantProduct);

    if (activeMainProduct === null) {
        return null;
    }
    if (activeVariantProduct !== null) {
        return null;
    }
    if (possibleVariants.length === 0) {
        return <div>Keine Varianten...</div>
    }
    return <VariantModal/>
}


const VariantModal = () => {
    const dispatch = useAppDispatch();
    const possibleVariants = useAppSelector(selectShopProductVariants);
    const [selectedVariant, setSelectedVariant] = useState<SpotProduct | null>(null);

    useEffect(() => {
        if (!selectedVariant && possibleVariants.length > 0) {
            setSelectedVariant(possibleVariants[0]);
        }
    }, [selectedVariant, setSelectedVariant, possibleVariants]);

    if (possibleVariants.length === 0) {
        return null;
    }
    if (selectedVariant === null) {
        return null;
    }

    const handleVariantSelection = (product: SpotProduct) => {
        setSelectedVariant(product);

    }

    const handleConfirm = () => {
        if (selectedVariant !== null) {
            dispatch(setActiveVariantProductId(selectedVariant.id));
        }
    }
    const handleCancel = () => {
        dispatch(clearActiveMainProductdId())
    }

    const productVariants = possibleVariants.map((p) => {

        const additionalColor = p.productAdditional ?? '';
        const systemColor = p.productSystem ?? '';

        const variantsClass = systemColor + ' ' + additionalColor;

        const isSelected = p.productNumber === selectedVariant.productNumber;

        return (
            <li key={p.id} className={isSelected? 'active' : ''}>
                <svg xmlns='http://www.w3.org/2000/svg'
                     viewBox='0 0 24 24'
                     className={isSelected? 'checkmark-icon' : tw('hidden')}>
                    <path d="M0 0h24v24H0z" className="fill-none"/>
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                </svg>
                <button title={getTitleBySystemColors(p.productSystem)} className={'btn-select-color ' + variantsClass}
                        onClick={() => handleVariantSelection(p)}>
                </button>
            </li>
        )
    })

    const productVariantsName = possibleVariants.map((p) => {
        const isSelected = p.productNumber === selectedVariant.productNumber;
        if(isSelected && p.productSystem) {
            return getTitleBySystemColors(p.productSystem);
        } else {
            return '';
        }
    })

    return (
        <ReactModal
            className={tw(spotVariantProductSelector)}
            isOpen={true}
            ariaHideApp={false}
            style={{
                overlay: {
                    top: '0',
                    left: '0',
                },
            }}
        >
            <div className={'variant-modal-inner'}>
                <button className={'variant-modal-close'} onClick={handleCancel}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         height={24}
                         width={24}
                         viewBox="0 0 24 24"
                         fill="#000000"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path
                            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                        />
                    </svg>
                </button>
                <div className={'variant-modal-head'}>
                    <h2>{selectedVariant.name} | {productVariantsName}</h2>
                </div>

                <div className={'variant-modal-content'}>
                    <figure className={'variant-img'}>
                        {selectedVariant.cover &&
                        <img width={600} src={selectedVariant.cover.url} alt={selectedVariant.name ?? ''}/>
                        }
                    </figure>
                    <div className={'variant-info'}>
                        <SpotAmountDisplay />
                        <SpotAmountEditor />
                        <div className={'variant-selection'}>
                            <h6>Varianten</h6>
                            <ul>
                                {productVariants}
                            </ul>
                            <h6>Name</h6>
                            {productVariantsName}
                        </div>
                        <div className={'variant-desc'}>
                            <RawHtml content={selectedVariant.description}/>
                        </div>
                    </div>
                </div>

                <div className={'variant-modal-footer'}>
                    <button className={'btn btn-close'} onClick={handleCancel}>Zurück</button>
                    <button className={'btn btn-open'} onClick={handleConfirm}>Anwenden</button>
                </div>
            </div>
        </ReactModal>
    )
}
