import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store/store';
import {EntityResource} from 'libs/client/clientApi';
import {PresetState} from 'state/preset/presetReducer';


export const selectPresetState = (state: RootState): PresetState => state.preset;


export const selectPresetEntities = createSelector(
    [selectPresetState],
    (state) => state.entities
);

export const selectCurrentPresetId = createSelector(
    [selectPresetState],
    (state) => state.currentPresetId
);

/**
 * Die erste Vorlage aus der Liste.
 */
export const selectFirstPresetEntity = createSelector(
    [selectPresetEntities],
    (presets): EntityResource | null => {
        if (presets.length > 0) {
            return presets[0];
        }
        return null;
    }
);

/**
 * Die aktuelle Vorlage oder nix.
 */
export const selectCurrentPresetEntity = createSelector(
    [selectCurrentPresetId, selectPresetEntities],
    (presetId, entities): EntityResource | null => {
        if (!presetId) {
            return null;
        }
        return entities.find((entity) => entity.entityId === presetId) || null;
    }
);

/**
 * Die aktuelle Vorlage, oder die erste aus der Liste der Vorlagen oder nix.
 */
export const selectCurrentOrFirstPresetEntity = createSelector(
    [selectCurrentPresetEntity, selectFirstPresetEntity],
    (current, first): EntityResource | null => current || first
);
