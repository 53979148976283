import {doubleSpotlightDistanceFromConnector, maxDistanceFromWallToSetSpotDirectionDown} from 'app/config/light';
import {SpotMode} from 'libs/models/3d/spotModels';
import {
    Line,
    lineDirection,
    lineLength,
    lineOrientation,
    Lines,
    sumLinesLengthWithoutCornerOffset
} from 'libs/models/line';
import {sortLinesFromLongToShort} from 'libs/models/line/sortLine';
import {Position} from 'libs/models/position';
import {
    axesDistanceLinesAsArrayWithDirection,
    calculateAxesDistanceAsLines,
    DistanceLineWithDirection,
    Room
} from 'libs/models/room';
import {NumberOfSpotlightsValidation} from 'libs/notification/lightNotifications';
import {PositionThree, positionThreeFromPositionCM} from 'libs/view';

export type Spotlight = {
    id: number;
    point: Position;
    position: PositionThree;
    direction: SpotMode;
    isHorizontal: boolean;
}

/**
 * Erstellt initial eine Liste mit Strahlern und deren ID, Position und Ausrichtung.
 * @param lines {Lines}
 * @param spotlightAmount {number}
 * @param room {Room}
 */
export function getInitialSpotlights<T extends Position>(lines: Lines<T>, spotlightAmount: number, room: Room): Spotlight[] {
    const spotlightPositions: SpotlightPositions[] = getSpotlightPositions(lines, spotlightAmount);

    return spotlightPositions.map((spotPos, index) => {
        const axesDistanceLines = calculateAxesDistanceAsLines(room.walls, spotPos.position);
        const shortestDistanceLine = axesDistanceLinesAsArrayWithDirection(axesDistanceLines).reduce((previousValue, currentValue): DistanceLineWithDirection => {
            return previousValue.length < currentValue.length ? previousValue : currentValue;
        });

        return {
            id: index,
            point: spotPos.position,
            position: positionThreeFromPositionCM(spotPos.position),
            direction: shortestDistanceLine.length < maxDistanceFromWallToSetSpotDirectionDown ? shortestDistanceLine.direction : 'down',
            isHorizontal: spotPos.isHorizontal
        };
    });
}

export function getValidatedNumberOfSpotlightsOrDefault(spotlightInput: number, maxNumberOfSpotlights: number): NumberOfSpotlightsValidation {
    if (spotlightInput > maxNumberOfSpotlights) {
        return {isValid: true, numberOfSpotlights: maxNumberOfSpotlights, type: 'invalid-spotlight-amount-max'};
    }

    if (spotlightInput < 0) {
        return {isValid: true, numberOfSpotlights: 0, type: 'invalid-spotlight-amount-min'};
    }

    return {isValid: true, numberOfSpotlights: spotlightInput, type: null};
}

export function getMaxSpotlightsPerTrackLine<T extends Position>(lines: Lines<T>, distanceBetweenSpotlights: number): number[] {

    return lines.map(trackLine => Math.ceil((lineLength(trackLine) - doubleSpotlightDistanceFromConnector) / distanceBetweenSpotlights));
}

/**
 * Erstellt eine Liste mit allen Positionen der Strahler.
 * @param lines {Lines}
 * @param spotlightAmount {number}
 */
export function getSpotlightPositions<T extends Position>(lines: Lines<T>, spotlightAmount: number): SpotlightPositions[] {
    const trackLineLengthSum = sumLinesLengthWithoutCornerOffset(lines);
    const distanceBetweenSpotlights = Math.round(trackLineLengthSum / spotlightAmount);
    const sortedTracksFromLongToShort = sortLinesFromLongToShort(lines);
    const maxSpotlightsPerTrackLine = getMaxSpotlightsPerTrackLine(lines, distanceBetweenSpotlights);
    const spotlightsPerTrackLine = getSpotlightsPerTrackLine(lines, spotlightAmount, maxSpotlightsPerTrackLine);

    return sortedTracksFromLongToShort.map((trackLine, index): SpotlightPositions[] => {
        return getSpotlightPointsOnLineEvenlyDistributed(trackLine, spotlightsPerTrackLine[index]);
    }).flat(1);
}

/**
 * Erstellt eine Liste mit der Anzahl von Strahlern pro Leiste.
 * @param lines {Lines}
 * @param spotlightAmount {number}
 * @param maxSpotlightsPerTrackLine {number[]}
 */
export function getSpotlightsPerTrackLine<T extends Position>(lines: Lines<T>, spotlightAmount: number, maxSpotlightsPerTrackLine: number[]): number[] {
    const spotlightsPerTrackLine: number[] = Array(lines.length).fill(0);
    let i = spotlightAmount;
    let index = 0;

    while (i > 0) {
        if (spotlightsPerTrackLine[index] < maxSpotlightsPerTrackLine[index]) {
            spotlightsPerTrackLine[index] = spotlightsPerTrackLine[index] + 1;
            i--;
        }
        index < (lines.length - 1) ? index++ : index = 0;
    }

    return spotlightsPerTrackLine;
}

type SpotlightPositions = {
    position: Position;
    isHorizontal: boolean;
}

/**
 * Gleichmaessige Verteilung von Positionen auf einer Linie anhand von einer Anzahl von Punkten.
 * @param line {Line}
 * @param pointsAmount {number}
 */
export function getSpotlightPointsOnLineEvenlyDistributed<T extends Position>(line: Line<T>, pointsAmount: number): SpotlightPositions[] {
    const points: SpotlightPositions[] = [];
    const distance = lineLength(line) / (pointsAmount * 2);
    const direction = lineDirection(line);
    const orientation = lineOrientation(line);

    let i = 1;
    while (i <= pointsAmount) {
        if (orientation.isHorizontal) {
            points.push({
                position: {
                    x: (line.from.x + Math.round((i + (i - 1)) * distance * direction)),
                    y: line.from.y
                },
                isHorizontal: true
            });
        }
        if (orientation.isVertical) {
            points.push({
                position: {
                    x: line.from.x,
                    y: (line.from.y + Math.round((i + (i - 1)) * distance * direction))
                },
                isHorizontal: false
            });
        }

        i++;
    }

    return points;
}
