import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {DraggableSpotlights} from 'components/scene/light/edit/DraggableSpotlight';
import {Spotlight} from 'libs/models/light/light';
import {IndexedLine} from 'libs/models/line';
import {Room} from 'libs/models/room';
import {PositionThree} from 'libs/view';
import React, {useEffect} from 'react';
import {useInitialSpotlights} from 'state/light/lightHooks';
import {setSpotlightPositionTo, setSpotlightsTo} from 'state/light/lightReducer';
import {selectHasSpotlightAmountChanged, selectSpotlights} from 'state/light/lightSelectors';
import {selectRoom} from 'state/room/roomSelectors';
import {selectTrackKnots, selectTrackLines} from 'state/track/trackSelectors';
import {Knot} from 'libs/models/knot';

export const LightEdit = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const {initialSpotlights} = useInitialSpotlights();
    const room: Room = useAppSelector(selectRoom);
    const trackLines: IndexedLine[] = useAppSelector(selectTrackLines);
    const spotlights: Spotlight[] = useAppSelector(selectSpotlights);
    const hasSpotlightAmountChanged: boolean = useAppSelector(selectHasSpotlightAmountChanged);
    const knots: Knot[] = useAppSelector(selectTrackKnots);

    useEffect(() => {
        if (hasSpotlightAmountChanged) {
            dispatch(setSpotlightsTo({spotlights: initialSpotlights()}))
        }
    }, [hasSpotlightAmountChanged, initialSpotlights, dispatch])

    const changeSpotlightPosition = (spotlight: Spotlight, position: PositionThree) => {
        dispatch(setSpotlightPositionTo({spotlight, position, knots, room}))
    }

    return (
        <DraggableSpotlights spotlights={spotlights} trackLines={trackLines} changePosition={(spot, pos) => changeSpotlightPosition(spot, pos)}/>
    );
}
