import {useAppSelector} from 'app/store/hooks';
import {RoomEditor} from 'components/scene/room/edit/RoomEditor';
import {RoomView, RoomWallOutlines} from 'components/scene/room/view/RoomView';
import React from 'react';
import {selectIsEditModeRoom} from 'state/view/viewSelectors';


export const RoomScene: React.FC = () => {
    const isEditMode = useAppSelector(selectIsEditModeRoom);

    if (isEditMode) {
        return (
            <>
                <RoomEditor />
                <RoomWallOutlines />
            </>
        );
    }
    return (
        <RoomView />
    );
};

