import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store/store';
import {ViewState} from 'state/view/viewReducer';


export const selectViewState = (state: RootState): ViewState => state.view;

export const selectEditMode = createSelector(
    [selectViewState],
    (state) => state.editMode
);

export const selectIsEditModeDisabled = createSelector(
    [selectEditMode],
    (state) => state === null
);

export const selectIsEditModeRoom = createSelector(
    [selectEditMode],
    (state) => state === 'room'
);

export const selectIsEditModeOutlet = createSelector(
    [selectEditMode],
    (state) => state === 'outlet'
);

export const selectIsEditModeTrack = createSelector(
    [selectEditMode],
    (state) => state === 'track'
);

export const selectIsEditModeLight = createSelector(
    [selectEditMode],
    (state) => state === 'light'
);

export const selectIsEditModeOrder = createSelector(
    [selectEditMode],
    (state) => state === 'order'
);

export const selectIsLoading = createSelector(
    [selectViewState],
    (state) => state.loading
);
