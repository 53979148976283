import {Position} from 'libs/models/position';
import {Line, lineIsHorizontal, lineIsVertical, lineLength, lineOrientation} from './line';


export function resizeLineTo<T extends Position>(line: Line<T>, amount: number): Line<T> {
    if (amount === 0) {
        return line;
    }
    const currentLength = lineLength(line);
    const newLength = Math.abs(amount);
    if (currentLength === newLength) {
        return line;
    }

    const orientation = lineOrientation(line);

    if (orientation.isHorizontal) {
        if (line.from.x <= line.to.x) {
            return {
                ...line,
                from: line.from,
                to: {
                    ...line.to,
                    x: (line.from.x + newLength),
                }
            }
        } else {
            return {
                ...line,
                to: line.to,
                from: {
                    ...line.from,
                    x: (line.to.x + newLength),
                }
            }
        }
    }
    if (orientation.isVertical) {
        if (line.from.y <= line.to.y) {
            return {
                ...line,
                from: line.from,
                to: {
                    ...line.to,
                    y: (line.from.y + newLength),
                }
            }
        }
        return {
            ...line,
            to: line.to,
            from: {
                ...line.from,
                y: (line.to.y + newLength),
            }
        }
    }

    return line;
}


/**
 * Erzeugt eine Kopie der Linie mit neuer Länge als offset.
 *
 * Das funktioniert nur mit horizontalen und vertikalen Linien.
 * Ansonsten wird die die Linie ohne Änderung zurückgegeben.
 *
 * Wenn die Linie positiv ist (links -> rechts || oben -> unten) wird {to} geändert.
 * Wenn die Linie negativ ist (rechts -> links || unten -> oben) wird {from} geändert.
 *
 * @param line Die Linie die verändert werden soll.
 * @param amount Die Änderung an der Länge.
 */
export function resizeLineBy<T extends Position>(line: Line<T>, amount: number): Line<T> {
    if (amount === 0) {
        return line;
    }
    const currentLength = lineLength(line);
    const offsetLength = (currentLength + amount);

    if (lineIsHorizontal(line)) {
        if (line.from.x <= line.to.x) {
            return {
                ...line,
                from: line.from,
                to: {
                    ...line.to,
                    x: line.from.x + offsetLength,
                }
            }
        }
        return {
            ...line,
            to: line.to,
            from: {
                ...line.from,
                x: line.to.x + (currentLength + amount),
            }
        }
    }
    if (lineIsVertical(line)) {
        if (line.from.y <= line.to.y) {
            return {
                ...line,
                from: line.from,
                to: {
                    ...line.to,
                    y: line.from.y + (currentLength + amount),
                }
            }
        }
        return {
            ...line,
            to: line.to,
            from: {
                ...line.from,
                y: line.to.y + (currentLength + amount),
            }
        }
    }

    return line;
}

