import {useAppSelector} from 'app/store/hooks';
import React from 'react';
import {selectOrderTracksList} from 'state/order/orderSelectors';
import {tw} from 'twind/css';

export const ItemTrackDetail = (): JSX.Element => {
    const orderListResults = useAppSelector(selectOrderTracksList);

  const titleStyle = {
    fontWeight: 700,
    paddingBottom: '.5rem',
  };

  const tableStyles = {
    width: '120mm',
    marginLeft: '25mm',
    marginBottom: '5mm',
    backgroundColor: '#fff'
  };

  const ThStyles = {
    fontWeight: 500,
    marginBottom: '1mm',
    paddingBottom: '0.5rem',
    width: '40mm',
  };

  const tBodyStyles = {
    width: '145mm',
  };

  const tHeadStyles = {
    borderBottom: '1px solid black',
  };

  const tdStyles = {
    borderRight: '1px solid rgb(100,100,100)',
    paddingBottom: '0.25rem',
    width: '40mm'
  }

  const tBodyItems = orderListResults.map((result, idx) => {
        return (
            <tr key={idx + '-tr-track-detail'}>
                <td style={tdStyles}>{result.originalLength} cm</td>
                <td style={tdStyles}>{result.forOrderTrack.map(ot => ot.key + ' (' + ot.length + ' cm), ')}</td>
                <td>{result.trackLength}cm</td>
            </tr>
        );
    });

    return (
        <div className={tw('w-full')}>

            <table style={tableStyles}>
                <thead style={tHeadStyles}>
                <tr>
                  <h3 style={titleStyle}>Schienen Detail</h3>
                </tr>
                <tr>
                    <th style={ThStyles}>Gesamtlänge</th>
                    <th style={ThStyles}>Verbindungen</th>
                    <th style={ThStyles}>Benötigte Leiste</th>
                </tr>
                </thead>
                <tbody style={tBodyStyles}>
                  {tBodyItems}
                </tbody>
            </table>
        </div>
    );
};


