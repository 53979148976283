import {NumberInputConfirm, NumberInputEventHandler} from 'components/common/forms/NumberInput';
import React, {useEffect, useState} from 'react';
import {PanelEditItem} from 'components/common/panel/PanelEditItem';
import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {selectLightErrorId, selectSpotlightAmount} from 'state/light/lightSelectors';
import {resetLightErrorId, setSpotlightAmountTo} from 'state/light/lightReducer';
import {selectMaxNumberOfSpotligths} from 'state/track/trackSelectors';


export const SpotAmountEditor = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const spotlightAmount: number = useAppSelector(selectSpotlightAmount);
    const maxSpotlights = useAppSelector(selectMaxNumberOfSpotligths);
    const errorId = useAppSelector(selectLightErrorId);

    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [value, setValue] = useState<number>(spotlightAmount)

    useEffect(() => {
        setValue(spotlightAmount);
    }, [spotlightAmount])

    useEffect(() => {
        if (errorId !== null) {
            setValue(spotlightAmount);
            dispatch(resetLightErrorId())
        }
    }, [errorId, spotlightAmount, setValue, dispatch]);

    const handleClickEvent = () => {
        setIsDisabled(true);
        dispatch(setSpotlightAmountTo({spotlightAmount: value, maxNumberOfSpotlights: maxSpotlights}))
    };

    const handleNumberChange: NumberInputEventHandler = ({value}) => {
        setIsDisabled(false);
        setValue(value);
    };


    return (
        <div>
            <PanelEditItem title={'Anzahl'} label={'Stk'} variant={'input'} isDisabled={isDisabled}
                           handleClickEvent={handleClickEvent}>
                <NumberInputConfirm
                    value={value}
                    onNumberChange={handleNumberChange}
                    confirmNumberChange={handleClickEvent}
                    min={0}
                    max={maxSpotlights}
                />
            </PanelEditItem>
        </div>
    );
}
