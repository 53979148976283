import {apply, Configuration, strict} from 'twind';


export const twindConfig: Configuration = {
    mode: strict,
    darkMode: false,
    theme: {
        extend: {
            screens: {
                'cmd': '',
            },
            colors: {
                primary: {
                    lightest: '#e2c9e8',
                    light: '#a050b5',
                    DEFAULT: '#723782',
                    dark: '#43204c',
                },
                black: {
                    lightest: '#575757',
                    light: '#414141',
                    DEFAULT: '#270A2F',
                },
            },
            fontFamily: {
                sans: 'Roboto, sans-serif'
            },
            gridTemplateRows: {
                page: 'auto 1fr'
            },
            width: {
                '40': '40px',
                '65': '65px',
            },
            maxWidth: {
                '310': '310px',
            },
            minWidth: {
                'unset': 'unset',
                '64': '64px',
                '65': '65px',
                '120': '120px',
                '190': '190px',
                '310': '310px'
            },
            height: {
                '40': '40px',
                '100': '100px',
            },
            minHeight: {
                'unset': 'unset',
                '64': '64px',
            },
            maxHeight: {
                'unset': 'unset',
                '64': '64px',
                '44': '44px',
                '200': '200px',
            },
            fill: {
                primary: '#723782',
                white: '#ffffff',
                none: 'none',
                red: '#f56565',
                green: '#15b881',
                gray: '#d1d5db',
            },
            zIndex: {
                '-1': '-1',
                '-10': '-10',
                '60':  '60',
                '70':  '70',
                '999': '999',
                '1000': '1000',
                '7777777': '7988888',
                '8888888': '8888888',
                '9999999' : '9999999'
            }
        },
    },
    variants: {
        fill: 'hover',
    },
    preflight: (preflight) => ({
        ...preflight,
        '@import': 'url(\'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap\')',
        body: apply`bg-gray-100`,
    })
};


