import {css} from 'twind/css';

export const cornerIconStyles = css({
    '@apply': 'pointer-events-none flex justify-center items-center bg-white rounded-full shadow-lg',
    'width': '40px',
    'height': '40px',
    'svg polygon': {
        '@apply': 'fill-primary'
    }
});
