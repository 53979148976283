import {useAppSelector} from 'app/store/hooks';
import {LightEdit} from 'components/scene/light/edit/LightEdit';
import {LightView} from 'components/scene/light/view/LightView';
import {selectIsEditModeLight} from 'state/view/viewSelectors';
import React from 'react';

export const LightScene = (): JSX.Element => {
    const isEditMode = useAppSelector(selectIsEditModeLight);

    if (isEditMode) {
        return (
            <LightEdit />
        );
    }
    return (
        <LightView />
    );
};
