import {css} from 'twind/css';

export const burgerMenuStyles = css({
    '@apply' : '',
    '.menu': {
        '@apply': 'fixed h-full md:mx-0 top-0 right-0 bg-white shadow-xl z-9999999 p-6 transform translate-x-full transition ease-in-out duration-200',
        'min-width': '250px',
        '@screen lg': {
            'min-width': '350px'
        }
    },
    '.menu.active': {
        '@apply': 'translate-x-0 transition ease-in-out duration-200',
    },
    '.menu-bg': {
        '@apply': 'absolute inset-0 bg-gray-600 bg-opacity-40 -z-10 opacity-0'
    },
    '.menu.active ~ .menu-bg': {
        '@apply': 'z-8888888 cursor-pointer opacity-100'
    },
    '.path':{
        '@apply': 'fill-none'
    },
    '.menu-open':{
        '@apply': 'cursor-pointer ml-4 md:ml-8 lg:ml-6 2xl:ml-16'
    },
    '.menu-title': {
        '@apply': 'flex justify-end'
    },
    '.menu-body': {
        '@apply': 'mt-4 pr-1'
    },
    '.menu-close':{
        '@apply': 'cursor-pointer m-0'
    },
    '.close-icon':{
        '@apply': 'fill-none'
    }
});