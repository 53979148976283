import {css} from 'twind/css';

export const styles = css({
    '.submit-group': {
      '@apply': 'relative flex justify-center items-center',
      'svg': {
        '@apply': 'pointer-events-none absolute fill-white',
        'path': {
          '@apply': 'pointer-events-none absolute',
          'stroke-width': '6px',
          'stroke': '#fff',
        },
      },
      '&.disabled': {
        'svg path': {
          'stroke': '#9c9c9c'
        },
      },
      '&:not(.disabled)': {
        '.submit': {
          '@apply': 'cursor-pointer',
        }
      }
    },
    '@apply': 'flex align-center m-4 py-2 px-3 rounded',
    '.item-title': {
        '@apply': 'text-white font-bold mr-4 flex-grow flex whitespace-nowrap',
    },
    '.form-field': {
        '@apply': 'flex justify-end relative'
    },
    '.form-field label': {
        '@apply': 'flex items-center text-black font-bold px-3 leading-normal',
    },
    '.form-field input': {
        '@apply': 'rounded-tr-none rounded-br-none',
    },
    '.submit-group-1': {
        '@apply': 'relative flex justify-center items-center',
        '&.disabled': {
            '.submit': {
                '@apply': 'px-4 py-0 min-w-unset bg-gray-300 pointer-events-none',
            },
        },
        '.submit': {
            '@apply': 'px-4 py-0 min-w-unset rounded-tl-none rounded-bl-none rounded-r  bg-green-500 cursor-pointer',
        }
    }
});

export const variantCss = {
    input: css({
        '@apply': 'bg-black-lightest flex',
        '.form-field label': {
            '@apply': 'bg-gray-200'
        },
        '.form-field input': {
            '@apply': 'flex items-center text-black font-bold pl-3 leading-normal rounded-l w-4/12 min-w-64'
        },
    }),
    limit: css({
        '@apply': 'bg-gray-200 text-black-lightest',
        '.item-title': {
            '@apply': 'text-black'
        },
        '.form-field label': {
            '@apply': 'bg-gray-400'
        },
        '.submit-group': {
            '@apply': 'hidden',
        },
        'p': {
            '@apply': 'font-bold'
        }
    })
}
