import {outletMinimumDistanceToWall} from 'app/config/outlet';
import {lineIsHorizontal, lineOrientation, Lines} from 'libs/models/line';
import {sortLinesFromShortToLong} from 'libs/models/line/sortLine';
import {IndexedPosition, Position} from 'libs/models/position';
import {AxesDistanceLines, Room} from 'libs/models/room';
import {getValidatedLengthOrDefault, isValidLength, MinMaxConfig} from 'libs/models/validation/validation';
import {NotificationProvider} from 'libs/notification/notificationProvider';
import {outletNotifications} from 'libs/notification/outletNotifications';

const addNotifyItem = NotificationProvider().addNotifyItem;

export function validateOutletPosition(room: Room, outletPosition: Position): boolean {
    const isPositionValid = room.walls.every(wall => {

        const orientation = lineOrientation(wall);

        if (orientation.isHorizontal) {
            if (wall.from.y < outletPosition.y + outletMinimumDistanceToWall) {
                return outletPosition.y >= wall.from.y + outletMinimumDistanceToWall;
            }
            return outletPosition.y <= wall.to.y - outletMinimumDistanceToWall;
        }

        if (orientation.isVertical) {
            if (wall.from.x < outletPosition.x + outletMinimumDistanceToWall) {
                return outletPosition.x >= wall.from.x + outletMinimumDistanceToWall;
            }
            return outletPosition.x <= wall.to.x - outletMinimumDistanceToWall;
        }

        return false;
    });

    if (!isPositionValid) {
        addNotifyItem(outletNotifications('invalid-position'));
    }

    return isPositionValid;
}

export function getValidatedOutletLengthOrDefault(outletAxesDistanceLines: AxesDistanceLines | null, length: number, isHorizontal: boolean): number {

    const MinMaxConfig: MinMaxConfig = {
        min: outletMinimumDistanceToWall,
        max: 0,
    };

    if (outletAxesDistanceLines) {
        if (isHorizontal) {
            MinMaxConfig.max = (outletAxesDistanceLines.left.length + outletAxesDistanceLines.right.length) - outletMinimumDistanceToWall;
        }

        if (!isHorizontal) {
            MinMaxConfig.max = (outletAxesDistanceLines.top.length + outletAxesDistanceLines.bottom.length) - outletMinimumDistanceToWall;
        }
    }

    if (isValidLength(MinMaxConfig, length)) {
        return length;
    }

    if (length < MinMaxConfig.min || length > MinMaxConfig.max) {
        addNotifyItem(outletNotifications('invalid-position'));
    }

    return getValidatedLengthOrDefault(MinMaxConfig, length);
}

export function calculateInitialOutletPosition<T extends IndexedPosition>(room: Lines<T>): Position {

    const horizontalLines = room.filter((wall) => {
        if (lineIsHorizontal(wall)) {
            return wall;
        }
        return false;
    });

    const sortedHorizontalLines = horizontalLines.sort((a, b) => {
        if (a.from.y < b.from.y) {
            return 1;
        }
        if (a.from.y > b.from.y) {
            return -1;
        }
        return 0;
    });

    const sortedHorizontalLinesByLength = sortLinesFromShortToLong([sortedHorizontalLines[0], sortedHorizontalLines[1]]);
    if (sortedHorizontalLinesByLength && sortedHorizontalLinesByLength[0]) {
        return {
            x: Math.floor(sortedHorizontalLinesByLength[0].to.x - ((Math.abs(sortedHorizontalLinesByLength[0].to.x) + Math.abs(sortedHorizontalLinesByLength[0].from.x)) / 2)),
            y: Math.floor(sortedHorizontalLines[0].from.y - (Math.abs(sortedHorizontalLines[1].from.y - Math.abs(sortedHorizontalLines[0].from.y)) / 2))
        };
    }

    return {x: 0, y: 0};
}
