import {Tooltip} from 'components/common/tooltip/Tooltip';

import React from 'react';


export const OrderToolTip = (): JSX.Element => {
    return (
        <Tooltip title={'Hinweis'} setDisbandTime={5000}>
            Lichtschienen werden an Verbindungsstücken und Stromauslass gekürzt.
        </Tooltip>
    );
};
