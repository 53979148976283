import {useAppSelector} from 'app/store/hooks';
import {ItemTable} from 'components/scene/order/canvas/items/ItemTable';
import {getOutletTypeName} from 'libs/models/order';
import React from 'react';
import {selectOrderOutletType} from 'state/order/orderSelectors';

export const ItemOutlet = (): JSX.Element => {
    const orderOutletType = useAppSelector(selectOrderOutletType);
    const outletType = getOutletTypeName(orderOutletType);
    const tdStyles = {
      borderRight: '1px solid rgb(100,100,100)',
      paddingBottom: '0.25rem',
      width: '40mm'
    }
    return (
        <ItemTable title={'Stromauslass'} info={'Info: (A) Ist der Stromauslass'}>
            <tr>
                <td style={tdStyles}>1</td>
                <td style={tdStyles}>{outletType}</td>
                <td> ? </td>
            </tr>
        </ItemTable>
    );
};


