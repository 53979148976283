import {useAppSelector} from 'app/store/hooks';
import {ItemTable} from 'components/scene/order/canvas/items/ItemTable';
import React from 'react';
import {selectOrderSpotlightAmount} from 'state/order/orderSelectors';
import {selectShopActiveVariantProduct} from 'state/shop/shopSelectors';

export const ItemLight = (): JSX.Element => {
    const spotlightAmount = useAppSelector(selectOrderSpotlightAmount);
    const activeVariantProduct = useAppSelector(selectShopActiveVariantProduct);
    const tdStyles = {
      borderRight: '1px solid rgb(100,100,100)',
      paddingBottom: '0.25rem',
      width: '40mm'
    }

    return (
        <ItemTable title={'Strahler'}>
            <tr>
                <td style={tdStyles}>{spotlightAmount}</td>
                <td style={tdStyles}>{activeVariantProduct?.name}</td>
                <td>{activeVariantProduct?.productNumber}</td>
            </tr>
        </ItemTable>
    );
};


