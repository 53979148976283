import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {SaveAction} from 'components/common/actions/SaveAction';
import React from 'react';
import {saveEntity} from 'state/entity/entityApi';
import {selectStepCompletePageTrack} from 'state/step/selectStepComplete';
import {useStepNavigationForPageLight} from 'state/step/useStepNavigation';

export const SaveTrackAction = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const nextNavigation = useStepNavigationForPageLight();
    const isComplete = useAppSelector(selectStepCompletePageTrack);

    const handleSave = async () => {
        await dispatch(saveEntity());
        if (nextNavigation.route) {
            dispatch(nextNavigation.route);
        }
    };

    return (
        <SaveAction
            disabled={!isComplete}
            onSave={handleSave}
            hasNext={nextNavigation.route !== null} />
    );
}
