import {useAppSelector} from 'app/store/hooks';
import React from 'react';
import {selectOrderTracksWithoutSort} from 'state/order/orderSelectors';

export const ItemTrack = (): JSX.Element => {
    const orderTracks = useAppSelector(selectOrderTracksWithoutSort);

    const titleStyle = {
      fontWeight: 700,
      marginTop: '8mm',
      paddingBottom: '.5rem',
    };

    const tableStyles = {
      width: '120mm',
      marginLeft: '25mm',
      marginBottom: '15mm',
      marginTop: '5mm',
      backgroundColor: '#fff'
    };

    const ThStyles = {
      fontWeight: 500,
      marginBottom: '1mm',
      paddingBottom: '0.5rem',
      width: '40mm',
    };

    const tBodyStyles = {
      width: '145mm',
    };

    const h4Style = {
      textDecoration: 'italic',
      fontSize: '10px',
      fontWeight: 500,
      paddingBottom: '.5rem',
    };

    const tHeadStyles = {
      borderBottom: '1px solid black',
    };

    const tdStyles = {
      borderRight: '1px solid rgb(100,100,100)',
      paddingBottom: '0.25rem',
      width: '40mm'
    }

    const tBodyItems = orderTracks.map((track, idx) => {
        return (
            <tr key={idx + '-tr-track'}>
                <td style={tdStyles}>{idx + 1}</td>
                <td style={tdStyles}>{track.length}cm</td>
                <td>{track.key}</td>
            </tr>
        );
    });

    return (
        <div className={'pdf_item_table'}>
            <table style={tableStyles}>
                <thead  style={tHeadStyles}>
                <tr>
                    <h3 style={titleStyle}>Schienen</h3>
                </tr>
                <tr>
                  <h4 style={h4Style}>Anzahl gesamt: {orderTracks.length}</h4>
                </tr>
                <tr>
                    <th style={ThStyles}>Schienen-Nr.</th>
                    <th style={ThStyles}>Länge</th>
                    <th style={ThStyles}>Verbindung</th>
                </tr>
                </thead>
                <tbody style={tBodyStyles}>
                    {tBodyItems}
                </tbody>
            </table>
        </div>
    );
};


