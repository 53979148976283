import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import React, {useEffect, useState} from 'react';
import {HexColorPicker} from 'react-colorful';
import {setRoofColor, setWallColor} from 'state/scene/sceneReducer';
import {selectRoofColor, selectWallColor} from 'state/scene/sceneSelectors';

export const RoofColorPicker = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const roofColor = useAppSelector(selectRoofColor);

    const changeColor = (color: string) => {
        dispatch(setRoofColor({roofColor: color}))
    }

    return (
        <ColorPickerButton buttonTitle={'Deckenfarbe'} activeColor={roofColor} changedColor={(color: string) => changeColor(color)}/>
    )
}
export const WallColorPicker = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const wallColor = useAppSelector(selectWallColor);

    const changeColor = (color: string) => {
        dispatch(setWallColor({wallColor: color}))
    }

    return (
        <ColorPickerButton buttonTitle={'Wandfarbe'} activeColor={wallColor} changedColor={(color: string) => changeColor(color)}/>
    )
}

type ColorPickerButtonProps = {
    buttonTitle: string,
    activeColor: string
    changedColor: (color: string) => void;
}

export const ColorPickerButton = ({activeColor, changedColor}: ColorPickerButtonProps): JSX.Element => {
    const changeColor = (color: string) => {
        changedColor(color)
    }

    return (
        <>
            <ColorPicker activeColor={activeColor} changedColor={(color: string) => changeColor(color)}/>
        </>
    )
}

type ColorPickerProps = {
    activeColor: string
    changedColor: (color: string) => void;
}

export const ColorPicker = ({activeColor, changedColor}: ColorPickerProps): JSX.Element => {
    const [color, setColor] = useState(activeColor);

    useEffect(() => {
        setColor(activeColor)
    }, [activeColor])

    const changeColor = (color: string) => {
        changedColor(color)
    }

    return (
        <div>
            <HexColorPicker
                className="picker"
                color={color}
                onChange={(color) => setColor(color)}
                onPointerUp={() => changeColor(color)}
                onPointerLeave={() => changeColor(color)}
            />
        </div>
    )
}
