import {createAsyncThunk} from '@reduxjs/toolkit';
import {shopClient} from 'app/config/shopClient';
import {AppAsyncThunkConfig} from 'app/store/store';
import {CartPrice, ProductItem} from 'libs/client/clientApi';
import {OrderPDF, SpotProduct} from 'libs/shop/shopClient';
import {pageErrorEntityNotFound} from 'state/location/errorRoutes';

export const fetchSpotProducts = createAsyncThunk<SpotProduct[], void, AppAsyncThunkConfig>(
    'shopwareApi/fetchSpotProducts',
    async (): Promise<SpotProduct[]> => {
        return await shopClient.fetchSpotProducts();
    }
);

export const uploadOrderPDF = createAsyncThunk<boolean, OrderPDF, AppAsyncThunkConfig>(
    'shopwareApi/uploadOrderPDF',
    async (orderPdf): Promise<boolean> => {
        return await shopClient.uploadOrderPDF(orderPdf);
    }
);

export const saveOrderProducts = createAsyncThunk<CartPrice, { entityId: string, products: ProductItem[] }, AppAsyncThunkConfig>(
    'shopwareApi/saveOrderProducts',
    async ({entityId, products}, thunkAPI) => {
        try {
            return await shopClient.saveOrderProducts(entityId, products);
        } catch (e) {
            thunkAPI.dispatch(pageErrorEntityNotFound.route())
            return thunkAPI.rejectWithValue('Could not save cart');
        }

    }
);
