import {useAppDispatch} from 'app/store/hooks';
import {ResetAction} from 'components/common/actions/ResetAction';
import React from 'react';
import {stepResetPageOrder} from 'state/step/stepReset';

export const ResetOrderAction = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const handleConfirmDelete = () => {
        dispatch(stepResetPageOrder());
    };
    // TODO: Kann das löschen der Bestellung abgebrochen werden?
    const handleCancelDelete = () => {
        console.warn('Was tun wenn das bestellen löschen abgebrochen wurde? macht das Sinn?')
    };
    return (
        <ResetAction onConfirm={handleConfirmDelete} onCancel={handleCancelDelete}>
            <p>
                Kann das hier passieren?
            </p>
        </ResetAction>
    );
};
