import {
    knotMinimumDistanceToKnot,
    trackMinimumDistanceToKnot,
    trackMinimumDistanceToTrack,
    trackMinimumDistanceToWall
} from 'app/config/track';
import {Knot} from 'libs/models/knot';
import {unknownValidation} from 'libs/notification/defaultNotifications';
import {NotifyItem} from 'libs/notification/notificationProvider';

export type TrackNotificationType = 'invalid-position-not-in-room' |
    'invalid-distance-to-wall' |
    'invalid-track-intersect-with-knot' |
    'invalid-track-intersect-with-track' |
    'invalid-distance-track-to-knot' |
    'invalid-distance-track-to-track' |
    'invalid-distance-knot-to-knot' |
    'invalid-track-length-short' |
    'invalid-track-length-long' |
    null;

export type TrackNotification = {
    type: TrackNotificationType;
    item: NotifyItem;
}

export type TrackValidation = {
    isValid: boolean;
    knots: Knot[];
    type: TrackNotificationType;
}

export const trackNotifications = (tnt: TrackNotificationType): NotifyItem => {
    const notifyItem = trackNotificationItems.find(nItem => nItem.type === tnt)

    if (notifyItem) {
        return notifyItem.item;
    }

    return unknownValidation;
}

const trackNotificationItems: TrackNotification[] = [
    {
        type: 'invalid-position-not-in-room',
        item: {
            id: 'invalid-position-room',
            title: 'Ungültige Position!',
            message: 'Die Lichtleiste liegt außerhalb vom Raum.',
            type: 'danger'
        }
    },
    {
        type: 'invalid-distance-to-wall',
        item: {
            id: 'invalid-distance-to-wall',
            title: 'Ungültiger Abstand!',
            message: `Der Abstand zu einer Wand muss min. ${trackMinimumDistanceToWall}cm betragen.`,
            type: 'danger'
        }
    },
    {
        type: 'invalid-track-intersect-with-knot',
        item: {
            id: 'invalid-track-intersect-with-knot',
            title: 'Ungültige Lichtleiste!',
            message: 'Die Lichtleiste überschneidet einen anderen Verbinder.',
            type: 'danger'
        }
    },
    {
        type: 'invalid-track-intersect-with-track',
        item: {
            id: 'invalid-track-intersect-with-track',
            title: 'Ungültige Lichtleiste!',
            message: 'Die Lichtleiste überschneidet einen anderen Lichtleiste.',
            type: 'danger'
        }
    },
    {
        type: 'invalid-distance-track-to-knot',
        item: {
            id: 'invalid-distance-track-to-knot',
            title: 'Ungültiger Abstand!',
            message: `Der Abstand von einer Lichtleiste zu einem Verbinder muss min. ${trackMinimumDistanceToKnot}cm betragen.`,
            type: 'danger'
        }
    },
    {
        type: 'invalid-distance-track-to-track',
        item: {
            id: 'invalid-distance-track-to-track',
            title: 'Ungültiger Abstand!',
            message: `Der Abstand von einer Lichtleiste zu einer anderen Lichtleiste muss min. ${trackMinimumDistanceToTrack}cm betragen.`,
            type: 'danger'
        }
    },
    {
        type: 'invalid-distance-knot-to-knot',
        item: {
            id: 'invalid-distance-knot-to-knot',
            title: 'Ungültiger Abstand!',
            message: `Der Abstand von einem Verbinder zu einem anderen Verbinder muss min. ${knotMinimumDistanceToKnot}cm betragen.`,
            type: 'danger'
        }
    },
    {
        type: 'invalid-track-length-short',
        item: {
            id: 'invalid-track-length-short',
            title: 'Ungültiger Länge!',
            message: `Die Länge einer Lichtleiste muss min. ${trackMinimumDistanceToKnot}cm betragen.`,
            type: 'info'
        }
    },
    {
        type: 'invalid-track-length-long',
        item: {
            id: 'invalid-track-length-long',
            title: 'Ungültiger Länge!',
            message: 'Die Länge der Lichtleiste ist zu lang, sie würde zu nah an der Wand oder außerhalb des Raumes liegen.',
            type: 'info'
        }
    }
]
