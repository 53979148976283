import {useAppSelector} from 'app/store/hooks';
import {ResetTypeAction} from 'components/actions/type/ResetTypeAction';
import {SaveTypeAction} from 'components/actions/type/SaveTypeAction';
import React from 'react';
import {selectStepNeedsResetPageType} from 'state/step/selectStepNeedReset';


export const TypeActions: React.FC = () => {
    const needsReset = useAppSelector(selectStepNeedsResetPageType);
    if (needsReset) {
        return (
            <ResetTypeAction />
        )
    }
    return (
        <SaveTypeAction />
    );
};



