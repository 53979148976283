import {nameForIndex} from 'app/config';
import {eckConnectorOffset, SystemColor, tConnectorOffset} from 'app/config/order';
import {findKnotById, Knot} from 'libs/models/knot';
import {mapKnotConnectionsToUniqueLines} from 'libs/models/knot/knotLines';
import {lineLength} from 'libs/models/line';
import {directionOpposite, directionRecordAsArray, directionRecordAsPairs} from 'libs/models/types';

/**
 * Erstellt aus allen Knoten die entsprechenden Leisten und zieht den offset für Verbidnungen ab.
 * @param knots
 */
export type OrderTrack = { key: string, length: number };

export const calculateLinesWithoutConnectorOffset = (knots: Knot[]): OrderTrack[] => {
    return calculateLines(knots).sort((a, b) => b.length - a.length);
};

export const calculateLinesWithoutConnectorOffsetWithoutSort = (knots: Knot[]): OrderTrack[] => {
    return calculateLines(knots);
};

const calculateLines = (knots: Knot[]): OrderTrack[] => {
    return mapKnotConnectionsToUniqueLines(knots).map((indexedLine) => {
        const fromKnot = findKnotById(knots, indexedLine.from.id);
        const toKnot = findKnotById(knots, indexedLine.to.id);

        const originalLength = lineLength(indexedLine);
        let lineLengthWithoutFromConnector = 0;

        if (fromKnot !== null && toKnot !== null) {
            const key = nameForIndex(fromKnot.id) + '-' + nameForIndex(toKnot.id);
            const fromConnectionType = getKnotConnectionType(fromKnot);
            lineLengthWithoutFromConnector = calculateLineLengthWithoutConnector(fromConnectionType, originalLength, fromKnot, indexedLine.to.id);

            const toConnectionType = getKnotConnectionType(toKnot);
            const lineLengthWithoutFromAndToConnector = calculateLineLengthWithoutConnector(toConnectionType, lineLengthWithoutFromConnector, toKnot, indexedLine.from.id);
            return {key: key, length: lineLengthWithoutFromAndToConnector};
        }

        return {key: '', length: originalLength};
    })
}

/**
 * SLK-260
 * id 0 = Stromauslass, hier werden 40mm von der Leiste abgezogen
 * Connection Types:
 *   1 & 2 = Ende einer Leiste oder die Leiste verläuft Horizontal oder Vertikal
 *   3 = Eine 90° Ecke, hier werden 28mm von der Leiste abgezogen
 *   4 = Ein T Stück:
 *       - liegt auf der Gegenseite auch eine Verbindung werden 65mm (130mm / 2) abgezogen
 *       - liegt auf der Gegenseite keine Verbidnung werden 74mm abgezogen
 * @param connectionType
 * @param originalLength
 * @param knot
 * @param connectedId
 */
export const calculateLineLengthWithoutConnector = (connectionType: ConnectionType, originalLength: number, knot: Knot, connectedId: number): number => {
    if (knot.id === 0) {
        const outletType = getOutletTypeFromKnot(knot);
        if (outletType === 'end') {
            return rounding(originalLength - 18);
        }
        if (outletType === 'mittel') {
            return rounding(originalLength - 15);
        }
        if (outletType === 'eck') {
            return rounding(originalLength - 4);
        }
        return originalLength;
    }

    if (connectionType === 1 || connectionType === 2) {
        return originalLength;
    }

    if (connectionType === 3) {
        return rounding(originalLength - eckConnectorOffset);
    }

    if (connectionType === 4) {
        const asPairs = directionRecordAsPairs(knot.connections);
        const connectedDirection = asPairs.find(directionPair => directionPair.connected === connectedId);

        if (connectedDirection) {
            const opposite = directionOpposite(connectedDirection.direction);
            const oppositeDirectionPair = asPairs.find(directionPair => directionPair.direction === opposite);
            if (oppositeDirectionPair && oppositeDirectionPair.connected >= 0) {
                return rounding(originalLength - tConnectorOffset.withOpposite);
            }
            return rounding(originalLength - tConnectorOffset.withoutOpposite);
        }

        return originalLength;
    }
    return originalLength;
};

/**
 * 1 = only one connection
 * 2 = two connections only horizontal or vertical (|, --)
 * 3 = two connections only 90° (L)
 * 4 = three connections (T)
 */
export type ConnectionType = 1 | 2 | 3 | 4 | null;
export const getKnotConnectionTypeTitle = (knot: Knot | null): string | null => {
    if (knot) {
        const type = getKnotConnectionType(knot);
        if (type) {
            if (type === 1) {
                return 'Endkappe';
            }
            if (type === 2) {
                return 'Innenverbinder';
            }
            if (type === 3) {
                return 'L-Stück';
            }
            if (type === 4) {
                return 'T-Stück';
            }
        }
    }
    return null;
}
export const getKnotConnectionType = (knot: Knot | null): ConnectionType => {
    if (knot) {
        const top = knot.connections.top;
        const right = knot.connections.right;
        const bottom = knot.connections.bottom;
        const left = knot.connections.left;

        const emptyConnections = directionRecordAsArray(knot.connections).filter(con => con === -1);

        if (emptyConnections.length === 3) {
            return 1;
        }

        if (
            ((top >= 0 && bottom >= 0) && (right < 0 && left < 0)) ||
            ((top < 0 && bottom < 0) && (right >= 0 && left >= 0))
        ) {
            return 2;
        }

        if (
            ((top >= 0 && right >= 0) && (bottom < 0 && left < 0)) ||
            ((right >= 0 && bottom >= 0) && (left < 0 && top < 0)) ||
            ((bottom >= 0 && left >= 0) && (top < 0 && right < 0)) ||
            ((left >= 0 && top >= 0) && (right < 0 && bottom < 0))
        ) {
            return 3;
        }

        if (emptyConnections.length === 1) {
            return 4;
        }
    }
    return null;
};

/**
 * Rounding given num.
 * @param num
 */
export const rounding = (num: number): number => {
    return Math.round(num * 100) / 100;
};

/**
 * end = Endeinspeisung
 * mittel = Mitteleinspeisung
 * eck = Eckeinspeisung
 * default null
 */
export type OutletType = 'end' | 'mittel' | 'eck' | null;
export const getOutletTypeName = (type: OutletType): string => {
    switch (type) {
        case 'end':
            return 'Endeinspeisung'
        case 'mittel':
            return 'Mitteleinspeisung'
        case 'eck':
            return 'Eckeinspeisung'
        default:
            return ''
    }
}
export const getOutletTypeFromKnots = (knots: Knot[]): OutletType => {
    const outlet = knots.find(knot => knot.id === 0);
    if (outlet) {
        const knotConnectionType = getKnotConnectionType(outlet);
        if (knotConnectionType === 1) {
            return 'end';
        }
        if (knotConnectionType === 2) {
            return 'mittel';
        }
        if (knotConnectionType === 3) {
            return 'eck';
        }
    }
    return null;
};
export const getOutletTypeFromKnot = (knot: Knot): OutletType => {
    const outlet = knot;
    if (outlet) {
        const knotConnectionType = getKnotConnectionType(outlet);
        if (knotConnectionType === 1) {
            return 'end';
        }
        if (knotConnectionType === 2) {
            return 'mittel';
        }
        if (knotConnectionType === 3) {
            return 'eck';
        }
    }
    return null;
};

/**
 * Zerlegt eine Liste von Leisten in deren möglichen längen.
 * 200cm, 100cm und unter 100cm.
 * @param orderTracks
 */
export const calculateSingleTracksFromLineLengths = (orderTracks: OrderTrack[]): number[][] => {
    return orderTracks.map((orderTrack) => {
        const result: number[] = [];
        let calcLength = orderTrack.length;

        while (calcLength > 0) {
            if (calcLength >= 200 || (calcLength < 200 && calcLength > 100)) {
                result.push(200);
                calcLength = calcLength - 200;
            }
            if (calcLength > 0 && calcLength <= 100) {
                result.push(rounding(calcLength));
                calcLength = 0;
            }
        }

        return result;
    });
};

/**
 * Erstellt aus allen Knoten, außer Stromauslass (id = 0), eine Liste der Verbindungs arten {ConnectionType} (Gerade, L, T).
 * @param knots
 */
export const calculateConnectorsFromKnots = (knots: Knot[]): ConnectionType[] => {
    return knots.filter(knot => knot.id !== 0)
        .map(knot => getKnotConnectionType(knot))
        .filter(connectionType => connectionType !== null);
};

/**
 * Berechnet wie viele Verbinder es innerhalb von Leisten gibt
 * @param tracksFromLineLengths
 */
export const calculateInnerConnectorsFromTracks = (tracksFromLineLengths: number[][]): number => {
    return tracksFromLineLengths.map(tracks => tracks.length - 1).reduce((acc, curr) => acc + curr, 0);
};

/**
 * Fügt alle Verbindungsstücke zu einem Objekt zusammen.
 * @param innerConnectorsFromTracks
 * @param connectorsFromKnots
 */
export type OrderConnectors = { innerConnectors: number; lConnectors: number; tConnectors: number; endCaps: number; }
export const calculateAllConnectors = (innerConnectorsFromTracks: number, connectorsFromKnots: ConnectionType[]): OrderConnectors => {
    const orderConnectors: OrderConnectors = {
        innerConnectors: innerConnectorsFromTracks,
        lConnectors: 0,
        tConnectors: 0,
        endCaps: 0,
    };

    connectorsFromKnots.forEach((conType) => {
        if (conType === 1) {
            orderConnectors.endCaps += 1;
        }
        if (conType === 2) {
            orderConnectors.innerConnectors += 1;
        }
        if (conType === 3) {
            orderConnectors.lConnectors += 1;
        }
        if (conType === 4) {
            orderConnectors.tConnectors += 1;
        }
    });

    return orderConnectors;
};

/**
 * Fügt alle Leisten zu einem Objekt zusammen.
 * @param tracksFromLineLengths
 */
export type KeyAmount = { key: number, amount: number, forLines?: string[] }

export const calculateSameNumbers = (orderTracks: OrderTrack[]): KeyAmount[] => {
    let tmp: KeyAmount[] = [];

    orderTracks.forEach((orderTrack) => {
        const find = tmp.find(ct => ct.key === orderTrack.length);

        if (find) {
            find.amount += 1;
            find.forLines ? find.forLines.push(orderTrack.key) : find.forLines = [orderTrack.key];
            tmp = [...tmp.filter(ct => ct.key !== orderTrack.length), find];
        } else {
            tmp.push({key: orderTrack.length, amount: 1, forLines: [orderTrack.key]});
        }
    });

    return tmp;
};

/**
 * Zerlegt die Liste in einzelteile mit einer maximalen länge.
 *
 * @param orderTracks
 * @param maxLength
 */
export const splitOrderTracksByMaxLength = (orderTracks: OrderTrack[], maxLength: number): (OrderTrack[] | OrderTrack)[] => {
    return orderTracks.map((orderTrack) => {
        if (orderTrack.length > maxLength) {
            const amount = Math.floor(orderTrack.length / maxLength);
            const rest = rounding(orderTrack.length % maxLength);

            const split: OrderTrack[] = new Array(amount)
                .fill({key: orderTrack.key, length: maxLength}, 0, amount);

            split.push({key: orderTrack.key, length: rest});
            return split;
        }
        return orderTrack;
    });
};

/**
 * Zerlegt eine Liste von Leisten in deren möglichen längen.
 * 200cm, 100cm und unter 100cm.
 * @param orderTracks
 */
type OrderListResult = {
    trackLength: number;
    originalLength: number;
    waste: number;
    forOrderTrack: OrderTrack[];
}

export const calculateOrderTracksFromLineLengths = (orderTracks: OrderTrack[]): OrderListResult[] => {
    const maxLength = 200;

    const splitOrderTracks = splitOrderTracksByMaxLength(orderTracks, maxLength)
        .flat(1).sort((a: OrderTrack, b: OrderTrack) => b.length - a.length);

    const tmpOrderTracks = [...splitOrderTracks];
    const orderTrackResult: OrderTrack[][] = [];

    while (tmpOrderTracks.length > 0) {
        const current = tmpOrderTracks.shift();
        if (current) {
            const match = orderTrackResult.findIndex((item) => {
                const sum = item.reduce((acc, curr) => acc + curr.length, 0);
                const rest = rounding(maxLength - sum);
                return current.length <= rest;
            });
            if (match >= 0) {
                orderTrackResult[match].push(current);
            } else {
                orderTrackResult.push([current]);
            }
        }
    }

    return orderTrackResult.map((forOrderTrack) => {
        const originalLength = rounding(forOrderTrack.reduce((acc, curr) => acc + curr.length, 0));
        const trackLength = originalLength > 100 ? 200 : 100;
        const waste = rounding(trackLength - originalLength);
        return {
            forOrderTrack,
            originalLength,
            waste,
            trackLength
        };
    });
};

export const getTitleBySystemColors = (sysColor: SystemColor | null): string => {
    switch (sysColor) {
        case 'sw':
            return 'Schwarz';
        case 'ws':
            return 'Weiß';
        case 'ch':
            return 'Chrom';
        case 'mcgy':
            return 'Matt Chrom';
        default:
            return '';
    }
};
