import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import React from 'react';
import {toggleViewMode} from 'state/scene/sceneReducer';
import {selectViewMode} from 'state/scene/sceneSelectors';
import {toggleViewModeStyles} from 'components/panel/3d/styles/styles';
import {tw} from 'twind/css';

type ToggleViewModeButtonProps = {
  showIcon?: string,
}


export const ToggleViewModeButton = (props:ToggleViewModeButtonProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const sceneViewMode = useAppSelector(selectViewMode);

    const toggleViewModel = () => {
        dispatch(toggleViewMode())
    }

  const buttonClasses = [
    tw(toggleViewModeStyles),
    props.showIcon ? 'toggle-icon' : '',
    sceneViewMode.isThree ? 'toggled' : '',
  ].filter(Boolean).join(' ');


  return (

      <button className={buttonClasses} onClick={toggleViewModel}>
        {!props.showIcon?
          <>
            <p>2D</p>
             <span className={'toggle'}>
             </span>
            <p>3D</p>
          </>
          :
          <svg
            version="1.1"
            id="Ebene_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 412.3 336.9"
          >
            <g>
              <path d="M308.7,64.8c-0.9-0.6-1.4-1-2-1.3c-29.7-16.9-59.5-33.7-89.2-50.6c-4.6-2.6-8.9-2.4-13.4,0.2c-13.5,7.9-27,15.6-40.5,23.4
                C147.4,46,131.2,55.3,114.4,65c1.7,1,2.8,1.7,4,2.4c30,17.4,60.1,34.7,90.1,52.1c2.4,1.4,4,1.3,6.4,0c30.1-17.6,60.2-35,90.3-52.5
                C306.3,66.4,307.4,65.7,308.7,64.8z"/>
              <path d="M320.3,188.7c0-33.9,0-67.7,0-101.6c0-0.8-0.2-1.5-0.3-2.5c-0.9,0.4-1.4,0.7-2,1c-30.9,17.9-61.7,35.8-92.6,53.7
                c-2.1,1.2-2.5,2.6-2.5,4.8c0.1,34.9,0,69.7,0,104.6c0,1.1,0.1,2.1,0.2,3.5c0.7-0.3,1.2-0.4,1.6-0.6c29.9-17.3,59.9-34.6,89.8-51.9
                C318.6,197.3,320.3,193.5,320.3,188.7z"/>
              <path d="M197.6,139.2c-30.6-17.6-61.2-35.3-91.8-53c-0.8-0.5-1.7-0.8-2.9-1.3c0,1.6,0,2.7,0,3.8c0,33,0.1,66,0,99
                c0,6,2.4,10.1,7.6,13c18.6,10.5,37,21.3,55.5,32c11.2,6.5,22.4,12.9,34.2,19.7c0.1-1.1,0.2-1.6,0.2-2.1c0-35.6,0-71.2,0.1-106.8
                C200.4,141.2,199.4,140.2,197.6,139.2z"/>
              <path d="M399.3,195.2c-9.9-30.2-40.9-42.1-48.6-43.6c-4.9-1-9.7,2.2-10.6,7.1c-1,4.9,2.2,9.6,7.1,10.5c0.3,0.1,27.3,7.7,35.1,31.5
                c4.6,14.2-3.7,27.8-6.1,30.4c-29.7,33.4-69.8,48-134.2,48.9c-5,0.1-8.9,4.2-8.9,9.1c0.1,4.9,4.1,8.9,9,8.9c0,0,0.1,0,0.1,0
                c69.9-1,114-17.4,147.4-54.9C394.7,237.4,406.5,217.4,399.3,195.2z"/>
              <path d="M193.3,286.5L143,243.3c-2.4-2.1-5.8-2.7-8.8-1.7c-3,1.1-5.3,3.7-5.9,6.8l-5,26.3c-33.9-7-58.8-21-78.9-43.5
                c-2.3-2.6-10.7-16.3-6.1-30.4c7.8-23.9,34.8-31.5,35-31.5c4.9-1,8.1-5.7,7.1-10.6c-1-4.9-5.7-8.1-10.6-7.1
                c-7.8,1.5-38.7,13.3-48.6,43.6c-7.3,22.2,4.6,42.2,9.7,48c23,25.8,51,41.6,89,49.3l-4,21.2c-0.6,3.2,0.5,6.4,3,8.5
                c1.7,1.4,3.7,2.2,5.9,2.2c1,0,2-0.2,3-0.5l62.7-22c3-1.1,5.3-3.7,5.9-6.8C196.9,291.9,195.8,288.6,193.3,286.5z"/>
            </g>
          </svg>

        }

        </button>
    )
}
