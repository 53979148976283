import {css} from 'twind/css';

export const TooltipStyles = css({
    '@apply' : ' absolute bg-white rounded shadow-xl max-w-xs min-w-310 lg:top-4 left-2/4 transform -translate-x-2/4 lg:-translate-x-0 z-7777777 pointer-events-none opacity-0 shadow-xl transition-all ease-in-out duration-200',
    'top': 'calc(0px - var(--main-height))',
    '@screen lg': {
        'left': 'unset',
        'right': '-340px',
    },
    '&.active': {
        '@apply' : 'pointer-events-auto opacity-100 transition-all ease-in-out duration-200',
    },
    '.tooltip-btn': 'absolute right-4 top-2/4 transform -translate-y-2/4 cursor-pointer',
    '.tooltip-btn svg': {
        '@apply': 'fill-white',
    },
    '.close-icon': {
        '@apply': 'absolute bg-red-500 rounded-full p-1 cursor-pointer',
        'right': '-10px',
        'top': '-10px'
    },
    'svg': {
        '@apply': 'fill-white'
    },
    '.tooltip-body': {
        '@apply': 'px-4 py-3 tracking-wide'
    },
    '.tooltip-title': {
        '@apply': 'font-bold text-black '
    },
    'p': {
        '@apply': 'text-black font-normal bg-white shadow-none p-0'
    }

});

export const TooltipButtonStyles = css({
    '@apply': 'absolute cursor-pointer right-4 top-1/2 -translate-y-2 -mt-1',

    'svg': {
        '@apply': 'fill-white',
    },
});
