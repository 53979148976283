import React from 'react';

export type ItemTableProps = React.ComponentProps<'html'> & {
    title?: string,
    info?: string,
}

export const ItemTable = ({children, title, info}: ItemTableProps): JSX.Element => {

  const titleStyle = {
    fontWeight: 700,
    paddingBottom: '.5rem',
  };

  const tableStyles = {
    width: '120mm',
    marginLeft: '25mm',
    marginBottom: '5mm',
    backgroundColor: '#fff'
  };

  const ThStyles = {
    fontWeight: 500,
    marginBottom: '1mm',
    paddingBottom: '0.5rem',
    width: '40mm',
  };

  const tBodyStyles = {
    width: '145mm',
  };

  const h4Style = {
    textDecoration: 'italic',
    fontSize: '10px',
    fontWeight: 500,
    paddingBottom: '.5rem',
  };

  const tHeadStyles = {
    borderBottom: '1px solid black',
  };

  const extraInfo = () => {
    if (info) {
      return <h4 style={h4Style}>{info}</h4>
    }
    return '';
  }

  return (
        <div className={'pdf_item_table'}>
            <table style={tableStyles}>
                <thead style={tHeadStyles}>
                  <tr>
                    <h3 style={titleStyle}>{title}</h3>
                  </tr>
                  <tr>
                    {extraInfo()}
                  </tr>
                  <tr>
                      <th style={ThStyles}>Anzahl</th>
                      <th style={ThStyles}>Typ</th>
                      <th style={ThStyles}>Artikel-Nr.</th>
                  </tr>
                </thead>
                <tbody style={tBodyStyles}>
                    {children}
                </tbody>
            </table>
        </div>
    );
};


