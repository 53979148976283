import {Position} from './position';

/**
 * Gleicht die Vertikale Position von {target} an die von {source} an.
 *
 * Mutiert nur, wenn es auch eine Änderung gibt.
 *
 * @param target
 * @param source
 */
export function alignPositionVerticalWith<T extends Position>(target: T, source: Position): T {
    if (target.x === source.x) {
        return target;
    }
    return {
        ...target,
        x: source.x
    };
}

/**
 * Gleicht die Horizontale Position von {target} an die von {source} an.
 *
 * Mutiert nur, wenn es auch eine Änderung gibt.
 *
 * @param target
 * @param source
 */
export function alignPositionHorizontalWith<T extends Position>(target: T, source: Position): T {
    if (target.y === source.y) {
        return target;
    }
    return {
        ...target,
        y: source.y
    };
}
