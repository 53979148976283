import {unknownValidation} from 'libs/notification/defaultNotifications';
import {NotifyItem} from 'libs/notification/notificationProvider';

export type RoomNotificationType = 'invalid-length' |
    'invalid-intersection' |
    'invalid-direction' |
    null;

export type RoomNotification = {
    type: RoomNotificationType;
    item: NotifyItem;
}

export type RoomValidation = {
    isValid: boolean;
    type: RoomNotificationType;
}

export const roomNotifications = (rnt: RoomNotificationType): NotifyItem => {
    const notifyItem = roomNotificationItems.find(nItem => nItem.type === rnt)

    if (notifyItem) {
        return notifyItem.item;
    }

    return unknownValidation;
}

const roomNotificationItems: RoomNotification[] = [
    {
        type: 'invalid-length',
        item: {
            id: 'invalid-length',
            title: 'Ungültige Länge!',
            message: 'Die Länge einer Wand darf min. 100cm und max. 10000cm betragen.',
            type: 'info'
        }
    },
    {
        type: 'invalid-intersection',
        item: {
            id: 'invalid-intersection',
            title: 'Ungültiger Raum!',
            message: 'Die Wände würden sich Überschneiden.',
            type: 'danger'
        }
    },
    {
        type: 'invalid-direction',
        item: {
            id: 'invalid-direction',
            title: 'Ungültiger Raum!',
            message: 'Die Wände würden ihre Richtung ändern.',
            type: 'danger'
        }
    },
]
