import {ItemConnector} from 'components/scene/order/canvas/items/ItemConnector';
import {ItemConnectorDetail} from 'components/scene/order/canvas/items/ItemConnectorDetail';
import {ItemLight} from 'components/scene/order/canvas/items/ItemLight';
import {ItemOutlet} from 'components/scene/order/canvas/items/ItemOutlet';
import {ItemTrack} from 'components/scene/order/canvas/items/ItemTrack';
import {ItemTrackDetail} from 'components/scene/order/canvas/items/ItemTrackDetail';
import React from 'react';

export const OrderItems = (): JSX.Element => {
    return (
        <div
          id={'orderItemsListPDF'}
        >
            <ItemOutlet />
            <ItemLight />
            <ItemTrack />
            <ItemConnector />
            <ItemConnectorDetail />
            <ItemTrackDetail />
        </div>
    );
};
