import {useAppSelector} from 'app/store/hooks';
import {saveAction} from 'components/common/actions/styles/styles';
import {Button} from 'components/common/buttons/Button';
import React from 'react';
import {selectViewMode} from 'state/scene/sceneSelectors';
import {tw} from 'twind/css';
import {ToggleViewModeButton} from '../../panel/3d/ToggleViewMode';

export type SaveActionProps = {
    onSave: () => void;
    hasNext: boolean;
    disabled: boolean;
};

export const SaveAction = ({disabled, onSave, hasNext}: SaveActionProps): JSX.Element => {
    const sceneViewMode = useAppSelector(selectViewMode);
    const isThree = sceneViewMode.isThree;

    return (
        <div className={isThree? tw(saveAction) + ' disabled' : tw(saveAction) + ' btn-save-wrapper'}>
          <ToggleViewModeButton
            showIcon={'true'}
          />
            <Button
                disabled={disabled}
                variant={'primary'}
                onClick={onSave}>
                Speichern {hasNext ? ' und weiter' : ''}
            </Button>
        </div>
    );
}
