import {useGLTF} from '@react-three/drei';
import {publicPath} from 'app/config/gltf';
import {SpotEuclidGLTFResult} from 'libs/models/3d/gltf';
import {SpotEuclidModeComponentMap, SpotMode} from 'libs/models/3d/spotModels';
import {PositionThree} from 'libs/view';
import * as THREE from 'three';
import {MeshStandardMaterial} from 'three';

type SpotEuclidProps = {
    systemMaterial: THREE.MeshStandardMaterial;
    additionalMaterial: THREE.MeshStandardMaterial;
    spotMode: SpotMode;
    position: PositionThree;
    isHorizontal: boolean;
}

export const SpotEuclid = (
    {
        systemMaterial,
        additionalMaterial,
        spotMode,
        position,
        isHorizontal
    }: SpotEuclidProps): JSX.Element => {
    const {nodes} = useGLTF(publicPath('/gltf/spotEuclidDraco.gltf')) as SpotEuclidGLTFResult;

    const strahlerkopfMaterial = systemMaterial;
    const innenreflektorMaterial = additionalMaterial;

    const baseRotation: PositionThree = isHorizontal ? [0, 0, 0] : [0, Math.PI / 2, 0];

    const spotComponent = spotComponentMap[spotMode];
    const spot = spotComponent({nodes, innenreflektorMaterial, strahlerkopfMaterial});

    return (
        <group scale={0.04} position={position} dispose={null}>
            <group name={'base'}>
                <mesh
                    geometry={nodes.schienenadapter.geometry}
                    material={systemMaterial}
                    rotation={baseRotation}
                    scale={[0.8, 0.2, -0.3]}
                />
                <mesh
                    geometry={nodes.stab.geometry}
                    material={systemMaterial}
                    position={[0, -0.42, 0]}
                    rotation={[0, 0, 3.14]}
                    scale={[0.08, 0.32, -0.08]}
                />
            </group>
            {spot}
        </group>
    );
};

const spotComponentMap: SpotEuclidModeComponentMap = {
    top: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps) => (
        <SpotEuclidTop
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    right: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps) => (
        <SpotEuclidRight
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    bottom: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps) => (
        <SpotEuclidBottom
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    left: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps) => (
        <SpotEuclidLeft
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
    down: ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps) => (
        <SpotEuclidDown
            nodes={nodes}
            innenreflektorMaterial={innenreflektorMaterial}
            strahlerkopfMaterial={strahlerkopfMaterial}
        />
    ),
};

export type SpotEuclid3DProps = {
    nodes: SpotEuclidGLTFResult['nodes'];
    innenreflektorMaterial: MeshStandardMaterial;
    strahlerkopfMaterial: MeshStandardMaterial;
}

export const SpotEuclidTop = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps): JSX.Element => {
    return (
        <group name={'top'}>
            <mesh
                geometry={nodes.hinten_linse.geometry}
                material={innenreflektorMaterial}
                position={[0, -1.01, 0.15]}
                rotation={[-1.03, -0.01, 2.61]}
                scale={[-0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.hinten_strahler001.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1, 0.15]}
                rotation={[-1.03, -0.01, 3.14]}
                scale={[-0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.hinten_strahler001_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -1, 0.15]}
                rotation={[-1.03, -0.01, 3.14]}
                scale={[-0.01, 0.01, 0.01]}
            />
        </group>
    );
};
export const SpotEuclidBottom = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps): JSX.Element => {
    return (
        <group name={'bottom'}>
            <mesh
                geometry={nodes.vorne_linse.geometry}
                material={innenreflektorMaterial}
                position={[0, -1.07, -0.12]}
                rotation={[-2.16, 0, Math.PI / 6]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.vorne_strahler001.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.83, 0.06]} rotation={[-2.16, 0, 0]} scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.vorne_strahler001_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.83, 0.06]} rotation={[-2.16, 0, 0]} scale={[0.01, 0.01, 0.01]}
            />
        </group>
    );
};
export const SpotEuclidLeft = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps): JSX.Element => {
    return (
        <group name={'left'}>
            <mesh
                geometry={nodes.rechts_linse.geometry}
                material={innenreflektorMaterial}
                position={[0.14, -1.02, 0.01]}
                rotation={[-Math.PI / 2, 0.54, -2.09]}
                scale={[-0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.rechts_strahler001.geometry}
                material={strahlerkopfMaterial}
                position={[0.15, -1, 0.01]}
                rotation={[-Math.PI / 2, 0.54, -1.57]}
                scale={[-0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.rechts_strahler001_1.geometry}
                material={strahlerkopfMaterial}
                position={[0.15, -1, 0.01]}
                rotation={[-Math.PI / 2, 0.54, -1.57]}
                scale={[-0.01, 0.01, 0.01]}
            />
        </group>
    );
};
export const SpotEuclidRight = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps): JSX.Element => {
    return (
        <group name={'right'}>
            <mesh
                geometry={nodes.links_linse.geometry}
                material={innenreflektorMaterial}
                position={[-0.14, -1.02, 0.01]}
                rotation={[-Math.PI / 2, -0.54, 2.09]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.links_strahler001.geometry}
                material={strahlerkopfMaterial}
                position={[0.04, -0.8, 0.01]}
                rotation={[-Math.PI / 2, -0.54, 1.57]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.links_strahler001_1.geometry}
                material={strahlerkopfMaterial}
                position={[0.04, -0.8, 0.01]}
                rotation={[-Math.PI / 2, -0.54, 1.57]}
                scale={[0.01, 0.01, 0.01]}
            />
        </group>
    );
};
export const SpotEuclidDown = ({nodes, innenreflektorMaterial, strahlerkopfMaterial}: SpotEuclid3DProps): JSX.Element => {
    return (
        <group name={'down'}>
            <mesh
                geometry={nodes.unten_linse.geometry}
                material={innenreflektorMaterial}
                position={[0, -0.9, -0.27]}
                rotation={[-Math.PI / 2, 0, Math.PI / 6]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.unten_strahler001.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.82, 0.01]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.unten_strahler001_1.geometry}
                material={strahlerkopfMaterial}
                position={[0, -0.82, 0.01]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[0.01, 0.01, 0.01]}
            />
        </group>
    );
};

useGLTF.preload(publicPath('/gltf/spotEuclidDraco.gltf'));
