import {PositionThree} from 'libs/view';
import React, {useEffect, useState} from 'react';

type CornerProps = {
    position?: PositionThree,
    cursorStylePointerEnter?: string,
    isDisabled?: boolean
}

export const Corner = (
    {
        position,
        cursorStylePointerEnter,
        isDisabled,
    }: CornerProps): JSX.Element => {

    const stylePointerEnter = cursorStylePointerEnter || 'grabbing';
    const disabled = isDisabled === undefined ? false : isDisabled;
    const [isMouseOver, setMouse] = useState(false);

    useEffect(() => {
        if (!disabled) {
            document.body.style.cursor = isMouseOver ? stylePointerEnter : 'auto'
        }
    }, [isMouseOver, stylePointerEnter, disabled])

    return (
        <mesh position={position}
              onPointerEnter={() => setMouse(true)}
              onPointerLeave={() => setMouse(false)}
        >
            <boxBufferGeometry args={[0.25, 0.2, 0.25]}/>
            <meshBasicMaterial color={'#f3f4f6'} transparent opacity={0} />
        </mesh>
    )
}
