import {IndexedPosition, Position} from './position';
import {LineDirection} from 'libs/models/line';


export function findPositionByIndexInDirection<T extends Position>(positions: T[], index: number, direction: LineDirection): IndexedPosition {
    if (direction >= 0) {
        return findPositionByIndexNext(positions, index);
    }
    return findPositionByIndexPrevious(positions, index);
}


/**
 * Sucht die nächste Position anhand des indexes.
 *
 * Gibt die erste Position zurück falls der Index überschritten wird.
 *
 * @param positions
 * @param index
 */
export function findPositionByIndexNext<T extends Position>(positions: T[], index: number): IndexedPosition {
    const nextIndex = (index >= 0 && index < positions.length - 1) ? index + 1 : 0;
    return {
        ...positions[nextIndex],
        id: nextIndex
    };
}

/**
 * Sucht die vorherige Position anhand des indexes.
 *
 * Gibt die erste Position zurück falls der Index überschritten wird.
 *
 * @param positions
 * @param index
 */
export function findPositionByIndexPrevious<T extends Position>(positions: T[], index: number): IndexedPosition {
    const prevIndex = (index > 0 && index < positions.length) ? index - 1 : positions.length - 1;
    return {
        ...positions[prevIndex],
        id: prevIndex
    };
}
