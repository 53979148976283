import {Plane} from '@react-three/drei';
import {useAppSelector} from 'app/store/hooks';
import {cmToThree, PositionThree, WallSegmentModel} from 'libs/view';
import React from 'react';
import {selectWallColor} from 'state/scene/sceneSelectors';
import * as THREE from 'three';

type WallPlanesProps = {
    wallSegments: WallSegmentModel[];
    roomHeight: number;
}

export const WallPlanes = ({wallSegments, roomHeight}: WallPlanesProps): JSX.Element => {
    const wallColor = useAppSelector(selectWallColor);
    const wallPlanes = getWallPlanesFromWallSegments(wallSegments, roomHeight, wallColor)

    return (
        <>
            {wallPlanes}
        </>
    )
}

function getWallPlanesFromWallSegments(wallSegments: WallSegmentModel[], roomHeight: number, color: string): JSX.Element[] {
    return wallSegments.map((wall, idx): JSX.Element => {
            const planeRotation = getPlaneRotationFromWallSegment(wall);

            // noinspection RequiredAttributes
            return (
                <Plane
                    key={idx}
                    args={[cmToThree(wall.length), roomHeight]}
                    position={wall.center}
                    rotation={planeRotation}
                >
                    <meshStandardMaterial color={color}/>
                </Plane>
            )
        }
    )
}

function getPlaneRotationFromWallSegment(wall: WallSegmentModel): PositionThree {
    if (wall.isHorizontal) {
        if (wall.direction < 0) {
            return [0, (THREE.MathUtils.degToRad(-180) * wall.direction), 0]
        }
        return [0, 0, 0]
    }

    return [0, THREE.MathUtils.degToRad(-90) * wall.direction, 0]
}
