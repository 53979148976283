import {Tooltip} from 'components/common/tooltip/Tooltip';

import React from 'react';


export const LightToolTip = (): JSX.Element => {
    return (
        <Tooltip title={'Strahler wählen'} setDisbandTime={5000}>
            Wählen Sie die gewünschten Strahler und deren Anzahl.
            Die Strahler werden gleichmäßig verteilt, Sie können diese aber mittels Drag & Drop verschieben.
            <strong>Hinweis:<br/></strong>
            Die Farbe der Lichtschienen ist durch die Farbe der Strahler vorgegeben.
        </Tooltip>
    );
};
