import {useAppSelector} from 'app/store/hooks';
import {styles} from 'components/common/panel/styles/PanelEditContainerStyles';
import React from 'react';
import {selectOrderSpotlightAmount} from 'state/order/orderSelectors';
import {tw} from 'twind/css';

export const OrderSpotlightAmount = (): JSX.Element => {
    const spotlightAmount = useAppSelector(selectOrderSpotlightAmount);

    return (
        <div className={tw(styles)}>
            <div className={'container'}>
                <div className={'title'}>
                    <h2>Strahler</h2>
                </div>
            </div>
            <div className={'item'}>
                <p>
                    {spotlightAmount} x Strahler
                </p>
            </div>
        </div>
    );
};
