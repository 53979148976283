import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store/store';
import {RoomEntityData} from 'libs/client/clientApi';
import {meassurePositionsMinMaxValues} from 'libs/models/position';
import {mapRoomFromPositions, meassureRoomWallsForOutlines} from 'libs/models/room';
import {mapWallSegmentsFromPositions} from 'libs/view';
import {RoomState} from 'state/room/roomReducer';

export const selectRoomState = (state: RootState): RoomState => state.room;

export const selectRoomCorners = createSelector(
    [selectRoomState],
    (state) => state.corners
);

export const selectRoomOutlines = createSelector(
    [selectRoomState],
    (state) => state.outlines
);

export const selectRoom = createSelector(
    [selectRoomState],
    (state) => mapRoomFromPositions(state.corners)
);

export const selectHasRoomCorners = createSelector(
    [selectRoomCorners],
    (state) => state.length > 0
);


export const selectWallSegments = createSelector(
    [selectRoomCorners],
    (corners) => mapWallSegmentsFromPositions(corners)
);


export const selectRoomMinMaxValues = createSelector(
    [selectRoomCorners],
    (corners) => meassurePositionsMinMaxValues(corners)
)

export const selectRoomWallsForOutlines = createSelector(
    [selectWallSegments, selectRoomOutlines],
    (wallSegments, outlines) => meassureRoomWallsForOutlines(wallSegments, outlines)
)


/**
 * Das sind die Daten, die in der Entität gespeichert werden.
 */
export const selectRoomEntityData = createSelector(
    [selectRoomCorners],
    (corners): RoomEntityData => {
        return {
            corners
        }
    }
);

