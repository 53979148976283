import {ViewEditMode} from 'state/view/viewModels';
import {createPageRoute, PageRoute} from 'state/location/routing/pageRouting';
import {extendDefaultView} from 'state/view/viewReducer';
import {loadEntity} from 'state/entity/entityApi';

/**
 * Standardtyp: eine url mit id (/foo/:entityId).
 */
export type EntityIdPayload = { entityId: string };


/**
 * shortcut: Eine 'bearbeiten' Seite.
 * - Der editMode wird gesetzt.
 * - Die entität wird vom client geladen.
 */
export function createEditPageRoute(pageName: string, editMode: ViewEditMode): PageRoute<EntityIdPayload> {
    return createPageRoute(pageName, {
        view: extendDefaultView({
            editMode
        }),
        loader: (payload, ctx) => {
            const entityId = payload.entityId;

            return ctx.dispatch(loadEntity(entityId));
        }
    });
}
