import {mapWallSegmentFromLine, WallSegmentModel} from 'libs/view';
import {Position} from 'libs/models/position/position';

export function mapPositionsToWallSegments(points: Position[]): WallSegmentModel[] {
    return points.map((point, index) => {
        const next = index < points.length - 1 ? points[index + 1] : points[0];
        const line = {
            from: {id: index, x: point.x, y: point.y},
            to: {id: index, x: next.x, y: next.y}
        };
        return mapWallSegmentFromLine(line)
    })
}