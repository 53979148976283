/**
 * Der Name für eine Wand
 */
import {Direction} from 'libs/models/types';


export function nameForIndex(index: number): string {
    return String.fromCharCode(index + 65);
}

export function nameForWallIndex(wallIndex: number): string {
    const char = nameForIndex(wallIndex);
    return `Wand ${char}`;
}

export function nameForTrackLine(fromIndex: number, toIndex:number): string {
    const fromChar = nameForIndex(Math.min(fromIndex, toIndex));
    const toChar = nameForIndex(Math.max(fromIndex, toIndex));

    return `Leiste ${fromChar} - ${toChar}`;
}


export function nameForDirection(direction: Direction): string {
    switch (direction) {
        case 'top':
            return 'Oben';
        case 'right':
            return 'Rechts';
        case 'bottom':
            return 'Unten';
        case 'left':
            return 'Links';
    }
    return '';
}
