import {useAppSelector} from 'app/store/hooks';
import {OutletEditor} from 'components/scene/outlet/edit/OutletEditor';
import {OutletView} from 'components/scene/outlet/view/OutletView';
import React from 'react';
import {selectIsEditModeOutlet} from 'state/view/viewSelectors';

export const OutletScene = (): JSX.Element => {
    const isEditMode = useAppSelector(selectIsEditModeOutlet);

    if (isEditMode) {
        return (
            <OutletEditor />
        );
    }
    return <OutletView />
};
