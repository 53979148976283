import {css} from 'twind/css';

export const styles = css({
    '@apply': 'relative',
    'h2': {
        '@apply': 'relative bg-black text-white font-bold px-4 py-2 tracking-wide'
    },
    '.container':{
        '@apply': 'relative bg-black text-white font-bold tracking-wide',
    },
    '.price':{
        '@apply': 'border-t border-black-lightest',
    },
    '.item': {
        '@apply': 'relative p-4',
        '&:not(:only-of-type)': {
            '@apply': 'pb-0',
        },
        '&:last-of-type': {
            '@apply': 'pb-4',
        }
    },
    'p': {
        '@apply': 'bg-black-lightest rounded shadow-xl text-left text-white py-2 px-3',
        'hr': {
            '@apply': 'my-2',
        }
    }
});
