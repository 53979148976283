import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {SaveAction} from 'components/common/actions/SaveAction';
import React from 'react';
import {saveEntity} from 'state/entity/entityApi';
import {selectStepCompletePageRoom} from 'state/step/selectStepComplete';
import {useStepNavigationForPageOutlet} from 'state/step/useStepNavigation';


export const SaveRoomAction = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const nextNavigation = useStepNavigationForPageOutlet();
    const isComplete = useAppSelector(selectStepCompletePageRoom);

    const handleSave = async () => {
        await dispatch(saveEntity());
        if (nextNavigation.route) {
            dispatch(nextNavigation.route);
        }
    };

    return (
        <SaveAction
            disabled={!isComplete}
            onSave={handleSave}
            hasNext={nextNavigation.route !== null} />
    );
}

