import {useAppSelector} from 'app/store/hooks';
import {selectMaxNumberOfSpotligths} from 'state/track/trackSelectors';
import {PanelEditItem} from 'components/common/panel/PanelEditItem';

export const SpotAmountDisplay = (): JSX.Element => {
    const maxSpotlights = useAppSelector(selectMaxNumberOfSpotligths);

    return (
        <PanelEditItem title={'Maximale Anzahl:'} variant={'limit'} isDisabled={true}>
            <p>{maxSpotlights}</p>
        </PanelEditItem>
    )
}
