import {createSelector} from '@reduxjs/toolkit';
import {selectHasSpotlightAmount} from 'state/light/lightSelectors';
import {selectOutletPosition} from 'state/outlet/outletSelectors';
import {selectCurrentPresetEntity} from 'state/preset/presetSelectors';
import {selectHasRoomCorners} from 'state/room/roomSelectors';
import {selectHasActiveMainAndVariantProductNumber} from 'state/shop/shopSelectors';
import {selectTrackLines} from 'state/track/trackSelectors';

/**
 * Selektoren um zu prüfen, ob ein Schritt "vollständig" ist.
 *
 * - wird u.a. dafür benutzt den "speichern" Button zu aktivieren.
 */


/**
 * TODO: Schritt 6 (Bestellung) - vollständig?
 */
export const selectStepCompletePageOrder = createSelector(
    [],
    (): boolean => {
        return true;
    }
);

/**
 * SLK-218: Schritt 5 (Strahler) - vollständig?
 */
export const selectStepCompletePageLight = createSelector(
    [selectHasSpotlightAmount, selectHasActiveMainAndVariantProductNumber],
    (hasSpotlightAmount, hasActiveMainAndVariantProductNumber): boolean => {
        return hasSpotlightAmount && hasActiveMainAndVariantProductNumber;
    }
);

/**
 * SLK-206: Schritt 4 (Schienen) - vollständig?
 *
 * - Es gibt mindestens eine Leiste?.
 */
export const selectStepCompletePageTrack = createSelector(
    [selectTrackLines],
    (lines): boolean => {
        return lines.length > 0;
    }
);

/**
 * SLK-202: Schritt 3 (Auslass) - vollständig?
 *
 * - Es gibt einen Stromauslass Position.
 */
export const selectStepCompletePageOutlet = createSelector(
    [selectOutletPosition],
    (outletPosition): boolean => outletPosition !== null
);

/**
 * SLK-204: Schritt 2 (Grundriss) - vollständig?
 *
 * - Der Raum hat Ecken?
 */
export const selectStepCompletePageRoom = createSelector(
    [selectHasRoomCorners],
    (hasCorners): boolean => hasCorners
);

/**
 * SLK-207: Schritt 1 (Raumform) - vollständig?
 *
 * - Es wurde eine Raumform ausgewählt
 */
export const selectStepCompletePageType = createSelector(
    [selectCurrentPresetEntity],
    (presetEntity): boolean => presetEntity !== null
);

