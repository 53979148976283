import {SpotBlop} from 'components/scene/3d/spots/SpotBlop';
import {SpotEuclid} from 'components/scene/3d/spots/SpotEuclid';
import {SpotFino} from 'components/scene/3d/spots/SpotFino';
import {SpotLenxx} from 'components/scene/3d/spots/SpotLenxx';
import {SpotStar} from 'components/scene/3d/spots/SpotStar';
import {Spotlight} from 'libs/models/light/light';
import {positionThreeFromPositionCM} from 'libs/view';
import React from 'react';
import * as THREE from 'three';

type ThreeDSpotlightsProps = {
    productNumber: string | null;
    spotlights: Spotlight[];
    roomHeight: number;
    systemMaterial: THREE.MeshStandardMaterial;
    additionalMaterial: THREE.MeshStandardMaterial;
    linsenMaterial: THREE.MeshStandardMaterial;
}

export const ThreeDSpotlights = ({productNumber, spotlights, roomHeight, systemMaterial, additionalMaterial, linsenMaterial}: ThreeDSpotlightsProps): JSX.Element => {
    const spots = getThreeDSpotlightByProductNumber({productNumber, spotlights, roomHeight, systemMaterial, additionalMaterial, linsenMaterial})

    return (
        <>
            {spots}
        </>
    )
}

const getThreeDSpotlightByProductNumber = ({productNumber, spotlights, roomHeight, systemMaterial, additionalMaterial, linsenMaterial}: ThreeDSpotlightsProps) => {
    switch(productNumber) {
        case '29997a8cb3b74339bf1cbf33058d2a6d': // Fino Strahler 50° Duolare
            // noinspection RequiredAttributes
            return spotlights.map((spot, index) => (
                <SpotFino
                    key={'spot-fino-' + index}
                    systemMaterial={systemMaterial}
                    additionalMaterial={additionalMaterial}
                    linsenMaterial={linsenMaterial}
                    isHorizontal={spot.isHorizontal}
                    spotMode={spot.direction}
                    position={positionThreeFromPositionCM(spot.point, (roomHeight / 2) - 0.015)}
                />
            ));
        case '9ebed694dc44401b9a9bad843502ec2c': // EUCLID Strahler 80° Duolare
            // noinspection RequiredAttributes
            return spotlights.map((spot, index) => (
                <SpotEuclid
                    key={'spot-euclid-' + index}
                    systemMaterial={systemMaterial}
                    additionalMaterial={linsenMaterial}
                    isHorizontal={spot.isHorizontal}
                    spotMode={spot.direction}
                    position={positionThreeFromPositionCM(spot.point, (roomHeight / 2) - 0.015)}
                />
            ));
        case 'a5dc616d1b9545b7b54d5c7ebb8976c0': // BLOP Strahler 60° Duolare
            // noinspection RequiredAttributes
            return spotlights.map((spot, index) => (
                <SpotBlop
                    key={'spot-blop-' + index}
                    systemMaterial={systemMaterial}
                    additionalMaterial={linsenMaterial}
                    isHorizontal={spot.isHorizontal}
                    spotMode={spot.direction}
                    position={positionThreeFromPositionCM(spot.point, (roomHeight / 2) - 0.015)}
                />
            ));
        case 'ef8aab1b1aef4fa88307c439ca810d85': // ACT Strahler 60° Duolare
            // noinspection RequiredAttributes
            return spotlights.map((spot, index) => (
                <mesh key={'spot-' + index} material={systemMaterial} position={positionThreeFromPositionCM(spot.point, (roomHeight / 2) - 0.05)}>
                    <boxGeometry args={[0.05, 0.1, 0.05]} />
                </mesh>
            ));
        case 'fec1e5c4b8424e6ca4fc247c1a2a5603': // STAR SPOT Strahler Duolare
            // noinspection RequiredAttributes
            return spotlights.map((spot, index) => (
                <SpotStar
                    key={'spot-star-' + index}
                    systemMaterial={systemMaterial}
                    additionalMaterial={linsenMaterial}
                    isHorizontal={spot.isHorizontal}
                    spotMode={spot.direction}
                    position={positionThreeFromPositionCM(spot.point, (roomHeight / 2) - 0.015)}
                />
            ));
        default:
            return spotlights.map((spot, index) => (
                <SpotLenxx
                    key={'spot-default-' + index}
                    systemMaterial={systemMaterial}
                    additionalMaterial={additionalMaterial}
                    isHorizontal={spot.isHorizontal}
                    spotMode={spot.direction}
                    position={positionThreeFromPositionCM(spot.point, (roomHeight / 2) - 0.015)}
                />
            ));
    }
}
