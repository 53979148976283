import {PositionThree, positionThreeFromPositionCM} from 'libs/view';
import React from 'react';

type WallProps = {
    isHorizontal: boolean,
    length: number,
    position: PositionThree,
    color?: string,
}

export const Wall = (
    {
        isHorizontal,
        length,
        position,
        color,
    }: WallProps): JSX.Element => {
    const meshColor = color || 'gray';

    const argsLength: PositionThree = isHorizontal ?
        positionThreeFromPositionCM({x: length,y: 10}, 0.1) :
        positionThreeFromPositionCM({x: 10, y: length}, 0.1);

    return (
        <mesh position={position}>
            <boxBufferGeometry args={argsLength} />
            <meshBasicMaterial color={meshColor} />
        </mesh>
    )
}
