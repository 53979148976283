import {lineDirection, lineIsHorizontal, lineIsVertical, moveLineBy, moveLineTo} from 'libs/models/line';
import {
    alignPositionHorizontalWith,
    alignPositionVerticalWith,
    findPositionByIndexNext,
    findPositionByIndexPrevious,
    Position
} from 'libs/models/position';
import {isPointInside} from 'libs/models/room/positionInRoom';
import {mapRoomFromPositions} from './mapRoom';
import {Room} from './room';

/**
 * Verschiebt eine Wand relativ
 */
export function moveRoomWallBy(room: Room, wallIndex: number, amount: number): Room {
    if (wallIndex < 0 || wallIndex >= room.walls.length || amount === 0) {
        return room;
    }
    const wallToMove = room.walls[wallIndex];
    const movedWall = moveLineBy(wallToMove, amount);

    const updatedPositions = room.corners.slice(0);
    updatedPositions[movedWall.from.id] = movedWall.from
    updatedPositions[movedWall.to.id] = movedWall.to

    return mapRoomFromPositions(updatedPositions);
}

/**
 * Verschiebt eine Wand absolut.
 */
export function moveRoomWallTo(room: Room, wallIndex: number, amount: number): Room {
    if (wallIndex < 0 || wallIndex >= room.walls.length) {
        return room;
    }
    const wallToMove = room.walls[wallIndex];
    const movedWall = moveLineTo(wallToMove, amount);

    const updatedPositions = room.corners.slice(0);
    updatedPositions[movedWall.from.id] = movedWall.from
    updatedPositions[movedWall.to.id] = movedWall.to

    return mapRoomFromPositions(updatedPositions);
}

/**
 * Verschiebt eine Ecke absolut.
 */
export function moveRoomCornerTo(room: Room, cornerIndex: number, newCornerPosition: Position): Room {
    if (cornerIndex < 0 || cornerIndex >= room.corners.length) {
        return room;
    }

    const currentCorner = room.corners[cornerIndex];
    const prevCorner = findPositionByIndexPrevious(room.corners, cornerIndex);
    const nextCorner = findPositionByIndexNext(room.corners, cornerIndex);

    const updatedPositions = room.corners.slice(0);

    updatedPositions[cornerIndex] = newCornerPosition;

    if (lineIsHorizontal({from: currentCorner, to: nextCorner})) {
        updatedPositions[nextCorner.id] = alignPositionHorizontalWith(nextCorner, newCornerPosition)
        updatedPositions[prevCorner.id] = alignPositionVerticalWith(prevCorner, newCornerPosition)
    } else {
        updatedPositions[prevCorner.id] = alignPositionHorizontalWith(prevCorner, newCornerPosition)
        updatedPositions[nextCorner.id] = alignPositionVerticalWith(nextCorner, newCornerPosition)
    }

    return mapRoomFromPositions(updatedPositions);
}

/**
 * Verschiebt alle Ecken
 * @param offset
 * @param room
 */
export function moveRoomPositionsByOffset(offset: number, room: Room): Position[] {

    const corners = [...room.corners];
    let movedPosition = {x: 0, y: 0};

    return corners.map((corner, index) => {

        if (index === 0) {
            movedPosition = {x: corner.x + offset, y: corner.y + offset};
            return movedPosition;
        }

        const previous = index > 0 ? corners[index - 1] : corners[corners.length - 1];
        const line = {from: previous, to: corner};
        const direction = lineDirection(line);

        if (lineIsHorizontal(line)) {
            if (isPointInside(room.walls, {...corner, x: corner.x + (offset * direction)})) {
                movedPosition = {...movedPosition, x: corner.x + (offset * direction)};
                return movedPosition;
            }
            movedPosition = {...movedPosition, x: corner.x - (offset * direction)};
            return movedPosition;

        }

        if (lineIsVertical(line)) {

            if (isPointInside(room.walls, {...corner, y: corner.y + (offset * direction)})) {
                movedPosition = {...movedPosition, y: corner.y + (offset * direction)};
                return movedPosition;
            }

            movedPosition = {...movedPosition, y: corner.y - (offset * direction)};
            return movedPosition;

        }

        return corner;

    });
}
