import {createSlice} from '@reduxjs/toolkit';
import {CartPrice} from 'libs/client/clientApi';
import {saveOrderProducts} from 'state/shop/shopApi';

export interface OrderState {
    price: CartPrice | null;
}

export const initialOrderState: OrderState = {
    price: null,
};

const orderSlice = createSlice({
    name: 'order',
    initialState: initialOrderState,
    reducers: {},
    extraReducers: (builder) => {
        // >>> load
        builder.addCase(saveOrderProducts.pending, () => {
            return initialOrderState;
        });
        builder.addCase(saveOrderProducts.fulfilled, (state, {payload}) => {
            state.price = payload
        });
        builder.addCase(saveOrderProducts.rejected, () => {
            return initialOrderState;
        });
    }
});

export const orderReducer = orderSlice.reducer;
