import {useAppSelector} from 'app/store/hooks';
import {selectEditMode} from 'state/view/viewSelectors';

export type EditMode = 'error' | 'type' | 'room' | 'outlet' | 'track' | 'light' | 'order';

export type ViewEditMode = null | EditMode;

export type ViewConfiguration = { editMode: ViewEditMode };

export type ComponentFn = () => JSX.Element | null;

export type EditModeComponentMap = Record<EditMode, ComponentFn>


export const useEditModeComponent = (componentMap: EditModeComponentMap): ComponentFn | null => {
    const currentEditMode = useAppSelector(selectEditMode);
    if (currentEditMode && componentMap[currentEditMode]) {
        return componentMap[currentEditMode];
    }
    return null;
}
