import {jsPDF} from 'jspdf';
import {GeneratePDFProps} from 'libs/models/order/pdf/PDFConfig';
import {createImage, createImgData} from 'libs/models/order/pdf/units/Image';

export function generatePDF({setPDF, entityId, canvas, spotlightAmount, knots}: GeneratePDFProps): void {
    if (canvas && canvas.getContext && entityId && spotlightAmount && knots) {
        const orderItemsListPDF = document.getElementById('orderItemsListPDF');
        if (orderItemsListPDF) {
            const pdf = new jsPDF('p', 'pt', 'a4', true);
            const image = createImage(pdf, canvas);
            const pdfWidth = pdf.internal.pageSize.getWidth() - 100;
            const pdfHeight = (image.height * pdfWidth) / image.width;

            pdf.addImage(createImgData(canvas), 'PNG', 0, 40, pdfWidth, pdfHeight);
            pdf.html(orderItemsListPDF, {
                callback: function (doc) {
                    setPDF(doc.output('datauristring', {filename: entityId + '.pdf'}));
                },
                image: {
                    type: 'png',
                    quality: 80,
                },
                y: pdfHeight + 40,
            })
        }
    }
}
