import {Box} from '@react-three/drei';
import {useAppSelector} from 'app/store/hooks';
import {Knot} from 'libs/models/knot';
import {Position} from 'libs/models/position';
import {directionRecordAsArray} from 'libs/models/types';
import {positionThreeFromPositionCM} from 'libs/view';
import React from 'react';
import {selectOrderOutletType} from 'state/order/orderSelectors';
import {selectTrackKnots} from 'state/track/trackSelectors';
import * as THREE from 'three';

type ThreeDOutletProps = {
    outletPosition: Position | null;
    roomHeight: number;
    systemMaterial: THREE.MeshStandardMaterial;
}

export const ThreeDOutlet = ({outletPosition, roomHeight, systemMaterial}: ThreeDOutletProps): JSX.Element => {
    const orderOutletType = useAppSelector(selectOrderOutletType);
    const knots: Knot[] = useAppSelector(selectTrackKnots);
    const outletKnot = knots.find((knot) => knot.id === 0);

    if (outletPosition) {
        const threeOutletPos = positionThreeFromPositionCM(outletPosition, (roomHeight / 2) - 0.005);
        const outlet = () => {
            if (orderOutletType === null) {
                // noinspection RequiredAttributes
                return (
                    <>
                        <Box
                            position={threeOutletPos}
                            args={[0.01, 0.01, 0.1]}
                            material={systemMaterial}
                        />
                        <Box
                            position={threeOutletPos}
                            args={[0.1, 0.01, 0.01]}
                            material={systemMaterial}
                        />
                    </>
                )
            }
            if (orderOutletType === 'eck') {
                // noinspection RequiredAttributes
                return (
                    <Box
                        position={threeOutletPos}
                        args={[0.1, 0.01, 0.1]}
                        material={systemMaterial}
                    />
                )
            }
            if (orderOutletType === 'end' || orderOutletType === 'mittel') {

                if (outletKnot) {
                    const top = outletKnot.connections.top;
                    const right = outletKnot.connections.right;
                    const bottom = outletKnot.connections.bottom;
                    const left = outletKnot.connections.left;

                    const emptyConnections = directionRecordAsArray(outletKnot.connections).filter(con => con === -1);

                    if (emptyConnections.length === 3) {
                        if ((top >= 0 || bottom >= 0)) {
                            // noinspection RequiredAttributes
                            return (
                                <Box
                                    position={threeOutletPos}
                                    args={[0.02, 0.01, 0.1]}
                                    material={systemMaterial}
                                />
                            )
                        }
                        if ((right >= 0 || left >= 0)) {
                            // noinspection RequiredAttributes
                            return (
                                <Box
                                    position={threeOutletPos}
                                    args={[0.1, 0.01, 0.02]}
                                    material={systemMaterial}
                                />
                            )
                        }
                    }
                    if (((top >= 0 && bottom >= 0) && (right < 0 && left < 0))) {
                        // noinspection RequiredAttributes
                        return (
                            <Box
                                position={threeOutletPos}
                                args={[0.02, 0.01, 0.1]}
                                material={systemMaterial}
                            />
                        )
                    }
                    if (((top < 0 && bottom < 0) && (right >= 0 && left >= 0))) {
                        // noinspection RequiredAttributes
                        return (
                            <Box
                                position={threeOutletPos}
                                args={[0.1, 0.01, 0.02]}
                                material={systemMaterial}
                            />
                        )
                    }
                }

                // noinspection RequiredAttributes
                return (
                    <Box
                        position={threeOutletPos}
                        args={[0.1, 0.01, 0.1]}
                        material={systemMaterial}
                    />
                )
            }
            return <></>;
        }

        return <>{outlet()}</>;
    }

    return <></>;
};
