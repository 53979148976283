import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ViewConfiguration, ViewEditMode} from './viewModels';


export interface ViewState {
    editMode: ViewEditMode;
    loading: boolean;
}


const defaultViewConfiguration: ViewConfiguration = {
    editMode: null,
};

export function extendDefaultView(config: Partial<ViewConfiguration>): ViewConfiguration {
    return {...defaultViewConfiguration, ...config};
}

export const initialViewState: ViewState = {
    editMode: null,
    loading: false,
};

const slice = createSlice({
    name: 'view',
    initialState: initialViewState,
    reducers: {
        disableEditMode: ((state) => {
            if (state.editMode !== null) {
                state.editMode = null;
            }
        }),
        updateViewConfiguration: (state, action: PayloadAction<ViewConfiguration>) => {
            if (state.editMode !== action.payload.editMode) {
                state.editMode = action.payload.editMode;
            }
        },
        updateLoadingState: (state, action: PayloadAction<boolean>) => {
            if (state.loading !== action.payload) {
                state.loading = action.payload;
            }
        }
    }
});

export const {
    disableEditMode,
    updateViewConfiguration,
    updateLoadingState
} = slice.actions;

export const viewReducer = slice.reducer;
