import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {deleteEntity, loadEntity, saveEntity} from 'state/entity/entityApi';

type SceneViewMode = 'three' | 'two';

export interface SceneState {
    viewMode: SceneViewMode;
    wallColor: string;
    roofColor: string;
    floorTexture: string;
}

export const initialSceneState: SceneState = {
    viewMode: 'two',
    wallColor: '#ffffff',
    roofColor: '#ffffff',
    floorTexture: 'ahorn.jpg'
};

const slice = createSlice({
    name: 'scene',
    initialState: initialSceneState,
    reducers: {
        toggleViewMode: ((state) => {
            state.viewMode = state.viewMode === 'three' ? 'two' : 'three'
        }),
        enableViewModeThree: ((state) => {
            if (!(state.viewMode === 'three')) {
                state.viewMode = 'three';
            }
        }),
        enableViewModeTwo: ((state) => {
            if (!(state.viewMode === 'two')) {
                state.viewMode = 'two';
            }
        }),
        setWallColor: ((state, {payload}: PayloadAction<{wallColor: string}>) => {
            const currentWallColor = state.wallColor;
            const changedWallColor = payload.wallColor;
            if (currentWallColor !== changedWallColor) {
                state.wallColor = changedWallColor;
            }
        }),
        setRoofColor: ((state, {payload}: PayloadAction<{roofColor: string}>) => {
            const currentRoofColor = state.roofColor;
            const changedRoofColor = payload.roofColor;
            if (currentRoofColor !== changedRoofColor) {
                state.roofColor = changedRoofColor;
            }
        }),
        setFloorTexture: ((state, {payload}: PayloadAction<{floorTexture: string}>) => {
            const currentFloorTexture = state.floorTexture;
            const changedFloorTexture = payload.floorTexture;
            if (currentFloorTexture !== changedFloorTexture) {
                state.floorTexture = changedFloorTexture;
            }
        }),
    },
    extraReducers: (builder) => {
        // >>> Load
        builder.addCase(loadEntity.fulfilled, (state, {payload}) => {
            state.wallColor = payload.data.scene.wallColor;
            state.roofColor = payload.data.scene.roofColor;
            state.floorTexture = payload.data.scene.floorTexture;
        });
        builder.addCase(loadEntity.rejected, () => {
            return initialSceneState;
        });

        // >>> Save
        builder.addCase(saveEntity.fulfilled, (state, {payload}) => {
            state.wallColor = payload.data.scene.wallColor;
            state.roofColor = payload.data.scene.roofColor;
            state.floorTexture = payload.data.scene.floorTexture;
        });
        builder.addCase(saveEntity.rejected, () => {
            return initialSceneState;
        });

        // >>> Delete
        builder.addCase(deleteEntity.fulfilled, () => {
            return initialSceneState;
        });
        builder.addCase(deleteEntity.rejected, () => {
            return initialSceneState;
        });
    },
});

export const {toggleViewMode, enableViewModeThree, enableViewModeTwo, setWallColor, setRoofColor, setFloorTexture} = slice.actions;
export const sceneReducer = slice.reducer;
