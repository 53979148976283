import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {nameForTrackLine} from 'app/config';
import {EntityData} from 'libs/client/clientApi';
import {Knot, KnotId} from 'libs/models/knot/knot';
import {
    createConnectedKnot,
    removeConnectionBetweenKnotsById,
    validatePositionOfMainKnot
} from 'libs/models/knot/knotConnection';
import {resizeKnotLineTo} from 'libs/models/knot/knotLines';
import {Room} from 'libs/models/room';
import {Direction} from 'libs/models/types';
import {NotificationProvider} from 'libs/notification/notificationProvider';
import {trackNotifications} from 'libs/notification/trackNotifications';
import {deleteEntity, loadEntity, saveEntity} from 'state/entity/entityApi';
import {stepResetPageTrack} from 'state/step/stepReset';

const addNotifyItem = NotificationProvider().addNotifyItem;

export interface TrackState {
    knots: Knot[];
    errorId: string | null;
}

export const initialTrackState: TrackState = {
    knots: [],
    errorId: null,
};

/**
 * Hilfsfunktion: Gleicht Knoten mit dem Stromauslass ab.
 */
function updateEntityData(state: Draft<TrackState>, entity: EntityData): void {
    const outletPosition = entity.outlet.position;
    if (outletPosition === null) {
        if (state.knots.length > 0) {
            state.knots = [];
        }
    } else {
        const entityKnots = entity.tracks.knots;
        state.knots = validatePositionOfMainKnot(entityKnots, outletPosition);
    }
}

const trackSlice = createSlice({
    name: 'track',
    initialState: initialTrackState,
    reducers: {
        addTrackKnot: (state, {payload}: PayloadAction<{ knotId: KnotId, direction: Direction, length: number, validTrackRoom: Room, room: Room }>) => {
            const currentKnots = state.knots;
            const trackValidation = createConnectedKnot(currentKnots, payload.knotId, payload.direction, payload.length, payload.validTrackRoom, payload.room);

            if (!trackValidation.isValid) {
                addNotifyItem(trackNotifications(trackValidation.type));
            }

            if (trackValidation.isValid && currentKnots !== trackValidation.knots) {
                state.knots = trackValidation.knots;
            }
        },
        setTrackLineSizeTo: (state, {payload}: PayloadAction<{ fromId: number, toId: number, length: number, room: Room, validTrackRoom: Room }>) => {
            const currentKnots = state.knots;
            const trackValidation = resizeKnotLineTo(currentKnots, payload.fromId, payload.toId, payload.length, payload.room, payload.validTrackRoom);

            if (!trackValidation.isValid) {
                addNotifyItem(trackNotifications(trackValidation.type));
                state.errorId = nameForTrackLine(payload.fromId, payload.toId);
            }
            if (trackValidation.isValid && trackValidation.type !== null) {
                addNotifyItem(trackNotifications(trackValidation.type));
            }

            if (trackValidation.isValid && currentKnots !== trackValidation.knots) {
                state.knots = trackValidation.knots;
            }
        },
        removeTrackBetweenKnots: (state, {payload}: PayloadAction<{ fromId: KnotId, toId: KnotId }>) => {
            const currentKnots = state.knots;
            const updatedKnots = removeConnectionBetweenKnotsById(currentKnots, payload.fromId, payload.toId);
            if (currentKnots !== updatedKnots) {
                state.knots = updatedKnots;
            }
        },
        resetTrackErrorId: (state) => {
            state.errorId = null;
        }
    },
    extraReducers: (builder) => {
        // >>> Reset
        builder.addCase(stepResetPageTrack.fulfilled, (state) => {
            state.knots = [];
        });

        // >>> Load
        builder.addCase(loadEntity.fulfilled, (state, {payload}) => {
            updateEntityData(state, payload.data);
        });
        builder.addCase(loadEntity.rejected, (state) => {
            state.knots = [];
        });

        // >>> Save
        builder.addCase(saveEntity.fulfilled, (state, {payload}) => {
            updateEntityData(state, payload.data);
        });
        builder.addCase(saveEntity.rejected, (state) => {
            state.knots = [];
        });

        // >>> Delete
        builder.addCase(deleteEntity.fulfilled, (state) => {
            state.knots = [];
        });
        builder.addCase(deleteEntity.rejected, (state) => {
            state.knots = [];
        });
    },
});

export const {addTrackKnot, setTrackLineSizeTo, removeTrackBetweenKnots, resetTrackErrorId} = trackSlice.actions;
export const trackReducer = trackSlice.reducer;
