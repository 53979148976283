import {knotMinimumDistanceToKnot} from 'app/config/track';
import {findKnotById, findKnotConnectionDirection} from 'libs/models/knot/findKnot';
import {Knot, mainKnotId, noKnotId} from 'libs/models/knot/knot';
import {createKnotPositionTable, createKnotTable, KnotTable} from 'libs/models/knot/knotCollection';
import {
    doesTrackIntersectWithoutSelfAndEdges,
    isLineTooCloseToAnotherWithoutSelfAndEdges,
    isLineTooCloseToKnotWithoutParentAndChildKnot
} from 'libs/models/knot/knotConnection';
import {IndexedLine, lineLength} from 'libs/models/line';
import {movePositionByOffsetInDirection} from 'libs/models/position/movePosition';
import {Room} from 'libs/models/room';
import {
    createDirectionNeighborPredicate,
    Direction,
    directionOpposite,
    directionRecordAsArray,
    directionRecordAsPairs
} from 'libs/models/types';
import {doesTrackIntersectWithInline, doesTrackIntersectWithKnot,} from 'libs/models/validation';
import {TrackValidation} from 'libs/notification/trackNotifications';

/**
 * Erzeugt eine Liste mit Linien aus den Verbindungen zwischen den Knoten.
 */
export function mapKnotConnectionsToUniqueLines(knots: Knot[]): IndexedLine[] {
    const lookup = createKnotPositionTable(knots);
    const collectedLines: { [key: string]: IndexedLine } = knots.reduce((acc: { [key: string]: IndexedLine }, curr) => {
        directionRecordAsArray(curr.connections)
            .filter((id) => !!lookup[id])
            .forEach((connectedId) => {
                const minId = Math.min(curr.id, connectedId);
                const maxId = Math.max(curr.id, connectedId);
                const lineId = minId + '-' + maxId;
                if (!acc[lineId]) {
                    acc[lineId] = {
                        from: lookup[curr.id],
                        to: lookup[connectedId]
                    };
                }
            });

        return acc;
    }, {});

    return Object.values(collectedLines);
}

export function resizeKnotLineTo(knots: Knot[], fromKnotId: number, toKnotId: number, length: number, room: Room, validTrackRoom: Room): TrackValidation {
    if (fromKnotId === noKnotId || toKnotId === noKnotId || toKnotId === mainKnotId) {
        return {isValid: false, knots: knots, type: null};
    }

    if (length < knotMinimumDistanceToKnot) {
        return {isValid: false, knots: knots, type: 'invalid-track-length-short'};
    }

    const fromKnot = findKnotById(knots, fromKnotId);
    if (fromKnot === null) {
        return {isValid: false, knots: knots, type: null};
    }

    const toKnot = findKnotById(knots, toKnotId);
    if (toKnot === null) {
        return {isValid: false, knots: knots, type: null};
    }

    let direction = findKnotConnectionDirection(fromKnot, toKnot.id);
    if (direction === null) {
        return {isValid: false, knots: knots, type: null};
    }
    const line: IndexedLine = {
        from: {...fromKnot.position, id: fromKnot.id},
        to: {...toKnot.position, id: toKnot.id}
    };
    const currentLength = lineLength(line);
    const offsetLength = length - currentLength;
    const knotTable = createKnotTable(knots);

    let affectedKnots = [
        toKnot,
        ...findKnotTreeAffectedByMovementInDirection(knotTable, toKnot.id, direction)
    ];

    const mainKnot = findKnotById(affectedKnots, mainKnotId);
    if (mainKnot !== null) {
        // Der Hauptkonoten (Stromauslass) darf nicht verschoben werden.
        direction = directionOpposite(direction);
        affectedKnots = [
            fromKnot,
            ...findKnotTreeAffectedByMovementInDirection(knotTable, fromKnot.id, direction)
        ];
        // return {isValid: false, knots: knots, type: null};
    }

    affectedKnots.forEach((affectedKnot) => {
        if (direction) {
            knotTable[affectedKnot.id] = {
                ...affectedKnot,
                position: {
                    ...affectedKnot.position,
                    ...movePositionByOffsetInDirection(affectedKnot.position, offsetLength, direction)
                }
            };
        }
    });

    const changedKnots = Object.values(knotTable);
    const changedTtrackLines = mapKnotConnectionsToUniqueLines(changedKnots);
    const maxChangedTrackLinesIndex = changedTtrackLines.length - 1;
    let i = 0;

    while (i <= maxChangedTrackLinesIndex) {
        if (doesTrackIntersectWithInline(room.walls, changedTtrackLines[i])) {
            return {isValid: false, knots: knots, type: 'invalid-position-not-in-room'};
        }
        if (doesTrackIntersectWithInline(validTrackRoom.walls, changedTtrackLines[i])) {
            return {isValid: false, knots: knots, type: 'invalid-distance-to-wall'};
        }
        if (isLineTooCloseToKnotWithoutParentAndChildKnot(changedKnots, changedTtrackLines[i], changedTtrackLines[i].to.id, changedTtrackLines[i].from.id)) {
            return {isValid: false, knots: knots, type: 'invalid-distance-track-to-knot'};
        }
        if (doesTrackIntersectWithKnot(changedKnots, changedTtrackLines[i], changedTtrackLines[i].to.id, changedTtrackLines[i].from.id)) {
            return {isValid: false, knots: knots, type: 'invalid-track-intersect-with-knot'};
        }
        if (doesTrackIntersectWithoutSelfAndEdges(changedTtrackLines, changedTtrackLines[i])) {
            return {isValid: false, knots: knots, type: 'invalid-track-intersect-with-track'};
        }
        if (isLineTooCloseToAnotherWithoutSelfAndEdges(changedTtrackLines, changedTtrackLines[i])) {
            return {isValid: false, knots: knots, type: 'invalid-distance-track-to-track'};
        }
        i++;
    }

    return {isValid: true, knots: changedKnots, type: null};
}

/**
 * Findet die Knoten, die durch das verschieben eines anderen Knoten betroffen sind.
 *
 * Es werden rekusrsiv alle verbundenen "Nachbar" Knoten zurückgegeben.
 *
 * Gibt eine leere Menge zurück wenn:
 * - die KnotenId nicht verbunden ist (-1)
 * - die KnotenId der Hauptknoten ist (0)
 * - die KnotenId nicht in der Tabelle ist
 *
 */
export function findKnotTreeAffectedByMovementInDirection(knotTable: KnotTable, movedKnotId: number, direction: Direction): Knot[] {
    if (movedKnotId === noKnotId || movedKnotId === mainKnotId) {
        return [];
    }
    if (!knotTable[movedKnotId]) {
        return [];
    }


    const isNeighbor = createDirectionNeighborPredicate(direction);
    const startKnot = knotTable[movedKnotId];
    const visited: { [key: number]: boolean } = {
        [startKnot.id]: true,
    };

    function recurse(currentKnot: Knot): Knot[] {

        return directionRecordAsPairs(currentKnot.connections)
            .filter((pair) => {
                if (visited[pair.connected]) {
                    return false;
                }
                visited[pair.connected] = true;
                return isNeighbor(pair.direction)
                    && knotTable[pair.connected];

            })
            .flatMap((pair): Knot[] => {
                const child = knotTable[pair.connected];
                return [child, ...recurse(child)];
            });
    }

    return recurse(startKnot)
        .sort((a, b) => a.id - b.id);
}
