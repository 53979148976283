import {Position} from 'libs/models/position';
import {Direction} from 'libs/models/types';
import {Line, PositionLine} from './line';


export function buildLineInDirection(from: Position, length: number, direction: Direction): Line<Position> {
    if (direction === 'top') {
        return buildLineVertical(from, (length * -1));
    }
    if (direction === 'right') {
        return buildLineHorizontal(from, length);
    }
    if (direction === 'bottom') {
        return buildLineVertical(from, length);
    }
    return buildLineHorizontal(from, (length * -1));
}


/**
 * Erstellt eine horizontale Linie vom Startpunkt mit Länge.
 *
 * @param from
 * @param length
 */
export function buildLineHorizontal(from: Position, length: number): PositionLine {
    const {x, y} = from;
    return {
        from: {x, y},
        to: {x: (x + length), y}
    }
}

/**
 * Erstellt eine vertikale Linie vom Startpunkt mit Länge.
 *
 * @param from
 * @param length
 */
export function buildLineVertical(from: Position, length: number): PositionLine {
    const {x, y} = from;
    return {
        from: {x, y},
        to: {x, y: (y + length)}
    }
}


