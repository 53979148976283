import {Html} from '@react-three/drei';
import {useThree} from '@react-three/fiber';
import {htmlDistanceFactor} from 'app/config/3DParams';
import {lightsIconStyles} from 'components/scene/light/edit/styles/styles';
import {Spotlight} from 'libs/models/light/light';
import {IndexedLine, Line, lineIsHorizontal, lineIsVertical} from 'libs/models/line';
import {Position} from 'libs/models/position';
import {validatePositionAIsInTheOffsetOfLine} from 'libs/models/validation/lineValidation';
import {PositionThree, positionThreeFromPositionCM} from 'libs/view';
import {convertMouseToPositionInCM} from 'libs/view/mouse';
import React, {useEffect, useState} from 'react';
import {useGesture} from 'react-use-gesture';
import {tw} from 'twind/css';

type SpotlightsProps = {
    trackLines: IndexedLine[],
    spotlights: Spotlight[],
    changePosition: (spotlight: Spotlight, position: PositionThree) => void
}

export const DraggableSpotlights = ({trackLines, spotlights, changePosition}: SpotlightsProps): JSX.Element => {
    const draggableSpotlights = spotlights.map((spotlight, index): JSX.Element => (
        <DraggableSpotlight
            key={index + '-spotlight'}
            position={spotlight.position}
            trackLines={trackLines}
            changePosition={(pos) => changePosition(spotlight, pos)}
        />
    ));

    return (
        <>
            {draggableSpotlights}
        </>
    );
}

type SpotlightProps = {
    trackLines: IndexedLine[],
    position: PositionThree,
    changePosition: (position: PositionThree) => void
}

export const DraggableSpotlight = ({trackLines, position, changePosition}: SpotlightProps): JSX.Element => {
    const [positionThree, setPositionThree] = useState<PositionThree>(position)
    const [activeLine, setActiveLine] = useState<Line<Position>>()
    const {mouse, viewport, camera} = useThree();
    const [isMouseOver, setMouse] = useState(false);

    useEffect(() => {
        document.body.style.cursor = isMouseOver ? 'grabbing' : 'auto'
    }, [isMouseOver])

    useEffect(() => {
        setPositionThree(position)
    }, [position])

    const isOnActiveLine = (dragPos: Position) => {
        trackLines.forEach((line) => {
            if (validatePositionAIsInTheOffsetOfLine(line, dragPos, 10)) {
                setActiveLine(line)
            }
        })
    }

    const calculatePositionByActiveLine = (dragPos: Position): Position => {
        if (activeLine) {
            if (lineIsHorizontal(activeLine)) {
                return {
                    x: minMaxX(dragPos, activeLine),
                    y: activeLine.from.y
                }
            }
            if (lineIsVertical(activeLine)) {
                return {
                    x: activeLine.from.x,
                    y: minMaxY(dragPos, activeLine)
                }
            }
        }
        return dragPos;
    }


    const dragMe = () => {
        const mouseCM = convertMouseToPositionInCM({mouse, viewport, camera});
        isOnActiveLine(mouseCM)
        setPositionThree(positionThreeFromPositionCM(calculatePositionByActiveLine(mouseCM)))
    }

    const bindUseGesture = useGesture({
        onDrag: () => dragMe(),
        onDragEnd: () => {
            changePosition(positionThree)
            setPositionThree(position);
        }
    })

    /* eslint-disable @typescript-eslint/no-explicit-any */
    // noinspection RequiredAttributes
    return (
        <group {...bindUseGesture() as any} position={positionThree}>
            <mesh
                onPointerEnter={() => setMouse(true)}
                onPointerLeave={() => setMouse(false)}
            >
                <boxBufferGeometry args={[0.25, 0, 0.25]}/>
                <meshBasicMaterial opacity={0} transparent={true}/>
            </mesh>
            <Html distanceFactor={htmlDistanceFactor} className={isMouseOver? tw(lightsIconStyles) + ' dragged' : tw(lightsIconStyles)}>
                <div className={'icon-container'}>
                    <svg version="1.1"
                         xmlns="http://www.w3.org/2000/svg"
                         x="0px" y="0px"
                         width={24}
                         height={24}
                         viewBox="0 0 24 24"
                    >
                        <path className={tw('fill-none')} d="M0,0h24v24H0V0z"/>
                        <polygon points="22.4,12 18.4,8 18.4,11 17.1,11 12.8,11 12.8,6.6 12.8,5.3 15.8,5.3 11.8,1.4 7.8,5.3 10.8,5.3 10.8,6.6 10.8,11 6.4,11 5.1,11 5.1,8 1.1,12 5.1,16 5.1,13 6.4,13 10.8,13 10.8,17.4 10.8,18.7 7.8,18.7 11.8,22.6 15.8,18.7 12.8,18.7 12.8,17.4 12.8,13 17.1,13 18.4,13 18.4,16 "/>
                    </svg>
                </div>
            </Html>
        </group>
    )
}

const minMaxX = (pos: Position, line: Line<Position>) => {
    if (line) {
        return getValidNumber(pos.x, line.from.x, line.to.x)
    }
    return pos.x
}
const minMaxY = (pos: Position, line: Line<Position>) => {
    if (line) {
        return getValidNumber(pos.y, line.from.y, line.to.y)
    }
    return pos.y
}

const getValidNumber = (pos: number, from: number, to: number) => {
    const max = Math.max(from, to)
    const min = Math.min(from, to)

    if (pos > max) {
        return max
    }
    if (pos < min) {
        return min
    }
    return pos
}
