import {mapLinesIndexedFromPositions} from 'libs/models/line';
import {Position} from 'libs/models/position';
import {Room} from './room';


export function mapRoomFromPositions(positions: Position[]): Room {
    return {
        corners: positions,
        walls: mapLinesIndexedFromPositions(positions)
    };
}
