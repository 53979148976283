import {wallSizeConfig} from 'app/config/wallSize';
import {Line, lineDirection, lineLength, lineOrientation} from 'libs/models/line';
import {isInnerLine} from 'libs/models/line/innerLine';
import {equalPosition, meassurePositionsMinMaxValues, Position, PositionsMinMax} from 'libs/models/position';
import {doIntersect, Room} from 'libs/models/room';
import {isValidLength} from 'libs/models/validation/validation';
import {RoomValidation} from 'libs/notification/roomNotifications';

/**
 * Prüfen, ob ein neu geformter Raum valide ist
 * @param currentRoom
 * @param resizedRoom
 */
export function isValidRoom(currentRoom: Room, resizedRoom: Room): RoomValidation {

    const minMaxValues = meassurePositionsMinMaxValues(resizedRoom.corners)
    const minMaxValuesCurrent = meassurePositionsMinMaxValues(currentRoom.corners)

    if (!isValidWallLength(resizedRoom.walls)) {
        return {isValid: false, type: 'invalid-length'};
    }

    if (!isDistanceGreaterthanMin(resizedRoom.walls, minMaxValues)) {
        return {isValid: false, type: 'invalid-length'};
    }

    if (doesInnerLineIntersect(resizedRoom.walls, minMaxValuesCurrent)) {
        return {isValid: false, type: 'invalid-intersection'};
    }

    if (directionChanged(currentRoom, resizedRoom)) {
        return {isValid: false, type: 'invalid-direction'};
    }

    return {isValid: true, type: null};
}

export function isDistanceGreaterthanMin(walls: Line<Position>[], minMaxValues: PositionsMinMax): boolean {
    const innerLines = walls.filter(wall => isInnerLine(wall, minMaxValues));

    if(innerLines.length < 3) {
        return true;
    }

    return innerLines.every((line) => {
        const orientation = lineOrientation(line);

        if (orientation.isHorizontal && isOuterWallConflict(line, minMaxValues, walls)) {
            return (line.from.y >= (minMaxValues.minY + wallSizeConfig.min)) && (line.from.y <= (minMaxValues.maxY - wallSizeConfig.min));
        }

        return true;
    });
}

export function isOuterWallConflict(line: Line<Position>, minMaxValues: PositionsMinMax, walls: Line<Position>[]): boolean {

    return walls.some((wall) => {
        return doIntersect(wall, {from: {x: line.from.x, y: minMaxValues.minY}, to: {x: line.to.x, y: minMaxValues.minY}})
    })

}

/**
 * Prüft, ob eine Linie seine mathematische Richtung ändert
 * @param currentRoom
 * @param resizedRoom
 */
export function directionChanged(currentRoom: Room, resizedRoom: Room): boolean {
    return !resizedRoom.walls.every((wall, index) => {
        return lineDirection(wall) === lineDirection(currentRoom.walls[index]);
    })
}

/**
 * Prüft, ob eine innere Line, eine äußere schneidet
 * @param walls
 * @param minMaxValues
 */
export function doesInnerLineIntersect(walls: Line<Position>[], minMaxValues: PositionsMinMax): boolean {

    const innerLines = walls.filter(wall => isInnerLine(wall, minMaxValues));
    const outerLines = walls.filter(wall => !isInnerLine(wall, minMaxValues));

    return innerLines.some(innerLine => {
        return outerLines.some(outerLine => {
            if (!equalPosition(outerLine.from, innerLine.to) && !equalPosition(outerLine.to, innerLine.from)) {
                return doIntersect(innerLine, outerLine);
            }
            return false;
        })
    })

}

/**
 * Prüft, ob die Längen aller Linien valide sind
 * @param walls
 */
export function isValidWallLength(walls: Line<Position>[]): boolean {

    return walls.every((wall) => {
        const length = lineLength(wall);

        return isValidLength(wallSizeConfig, length);

    })
}
