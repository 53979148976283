import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {EditablePowerOutlet} from 'components/common/3d/outlet/EditablePowerOutlet';
import {Position} from 'libs/models/position';
import React from 'react';
import {setOutletPosition} from 'state/outlet/outletReducer';
import {selectOutletDistanceLines, selectOutletPosition} from 'state/outlet/outletSelectors';
import {DistanceToPowerOutlet} from '../../../common/3d/outlet/DistanceToPowerOutlet';
import {RoomInlines} from 'components/scene/room/RoomInlines';
import {outletMinimumDistanceToWall} from 'app/config/outlet';

export const OutletEditor = (): JSX.Element => {
    const outletPosition = useAppSelector(selectOutletPosition);
    const outletAxesDistanceLines = useAppSelector(selectOutletDistanceLines);
    const dispatch = useAppDispatch();

    const onOutletPositionChanged = (position: Position) => {
        dispatch(setOutletPosition(position));
    };

    return (
        <>
            <RoomInlines minimumDistance={outletMinimumDistanceToWall} />
            <EditablePowerOutlet position={outletPosition} onPositionChange={onOutletPositionChanged} />
            <DistanceToPowerOutlet distanceLines={outletAxesDistanceLines} position={outletPosition} />
        </>
    )
}
