import {useAppSelector} from 'app/store/hooks';
import {ResetLightAction} from 'components/actions/light/ResetLightAction';
import {SaveLightAction} from 'components/actions/light/SaveLightAction';
import {selectStepNeedsResetPageLight} from 'state/step/selectStepNeedReset';

export const LightActions = (): JSX.Element => {
    const needsReset = useAppSelector(selectStepNeedsResetPageLight);
    if (needsReset) {
        return (
            <ResetLightAction />
        );
    }

    return (
        <SaveLightAction />
    );
}
