import {Line} from '@react-three/drei';
import {useAppSelector} from 'app/store/hooks';
import {mapPositionsToWallSegments} from 'libs/models/position/mapPositions';
import {moveRoomPositionsByOffset} from 'libs/models/room';
import {selectRoom} from 'state/room/roomSelectors';

export type RoomInlinesProps = {
    minimumDistance: number;
}

export const RoomInlines = ({minimumDistance}: RoomInlinesProps): JSX.Element => {
    const room = useAppSelector(selectRoom);

    const movedPositions = moveRoomPositionsByOffset(minimumDistance, room);
    const wallSegmentsForInnerLines = mapPositionsToWallSegments(movedPositions);

    const inlines = wallSegmentsForInnerLines.map((wall) => (
        <Line
            key={wall.index + '-inline'}
            color={'#ff0000'}
            points={[wall.from, wall.to]}
            opacity={0.7}
        />
    ));

    return (
        <>
            {inlines}
        </>
    )
};
