import {Html} from '@react-three/drei';
import {htmlDistanceFactor} from 'app/config/3DParams';
import {useAppSelector} from 'app/store/hooks';
import {trackKnotMenuStyles} from 'components/scene/track/edit/styles/styles';
import {positionThreeFromPositionCM} from 'libs/view';
import React from 'react';
import {selectTrackKnots} from 'state/track/trackSelectors';
import {tw} from 'twind/css';

export const OutletView = (): JSX.Element | null => {
    const trackKnots = useAppSelector(selectTrackKnots);
    const outletView = trackKnots.filter(knot => knot.id === 0).map((knot, idx): JSX.Element => {
        // noinspection RequiredAttributes
        return (
            <Html position={positionThreeFromPositionCM(knot.position, 0.01)}
                  distanceFactor={htmlDistanceFactor}
                  key={idx + '-knot'}
                  center>
                <div className={tw(trackKnotMenuStyles)}>
                    <div className={'icon-container'}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 25.9 22.5"
                             width={26}
                             height={26}
                        >
                            <path
                                className={'fill-currentColor'}
                                d="M12.9,0L0,22.5h25.9L12.9,0z M12.9,3.8l9.3,16.7H3.6L12.9,3.8z"
                            />
                            <polygon
                                className={'fill-currentColor'}
                                points="10.5,14.3 12.7,14.3 12.1,19.2 15.4,12.4 13.2,12.4 13.8,7.5 "
                            />
                        </svg>
                    </div>
                </div>
            </Html>
        );
    });
    return (
        <>
            {outletView}
        </>
    );
};
