import * as React from 'react';
import {NavLink, NavLinkProps, To} from 'redux-first-router-link';
import {tw} from 'twind/css';
import {styles} from 'components/navigation/styles/NavigationItemStyles';


export interface NavigationItemProps extends Omit<NavLinkProps, 'to'> {
    route: To | null;
    showInNavigation: boolean;
    label: string;
}

export const NavigationItem = (
    {
        route,
        label,
        showInNavigation,
        isActive,
        children,
        ...props
    }: NavigationItemProps): JSX.Element => {
    if (!route || !showInNavigation) {
        return (
            <div className={tw(styles)} {...props}>
                {children}
                <span>{label}</span>
            </div>
        );
    }
    return (
        <NavLink to={route} className={tw(styles)} isActive={isActive} {...props}>
            {children}
            <span>{label}</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className={'checkmark-icon fill-black'}
            >
                <path d="M0 0h24v24H0z" className={'fill-none'}/>
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
            </svg>

        </NavLink>
    );
}
