import React from 'react';
import {tw} from 'twind/css';

export const OrderNavigationSvg = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             version="1.1"
             x="0px" y="0px"
             viewBox="0 0 22.8 21.3"
             className={tw('mb-1') + ' order-icon'}
             width={24}
             height={24}
             {...props}
        >
            <path className={tw('fill-current')}
                  d="M20.6,11.8l0.8-6.8L4.7,3.1V0H0.1v2h2.6v11.8h18.1v1.7H2.7v2h20.1v-5.6H20.6z M19.2,6.8l-0.6,5H4.7V5.1  L19.2,6.8z"/>
            <path className={tw('fill-current')}
                  d="M7.2,18.5c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C8.7,19.1,8,18.5,7.2,18.5z"/>
            <path className={tw('fill-current')}
                  d="M19.1,18.5c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C20.5,19.1,19.9,18.5,19.1,18.5  z"/>
        </svg>
    );
};
