import React from 'react';
import {tw} from 'twind/css';

export const TrackNavigationSvg = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 26 20.48"
             className={tw('mb-1') + ' track-icon'}
             width={28}
             height={28}
             {...props}>
            <g transform="translate(-240 -242.68)">
                <path
                    d="M260.59,249.21v-6.53H245.53v6.53H240v2h5.53v6.53H252v5.42h2v-5.42h6.59v-6.53H266v-2ZM259,256.16H247.11V244.27H259Z"
                    fill="currentColor" />
            </g>
        </svg>
    );
};
