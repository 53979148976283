import {loadPresetEntityIfEmpty} from 'state/entity/entityPreset';
import {createPageRoute} from 'state/location/routing/pageRouting';
import {extendDefaultView} from 'state/view/viewReducer';
import {createEditPageRoute} from 'state/location/routing/editPageRouting';


/**
 * Action: Route zur Seite "Raumform".
 */
export const pageSelectType = createPageRoute('selectType', {
    view: extendDefaultView({
        editMode: 'type',
    }),
    loader: (payload, ctx) => {
        return ctx.dispatch(loadPresetEntityIfEmpty());
    }
});

/**
 * Action: Route zur Seite "Grundriss".
 */
export const pageEditRoom = createEditPageRoute(
    'editRoom',
    'room'
);

/**
 * Action: Route zur Seite "Auslass".
 */
export const pageEditOutlet = createEditPageRoute(
    'editOutlet',
    'outlet'
);

/**
 * Action: Route zur Seite "Schienen".
 */
export const pageEditTrack = createEditPageRoute(
    'editTrack',
    'track'
);


/**
 * Action: Route zur Seite "Strahler".
 */
export const pageEditLight = createEditPageRoute(
    'editLight',
    'light'
);

/**
 * Action: Route zur Seite "Bestellen".
 */
export const pageEditOrder = createEditPageRoute(
    'editOrder',
    'order'
);

