import {ShopClient} from 'libs/shop/shopClient';
import {createShopwareClient} from 'libs/shop/shopwareClient';
import {appEnvironment} from './environment';
import axios from 'axios';


export const shopBuildPublicUrl = (path: string): string => {
    return appEnvironment.shopwarePublicUrl + path;
}


export const shopClient: ShopClient = createShopwareClient({
    axios: axios.create({
        headers: {
            'Accept': ' application/json',
            'Content-Type': 'application/json',
            'sw-access-key': appEnvironment.shopwareAccessKey,
            'sw-inheritance': '1',
        }
    })
});
