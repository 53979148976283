import {Tooltip} from 'components/common/tooltip/Tooltip';

import React from 'react';


export const OutletToolTip = (): JSX.Element => {
    return (
        <Tooltip title={'Hinweis zum Anschluss'} setDisbandTime={5000}>
            Beim Stromauslass handelt es sich um den Stromanschluss, an dem die Schienen beginnen werden.<br/>
            Diesen können Sie mithilfe des weißen Kreis per Drag & Drop verschieben.
        </Tooltip>
    );
};
