import {htmlDistanceFactor} from 'app/config/3DParams';
import React, {useEffect, useState} from 'react';
import {Html} from '@react-three/drei';
import {outletMeshStyles} from 'components/common/3d/outlet/styles/styles';
import {tw} from 'twind/css';

export const OutletMesh = (): JSX.Element => {
    const [isMouseOver, setMouse] = useState(false);

    useEffect(() => {
        document.body.style.cursor = isMouseOver ? 'grabbing' : 'auto'
    }, [isMouseOver])

    // noinspection RequiredAttributes
    return (
        <group>
            <mesh
                onPointerEnter={() => setMouse(true)}
                onPointerLeave={() => setMouse(false)}
            >
                <boxBufferGeometry args={[0.25, 0, 0.25]} />
                <meshBasicMaterial transparent opacity={0} />
            </mesh>
            <Html
                center
                className={isMouseOver? tw(outletMeshStyles) + ' dragged' : tw(outletMeshStyles)}
                distanceFactor={htmlDistanceFactor}
            >
                <div className={'icon-container'}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 25.9 22.5"
                         width={26}
                         height={26}
                    >
                        <path
                            d="M12.9,0L0,22.5h25.9L12.9,0z M12.9,3.8l9.3,16.7H3.6L12.9,3.8z"
                        />
                        <polygon
                            points="10.5,14.3 12.7,14.3 12.1,19.2 15.4,12.4 13.2,12.4 13.8,7.5 "
                        />
                    </svg>
                </div>
            </Html>
        </group>
    );
}
