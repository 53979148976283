import {createAsyncThunk} from '@reduxjs/toolkit';
import {apiClient} from 'app/config/apiClient';
import {AppAsyncThunkConfig} from 'app/store/store';
import {EntityId, EntityResource} from 'libs/client/clientApi';
import {selectEntityData, selectEntityId} from 'state/entity/entitySelectors';
import {pageErrorEntityNotFound} from 'state/location/errorRoutes';

export const saveEntity = createAsyncThunk<EntityResource, void, AppAsyncThunkConfig>(
    'entity/saveEntity',
    async (_, thunkAPI) => {
        const entityId = selectEntityId(thunkAPI.getState());
        if (!entityId) {
            return thunkAPI.rejectWithValue('No entityId in state');
        }
        const data = selectEntityData(thunkAPI.getState());

        return await apiClient.updateEntity(entityId, data);
    }
);

export const loadEntity = createAsyncThunk<EntityResource, EntityId, AppAsyncThunkConfig>(
    'entity/loadEntity',
    async (entityId, thunkAPI) => {
        try {
            return await apiClient.findEntity(entityId);
        } catch (e) {
            thunkAPI.dispatch(pageErrorEntityNotFound.route())
            return thunkAPI.rejectWithValue('FOO');
        }
    }
);

export const deleteEntity = createAsyncThunk<boolean, void, AppAsyncThunkConfig>(
    'entity/deleteEntity',
    () => {
        return true;
    }
)
