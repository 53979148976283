import {nameForTrackLine} from 'app/config';
import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {NumberInputConfirm, NumberInputEventArgs, NumberInputEventHandler} from 'components/common/forms/NumberInput';
import {PanelEditContainer} from 'components/common/panel/PanelEditContainer';
import {PanelEditItem} from 'components/common/panel/PanelEditItem';
import {KnotId} from 'libs/models/knot';
import {IndexedLine, lineLength} from 'libs/models/line';
import React, {useEffect, useState} from 'react';
import {selectRoom} from 'state/room/roomSelectors';
import {removeTrackBetweenKnots, resetTrackErrorId, setTrackLineSizeTo} from 'state/track/trackReducer';
import {selectTrackErrorId, selectTrackLines, selectTrackValidationRoom} from 'state/track/trackSelectors';
import {deleteIconStyles} from 'components/panel/track/styles/styles';
import {tw} from 'twind/css';

export const EditTrackPanel = (): JSX.Element => {
    const tracks = useAppSelector(selectTrackLines);
    const room = useAppSelector(selectRoom);
    const errorId = useAppSelector(selectTrackErrorId);
    const validTrackRoom = useAppSelector(selectTrackValidationRoom);
    const dispatch = useAppDispatch();

    const handleTrackChange = ({value}: NumberInputEventArgs, line: IndexedLine) => {
        const payload = {
            fromId: line.from.id,
            toId: line.to.id,
            length: value,
            room: room,
            validTrackRoom: validTrackRoom
        };
        dispatch(setTrackLineSizeTo(payload));
    };

    const resetError = () => {
        dispatch(resetTrackErrorId());
    }

    const removeTrack = (fromId: KnotId, toId: KnotId) => {
        dispatch(removeTrackBetweenKnots({fromId, toId}));
    };

    const editors = tracks
        .sort((a, b) => nameForTrackLine(a.from.id, a.to.id).localeCompare(nameForTrackLine(b.from.id, b.to.id)))
        .map((track, idx) => {
        const title = nameForTrackLine(track.from.id, track.to.id);
        return (
            <TrackEditor key={idx}
                         title={title}
                         line={track}
                         resetError={resetError}
                         errorId={errorId}
                         onNumberChange={args => handleTrackChange(args, track)}
                         removeConnection={removeTrack}
            />
        );
    });

    return (
        <PanelEditContainer title={'Schienen'}>
            {editors}
        </PanelEditContainer>
    );
};


type TrackEditorProps = {
    title: string;
    line: IndexedLine;
    onNumberChange: NumberInputEventHandler;
    removeConnection: (fromId: number, toId: number) => void;
    resetError: () => void;
    errorId: string | null;
};


const TrackEditor = ({title, line, onNumberChange, removeConnection, resetError, errorId}: TrackEditorProps): JSX.Element => {
    const length = lineLength(line);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [value, setValue] = useState(length);

    useEffect(() => {
        setValue(lineLength(line));
    }, [line]);

    useEffect(() => {
        if (errorId !== null && errorId === title) {
            setValue(lineLength(line));
            resetError();
        }
    }, [errorId, line, resetError, title]);

    const deleteLine = () => {
        removeConnection(line.from.id, line.to.id);
    };

    const handleClickEvent = () => {
        setIsDisabled(true);
        if (onNumberChange) {
            onNumberChange({value: value});
        }
    };

    const numberHasChanges = (length: number) => {
        setIsDisabled(false);
        setValue(length);
    };

    return (
        <PanelEditItem title={title} label={'cm'} variant={'input'} handleClickEvent={handleClickEvent}
                       isDisabled={isDisabled}
        >
            <NumberInputConfirm
                value={value}
                onNumberChange={({value}) => numberHasChanges(value)}
                confirmNumberChange={handleClickEvent}
            />
            <button
                onClick={deleteLine}
                className={tw(deleteIconStyles)}
            >
                <svg
                    viewBox="0 0 20 20"
                    width={12}
                    height={12}
                >
                    <path
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z">
                    </path>
                </svg>
            </button>
        </PanelEditItem>
    );
};
