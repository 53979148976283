import {css} from 'twind/css';

export const tracklightAppStyles = css({
  'z-index': '9999999',
  'top': 'var(--header-height)',
    '@screen lg': {
      'left':'var(--sb-width)',
    },
    '~ .main': {
        'min-height': 'calc(100vh - 182px)',
    },
});

export const appMainStyles = css({
    '@apply': 'relative bg-gray-100 flex flex-col lg:flex-row',
    'background-size': '86px 86px',
    'height': 'calc(100vh - 130px)',
    'background-position': 'center center',
    '@screen lg': {
        'height' : 'calc(100vh - 84px)',
    },
    '.btn-show-sidebar': {
       '@apply': 'bg-primary p-2 shadow-lg',
        'svg': {
          '@apply': 'fill-white'
        },
    },
    '.toggle-icon': {
      '@apply': 'bg-primary p-2 shadow-lg rounded-full  w-[40px] h-[40px] mt-4 left-2',
        '&.toggled': {
          '@apply': 'bg-primary-light',
        },
      'svg': {
        '@apply': 'fill-white h-full w-full',
      },
    },
    'aside': {
        '@apply' : 'relative order-2 transition ease-in-out duration-200',
        '> div' : {
            '@apply': 'h-screen overflow-y-auto overflow-x-hidden md:min-w-min lg:min-w-310 bg-black-light order-2 lg:order-1 min-h-full'
        },
        '.btn-wrapper': {
          '@apply': 'hidden lg:flex flex-col justify-center absolute top-0 -right-10 z-60 h-auto bg-transparent min-w-0 min-h-0  overflow-visible',
        },
        '&.not-shown': {
            '@apply': 'w-0 min-w-0 transition ease-in-out duration-200',
            '> div' : {
                '@apply': 'w-0 min-w-0'
            },
            '.main-panel.overflow': {
                'overflow': 'hidden !important',
            },
            '.btn-show-sidebar': {
              '@apply': 'transform rotate-180',
            },
        },
    },
    'main': {
        '@apply' : 'relative order-1 lg:order-2 w-full',
    },
    '.main-panel': {
      '@apply': 'h-full lg:max-w-310',
      '&.overflow': {
        'overflow': 'visible !important'
      },
      '.amounts-wrapper': {
        '.submit-group': {
          '@apply': 'lg:w-[40px] overflow-hidden',
          '.submit': {
            '@apply': 'rounded-tl-none rounded-bl-none rounded-tr rounded-br'
          },
          '&:not(.disabled)': {
            '.submit': {
              '@apply': 'bg-green-500'
            }
          }
        }
      },
      '.btn-save-wrapper': {
        '@apply': 'sticky bottom-0',
        'button': {
          '@apply': 'w-full'
        },
        '.toggle-icon': {
          '@apply': 'hidden'
        }
      }
    },
});

export const AppNavigationStyles = css({
    '@apply': 'bg-white flex flex-wrap lg:flex-nowrap md:pt-4 lg:py-4 lg:px-3 justify-between',
    '.logo-container' : {
        '@apply' : 'md:flex-grow lg:flex-grow-0 w-6/12 lg:w-3/12 py-4 px-3 md:py-0 lg:px-0 flex align-center'
    },
    '.logo' : {
        'width' : '200px'
    },
    '.button-group': {
        '@apply': 'flex flex-column'
    },
    '.contact': {
        '@apply': 'block mt-4 bg-gray-200 rounded-full px-4 py-3 text-center font-medium hover:bg-gray-300 transition ease-in-out duration-200'
    },
    '.SecondaryNavigation': {
        '@apply': 'flex items-center justify-end w-6/12 lg:w-3/12 pr-2'
    }
});

export const tracklightAppLoadingStyles = css({
  '@apply' : 'relative overflow-hidden',

  '.container': {
    '.inner': {
        '@apply' : 'absolute flex justify-center items-center flex-col h-screen w-screen p-16 z-9999999',
      },
      '.loading-headline': {
        '@apply' : 'relative text-white text-center text-2xl mb-8',
      },
      '&::after': {
        'content': '""',
        '@apply' : 'absolute bg-gray-600 bg-opacity-90 z-10 top-0 right-0 bottom-0 left-0 z-8888888',
      },
      '.blur': {
        '@apply' : 'filter blur-md',
      },
      '.spinner': {
        '@apply' : 'rounded-full border-4 border-gray-300 border-r-4 min-w-64 min-h-64',
        'border-right-color': '#a050b5',
        'animation': 'spinner 1s linear infinite',
      },
    },
});
