import {outletMinimumDistanceToWall} from 'app/config/outlet';
import {unknownValidation} from 'libs/notification/defaultNotifications';
import {NotifyItem} from 'libs/notification/notificationProvider';

export type OutletNotificationType = 'invalid-position' | null;

export type OutletNotification = {
    type: OutletNotificationType;
    item: NotifyItem;
}

export type OutletValidation = {
    isValid: boolean;
    type: OutletNotificationType;
}

export const outletNotifications = (ont: OutletNotificationType): NotifyItem => {
    const notifyItem = outletNotificationItems.find(nItem => nItem.type === ont)

    if (notifyItem) {
        return notifyItem.item;
    }

    return unknownValidation;
}

const outletNotificationItems: OutletNotification[] = [
    {
        type: 'invalid-position',
        item: {
            id: 'invalid-position',
            title: 'Ungültiger Abstand!',
            message: `Der Abstand zu einer Wand muss min. ${outletMinimumDistanceToWall}cm betragen.`,
            type: 'info'
        }
    }
]
