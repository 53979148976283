import {FloorPlane} from 'components/scene/3d/planes/FloorPlane';
import {RoofPlane} from 'components/scene/3d/planes/RoofPlane';
import {WallPlanes} from 'components/scene/3d/planes/WallPlanes';
import {WallSegmentModel} from 'libs/view';
import React, {useMemo} from 'react';
import * as THREE from 'three';
import {Shape} from 'three';

export type CustomPlaneProps = {
    shape: Shape;
    roomHeight: number;
}

type ThreeDPlanesProps = {
    wallSegments: WallSegmentModel[];
    roomHeight: number;
}

export const ThreeDPlanes = ({wallSegments, roomHeight}: ThreeDPlanesProps): JSX.Element => {
    const shape = useMemo(() => new THREE.Shape(), []);
    buildShape(wallSegments, shape);

    return (
        <>
            <RoofPlane shape={shape} roomHeight={roomHeight}/>
            <WallPlanes wallSegments={wallSegments} roomHeight={roomHeight}/>
            <FloorPlane shape={shape} roomHeight={roomHeight}/>
        </>
    );
};

function buildShape(wallSegments: WallSegmentModel[], shape: Shape) {
    const poly = wallSegments.map(wall => [wall.from[0], wall.from[2]]);

    if (poly && poly.length > 0) {
        shape.moveTo(poly[0][0], poly[0][1]);

        for (let i = 1; i < poly.length; ++i) {
            shape.lineTo(poly[i][0], poly[i][1]);
        }

        shape.lineTo(poly[0][0], poly[0][1]);
    }
}
