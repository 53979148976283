import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store/store';
import {LocationState} from 'redux-first-router';

export const selectLocationState = (state: RootState): LocationState => state.location;

export const selectLocationType = createSelector(
    [selectLocationState],
    (state) => state.type
);
