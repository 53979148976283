import {Wall} from 'components/common/3d/wall/Wall';
import {PositionThree} from 'libs/view';
import React from 'react';

export type WallSegmentProps = {
    isHorizontal: boolean,
    wallLength: number,
    wallPosition: PositionThree,
}

export const WallSegment = ({isHorizontal, wallLength, wallPosition}: WallSegmentProps): JSX.Element => {
    return (
        <group position={wallPosition}>
            <Wall
                isHorizontal={isHorizontal}
                length={wallLength}
                position={[0, 0, 0]}
            />
        </group>
    );
};
