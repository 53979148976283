import {Html} from '@react-three/drei';
import {nameForIndex} from 'app/config';
import {htmlDistanceFactor} from 'app/config/3DParams';
import {TrackMenuBottom, TrackMenuLeft, TrackMenuRight, TrackMenuTop} from 'components/scene/track/edit/TrackMenu';
import {Knot} from 'libs/models/knot';
import {Direction, directionOpposite, directionRecordAsArray, directionRecordAsPairs} from 'libs/models/types';
import {positionThreeFromPositionCM} from 'libs/view';
import React from 'react';
import {trackKnotMenuStyles} from 'components/scene/track/edit/styles/styles';
import {tw} from 'twind/css';




export type TrackKnotMenuProps = {
    knot: Knot;
    addNewTrackKnot: (knotId: number, direction: Direction, length: number) => void
}

export const TrackKnotMenu = ({knot, addNewTrackKnot}: TrackKnotMenuProps): JSX.Element => {
    const threePosition = positionThreeFromPositionCM(knot.position, 0.01);
    const trackMenuItemOffset = 20;

    const canWeAddNewConnections = (knot: Knot, direction: Direction): boolean => {
      const emptyConnections = directionRecordAsArray(knot.connections).filter(con => con === -1);
      if (knot.id === 0) {
        if (emptyConnections.length === 4) {
          return true
        }

        if (emptyConnections.length === 3) {
          const pairs = directionRecordAsPairs(knot.connections);
          const pair = pairs.find(value => value.connected !== -1);
          if (pair) {
            const opposite = directionOpposite(pair.direction);
            return opposite === direction
          }
        }

        return false
      }

      return emptyConnections.length > 1
    }

    const setKnot = () => {
        if(knot.id === 0) {
            return <div className={'icon-container'}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 25.9 22.5"
                             width={26}
                             height={26}
                        >
                            <path
                                className={'fill-currentColor'}
                                d="M12.9,0L0,22.5h25.9L12.9,0z M12.9,3.8l9.3,16.7H3.6L12.9,3.8z"
                            />
                            <polygon
                                className={'fill-currentColor'}
                                points="10.5,14.3 12.7,14.3 12.1,19.2 15.4,12.4 13.2,12.4 13.8,7.5 "
                            />
                        </svg>
                    </div>;
        } else {
            return nameForIndex(knot.id)
        }
    }

    // noinspection RequiredAttributes
    return (
        <>
            <Html position={threePosition} distanceFactor={htmlDistanceFactor} center>
                <div className={tw(trackKnotMenuStyles)}>
                    {setKnot()}
                </div>
            </Html>

            <TrackMenuTop
                active={canWeAddNewConnections(knot, 'top')}
                position={threePosition}
                offset={trackMenuItemOffset}
                connection={knot.connections.top}
                addNewTrackKnot={(length) => addNewTrackKnot(knot.id, 'top', length)}
            />
            <TrackMenuRight
                active={canWeAddNewConnections(knot, 'right')}
                position={threePosition}
                offset={trackMenuItemOffset}
                connection={knot.connections.right}
                addNewTrackKnot={(length) => addNewTrackKnot(knot.id, 'right', length)}
            />
            <TrackMenuBottom
                active={canWeAddNewConnections(knot, 'bottom')}
                position={threePosition}
                offset={trackMenuItemOffset}
                connection={knot.connections.bottom}
                addNewTrackKnot={(length) => addNewTrackKnot(knot.id, 'bottom', length)}
            />
            <TrackMenuLeft
                active={canWeAddNewConnections(knot, 'left')}
                position={threePosition}
                offset={trackMenuItemOffset}
                connection={knot.connections.left}
                addNewTrackKnot={(length) => addNewTrackKnot(knot.id, 'left', length)}
            />
        </>
    )
}
