import {
    alignLinePositionNeighbors,
    findLineChangedPositions,
    Line,
    lineDirection,
    lineOrientation,
    resizeLineBy,
    resizeLineTo
} from 'libs/models/line';
import {IndexedPosition} from 'libs/models/position';
import {mapRoomFromPositions} from './mapRoom';
import {Room} from './room';


export type LineResizer = (line: Line<IndexedPosition>, amount: number) => Line<IndexedPosition>;

export function resizeRoomWallTo(room: Room, wallIndex: number, amount: number): Room {
    return resizeRoomWallUsing(resizeLineTo, room, wallIndex, amount);
}


export function resizeRoomWallBy(room: Room, wallIndex: number, amount: number): Room {
    return resizeRoomWallUsing(resizeLineBy, room, wallIndex, amount);
}

export function resizeRoomWallUsing(resizeLine: LineResizer, room: Room, wallIndex: number, amount: number): Room {
    if (wallIndex < 0 || wallIndex >= room.walls.length || amount === 0) {
        return room;
    }

    const positions = room.corners;
    const lineOriginal = room.walls[wallIndex];
    const lineResized = resizeLine(lineOriginal, amount);

    // Resize should not mutate the line, so its safe to short circuit
    if (lineResized === lineOriginal) {
        return room;
    }

    const orientation = lineOrientation(lineOriginal);
    const direction = lineDirection(lineOriginal);

    const changedPositions: IndexedPosition[] = findLineChangedPositions(lineOriginal, lineResized)
        .flatMap((pos) => {
            const connected = alignLinePositionNeighbors(positions, pos, orientation, direction);
            return [pos, ...connected];
        });

    if (changedPositions.length <= 0) {
        return room;
    }

    // apply changes
    const finalPositions = room.corners.slice(0);
    changedPositions.forEach((position) => {
        finalPositions[position.id] = position;
    })

    return mapRoomFromPositions(finalPositions);
}


