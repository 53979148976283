import React from 'react';
import {tw} from 'twind/css';


export const OutletNavigationSvg = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 25.9 22.5"
             className={tw('mb-1') + ' outlet-icon'}
             width={26}
             height={26}
             {...props}>
            <path
                className={'fill-white md:fill-currentColor'}
                d="M12.9,0L0,22.5h25.9L12.9,0z M12.9,3.8l9.3,16.7H3.6L12.9,3.8z"
            />
            <polygon
                className={'fill-white md:fill-currentColor'}
                points="10.5,14.3 12.7,14.3 12.1,19.2 15.4,12.4 13.2,12.4 13.8,7.5 "
            />
        </svg>
    );
}
