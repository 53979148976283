import {css} from 'twind/css';

export const styles = css({
    '@apply' : ' p-4 bg-primary',
    'h2': {
        '@apply': 'text-white font-bold tracking-wide text-center'
    },
    '.contact': {
        '@apply': 'block mt-4 bg-white text-black rounded-full px-4 py-3 text-center font-medium hover:bg-primary-light hover:text-white transition ease-in-out duration-200'
    },
});
