import {css} from 'twind/css';

export const mainNavigationStyles = css({
    '@apply': 'flex items-center order-3 w-full lg:order-none md:mt-4 lg:my-0 justify-between md:justify-center lg:justify-start xl:justify-center lg:w-6/12',
    '&.disabled': {
        'filter': 'grayscale(1)',
        '.active[aria-current="true"]': {
            '@apply': 'pointer-events-none opacity-50',
            'span': {
                '@apply': 'text-gray-300',
            },
            'svg': {
                '@apply': 'opacity-70',
            },
        },
        'span': {
            '@apply': 'text-gray-400',
        }
    },
    '.active[aria-current="true"]': {
        '@apply': 'relative',
        '.checkmark-icon': {
            '@apply': 'absolute right-0 -top-2 bg-green-500 fill-white rounded-full',
            'width': '18px',
            'height': '18px',
            'padding': '2px'
        },
        '&:not(:only-of-type)': {
            '@apply': 'text-green-500',
            '&:after': {
                '@apply' : 'bg-green-500',
            },
            '.outlet-icon': {
                '@apply': 'fill-green',
            },
        },
        '&:last-of-type': {
            '@apply': 'text-primary',
            '&:after': {
                '@apply' : 'bg-primary',
            },
            '.checkmark-icon': {
                '@apply': 'hidden',
            },
            '.type-icon path:last-of-type, .room-icon path:last-of-type, .outlet-icon, .track-icon path, .light-icon path, .light-icon polygon:not(:first-of-type), .light-icon rect, .order-icon path': {
                '@apply': 'fill-white md:fill-primary',
            },
        },
    },
});
