import React from 'react';
import {tw} from 'twind/css';

export const RoomNavigationSvg = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 26.24 25.87"
             className={tw('mb-1') + ' room-icon'}
             width={26}
             height={26}
             {...props}>
            <g transform="translate(-236.44 -239.98)">
                <path
                    d="m252.18 263.85v-2.91h-2v2.91h-3.73v-2.91h-2v2.91h-6.01v-1.5h3.33v-2h-3.33v-2.91h3.33v-2h-3.33v-2.92h3.33v-2h-3.33v-5.75l19.36 19.08z"
                    fill="none" />
                <path d="M236.44,239.98v.01l26.24,25.86Z" fill="currentColor" />
                <path
                    d="m236.44 239.99v25.86h26.24zm13.74 20.95v2.91h-3.73v-2.91h-2v2.91h-6.01v-1.5h3.33v-2h-3.33v-2.91h3.33v-2h-3.33v-2.92h3.33v-2h-3.33v-5.75l19.36 19.08h-5.62v-2.91z"
                    fill="currentColor" />
            </g>
        </svg>
    );
}
