import {Text} from '@react-three/drei';
import {useAppSelector} from 'app/store/hooks';
import {OutlineLine} from 'components/common/3d/lines/OutlineLine';
import {OutlineMarker} from 'components/common/3d/lines/OutlineMarker';
import {WallSegment} from 'components/common/3d/wall/WallSegment';

import {lineCenter, lineIsHorizontal, lineIsVertical, lineLength} from 'libs/models/line';
import {Position} from 'libs/models/position';
import {Direction, directionIsHorizontal, directionIsVertical} from 'libs/models/types';
import {
    calculateOffset,
    calculateWallOutlineWithOffset,
    FROM_THREE,
    mapLineFromWallSegment,
    movePositionXByCM,
    movePositionZByCM,
    needToChangeOffset,
    PositionThree,
    WallSegmentModel
} from 'libs/view';
import React from 'react';
import {selectRoomMinMaxValues, selectRoomWallsForOutlines, selectWallSegments} from 'state/room/roomSelectors';
import {selectEditMode} from 'state/view/viewSelectors';
import * as THREE from 'three';

export const RoomView = (): JSX.Element => {
    const editMode = useAppSelector(selectEditMode);

    if (editMode === 'type') {
        return (
            <>
                <RoomWalls/>
            </>
        )
    }

    return (
        <>
            <RoomWalls/>
            <RoomWallOutlines/>
        </>
    );
};


const RoomWalls = (): JSX.Element => {
    const wallSegmentModels = useAppSelector(selectWallSegments);

    const wallSegments = wallSegmentModels.map((wall) => {
        return (
            <WallSegment key={wall.index}
                         isHorizontal={wall.isHorizontal}
                         wallLength={wall.length}
                         wallPosition={wall.center}/>
        );
    });

    return (
        <>
            {wallSegments}
        </>
    );
};

export const RoomWallOutlines = (): JSX.Element => {
    const wallsForOutlines = useAppSelector(selectRoomWallsForOutlines);
    return (
        <>
            <WallOutlines walls={wallsForOutlines.top} direction={'top'}/>
            <WallOutlines walls={wallsForOutlines.right} direction={'right'}/>
            <WallOutlines walls={wallsForOutlines.bottom} direction={'bottom'}/>
            <WallOutlines walls={wallsForOutlines.left} direction={'left'}/>
        </>
    );
};

type WallOutlineProps = {
    walls: WallSegmentModel[],
    direction: Direction;
}

const WallOutlines = ({walls, direction}: WallOutlineProps): JSX.Element => {
    const minMaxValues = useAppSelector(selectRoomMinMaxValues);
    const mode = useAppSelector(selectEditMode);

    const sortedWalls = walls;
    let offset = 50;

    const outlineLines = sortedWalls.map((wall, index) => {

        const pointsWithOffset = calculateWallOutlineWithOffset({wall, direction, offset, minMaxValues});
        const markerPoints = calculateMarkers(pointsWithOffset, direction, 10);
        const labels = calculateLabels(pointsWithOffset, direction, 20);

        if (needToChangeOffset(wall, sortedWalls, direction)) {
            offset += 50;
        } else if (index < sortedWalls.length - 1) {
            if (!isLineOnSameLevel(wall, sortedWalls[index + 1])) {
                offset += 50;
            }
        }

        // noinspection RequiredAttributes
        return (
            <group key={wall.index}>
                <OutlineLine opacity={mode === 'type' ? 1 : 0.8} points={pointsWithOffset}/>
                {markerPoints.map((marker, idx) => (
                    <OutlineMarker opacity={mode === 'type' ? 1 : 0.8} key={idx} points={marker}/>
                ))}
                {labels.map((label, idx) => (
                    <Text
                        fillOpacity={mode === 'type' ? 1 : 0.5}
                        key={idx}
                        color={'black'}
                        fontSize={mode === 'type' ? 0.2 : 0.15}
                        rotation={label.rotation}
                        position={label.center}>
                        {label.length} cm
                    </Text>
                ))}
            </group>
        );
    });

    return (
        <>
            {outlineLines}
        </>
    );
};

function isLineOnSameLevel(line: WallSegmentModel, nextLine: WallSegmentModel): boolean {
    const mappedLine = mapLineFromWallSegment(line);
    const mappedNextLine = mapLineFromWallSegment(nextLine);

    if (lineIsHorizontal(mappedLine)) {
        return mappedLine.to.x === mappedNextLine.from.x;
    }

    if (lineIsVertical(mappedLine)) {
        return mappedLine.to.y === mappedNextLine.from.y;
    }

    return false;
}

function calculateMarkers(positions: PositionThree[], direction: Direction, markerCM: number): PositionThree[][] {
    const positiveHalfLength = (markerCM * 0.5);
    const negativeHalfLength = (markerCM * -0.5);

    if (directionIsVertical(direction)) {
        return positions.map((pos) => [movePositionZByCM(pos, positiveHalfLength), movePositionZByCM(pos, negativeHalfLength)]);
    }
    if (directionIsHorizontal(direction)) {
        return positions.map((pos) => [movePositionXByCM(pos, positiveHalfLength), movePositionXByCM(pos, negativeHalfLength)]);
    }

    return [];
}

type LabelArgs = {
    length: number;
    center: PositionThree;
    rotation: PositionThree;
}

const rotationHorizontal: PositionThree = [THREE.MathUtils.degToRad(-90), 0, 0];
const rotationVertical: PositionThree = [THREE.MathUtils.degToRad(-90), 0, THREE.MathUtils.degToRad(-90)];

function calculateLabels(positions: PositionThree[], direction: Direction, offsetCM: number): LabelArgs[] {
    const max = positions.length - 1;
    if (max < 1) {
        return [];
    }

    const withOffset = (position: PositionThree): PositionThree => {
        return calculateOffset([position], direction, offsetCM)[0];
    };

    const labels: LabelArgs[] = [];
    for (let idx = 0; idx < max; idx++) {
        const from: Position = {x: positions[idx][0], y: positions[idx][2]};
        const to: Position = {x: positions[idx + 1][0], y: positions[idx + 1][2]};
        const posCenter = lineCenter({from, to});
        const posLength = lineLength({from, to});
        const length = Math.trunc(FROM_THREE * posLength);

        labels.push({
            length,
            center: withOffset([posCenter.x, positions[idx][1], posCenter.y]),
            rotation: lineIsVertical({from, to}) ? rotationVertical : rotationHorizontal
        });
    }

    return labels;
}


