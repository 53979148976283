import {Tooltip} from 'components/common/tooltip/Tooltip';

import React from 'react';


export const TypeToolTip = (): JSX.Element => {
    return (
        <Tooltip title={'Raumauswahl'} setDisbandTime={5000}>
           Wählen Sie die Grundform Ihres Raumes. Im nächsten Schritt können Sie diese noch weiter anpassen.
            <br/>Sollte Ihre gewünschte Raumform nicht dabei sein, können Sie diese gerne bei uns anfragen.
        </Tooltip>
    );
};
