import {css} from 'twind/css';

export const productContainerStyles = css({
    '@apply ': 'flex',
    '.title': {
        '@apply': 'bg-black text-white font-bold px-4 py-2 tracking-wide mb-2',
        '&.sticky': {
            '@apply': 'sticky top-0',
        },
    },
    '.container': {
        '@apply': 'flex flex-wrap p-2 lg:p-0 lg:mx-2 lg:mt-2 pb-2'
    },
    '.active-product': {
        '@apply': 'relative rounded',
    },
    '.selected-product': {
        '@apply': 'w-full pt-2 pb-4 px-4',
        '.product': {
            '@apply': 'relative bg-black-lightest rounded shadow-xl text-left text-white py-2 px-3',
            '&:hover': {
                '@apply': 'bg-primary-light shadow-xl transition-all ease-in-out duration-200',
            },
        },
        'figure': {
            '@apply': 'w-full h-100 rounded-tl rounded-tr overflow-hidden flex justify-center items-center bg-white overflow-hidden',
        },
        'figure img': {
            '@apply': 'w-full h-full object-contain transform scale-150',
        },
    },
    '.close': {
        '@apply': 'absolute right-2 top-0'
    },
    'svg': {
        '@apply': 'bg-red-500 rounded-full fill-white'
    },
});

export const produktListStyles = css({
    '@apply': 'flex align-center w-6/12 sm:w-1/5 lg:w-6/12 xl:w-full p-2 lg:p-0 lg:m-0 lg:p-2',
    '.item': {
        '@apply': 'w-full bg-black-lightest rounded',
        '&.active': {
            '@apply': 'bg-primary',
        },
    },
    'button': {
        '@apply': 'w-full rounded overflow-hidden transition-all ease-in-out duration-200',
        '&:hover': {
            '@apply': 'bg-primary-light shadow-xl transition-all ease-in-out duration-200',
        },
    },
    'figure': {
        '@apply': 'w-full h-100 flex justify-center items-center bg-white',
    },
    'figure img': {
        '@apply': 'w-full h-full object-contain',
    },
    '.content': {
        '@apply': 'flex flex-col items-start text-xs py-2 px-3',
    },
    'p': {
        '@apply': 'text-white text-left',
    },
    '.name': {
        '@apply': 'text-base',
    },
});

export const anzahlStyles = css({
    '@apply': 'flex flex-wrap md:flex-nowrap lg:flex-wrap',
    '> div': {
        '@apply': 'w-full md:w-6/12 lg:w-full',
        '&:Last-of-type': {
          '@apply': 'mt-0 md:mt-4 md:ml-0 lg:ml-4 lg:mt-0',
        },
    },
});

export const spotVariantProductSelector = css({
    '@apply': 'w-full md:w-10/12 lg:w-6/12 xl:w-5/12 md:p-0 mx-auto',
    '.variant-modal-head': {
        '@apply': 'bg-primary text-white px-4 py-2',
        'h2': {
            '@apply': 'text-xl font-bold',
        },
        'h5': {
            '@apply': 'text-sm',
        },
    },
    '.variant-modal-content': {
        '@apply': 'p-4 md:flex',
        'h6': {
            '@apply': 'font-bold',
        },
        '.variant-img': {
            '@apply': 'md:max-h-full md:w-6/12',
            'img': {
                '@apply': 'w-full object-contain object-center max-h-200 md:max-h-full',
            },
        },
        '.variant-info': {
            '@apply': 'md:pl-8 md:w-6/12',
          '.amounts-wrapper': {
            '@apply': 'm-0',
          },
          'div .amounts-wrapper': {
            '@apply': 'bg-transparent p-0 mt-6 mb-4',
            '.item-title': {
              '@apply': 'text-black mr-8'
            },
            '.form-field': {
              '@apply': 'justify-start'
            },
            'input': {
              '@apply': 'bg-gray-200 pl-0 text-center rounded',
            },
            'label': {
              '@apply': 'bg-transparent pl-1 font-normal',
            },
            '.submit-group': {
              '@apply': 'flex justify-center w-full rounded',
              '&.disabled': {
                'input': {
                  '@apply': 'bg-gray-200',
                },
              },
              'input': {
                '@apply': 'w-full p-0 bg-green-500',
              },
            },
          },
        },
        '.variant-selection': {
            '@apply': 'mb-4',
            'ul': {
                '@apply': 'flex',
            },
        },
        '.variant-desc div b': {
            '@apply': 'block mt-4',
        },
    },
    '.variant-modal-inner': {
        '@apply': 'relative bg-white shadow-xl',
        '.variant-modal-close': {
            '@apply': 'hidden md:block absolute bg-red-500 rounded-full',
            'right': '-10px',
            'top': '-10px',
            'padding': '2px',
            'svg': {
                '@apply': 'fill-white',
                'height': '20px',
                'width': '20px',
            },
        },
    },
    '.variant-selection': {
        'ul': {
            '@apply': 'flex flex-wrap',
            'li': {
                '@apply': 'relative mr-2 mb-2',
                '.checkmark-icon': {
                    '@apply': 'absolute fill-green border-2 border-green-500 bg-white bg-opacity-25',
                    'box-sizing': 'content-box'
                },
            },
        },
        '.btn-select-color': {
            '@apply': 'mr-0 rounded-none w-40 h-40 border border-gray-300',
            '&.sw': {
                '@apply': 'bg-black',
                '&.go': {
                    'background': 'linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(112,106,70,1) 50%, rgba(255,242,160,1) 50%, rgba(237,185,2,1) 100%)',
                }
            },
            '&.ws': {
                '@apply': 'bg-white',
                '&.go': {
                  'background': 'linear-gradient(45deg, rgba(224,224,224,1) 0%, rgba(255,255,255,1) 50%, rgba(255,242,160,1) 50%, rgba(237,185,2,1) 100%)',
                },
            },
            '&.mcgy': {
                'background': 'linear-gradient(-90deg, rgba(148,150,149,1) 0%, rgba(216,216,216,1) 100%)',
                '&.sw': {
                    'background': 'linear-gradient(45deg, rgba(143,141,141,1) 0%, rgba(42,42,42,1) 49%, rgba(245,245,245,1) 50%, rgba(217,217,217,1) 100%)',
                }
            },
            '&.ch': {
                'background': 'linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(208,208,208,1) 49%, rgba(245,245,245,1) 50%, rgba(217,217,217,1) 100%)',
            },
            '&.ch.sw': {
                'background': 'linear-gradient(45deg, rgba(143,141,141,1) 0%, rgba(42,42,42,1) 49%, rgba(221,221,221,1) 50%, rgba(181,181,181,1) 100%)',
            }
        },
    },
    '.variant-modal-footer': {
        '@apply': 'flex flex-wrap md:flex-nowrap justify-center items-center pl-4 pr-4 pb-4 md:pt-4 md:pb-4',
        '.btn': {
            '@apply': 'flex justify-center items-center text-sm font-bold min-w-190 rounded-full max-h-44 z-50 w-full md:w-6/12 py-3',
        },
        '.btn-open': {
            '@apply': 'bg-primary text-white shadow-xl transition ease-in-out duration-200 md:ml-2 order-1 md:order-2 hover:bg-primary-light',
        },
        '.btn-close': {
            '@apply': 'text-primary border-2 border-primary order-2 mt-4 mb-0 ml-0 mr-0 md:mt-0 md:ml-2 md:order-1 hover:text-white hover:bg-primary-light',
        },
    },
});
