import {createSelector} from '@reduxjs/toolkit';
import {AdditionalColor, SystemColor} from 'app/config/order';
import {RootState} from 'app/store/store';
import {ShopEntityData} from 'libs/client/clientApi';
import {SpotProduct} from 'libs/shop/shopClient';
import {ShopState} from 'state/shop/shopReducer';

export const selectShopState = (state: RootState): ShopState => state.shop;

export const selectShopSpotProducts = createSelector(
    [selectShopState],
    (state) => state.spotProducts,
);

export const selectShopMainProducts = createSelector(
    [selectShopSpotProducts],
    (spotProducts) => spotProducts.filter((p) => p.parentId === null),
);

export const selectShopActiveMainProductId = createSelector(
    [selectShopState],
    (state) => state.activeMainProductId,
);

export const selectShopActiveMainProduct = createSelector(
    [selectShopMainProducts, selectShopActiveMainProductId],
    (mainProducts, activeMainProductId): SpotProduct | null => {
        if (activeMainProductId === null) {
            return null;
        }
        return mainProducts.find((p) => p.id === activeMainProductId) || null;
    }
);

export const selectShopProductVariants = createSelector(
    [selectShopSpotProducts, selectShopActiveMainProductId],
    (products, activeMainProductId): SpotProduct[] => {
        if (activeMainProductId === null) {

            return [];
        }
        return products.filter((p) => p.parentId === activeMainProductId);
    }
);

export const selectShopActiveVariantProductId = createSelector(
    [selectShopState],
    (state) => state.activeVariantProductId
);

export const selectShopActiveVariantProduct = createSelector(
    [selectShopSpotProducts, selectShopActiveVariantProductId],
    (products, activeVariantProductId): SpotProduct | null => {
        if (activeVariantProductId === null) {
            return null;
        }
        return products.find((p) => p.id === activeVariantProductId) || null;
    }
);

export const selectShopActiveVariantProductSystemColor = createSelector(
    [selectShopActiveVariantProduct],
    (product): SystemColor | null => {
        if (product && product.productSystem) {
            return product.productSystem;
        }
        return null;
    }
);

export const selectShopActiveVariantProductAdditionalColor = createSelector(
    [selectShopActiveVariantProduct],
    (product): AdditionalColor | null => {
        if (product && product.productAdditional) {
            return product.productAdditional;
        }
        return null;
    }
);

export const selectShopActiveVariantProductNumber = createSelector(
    [selectShopActiveVariantProduct],
    (product): string | null => {
        if (product && product.productNumber) {
            return product.productNumber;
        }
        return null;
    }
);

export const selectHasActiveMainAndVariantProductNumber = createSelector(
    [selectShopActiveMainProductId, selectShopActiveVariantProductId],
    (mainProductId, variantProductId): boolean => {
        return !!(mainProductId && variantProductId);

    }
);

/**
 * Das sind die Daten, die in der Entität gespeichert werden.
 */
export const selectShopEntityData = createSelector(
    [selectShopActiveMainProductId, selectShopActiveVariantProductId],
    (activeMainProductId, activeVariantProductId): ShopEntityData => {
            return {
                activeMainProductId,
                activeVariantProductId
            };
    }
);
