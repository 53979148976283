import {useAppSelector} from 'app/store/hooks';
import {CustomPlaneProps} from 'components/scene/3d/ThreeDPlanes';
import {PositionThree} from 'libs/view';
import React from 'react';
import {selectRoofColor} from 'state/scene/sceneSelectors';
import * as THREE from 'three';

export const RoofPlane = ({shape, roomHeight}: CustomPlaneProps): JSX.Element => {
    const roofColor = useAppSelector(selectRoofColor);
    const rotation: PositionThree = [THREE.MathUtils.degToRad(90), 0, 0];

    // noinspection RequiredAttributes
    return (
        <mesh
            position={[0, (roomHeight / 2), 0]}
            rotation={rotation}
        >
            <shapeBufferGeometry args={[shape]}/>
            <meshStandardMaterial color={roofColor} side={THREE.FrontSide}/>
        </mesh>
    );
};
