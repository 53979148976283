import {useAppDispatch} from 'app/store/hooks';
import {ResetAction} from 'components/common/actions/ResetAction';
import React from 'react';
import {stepResetPageOutlet} from 'state/step/stepReset';
import {useStepNavigationForPageTrack} from 'state/step/useStepNavigation';


export const ResetOutletAction = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const nextNavigation = useStepNavigationForPageTrack();

    const handleConfirmDelete = () => {
        dispatch(stepResetPageOutlet());
    };
    const handleCancelDelete = () => {
        if (nextNavigation.route) {
            dispatch(nextNavigation.route);
        }
    };

    return (
        <ResetAction onConfirm={handleConfirmDelete} onCancel={handleCancelDelete}>
            <p>
                Durch Änderungen am Stromauslass gehen bereits platzierte Schienen verloren.
            </p>
        </ResetAction>
    );
}

