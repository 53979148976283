import React from 'react';
import {tw} from 'twind/css';

export const TypeNavigationSvg = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 26 26"
             className={tw('mb-1') + ' type-icon'}
             width={26}
             height={26}
             {...props}>
            <g transform="translate(-236.89 -236.86)">
                <path d="M260.89,238.86v22H248.9v-9.5h-2v9.5h-8.01v-13.5h9.5v-2h-9.5v-6.5Z" fill="none" />
                <path d="M236.89,236.86v26h26v-26Zm24,24H248.9v-9.5h-2v9.5h-8.01v-13.5h9.5v-2h-9.5v-6.5h22Z"
                      fill="currentColor" />
            </g>
        </svg>
    );
};
