import {Html} from '@react-three/drei';
import {htmlDistanceFactor} from 'app/config/3DParams';
import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {EditableCorner} from 'components/common/3d/wall/EditableCorner';
import {EditableWallSegment} from 'components/common/3d/wall/EditableWallSegment';
import {Position} from 'libs/models/position';
import {WallSegmentModel} from 'libs/view';
import React from 'react';
import {setCornerPositionTo, setWallPositionTo, setWallSizeTo} from 'state/room/roomReducer';
import {selectWallSegments} from 'state/room/roomSelectors';
import {cornerIconStyles} from 'components/scene/room/edit/styles/styles';
import {tw} from 'twind/css';

export const RoomEditor = (): JSX.Element => {
    const wallSegmentModels = useAppSelector(selectWallSegments);
    const dispatch = useAppDispatch();

    const handleLengthChanged = (value: { length: number, wallId: number }) => {
        dispatch(setWallSizeTo(value));
    }

    const handlePositionChanged = (value: { position: Position, wallId: number }) => {
        const wallSegment = wallSegmentModels[value.wallId];
        if (wallSegment.isHorizontal) {
            dispatch(setWallPositionTo({wallId: value.wallId, amount: value.position.y}));
        } else {
            dispatch(setWallPositionTo({wallId: value.wallId, amount: value.position.x}));
        }
    }

    const handleCornerPositionChanged = (value: { cornerIndex: number, position: Position }) => {
        dispatch(setCornerPositionTo({cornerIndex: value.cornerIndex, newCornerPosition: value.position}))
    }

    const wallSegments = wallSegmentModels.map((wall: WallSegmentModel) => {
        return (
            <EditableWallSegment
                key={wall.index + 'wall'}
                wall={wall}
                onLengthChange={(e) => handleLengthChanged({length: e, wallId: wall.index})}
                onPositionChange={(e) => handlePositionChanged({position: e, wallId: wall.index})}
            />
        );
    });

    const corners = wallSegmentModels.map((wall: WallSegmentModel) => {
        // noinspection RequiredAttributes
        return (
            <group key={wall.index + 'corner'}>
                <Html distanceFactor={htmlDistanceFactor} position={wall.from} center className={tw(cornerIconStyles)}>
                    <div className={'icon-container'}>
                        <svg version="1.1"
                             xmlns="http://www.w3.org/2000/svg"
                             x="0px" y="0px"
                             width={27}
                             height={27}
                             viewBox="0 0 24 24"
                        >
                            <path className={tw('fill-none')} d="M0,0h24v24H0V0z"/>
                            <polygon points="22.4,12 18.4,8 18.4,11 17.1,11 12.8,11 12.8,6.6 12.8,5.3 15.8,5.3 11.8,1.4 7.8,5.3 10.8,5.3 10.8,6.6 10.8,11 6.4,11 5.1,11 5.1,8 1.1,12 5.1,16 5.1,13 6.4,13 10.8,13 10.8,17.4 10.8,18.7 7.8,18.7 11.8,22.6 15.8,18.7 12.8,18.7 12.8,17.4 12.8,13 17.1,13 18.4,13 18.4,16 "/>
                        </svg>
                    </div>
                </Html>
                <EditableCorner
                    cornerPosition={wall.from}
                    onPositionChange={(e) => handleCornerPositionChanged({cornerIndex: wall.index, position: e})}
                />
                <EditableCorner
                    cornerPosition={wall.center}
                    onPositionChange={(e) => handlePositionChanged({position: e, wallId: wall.index})}
                />
            </group>
        );
    });

    return (
        <>
            {wallSegments}
            {corners}
        </>
    );
}
