import {css} from 'twind/css';

export const styles = css({
    '@apply' : '',
    '.modal': {
        '@apply': 'absolute md:mx-0 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 bg-white rounded w-full lg:max-w-md px-6 py-4 -z-10 opacity-0 shadow-xl transition-all ease-in-out duration-200',
    },
    '.modal.active': {
        '@apply': 'opacity-100 transition-all ease-in-out duration-200 z-9999999',
    },
    '.modal-open': {
        '@apply': 'flex items-center justify-center bg-primary text-sm font-bold text-white w-32 md:w-full md:min-w-190 lg:min-w-120 xl:min-w-190 shadow-lg z-50 rounded-full py-3',
        'max-height': '44px',
        '&:hover': {
            '@apply': 'bg-primary-light shadow-xl',
        },
        '&:focus, &:active': {
            '@apply': 'bg-primary-dark',
        },
        '&[disabled]': {
            '@apply' : 'bg-gray-300 pointer-events-none',
        },
        'svg': {
            '@apply': 'mr-3',
            'width': '20px'
        }
    },
    '.modal-title': {
        '@apply': 'flex items-center justify-between mb-4',
        'h3': {
            '@apply': 'font-bold text-xl',
        },
    },
    '.modal-body': {
        'p': {
            '@apply': 'text-sm leading-6 tracking-wide'
        }
    },
    '.modal-close': {
        '@apply': 'flex justify-end mt-4'
    },
    '.modal-bg': {
        '@apply': 'absolute inset-0 bg-gray-600 bg-opacity-40 -z-10 opacity-0'
    },
    '.modal.active ~ .modal-bg': {
        '@apply': 'z-8888888 cursor-pointer opacity-100'
    },
});
