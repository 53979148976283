import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {wallSizeConfig} from 'app/config/wallSize';
import {EntityResource} from 'libs/client/clientApi';
import {Position} from 'libs/models/position';
import {mapRoomFromPositions, moveRoomCornerTo, moveRoomWallTo, resizeRoomWallTo} from 'libs/models/room';
import {getValidatedLengthOrDefault, isValidRoom} from 'libs/models/validation';
import {NotificationProvider} from 'libs/notification/notificationProvider';
import {roomNotifications} from 'libs/notification/roomNotifications';
import {deleteEntity, loadEntity, saveEntity} from 'state/entity/entityApi';
import {Direction} from 'libs/models/types';

const addNotifyItem = NotificationProvider().addNotifyItem

export interface RoomState {
    dirty: boolean;
    corners: Position[];
    outlines: Direction[];
}

export const initialRoomState: RoomState = {
    dirty: false,
    corners: [],
    outlines: [],
};

const slice = createSlice({
    name: 'room',
    initialState: initialRoomState,
    reducers: {
        setPreviewRoom: (state, {payload}: PayloadAction<EntityResource | null>) => {
            state.dirty = false;
            if (payload !== null) {
                state.corners = payload.data.room.corners;
            } else {
                state.corners = [];
            }
        },
        setWallSizeTo: (state, {payload}: PayloadAction<{ wallId: number, length: number }>) => {
            const validatedLength = getValidatedLengthOrDefault(wallSizeConfig, payload.length);
            if (validatedLength !== payload.length) {
                addNotifyItem(roomNotifications('invalid-length'))
            }

            const currentRoom = mapRoomFromPositions(state.corners);
            const resizedRoom = resizeRoomWallTo(currentRoom, payload.wallId, validatedLength);
            const validation = isValidRoom(currentRoom, resizedRoom);

            if (!validation.isValid) {
                addNotifyItem(roomNotifications(validation.type))
            }

            if (currentRoom !== resizedRoom && validation.isValid) {
                state.corners = resizedRoom.corners;
                state.dirty = true;
            }
        },
        setWallPositionTo: (state, {payload}: PayloadAction<{ wallId: number, amount: number }>) => {
            const currentRoom = mapRoomFromPositions(state.corners);
            const movedRoom = moveRoomWallTo(currentRoom, payload.wallId, payload.amount);
            const validation = isValidRoom(currentRoom, movedRoom);

            if (!validation.isValid) {
                addNotifyItem(roomNotifications(validation.type))
            }

            if (currentRoom !== movedRoom && validation.isValid) {
                state.corners = movedRoom.corners;
                state.dirty = true;
            }
        },
        setCornerPositionTo: (state, {payload}: PayloadAction<{ cornerIndex: number, newCornerPosition: Position }>) => {
            const currentRoom = mapRoomFromPositions(state.corners);
            const movedRoom = moveRoomCornerTo(currentRoom, payload.cornerIndex, payload.newCornerPosition);
            const validation = isValidRoom(currentRoom, movedRoom);

            if (!validation.isValid) {
                addNotifyItem(roomNotifications(validation.type))
            }

            if (currentRoom !== movedRoom && validation.isValid) {
                state.corners = movedRoom.corners;
                state.dirty = true;
            }
        },
    },
    extraReducers: (builder) => {
        // >>> Reset - Hier muss aktuell nix gemacht werden

        // >>> Load
        builder.addCase(loadEntity.fulfilled, (state, {payload}) => {
            const room = mapRoomFromPositions(payload.data.room.corners);

            state.corners = room.corners;
            state.outlines = payload.data.outlines;
            state.dirty = false;
        });
        builder.addCase(loadEntity.rejected, () => {
            return initialRoomState;
        });

        // >>> Save
        builder.addCase(saveEntity.fulfilled, (state, {payload}) => {
            const room = mapRoomFromPositions(payload.data.room.corners);

            state.corners = room.corners;
            state.outlines = payload.data.outlines;
            state.dirty = false;
        });
        builder.addCase(saveEntity.rejected, () => {
            return initialRoomState;
        });

        // >>> Delete
        builder.addCase(deleteEntity.fulfilled, () => {
            return initialRoomState;
        });
        builder.addCase(deleteEntity.rejected, () => {
            return initialRoomState;
        });


    }
});


export const {setWallSizeTo, setWallPositionTo, setPreviewRoom, setCornerPositionTo} = slice.actions;


export const roomReducer = slice.reducer;

