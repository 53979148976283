import {mapLineFromWallSegment, WallSegmentModel} from 'libs/view/wallSegment';
import {buildLineHorizontal, buildLineVertical, lineCenter, lineIsHorizontal, lineIsVertical} from 'libs/models/line';
import {wallSizeConfig} from 'app/config/wallSize';
import {PositionsMinMax} from 'libs/models/position';
import {cmToThree, movePositionXByCM, movePositionZByCM, PositionThree} from 'libs/view/position';
import {Direction} from 'libs/models/types';
import {doIntersect} from 'libs/models/room';

export const needToChangeOffset = (wallGiven: WallSegmentModel, walls: WallSegmentModel[], direction: string): boolean => {
    const line = mapLineFromWallSegment(wallGiven);


    if(lineIsHorizontal(line)) {
        return walls.some((wall) => {
            if (wallGiven.index !== wall.index) {
                const lineFromWall = mapLineFromWallSegment(wall);
                const turnedLineCenter = buildLineVertical(lineCenter(line), direction === 'top' ? wallSizeConfig.max * -1 : wallSizeConfig.max);

                return doIntersect(turnedLineCenter, lineFromWall);
            }
            return false;
        })
    }

    if(lineIsVertical(line)) {
        return walls.some((wall) => {
            if (wallGiven.index !== wall.index) {
                const lineFromWall = mapLineFromWallSegment(wall);
                const turnedLineCenter = buildLineHorizontal(lineCenter(line), direction === 'left' ? wallSizeConfig.max * -1 : wallSizeConfig.max);

                return doIntersect(turnedLineCenter, lineFromWall);
            }
            return false;
        })
    }
    return true;
}

export type CalculateWallOutlineWithOffsetProps = {
    wall: WallSegmentModel,
    direction: string,
    offset: number,
    minMaxValues: PositionsMinMax,
}

export const calculateWallOutlineWithOffset = (
    {
        wall,
        direction,
        offset,
        minMaxValues,
    }: CalculateWallOutlineWithOffsetProps): PositionThree[] => {



    if (direction === 'top') {
        return calculateOffset([[wall.from[0], 0, cmToThree(minMaxValues.minY)], [wall.to[0], 0, cmToThree(minMaxValues.minY)]], direction, offset)
    }

    if (direction === 'right') {
        return calculateOffset([[cmToThree(minMaxValues.maxX), 0, wall.from[2]], [cmToThree(minMaxValues.maxX), 0, wall.to[2]]], direction, offset)
    }

    if (direction === 'bottom') {
        return calculateOffset([[wall.from[0], 0, cmToThree(minMaxValues.maxY)], [wall.to[0], 0, cmToThree(minMaxValues.maxY)]], direction, offset)
    }

    if (direction === 'left') {
        return calculateOffset([[cmToThree(minMaxValues.minX), 0, wall.from[2]], [cmToThree(minMaxValues.minX), 0, wall.to[2]]], direction, offset)
    }

    return [];
}

export function calculateOffset(positions: PositionThree[], direction: Direction, offsetCM: number): PositionThree[] {
    if (direction === 'top') {
        return positions.map((pos) => movePositionZByCM(pos, (offsetCM) * -1));
    }
    if (direction === 'right') {
        return positions.map((pos) => movePositionXByCM(pos, (offsetCM)));
    }
    if (direction === 'bottom') {
        return positions.map((pos) => movePositionZByCM(pos, (offsetCM)));
    }
    return positions.map((pos) => movePositionXByCM(pos, (offsetCM) * -1));
}