import {Knot, KnotId} from 'libs/models/knot/knot';
import {Direction} from 'libs/models/types';


/**
 *  Sucht einen Knoten anhand der Knoten-ID.
 */
export function findKnotById(knots: Knot[], id: KnotId): Knot | null {
    if (knots.length === 0) {
        return null;
    }
    return knots.find((knot) => knot.id === id) || null;
}

/**
 * Findet die Richtung in der zwei Knoten verbunden sind.
 */
export function findKnotConnectionDirection(knot: Knot, connectedKnotId: number): Direction | null {
    if (connectedKnotId < 0) {
        return null;
    }
    if (knot.connections.top === connectedKnotId) {
        return 'top';
    }
    if (knot.connections.right === connectedKnotId) {
        return 'right';
    }
    if (knot.connections.bottom === connectedKnotId) {
        return 'bottom';
    }
    if (knot.connections.left === connectedKnotId) {
        return 'left';
    }
    return null;
}
