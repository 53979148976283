import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Position} from 'libs/models/position';
import {deleteEntity, loadEntity, saveEntity} from 'state/entity/entityApi';
import {stepResetPageOutlet} from 'state/step/stepReset';

export interface OutletState {
    position: Position | null;
}

export const initialOutletState: OutletState = {
    position: null,
}

const outletSlice = createSlice({
    name: 'outlet',
    initialState: initialOutletState,
    reducers: {
        setOutletPosition: (state, {payload}: PayloadAction<Position | null>) => {
            if (payload && state.position !== payload) {
                state.position = payload;
            }
        },
        deleteOutletPosition: (state) => {
            if (state.position !== null) {
                state.position = null;
            }
        }
    },
    extraReducers: (builder) => {

        // >>> Reset
        builder.addCase(stepResetPageOutlet.fulfilled, (state) => {
            state.position = null;
        });

        // >>> Load
        builder.addCase(loadEntity.fulfilled, (state, {payload}) => {
            state.position = payload.data.outlet.position;
        });
        builder.addCase(loadEntity.rejected, () => {
            return initialOutletState;
        });

        // >>> Save
        builder.addCase(saveEntity.fulfilled, (state, {payload}) => {
            state.position = payload.data.outlet.position;
        });
        builder.addCase(saveEntity.rejected, () => {
            return initialOutletState;
        });

        // >>> Delete
        builder.addCase(deleteEntity.fulfilled, () => {
            return initialOutletState;
        });
        builder.addCase(deleteEntity.rejected, () => {
            return initialOutletState;
        });

    },
});


export const {setOutletPosition, deleteOutletPosition} = outletSlice.actions;
export const outletReducer = outletSlice.reducer;
