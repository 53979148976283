import {shopBuildPublicUrl} from 'app/config/shopClient';
import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {saveAction} from 'components/common/actions/styles/styles';
import {Button} from 'components/common/buttons/Button';
import {generatePDF} from 'libs/models/order/pdf/generatePDF';
import React from 'react';
import {selectEntityId} from 'state/entity/entitySelectors';
import {selectSpotlightAmount} from 'state/light/lightSelectors';
import {uploadOrderPDF} from 'state/shop/shopApi';
import {selectTrackKnots} from 'state/track/trackSelectors';
import {updateLoadingState} from 'state/view/viewReducer';
import {tw} from 'twind/css';

export const SaveOrderAction = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const entityId = useAppSelector(selectEntityId);
    const knots = useAppSelector(selectTrackKnots);
    const spotlightAmount = useAppSelector(selectSpotlightAmount);

    const uploadPdfAndRedirect = async (blobString: string | null) => {
        if (blobString && entityId) {
            await dispatch(uploadOrderPDF({entityId: entityId, pdfBlob: blobString}));
            dispatch(updateLoadingState(false));
            window.location.href = shopBuildPublicUrl('/bitit-api/cart/' + entityId);
        }
    };

    const generatePDFBlob = (): void => {
        const canvasDiv = document.getElementById('orderCanvas');
        if (canvasDiv && entityId) {
            dispatch(updateLoadingState(true));
            const canvas = canvasDiv.getElementsByTagName('canvas').item(0);
            generatePDF({
                setPDF: uploadPdfAndRedirect,
                entityId,
                canvas,
                spotlightAmount,
                knots,
            });
        }
    };

    const handlePdf = async () => {
        generatePDFBlob();
    };

    return (
        <div className={tw(saveAction)}>
            <Button onClick={handlePdf} variant={'primary'}>Zum Warenkorb</Button>
        </div>
    );
};
