import {ReactNotificationOptions, store} from 'react-notifications-component';

export type NotificationsContext = {
    addNotifyItem: (notifyItem: NotifyItem) => void;
}

export type NotifyItem = {
    id: string;
    title: string;
    message: string;
    type: 'success' | 'danger' | 'info' | 'default' | 'warning' | undefined;
}

export const NotificationProvider = (): NotificationsContext => {
    const notifyItems: ReactNotificationOptions[] = [];

    const NotifyItemInit: ReactNotificationOptions = {
        container: 'top-left',
        dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true
        },
        onRemoval: (id) => {
            removeNotifyItem(id);
        }
    };

    const addNotifyItem = (notifyItem: NotifyItem) => {
        const item = notifyItems.find((item) => item.id === notifyItem.id);
        if (!item) {
            const mergedItem = {...NotifyItemInit, ...notifyItem};
            notifyItems.push(mergedItem);
            store.addNotification(mergedItem);
        }
    };

    const removeNotifyItem = (notifyItemId: string) => {
        const itemIndex = notifyItems.findIndex((item) => item.id === notifyItemId);
        if (itemIndex >= 0) {
            notifyItems.splice(itemIndex, 1);
        }
    };

    return {
        addNotifyItem
    }
};
