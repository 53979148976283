import {Position} from 'libs/models/position';
import {IndexedLine, Lines} from './line';


/**
 * Erzeugt eine Liste von Linien aus einer Liste von Positionen.
 *
 * Es müssen mindestens 2 Positionen in der Liste sein, sonst gibts keine Linien.
 * Die letzte Linie wird von der letzten Position zurück zur ersten gezogen.
 *
 * Es gibt hier keine Validierung.
 *
 * @param positions
 */
export function mapLinesIndexedFromPositions(positions: Position[]): IndexedLine[] {
    const lastIndex = positions.length - 1;
    if (lastIndex <= 0) {
        return [];
    }
    return positions.map(mapLineIndexedFromPosition);
}

/**
 * Mapping für eine Position, kann in Array.map() benutzt werden.
 *
 * @param current
 * @param currentIndex
 * @param positions
 */
export function mapLineIndexedFromPosition(current: Position, currentIndex: number, positions: Position[]): IndexedLine {
    const lastIndex = positions.length - 1;
    const nextIndex = (currentIndex + 1) <= lastIndex ? (currentIndex + 1) : 0;

    return {
        from: {
            ...current,
            id: currentIndex
        },
        to: {
            ...positions[nextIndex],
            id: nextIndex
        }
    };
}

/**
 * Erzeugt eine Liste mit Positionen aus einer Liste von Linien.
 *
 * @param lines
 */
export function mapLinesToPositions<T extends Position>(lines: Lines<T>): Position[] {
    return lines.map((current) => {
        return {
            x: current.from.x,
            y: current.from.y
        }
    });
}
