import {Position} from 'libs/models/position';
import * as THREE from 'three';

export type PositionThree = [x: number, y: number, z: number];

export const FROM_CM = 0.01;
export const FROM_THREE = 100;

export function positionThreeFromPointThree(position: Position, z = 0): PositionThree {
    return [
        (position.x),
        z,
        (position.y)
    ]
}

export function positionThreeFromPositionCM(position: Position, z = 0): PositionThree {
    return [
        (FROM_CM * position.x),
        z,
        (FROM_CM * position.y)
    ]
}

export function positionCMFromThree(position: Position, z = 0): PositionThree {
    return [
        (FROM_THREE * position.x),
        z,
        (FROM_THREE * position.y)
    ]
}

export function pointThreeFromPointCM(position: Position): Position {
    return {
        x: (FROM_CM * position.x),
        y: (FROM_CM * position.y),
    }
}

export function pointCMFromPointThree(position: Position): Position {
    return {
        x: Math.trunc(FROM_THREE * position.x),
        y: Math.trunc(FROM_THREE * position.y),
    }
}

export function pointCMFromPositionThree(position: PositionThree): Position {
    return {
        x: Math.trunc(FROM_THREE * position[0]),
        y: Math.trunc(FROM_THREE * position[2]),
    }
}

export function cmToThree(length: number): number {
    return (FROM_CM * length);
}

export function threeToCM(length: number): number {
    return Math.trunc(FROM_THREE * length);
}


export function movePositionYByCM(position: PositionThree, moveCM: number): PositionThree {
    return [
        position[0],
        (FROM_CM * moveCM) + position[1],
        position[2],
    ];
}

export function movePositionXByCM(position: PositionThree, moveCM: number): PositionThree {
    return [
        (FROM_CM * moveCM) + position[0],
        position[1],
        position[2],
    ];
}

export function movePositionZByCM(position: PositionThree, moveCM: number): PositionThree {
    return [
        position[0],
        position[1],
        (FROM_CM * moveCM) + position[2],
    ];
}

export const rotationHorizontal: PositionThree = [THREE.MathUtils.degToRad(-90), 0, 0];

export const rotationVertical: PositionThree = [THREE.MathUtils.degToRad(-90), 0, THREE.MathUtils.degToRad(-90)];
